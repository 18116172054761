import { createSelector } from '@ngrx/store';

import { getNotificationsState } from '../reducers';
import * as fromReducer from './notifications.reducer';

export const getRegisterDeviceActionState = createSelector(
  getNotificationsState,
  fromReducer.getRegisterDeviceActionState
);

export const getSuccessfulTokenRegistration = createSelector(
  getNotificationsState,
  fromReducer.getSuccessfulTokenRegistration
);

export const getUnregisterDeviceActionState = createSelector(
  getNotificationsState,
  fromReducer.getUnregisterDeviceActionState
);
