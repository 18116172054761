import { Directive, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromTicketState from '../../../+state/tickets';
import {
  ITicketCreationSummary,
  ITicketImage,
  ScoreQuestionType,
  TicketIssueType,
} from '../../../models';
import { IActionState } from '../../../utils';

@Directive()
export class TicketQuestionsBaseComponent implements OnInit {
  @Input() issueType: TicketIssueType;
  @Input() ticketCreationSummary: ITicketCreationSummary;
  @Input() step: { index: number };
  @Input() questionsForm: FormGroup;
  public ticketQuestionsActionsState$: Observable<IActionState>;
  public ScoreQuestionType = ScoreQuestionType;

  constructor(private ticketStore: Store<fromTicketState.ITicketsState>) {}

  ngOnInit() {
    if (this.issueType === TicketIssueType.PROPERTY_DAMAGE) {
      this.ticketQuestionsActionsState$ = this.ticketStore.select(
        fromTicketState.getDamageQuestionsActionState
      );
    } else if (this.issueType === TicketIssueType.CONCERN) {
      this.ticketQuestionsActionsState$ = this.ticketStore.select(
        fromTicketState.getConcernQuestionsActionState
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public selectAnswer(item: any, answer: any): void {
    this.questionsForm.controls[item.id].patchValue(answer.key);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public addImages(item: any, images: ITicketImage[]): void {
    this.questionsForm.controls[item.id].patchValue(images);
  }
}
