export enum WhitelabelImage {
  GENERAL_SUCCESS = 'general-success',
  WAITING = 'waiting',
  REGISTRATION_SUCCESS = 'registration-success',
  ERROR = 'error',
  PASSWORD_RESET = 'pw-reset',
  SEARCHING = 'searching',
  MAIL = 'mail',
  ORDER_PLACED = 'order-placed',
  UNAUTHORIZED = 'guard-dog',
  ADDRESS_CHANGE = 'address-change',
}

export enum WhitelabelImageType {
  PNG = 'png',
  SVG = 'svg',
}
