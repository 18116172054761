import { Inject, Injectable, Optional } from '@angular/core';
import { TranslateDefaultParser } from '@ngx-translate/core';

@Injectable()
export class TranslateParserService extends TranslateDefaultParser {
  overwrites: { [key: string]: string } = {};

  constructor(
    @Inject('TRANSLATION_KEY') @Optional() public prefix = 'resident_shared',
    @Inject('CUSTOM_TRANSLATIONS') @Optional() overwrites
  ) {
    super();
    if (overwrites && Array.isArray(overwrites)) {
      this.overwrites = {};
      for (let i = 0; i < overwrites.length; i++) {
        const overwrite = overwrites[i];
        this.overwrites[overwrite.key] = overwrite.value;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  override getValue(target: any, key: string) {
    // needed to use the placeholder function from phrase. you can use {{customValeName}} e.g. in the translation.
    // without that it automatically adds the prefix to the value we want to replace
    if (!key.includes('customValue')) {
      key = this.parseKey(key);
    }
    return super.getValue(target, key);
  }

  parseKey(key: string) {
    const overwrite = this.overwrites[key];
    let query;
    if (overwrite) {
      query = overwrite;
    } else {
      query = `${this.prefix}.${key}`;
    }
    return query;
  }
}
