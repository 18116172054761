import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonTypeEnum } from '@resident-nx/shared';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonWebComponent } from '../../../atoms/button/button.component';

@Component({
  selector: 'rs-web-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonWebComponent, TranslateModule],
})
export class ConfirmationDialogComponent {
  @Input() message: string;
  @Input() acknowledge = false;
  @Input() innerHTML = false;
  @Input() titleMessage: string;
  @Input() okButtonMessage = 'general.accept';
  @Input() cancelButtonMessage = 'general.cancel';
  @Input() titleValue = null;
  @Input() messageValue = null;

  public buttonTypes = ButtonTypeEnum;
  constructor(private ngbActiveModal: NgbActiveModal) {}

  okAction() {
    this.ngbActiveModal.close();
  }

  cancelAction() {
    this.ngbActiveModal.dismiss();
  }
}
