import { Directive, Input, OnInit } from '@angular/core';
import { ITicketResidentOverview } from '../../../models';

// libs

@Directive()
export abstract class TicketCardBaseComponent implements OnInit {
  @Input() ticket: ITicketResidentOverview;
  public displayDate: number;
  @Input() fixedWidth: number;

  ngOnInit() {
    if (this.ticket?.created?.date) {
      this.displayDate = new Date(this.ticket.created.date).getTime();
    }
  }
}
