import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'rs-web-form-field',
  standalone: true,
  templateUrl: './form-field.component.html',
  styleUrl: './form-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldComponent {}
