import { ContractStatus, IContract } from '../../../models';

export const contractStatusConverter = {
  isContractActive: (contract: IContract): boolean => {
    return contract.status === ContractStatus.ACTIVE || contract.status === ContractStatus.ENDING;
  },
  isAnyContractActive: (contracts: IContract[]): boolean => {
    return contracts.some(contract => contractStatusConverter.isContractActive(contract));
  },
  isAnyContractEnded: (contracts: IContract[]): boolean => {
    return contracts.some(contract => contract.status === ContractStatus.ENDED);
  },
  allContractsExpired: (contracts: IContract[]): boolean => {
    return contracts.every(contract => contract.status === ContractStatus.EXPIRED);
  },
};
