import { createSelector } from '@ngrx/store';

import { getAppSettingsState } from '../../reducers';
import * as fromReducer from './app-settings-reducer';

export const getAppSettings = createSelector(getAppSettingsState, fromReducer.getAppSettings);
export const getTheme = createSelector(getAppSettingsState, fromReducer.getTheme);
export const getLogo = createSelector(getAppSettingsState, fromReducer.getLogo);
export const getAppSettingsActionState = createSelector(
  getAppSettingsState,
  fromReducer.getAppSettingsActionState
);
