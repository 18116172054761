export enum PaymentRequestStatus {
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  PENDING_PAY_OUT = 'PENDING_PAY_OUT',
  PAID_OUT = 'PAID_OUT',
  CHECK_MANUALLY = 'CHECK_MANUALLY',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
}

export enum PaymentRequestCategory {
  DEPOSIT = 'DEPOSIT',
  ENTRY_FEE = 'ENTRY_FEE',
  OTHER = 'OTHER',
  OTHER_RESIDENT = 'OTHER_RESIDENT',
}

export interface IPaymentResponse {
  paymentPositionResidentBeans: IPaymentPositionResident[];
}

export interface IPaymentPositionResident {
  id: number;
  subject: string;
  amount: number;
  dueDate: string;
  lastReminderDate: string;
  status: PaymentRequestStatus;
  category: PaymentRequestCategory;
  paymentLandingPageUrl: string;
}
