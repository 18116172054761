import { Pipe, PipeTransform } from '@angular/core';
import { IPaymentPositionResident, PaymentRequestStatus } from '../models';

@Pipe({
  name: 'paymentDueStatus',
  standalone: true,
})
export class PaymentDueStatusPipe implements PipeTransform {
  transform(value: IPaymentPositionResident): string | null {
    if (!value) {
      return '';
    }
    const d = new Date(parseInt(value.dueDate));
    switch (value.status) {
      case PaymentRequestStatus.OPEN:
      case PaymentRequestStatus.OVERDUE:
      case PaymentRequestStatus.PAID:
      case PaymentRequestStatus.PAID_OUT:
      case PaymentRequestStatus.REFUNDED:
      case PaymentRequestStatus.ERROR:
        return d.getDate() + '.' + d.getMonth() + '.' + d.getFullYear();
      case PaymentRequestStatus.PENDING:
      case PaymentRequestStatus.PENDING_PAY_OUT:
      case PaymentRequestStatus.CANCELED:
      case PaymentRequestStatus.CHECK_MANUALLY:
        return '';
      default:
        return '';
    }
  }
}
