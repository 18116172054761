import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { getTicketDetails, getTicketDetailsActionState, LoadTicketDetails } from '../../+state';
import { IHQTicketDetailsResponse } from '../../models';
import { ResolverCommonService } from './resolver-common.service';

export const TicketDetailsResolver: ResolveFn<IHQTicketDetailsResponse> = route => {
  const common = inject(ResolverCommonService);

  const { resolverWaitForData } = route.data;
  return common.resolveHandler<IHQTicketDetailsResponse>(
    getTicketDetailsActionState,
    LoadTicketDetails({ ticketId: route.params.id, ticketIssueType: route.params.issueType }),
    getTicketDetails,
    resolverWaitForData
  );
};
