import { Directive, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Directive()
export class StarRatingBaseComponent implements OnChanges {
  @Input() className: string;
  @Input() rating: number;
  @Input() maxRating: number;
  @Input() filledStar: string;
  @Input() emptyStar: string;
  @Output() ratingChange = new EventEmitter<number>();
  public stars: string[];

  ngOnChanges(): void {
    this.stars = Array(this.maxRating).fill(this.emptyStar);
    for (let i = 0; i < this.rating; i++) {
      this.stars[i] = this.filledStar;
    }
  }

  onStarTap(rating: number): void {
    this.rating = rating;
    this.ratingChange.emit(this.rating);
  }
}
