import { createAction, props } from '@ngrx/store';
import { IFederatedLoginResponse } from '../../../models';

export const LoadFederatedLoginData = createAction(
  '[Login] Load LoginData',
  props<{ email: string; nonce: string; state: string; redirectUri: string; isWeb?: boolean }>()
);

export const LoadFederatedLoginDataSuccess = createAction(
  '[Login] Load LoginData Success',
  props<{ federatedLoginData: IFederatedLoginResponse }>()
);
export const LoadFederatedLoginDataFailed = createAction(
  '[Login] Load LoginData Failed',
  props<{ error: Error }>()
);
