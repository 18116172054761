import { CategoryAnswerSetRelationId, FormResponseQuestionType, TicketIssueType } from '.';
import { ContractId } from '../masterdata';

export type Base64EncodedString = string;

export interface ITicketMetadataCreate {
  formResponses: IFormResponseCreate[];
  issueType: TicketIssueType;
  contractNumber: ContractId;
  categoryAnswerSetRelationId: CategoryAnswerSetRelationId;
}

export interface IFormResponseCreate {
  index: number;
  questionText: string;
  responses: string[];
  type: FormResponseQuestionType;
  attachments: IFormResponseAttachment[];
}

export interface IFormResponseAttachment {
  name: string;
  extension: string;
  content: Base64EncodedString;
}
