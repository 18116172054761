import { gql } from 'apollo-angular';
import { addressFields } from '../common';

export const sepaMandateFields = `
  sepaMandate {
    iban
    bic
    issuedAt
    issuedUntil
  }
`;

export const administrationUnitFields = `
  administrationUnit {
    id
    name
    parentAdministrationUnit {
      id
      name
    }
  }
`;

export const propertyFields = `
  property {
    externalId
    id
    ${administrationUnitFields}
    name
    ${addressFields}
    floor
    rooms
  }
`;

export const paymentsFields = `
  payments {
    type
    cost
  }
`;

export const responsibilitesFields = `
  responsibilities {
    id
    name
  }
`;

export const agentsFields = `
  agents {
    firstName
    name
    phone
    ${addressFields}
    emergencyPhone
    email
    ${responsibilitesFields}
  }
`;

export const conditionsFields = `
  conditions {
    type
    value
  }
`;

export const accountBalanceFields = `
  accountBalance {
    amount
    infoText
    latestUpdate
  }
`;

export const contractsFields = `
  contracts {
    externalId
    id
    type
    ${sepaMandateFields}
    ${addressFields}
    contractStart
    contractEnd
    status
    ${propertyFields}
    ${paymentsFields}
    ${agentsFields}
    ${conditionsFields}
    ${accountBalanceFields}
    movingAddressSent
  }
`;

export const getContractsQuery = gql`
  query getContracts {
    getContracts {
      ${contractsFields}
    }
  }
`;

export const documentsFields = `
  documents{
    title
    name
    type
    identifier
    extension
    encrypted
    url
    residentFileType
    location
  }
`;

export const getContractDocumentsList = gql`
  query getContractDocumentsList($contractId: String!) {
    getContractDocumentsList(contractId: $contractId) {
      ${documentsFields}
    }
  }
`;

export const setMovingAddressSent = gql`
  mutation setMovingAddressSent($contractId: String!, $movingAddressSent: Boolean!) {
    setMovingAddressSent(contractId: $contractId, movingAddressSent: $movingAddressSent)
  }
`;
