import { createSelector } from '@ngrx/store';

import { getConsumptionState } from '../reducers';
import * as fromReducer from './consumption.reducer';

export const getCurrentMonthConsumption = createSelector(
  getConsumptionState,
  fromReducer.getCurrentMonthConsumption
);
export const getCurrentMonthConsumptionActionState = createSelector(
  getConsumptionState,
  fromReducer.getCurrentMonthConsumptionActionState
);

export const getSelectedMeters = createSelector(getConsumptionState, fromReducer.getSelectedMeters);

export const getFirstSelectedMeter = createSelector(
  getSelectedMeters,
  fromReducer.getFirstSelectedMeter
);

export const getSelectedConsumptions = createSelector(
  getConsumptionState,
  fromReducer.getSelectedConsumptions
);

export const getSelectedConsumptionForMeter = (deviceId: string) =>
  createSelector(getSelectedConsumptions, selectedConsumptions => selectedConsumptions[deviceId]);

export const getSelectedConsumptionForFirstMeter = createSelector(
  getSelectedConsumptions,
  getFirstSelectedMeter,
  fromReducer.getSelectedConsumptionForFirstMeter
);

export const getSelectedPeriodConsumptions = createSelector(
  getConsumptionState,
  fromReducer.getSelectedPeriodConsumptions
);

export const getSelectedPeriodConsumptionForMeter = (deviceId: string) =>
  createSelector(getSelectedPeriodConsumptions, consumptions => consumptions[deviceId]);

export const getSelectedPeriodConsumptionForFirstMeter = createSelector(
  getSelectedPeriodConsumptions,
  getFirstSelectedMeter,
  fromReducer.getSelectedPeriodConsumptionForFirstMeter
);

export const getSelectedPeriodPastConsumptions = createSelector(
  getConsumptionState,
  fromReducer.getSelectedPeriodPastConsumptions
);

export const getSelectedPeriodPastConsumptionForMeter = (deviceId: string) =>
  createSelector(getSelectedPeriodPastConsumptions, consumptions => consumptions[deviceId]);

export const getSelectedPeriodPastConsumptionForFirstMeter = createSelector(
  getSelectedPeriodPastConsumptions,
  getFirstSelectedMeter,
  fromReducer.getSelectedPeriodPastConsumptionForFirstMeter
);

export const getSelectedPeriodConsumptionBenchmarks = createSelector(
  getConsumptionState,
  fromReducer.getSelectedPeriodConsumptionBenchmarks
);

export const getSelectedPeriodConsumptionBenchmarkForFirstMeter = createSelector(
  getSelectedPeriodConsumptionBenchmarks,
  getFirstSelectedMeter,
  fromReducer.getSelectedPeriodConsumptionBenchmarkForFirstMeter
);

export const getSelectedPeriodConsumptionActionStates = createSelector(
  getConsumptionState,
  fromReducer.getSelectedPeriodConsumptionActionStates
);

export const getSelectedPeriodConsumptionActionStateForFirstMeter = createSelector(
  getSelectedPeriodConsumptionActionStates,
  getFirstSelectedMeter,
  fromReducer.getSelectedPeriodConsumptionActionStateForFirstMeter
);

export const getSelectedPeriodPastConsumptionActionStates = createSelector(
  getConsumptionState,
  fromReducer.getSelectedPeriodPastConsumptionActionStates
);

export const getSelectedPeriodPastConsumptionActionStateForFirstMeter = createSelector(
  getSelectedPeriodPastConsumptionActionStates,
  getFirstSelectedMeter,
  fromReducer.getSelectedPeriodPastConsumptionActionStateForFirstMeter
);

export const getSelectedPeriodConsumptionBenchmarkActionStates = createSelector(
  getConsumptionState,
  fromReducer.getSelectedPeriodConsumptionBenchmarkActionStates
);

export const getSelectedPeriodConsumptionBenchmarkActionStateForFirstMeter = createSelector(
  getSelectedPeriodConsumptionBenchmarkActionStates,
  getFirstSelectedMeter,
  fromReducer.getSelectedPeriodConsumptionBenchmarkActionStateForFirstMeter
);

export const getShowPreviousArrowForFirstMeter = createSelector(
  getConsumptionState,
  getFirstSelectedMeter,
  fromReducer.getShowPreviousArrowForFirstMeter
);
export const getShowNextArrowForFirstMeter = createSelector(
  getConsumptionState,
  getFirstSelectedMeter,
  fromReducer.getShowNextArrowForFirstMeter
);

export const getMetersByContract = createSelector(
  getConsumptionState,
  fromReducer.getMetersByContract
);

export const getMetersByContractActionState = createSelector(
  getConsumptionState,
  fromReducer.getMetersByContractActionState
);

export const getAvailableMeters = createSelector(
  getConsumptionState,
  fromReducer.getAvailableMeters
);

export const getSavedConsumptionData = createSelector(
  getConsumptionState,
  fromReducer.getSavedConsumptionData
);

export const getSelectedPeriodsForCharts = createSelector(
  getConsumptionState,
  fromReducer.getSelectedPeriodsForCharts
);

export const getSelectedPeriodForChartForMeter = (deviceId: string) =>
  createSelector(getSelectedPeriodsForCharts, periodsForCharts => periodsForCharts[deviceId]);

export const getSelectedPeriodForChartForFirstMeter = createSelector(
  getConsumptionState,
  getFirstSelectedMeter,
  fromReducer.getSelectedPeriodForChartForFirstMeter
);

export const getSelectedPeriodsForRequests = createSelector(
  getConsumptionState,
  fromReducer.getSelectedPeriodsForRequests
);

export const getSelectedPeriodForRequestForMeter = (deviceId: string) =>
  createSelector(getSelectedPeriodsForRequests, periodsForRequests => periodsForRequests[deviceId]);

export const getSelectedPeriodForRequestForFirstMeter = createSelector(
  getConsumptionState,
  getFirstSelectedMeter,
  fromReducer.getSelectedPeriodForRequestForFirstMeter
);

export const getSavedDataForContractDeviceAndPeriodForFirstMeter = createSelector(
  getConsumptionState,
  getFirstSelectedMeter,
  fromReducer.getSavedDataForContractDeviceAndPeriodForFirstMeter
);

export const getChartMonthsForFirstMeter = createSelector(
  getConsumptionState,
  getFirstSelectedMeter,
  fromReducer.getChartMonthsForFirstMeter
);

export const getDashboardDataForContractDeviceAndPeriodForFirstMeter = createSelector(
  getConsumptionState,
  getFirstSelectedMeter,
  fromReducer.getDashboardDataForContractDeviceAndPeriodForFirstMeter
);

export const getDashboardChartMonthsForFirstMeter = createSelector(
  getConsumptionState,
  getFirstSelectedMeter,
  fromReducer.getDashboardChartMonthsForFirstMeter
);

export const getSelectedTimeframe = createSelector(
  getConsumptionState,
  fromReducer.getSelectedTimeframe
);
