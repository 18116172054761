import {
  addMonths,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subMonths,
} from 'date-fns';

export type Seconds = number;
export type Milliseconds = number;
const DAY_IN_MILLISECONDS = 86400000;

// TODO use toUtcTimeZone only on api requests and to convert it with fromUtcToLocalTimeZone into app
export const toUtcTimeZone = function (date: Milliseconds): Milliseconds {
  return date - new Date(date).getTimezoneOffset() * 60000;
};
export const fromUtcToLocalTimeZone = function (date: Milliseconds): Milliseconds {
  return date + new Date(date).getTimezoneOffset() * 60000;
};

export const secondsToMilliseconds = function (date: Seconds): Milliseconds {
  return date * 1000;
};

export const millisecondsToSeconds = function (date: Milliseconds): Seconds {
  return Math.floor(date / 1000);
};

export const startOfQuarterMilliseconds = function (date: Milliseconds): Milliseconds {
  return startOfQuarter(date).getTime();
};

export const endOfQuarterMilliseconds = function (date: Milliseconds): Milliseconds {
  return endOfQuarter(date).getTime();
};

export const oneMonthBeforeMilliseconds = function (date: Milliseconds): Milliseconds {
  return subMonths(date, 1).getTime();
};

export const addMonth = function (date: Milliseconds): Milliseconds {
  return addMonths(date, 1).getTime();
};

export const addDays = function (date: Milliseconds, numberOfDays: number): Milliseconds {
  return date + numberOfDays * DAY_IN_MILLISECONDS;
};

export const subDays = function (date: Milliseconds, numberOfDays: number): Milliseconds {
  return date - numberOfDays * DAY_IN_MILLISECONDS;
};

export const startOfDayUtc = function (date: Milliseconds): Milliseconds {
  return startOfDay(date).getTime();
};

export const endOfDayUtc = function (date: Milliseconds): Milliseconds {
  return endOfDay(date).getTime();
};

export const startOfYearMilliseconds = function (date: Milliseconds): Milliseconds {
  return startOfYear(date).getTime();
};

export const endOfYearMilliseconds = function (date: Milliseconds): Milliseconds {
  return endOfYear(date).getTime();
};

export const endOfMonthMilliseconds = function (date: Milliseconds): Milliseconds {
  return endOfMonth(date).getTime();
};

export const startOfMonthMilliseconds = function (date: Milliseconds): Milliseconds {
  return startOfMonth(date).getTime();
};

export const getMonthName = (month: number): string => {
  switch (month) {
    case 0:
      return 'consumption_detail.month_january_l';
    case 1:
      return 'consumption_detail.month_february_l';
    case 2:
      return 'consumption_detail.month_march_l';
    case 3:
      return 'consumption_detail.month_april_l';
    case 4:
      return 'consumption_detail.month_may_l';
    case 5:
      return 'consumption_detail.month_june_l';
    case 6:
      return 'consumption_detail.month_july_l';
    case 7:
      return 'consumption_detail.month_august_l';
    case 8:
      return 'consumption_detail.month_september_l';
    case 9:
      return 'consumption_detail.month_october_l';
    case 10:
      return 'consumption_detail.month_november_l';
    case 11:
      return 'consumption_detail.month_december_l';
    default:
      return '';
  }
};

export const dateStringToMilliseconds = (date: string): Milliseconds => {
  return new Date(date).getTime();
};
