import { gql } from 'apollo-angular';

export const emailSettingsQuery = gql`
  query getEmailNotificationSettings {
    getEmailNotificationSettings {
      allowedNotifications
    }
  }
`;

export const emailSettingsMutation = gql`
  mutation updateEmailNotificationSettings($input: EmailNotificationSettingsEditBean!) {
    updateEmailNotificationSettings(input: $input) {
      allowedNotifications
    }
  }
`;
