import { Directive, EventEmitter, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as fromAnnouncementsState from '../../+state/news/announcements';
import { WidgetBaseComponent } from './widget.base-component';
import { LoadingType } from '../../models';

@UntilDestroy()
@Directive()
export abstract class AnnouncementsWidgetBaseComponent extends WidgetBaseComponent {
  @Output() tapped = new EventEmitter();
  announcements$ = this.announcementsStore
    .select(fromAnnouncementsState.getLatestAnnouncements)
    .pipe(untilDestroyed(this));
  announcementsActionState$ = this.announcementsStore
    .select(fromAnnouncementsState.getAnnouncementsActionState)
    .pipe(untilDestroyed(this));
  public loadingTypes: LoadingType[] = [];

  constructor(protected announcementsStore: Store<fromAnnouncementsState.IAnnouncementsState>) {
    super();
  }
}
