<div class="d-flex flex-row justify-content-between">
  <div class="d-flex flex-row gap-2 align-items-start">
    <div class="pt-1">
      <rs-web-icon [size]="20" [icon]="meter.type | consumptionIcon"></rs-web-icon>
    </div>

    <div class="d-flex flex-column">
      <span class="title-m primary-text">
        {{ meter.type | consumptionName: NameDisplayMode.LONG | translate }}
      </span>

      <span class="title-xs">ZN {{ meter.deviceId }}</span>
    </div>
  </div>

  @if (selectedConsumption$ | async; as selectedConsumption) {
    <div class="d-flex flex-column">
      <div class="text-end title-m">
        {{ selectedConsumption?.consumption?.amount | number: '1.0-0' }}
        {{ meter.type | consumptionUnitTypePipe }}
      </div>
      <div class="text-end title-xs date">
        {{ selectedConsumption?.consumption?.period?.start | date: 'MMMM yyyy' }}
      </div>
    </div>
  }
</div>
