import { Directive, Input } from '@angular/core';
import { ImmomioIconName, NotificationAccordionTypes } from '../../models';

@Directive()
export class NotificationAccordionBaseComponent {
  @Input() type: NotificationAccordionTypes;
  @Input() isOpenKey: string;
  @Input() title: string;
  @Input() description: string;
  public immomioIconName = ImmomioIconName;
  @Input() noContract: boolean;
  public open = false;

  toggleAccordion() {
    this.open = !this.open;
  }
}
