import { Injectable } from '@angular/core';

import { AuthTokenService, IAuthToken } from '@resident-nx/shared';
import { LocalStorageService } from '../storage';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenWebService extends AuthTokenService {
  constructor(private storageService: LocalStorageService) {
    super();
  }

  public setToken(token: IAuthToken): boolean {
    return this.storageService.setItem('authToken', token);
  }

  public getToken(): IAuthToken {
    const token = this.storageService.getItem('authToken') as IAuthToken;
    return token;
  }

  public removeToken(): boolean {
    return this.storageService.removeItem('authToken');
  }
}
