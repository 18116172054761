import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoaderBaseComponent } from '@resident-nx/shared';

@Component({
  selector: 'rs-web-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderWebComponent extends LoaderBaseComponent {
  @Input() centered = false;
  @Input() biggerSpinner = false;
}
