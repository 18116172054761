import { IActionState } from './action-state.model';

export class ActionStateCreator {
  public static isInitialState(state: IActionState): boolean {
    const { pending, done, error } = state;
    return !pending && !done && !error;
  }

  public static create() {
    return {
      done: false,
      pending: false,
      error: null,
      doneTime: null, // be aware to change this -> effects the resolvers
    } as IActionState;
  }

  public static onStart() {
    return {
      done: false,
      pending: true,
      error: null,
      doneTime: Date.now(),
    } as IActionState;
  }

  public static onSuccess() {
    return {
      done: true,
      pending: false,
      error: null,
      doneTime: Date.now(),
    } as IActionState;
  }

  public static onError(error: Error) {
    return {
      done: false,
      pending: false,
      error: error ? error : true,
      doneTime: Date.now(),
    } as IActionState;
  }

  public static reset() {
    return {
      done: false,
      pending: false,
      error: null,
      doneTime: 0,
    } as IActionState;
  }
}
