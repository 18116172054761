<div class="modal-xs">
  <div class="modal-body confirmation-dialog__body">
    <p class="title-m confirmation-dialog__title">
      {{ titleMessage | translate: { value: titleValue } }}
    </p>
    @if (!innerHTML) {
      <p class="default-s confirmation-dialog__message">
        {{ message | translate: { value: messageValue } }}
      </p>
    } @else {
      <p
        class="default-s confirmation-dialog__message"
        [innerHTML]="message | translate: { value: messageValue }"
      ></p>
    }
  </div>

  <div class="modal-footer confirmation-dialog__footer">
    @if (!acknowledge) {
      <rs-web-button (tapped)="cancelAction()" [type]="buttonTypes.LIGHT_BORDERED">{{
        cancelButtonMessage | translate
      }}</rs-web-button>
    }
    <rs-web-button (tapped)="okAction()">{{ okButtonMessage | translate }}</rs-web-button>
  </div>
</div>
