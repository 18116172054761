<div class="emergency-header-container">
  <rs-web-icon
    class="icon-container"
    [icon]="immomioIconName.Warning"
    [color]="'white'"
    [size]="16"
  ></rs-web-icon>
  <rs-web-label
    class="text-center"
    className="fs-1 text-dark fw-semibold"
    [text]="bannerTitle | translate"
  ></rs-web-label>
  <div class="w-75">
    <rs-web-label
      class="text-center"
      className="text-secondary"
      [text]="bannerDescription | translate"
    ></rs-web-label>
  </div>
</div>
