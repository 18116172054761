import { Directive, Input, OnInit } from '@angular/core';
import {
  IHQTicketDetailsResponse,
  ImmomioIconName,
  ITicketDetailsSummary,
  ScoreQuestionType,
  TicketingVersion,
} from '../../../models';

const DESCRIPTION_MAX_LINES = 2;
const DEFAULT_SHOW_QUESTIONS_COUNT = 3;

@Directive()
export abstract class TicketDetailsOverviewHQBaseComponent implements OnInit {
  @Input() ticketDetails: IHQTicketDetailsResponse;
  public ticketingVersion = TicketingVersion.HIERARCHICAL;
  public questionsAndAnswers: ITicketDetailsSummary[] = [];
  public scoreQuestionType = ScoreQuestionType;
  public immomioIconName = ImmomioIconName;
  public descriptionMaxLines = DESCRIPTION_MAX_LINES;
  public shownAnswersCount = DEFAULT_SHOW_QUESTIONS_COUNT;

  ngOnInit() {
    this.prepareQuestionsAndAnswers();
  }

  public showMore() {
    this.shownAnswersCount = this.questionsAndAnswers.length;
  }

  public get showMoreButton() {
    return (
      this.questionsAndAnswers.length > DEFAULT_SHOW_QUESTIONS_COUNT &&
      this.shownAnswersCount !== this.questionsAndAnswers.length
    );
  }

  private prepareQuestionsAndAnswers(): void {
    const findQuestion = (questionId: string) =>
      this.ticketDetails.questions.rootQuestion.questions.find(el => el.id === questionId);

    this.ticketDetails?.questions?.responses?.forEach(response => {
      const question = findQuestion(response.questionId);
      const answer: ITicketDetailsSummary = {
        question: question.data.title,
        type: response.data.type,
        textAnswer: null,
        dateAnswer: null,
        selectionAnswer: null,
        attachments: null,
        order: question.data.order,
      };
      if (response.data.type == ScoreQuestionType.INPUT_TEXT) {
        answer.textAnswer = response.data.response as string;
      } else if (response.data.type === ScoreQuestionType.INPUT_DATE) {
        answer.dateAnswer = response.data.response as string;
      } else if (response.data.type === ScoreQuestionType.INPUT_ATTACHMENTS) {
        answer.attachments = response.data.attachments;
      } else if (response.data.type === ScoreQuestionType.SELECTION) {
        answer.selectionAnswer = question.answers.find(answer =>
          response.data.answerIds?.includes(answer.id)
        )?.data?.title;
      }

      this.questionsAndAnswers.push(answer);
    });

    this.questionsAndAnswers.sort((q1, q2) => q1.order - q2.order);
  }
}
