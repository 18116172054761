import { registerLocaleData } from '@angular/common';
import { importProvidersFrom, LOCALE_ID, NgModule } from '@angular/core';
import localeDe from '@angular/common/locales/de';
import {
  AuthService,
  AuthTokenService,
  DialogService,
  ErrorHandlerService,
  FeedbackService,
  RequestService,
  RouterService,
  SearchService,
  ServiceFacade,
  SharedModule,
  StorageService,
  ThemeService,
} from '@resident-nx/shared';
import {
  AuthTokenWebService,
  AuthWebService,
  DialogWebService,
  ErrorHandlerWebService,
  FeedbackWebService,
  FileDownloadWebService,
  LocalStorageService,
  NewRelicWebService,
  RequestWebService,
  RouterWebService,
  ServiceWebFacade,
  SessionStorageService,
  ThemeWebService,
} from './services';
import { ModalService } from './components/molecules/modal/modal.service';

registerLocaleData(localeDe);

@NgModule({
  providers: [
    importProvidersFrom(SharedModule),
    { provide: AuthService, useClass: AuthWebService },
    { provide: AuthTokenService, useClass: AuthTokenWebService },
    { provide: RouterService, useClass: RouterWebService },
    { provide: DialogService, useClass: DialogWebService },
    { provide: FeedbackService, useExisting: FeedbackWebService },
    { provide: ThemeService, useExisting: ThemeWebService },
    { provide: RequestService, useClass: RequestWebService },
    { provide: StorageService, useClass: LocalStorageService },
    { provide: ServiceFacade, useClass: ServiceWebFacade },
    { provide: ErrorHandlerService, useClass: ErrorHandlerWebService },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    SessionStorageService,
    LocalStorageService,
    SearchService,
    ModalService,
    NewRelicWebService,
    FileDownloadWebService,
  ],
})
export class WebModule {}
