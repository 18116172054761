import { Directive, Injector } from '@angular/core';
import { IConsumptionInfo, IContract, LoadingType } from '../../models';
import { Observable } from 'rxjs';
import { IActionState } from '../../utils';
import { Store } from '@ngrx/store';
import {
  getAvailableMeters,
  getContractsCount,
  getMetersByContractActionState,
  getSelectedContract,
  IMasterDataState,
} from '../../+state';
import * as fromConsumptionState from '../../+state/consumption';

@Directive()
export abstract class ConsumptionWidgetBaseComponent {
  public metersByContractActionState$: Observable<IActionState>;
  public metersByContract$: Observable<IConsumptionInfo>;
  public selectedContract$: Observable<IContract>;
  public contractsCount$: Observable<number>;
  public loadingTypes: LoadingType[] = [];
  private consumptionStore: Store<fromConsumptionState.IConsumptionState>;
  private masterDataStore: Store<IMasterDataState>;

  constructor(
    protected injector: Injector,
    protected store: Store
  ) {
    this.consumptionStore = injector.get(Store<fromConsumptionState.IConsumptionState>);
    this.masterDataStore = injector.get(Store<IMasterDataState>);
    this.selectedContract$ = this.consumptionStore.select(getSelectedContract);
    this.metersByContractActionState$ = this.consumptionStore.select(
      getMetersByContractActionState
    );
    this.metersByContract$ = this.consumptionStore.select(getAvailableMeters);
    this.contractsCount$ = this.consumptionStore.select(getContractsCount);
  }
}
