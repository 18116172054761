<div class="bar-container">
  <div
    [ngStyle]="{ width: width + '%' }"
    [class.comparison-current]="type === comparisonType.CURRENT"
    [class.good]="status === comparisonStatus.GOOD"
    [class.medium]="status === comparisonStatus.MEDIUM"
    [class.bad]="status === comparisonStatus.BAD"
    class="bar"
  ></div>
  <span [ngStyle]="{ 'margin-left': -width + '%' }" class="title">{{ title }}</span>
  <span class="amount">
    {{ amount | number: '1.0-0' }} {{ consumptionType | consumptionUnitTypePipe }}
  </span>
</div>
