import { SHARED_ENDPOINTS } from '@resident-nx/shared';

export const ENDPOINTS = {
  ...SHARED_ENDPOINTS,
  // TODO check if we have to refactor the SHARED_ENDPOINTS to use without / at the url beginning
  AUTH: 'auth',
  HOME: 'home',
  SETTINGS: 'settings',
  LOGOUT: 'logout',
  IMPRESSUM: 'impressum',
  ABG: 'abg',
  DATENSCHUTZ: 'datenschutz',
  NOTIFICATIONS: 'notifications',
};
