import { createSelector } from '@ngrx/store';
import * as fromReducer from './user-apps.reducer';
import { getUserAppsState } from '../../reducers';

export const getRegisteredUserAppsActionState = createSelector(
  getUserAppsState,
  fromReducer.getRegisteredUserAppsActionState
);

export const getRegisteredUserApps = createSelector(
  getUserAppsState,
  fromReducer.getRegisteredUserApps
);

export const getSelectedCustomerIdent = createSelector(
  getUserAppsState,
  fromReducer.getSelectedCustomerIdent
);
