<apx-chart
  [series]="series"
  [colors]="colors"
  [fill]="fill"
  [stroke]="stroke"
  [chart]="options.chart"
  [dataLabels]="options.dataLabels"
  [grid]="options.grid"
  [legend]="options.legend"
  [markers]="markers"
  [tooltip]="tooltip"
  [xaxis]="options.xaxis"
  [yaxis]="options.yaxis"
></apx-chart>
