import { Directive, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RouterService } from '../../services';
import { IActionState } from '../../utils';
import { ErrorOrigin, IRegisterExistingResident, IRegisterResidentResponse } from '../../models';
import * as fromRegistrationStore from '../../+state/account/registration';

@UntilDestroy()
@Directive()
export abstract class ConsentFormBaseComponent implements OnInit {
  public fg: FormGroup = this.fb.group({
    consent: [false, [Validators.requiredTrue]],
  });
  public registerResidentResponse$: Observable<IRegisterResidentResponse>;
  public registerResidentActionState$: Observable<IActionState>;
  private email: string;
  private invitationCode: string;

  constructor(
    private fb: FormBuilder,
    private routerService: RouterService,
    private activeRoute: ActivatedRoute,
    private registerStore: Store<fromRegistrationStore.RegistrationState>
  ) {}

  ngOnInit() {
    this.registerStore
      .select(fromRegistrationStore.getRegistrationForm)
      .pipe(untilDestroyed(this))
      .subscribe(regForm => {
        this.email = regForm.email;
        this.invitationCode = regForm.invitationCode;
      });

    this.registerResidentActionState$ = this.registerStore.select(
      fromRegistrationStore.getRegisterResidentActionState
    );
  }

  public submit() {
    const registerResidentInput: IRegisterExistingResident = {
      email: this.email,
      invitationCode: this.invitationCode,
    };
    this.registerStore.dispatch(fromRegistrationStore.RegisterResident({ registerResidentInput }));
    this.handleRegistrationResponse();
  }

  private handleRegistrationResponse(): void {
    this.registerResidentActionState$.pipe(untilDestroyed(this)).subscribe(response => {
      if (response.done) {
        void this.routerService.navigate(['../register-page4-confirmation-email-info'], {
          relativeTo: this.activeRoute,
        });
      } else if (response.error) {
        void this.routerService.navigate(
          [
            '../register-page-registration-error-screen',
            { error: response.error.message, origin: ErrorOrigin.REGISTRATION_CONSENT },
          ],
          {
            relativeTo: this.activeRoute,
          }
        );
      }
    });
  }
}
