import { APP_INITIALIZER, enableProdMode, importProvidersFrom, LOCALE_ID } from '@angular/core';

// libs
import { environment } from './environments/environment';

// app
import { AppComponent } from './app/app.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { routes } from './app/app.routing';
import { EffectsModule } from '@ngrx/effects';
import { localStorageSync } from 'ngrx-store-localstorage';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { CUSTOM_TRANSLATIONS } from '../custom-translations';
import { HttpLink } from 'apollo-angular/http';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import {
  AngularKeycloakService,
  INFRASTRUCTURE_CONFIG,
  initializeKeycloak,
  WebModule,
} from '@resident-nx/web';
import { ENDPOINTS } from './endpoints';
import {
  appEffects,
  appReducers,
  AuthInterceptor,
  clearState,
  createDefaultApolloConnection,
  HttpInterceptorService,
  IInfrastructureConfig,
  TranslateParserService,
  TransLoader,
} from '@resident-nx/shared';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { provideRouter, withComponentInputBinding } from '@angular/router';

const infrastructureConfig: IInfrastructureConfig = {
  environment,
};

function GqlLoaderFactory(apollo: Apollo, http: HttpClient) {
  return new TransLoader(apollo, http);
}

function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      { masterData: { encrypt: state => btoa(state), decrypt: state => atob(state) } },
      {
        ['consumption']: [
          'availableMeters',
          'selectedContract',
          'metersByContract',
          'metersByContractActionState',
          'selectedMeters',
          'selectedPeriodConsumptionActionStates',
          'selectedPeriodPastConsumptionActionStates',
          'selectedPeriodConsumptionBenchmarkActionStates',
        ],
      },
      {
        ['userApps']: ['selectedCustomerIdent'],
      },
      'appSettings',
    ],
    rehydrate: true,
  })(reducer);
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      WebModule,
      StoreModule.forRoot(appReducers, { metaReducers: [localStorageSyncReducer, clearState] }),
      EffectsModule.forRoot(appEffects),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: GqlLoaderFactory,
          deps: [Apollo],
        },
        parser: { provide: TranslateParser, useClass: TranslateParserService },
      }),
      AngularSvgIconModule.forRoot(),
      !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : []
    ),
    provideHttpClient(withInterceptorsFromDi()),
    { provide: 'ENVIRONMENT', useValue: environment },
    { provide: 'ENDPOINTS', useValue: ENDPOINTS },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      deps: [AngularKeycloakService],
      multi: true,
    },
    { provide: INFRASTRUCTURE_CONFIG, useValue: infrastructureConfig },
    Apollo,
    {
      provide: APOLLO_OPTIONS,
      useFactory: createDefaultApolloConnection,
      deps: [HttpLink, INFRASTRUCTURE_CONFIG, 'ENVIRONMENT'],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: 'TRANSLATION_KEY', useValue: environment.translationKey },
    { provide: 'CUSTOM_TRANSLATIONS', useValue: [...CUSTOM_TRANSLATIONS] },
    provideAnimations(),
    provideRouter(routes, withComponentInputBinding()),
  ],
}).catch(err => console.log(err));
