@if (documents$ | async) {
  @if ((documents$ | async)?.folderName === 'DOCUMENT_MANAGEMENT_ROOT_FOLDER_NAME_L') {
    <div class="d-flex justify-content-end pb-3">
      @if ((numberOfContracts$ | async) >= 2) {
        <rs-web-contract-menu
          class="mb-3 mobile"
          [immomioIconName]="immomioIconName.ContextMenu"
          [iconSize]="12"
          [contractMenuDesign]="ContractMenuDesign.SELECT"
          [showContract]="false"
          [disableExpiredContractSelection]="true"
        ></rs-web-contract-menu>
      }
      @if (documentSortSupported$ | async) {
        <rs-web-sorting-menu class="sortMenu" [type]="sortTypes.DOCUMENTS"></rs-web-sorting-menu>
      }
    </div>
  }
  <div
    [closeOthers]="true"
    class="m-y-4 web-documents-container"
    ngbAccordion
    #accordion="ngbAccordion"
  >
    @if (showSubFolderContent | async) {
      @for (folder of (documents$ | async)?.folders; track folder.id) {
        <div class="mb-2" ngbAccordionItem>
          <div ngbAccordionHeader>
            <button class="primary-text" ngbAccordionButton>
              <div class="chevron-icon">
                <rs-web-icon [icon]="immomioIconName.ChevronDown" [size]="14"></rs-web-icon>
              </div>
              <div class="icon icon-container">
                <rs-web-icon [icon]="immomioIconName.FolderOutlined"></rs-web-icon>
              </div>
              {{ folder.name }}
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <rs-web-documents [folderId]="folder.id" [subFolder]="true"></rs-web-documents>
              </ng-template>
            </div>
          </div>
        </div>
      }
      @for (document of (documents$ | async)?.documents; track document.id) {
        <div class="document-container" (click)="preview(document)">
          <div class="icon icon-container">
            <rs-web-icon [icon]="immomioIconName.Document"></rs-web-icon>
          </div>
          <span class="primary-text">{{ document.name }}</span>
        </div>
      }
    }
  </div>
} @else {
  @if (subFolder) {
    <div class="sub-folder">
      <rs-web-loading-skeleton
        [show]="true"
        [types]="[LoadingType.DOCUMENT]"
      ></rs-web-loading-skeleton>
    </div>
  } @else {
    <rs-web-loading-skeleton
      [show]="true"
      [types]="[LoadingType.DOCUMENT]"
    ></rs-web-loading-skeleton>
  }
}
<div class="mobile-documents-container">
  <rs-web-documents-mobile [download]="download"></rs-web-documents-mobile>
</div>
