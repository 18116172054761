import { Directive } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IActionState } from '../../utils';
import * as fromNewsState from '../../+state/news/news';
import { IPublishedNewsEdge } from '../../models';

@Directive()
export abstract class CurrentNewsOverviewBaseComponent {
  public news$: Observable<IPublishedNewsEdge[]> = this.newsStore.select(fromNewsState.getNews);
  public hasNextPage$: Observable<boolean> = this.newsStore.select(fromNewsState.hasNextPageNews);
  public newsActionState$: Observable<IActionState> = this.newsStore.select(
    fromNewsState.getNewsActionState
  );

  public pageSize;
  public pagination;

  constructor(
    protected newsStore: Store<fromNewsState.INewsState>,
    pageSize = 10
  ) {
    this.pageSize = pageSize;
    this.pagination = { offset: 0, limit: this.pageSize };
  }

  public loadMoreNews(): void {
    this.pagination = { offset: this.pagination.offset + this.pageSize, limit: this.pageSize };
    this.newsStore.dispatch(fromNewsState.LoadNews(this.pagination));
  }
}
