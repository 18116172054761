@if ((legalTextsState$ | async).done) {
  <div class="container mt-4 mb-3">
    <div
      class="primary-text"
      [innerHTML]="(legalTexts$ | async)?.[(route.queryParams | async).link]"
    ></div>
  </div>
} @else {
  <div class="loading-area d-flex justify-content-center">
    <rs-web-loader></rs-web-loader>
  </div>
}
