import { createReducer, on } from '@ngrx/store';
import {
  ICheckEmailExists,
  ICheckInvitationCode,
  ICheckNumbersResponse,
  IRegisterNewResident,
  IRegisterResidentResponse,
  IRegistrationForm,
} from '../../../models';
import { ActionStateCreator, IActionState } from '../../../utils/state-utils';
import * as fromActions from './registration.actions';

export interface IRegistrationState {
  registerResidentActionState: IActionState;
  verifyContractAndPartnerNumberActionState: IActionState;
  registerResidentResponse: IRegisterResidentResponse;
  verifyContractAndPartnerNumberResponse: ICheckNumbersResponse;
  triggerRegisterConfirmEmailActionState: IActionState;
  verifyRegistrationEmailActionState: IActionState;
  resetPasswordActionState: IActionState;
  initResetPasswordActionState: IActionState;
  checkInvitationCodeResponse: ICheckInvitationCode;
  checkInvitationCodeState: IActionState;
  checkEmailExistsResponse: ICheckEmailExists;
  checkEmailExistsState: IActionState;
  registrationForm: IRegistrationForm;
  requestInvitationCodeResponse: boolean;
  requestInvitationCodeState: IActionState;
}

export const initialState: IRegistrationState = {
  registerResidentActionState: ActionStateCreator.create(),
  verifyContractAndPartnerNumberActionState: ActionStateCreator.create(),
  registerResidentResponse: null,
  verifyContractAndPartnerNumberResponse: null,
  triggerRegisterConfirmEmailActionState: ActionStateCreator.create(),
  verifyRegistrationEmailActionState: ActionStateCreator.create(),
  resetPasswordActionState: ActionStateCreator.create(),
  initResetPasswordActionState: ActionStateCreator.create(),
  checkInvitationCodeResponse: null,
  checkInvitationCodeState: ActionStateCreator.create(),
  checkEmailExistsResponse: null,
  checkEmailExistsState: ActionStateCreator.create(),
  registrationForm: null,
  requestInvitationCodeResponse: null,
  requestInvitationCodeState: ActionStateCreator.create(),
};

export const registrationReducer = createReducer(
  initialState,

  on(fromActions.RegisterResident, (state, { registerResidentInput }) => {
    const registrationForm = { ...state.registrationForm };
    const input = registerResidentInput as IRegisterNewResident;
    if (input.password) {
      registrationForm.password = input.password;
    }
    return {
      ...state,
      registrationForm: { ...registrationForm },
      registerResidentActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.RegisterResidentSuccess, (state, { registerResidentResponse }) => {
    return {
      ...state,
      registerResidentActionState: ActionStateCreator.onSuccess(),
      registerResidentResponse,
    };
  }),
  on(fromActions.RegisterResidentFailed, (state, { error }) => {
    return {
      ...state,
      registerResidentActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.VerifyContractAndPartnerNumber, state => {
    return {
      ...state,
      verifyContractAndPartnerNumberActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.VerifyContractAndPartnerNumberSuccess, (state, { checkNumbersResponse }) => {
    return {
      ...state,
      verifyContractAndPartnerNumberActionState: ActionStateCreator.onSuccess(),
      verifyContractAndPartnerNumberResponse: checkNumbersResponse,
    };
  }),
  on(fromActions.VerifyContractAndPartnerNumberFailed, (state, { error }) => {
    return {
      ...state,
      verifyContractAndPartnerNumberActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.TriggerRegisterConfirmationEmail, state => {
    return {
      ...state,
      triggerRegisterConfirmEmailActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.TriggerRegisterConfirmationEmailSuccess, state => {
    return {
      ...state,
      triggerRegisterConfirmEmailActionState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.TriggerRegisterConfirmationEmailFailed, (state, { error }) => {
    return {
      ...state,
      triggerRegisterConfirmEmailActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.VerifyRegistrationEmail, state => {
    return {
      ...state,
      verifyRegistrationEmailActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.VerifyRegistrationEmailSuccess, state => {
    return {
      ...state,
      verifyRegistrationEmailActionState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.VerifyRegistrationEmailFailed, (state, { error }) => {
    return {
      ...state,
      verifyRegistrationEmailActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.ResetPassword, state => {
    return {
      ...state,
      resetPasswordActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.ResetPasswordSuccess, state => {
    return {
      ...state,
      resetPasswordActionState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.ResetPasswordFailed, (state, { error }) => {
    return {
      ...state,
      resetPasswordActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.InitResetPassword, state => {
    return {
      ...state,
      initResetPasswordActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.InitResetPasswordSuccess, state => {
    return {
      ...state,
      initResetPasswordActionState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.InitResetPasswordFailed, (state, { error }) => {
    return {
      ...state,
      initResetPasswordActionState: ActionStateCreator.onError(error),
    };
  }),
  on(fromActions.CheckInvitationCode, (state, { invitationCode }) => {
    return {
      ...state,
      registrationForm: { ...state.registrationForm, invitationCode },
      checkInvitationCodeResponse: null,
      checkInvitationCodeState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.CheckInvitationCodeSuccess, (state, { response }) => {
    return {
      ...state,
      checkInvitationCodeState: ActionStateCreator.onSuccess(),
      checkInvitationCodeResponse: response,
    };
  }),
  on(fromActions.CheckInvitationCodeFailed, (state, { error }) => {
    return {
      ...state,
      checkInvitationCodeState: ActionStateCreator.onError(error),
    };
  }),
  on(fromActions.CheckEmailExists, (state, { checkEmailExists }) => {
    return {
      ...state,
      registrationForm: { ...state.registrationForm, email: checkEmailExists.email },
      checkEmailExistsResponse: null,
      checkEmailExistsState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.CheckEmailExistsSuccess, (state, { response, email }) => {
    return {
      ...state,
      checkEmailExistsState: ActionStateCreator.onSuccess(),
      checkEmailExistsResponse: response,
      checkInvitationCodeResponse: {
        ...state.checkInvitationCodeResponse,
        email,
      },
    };
  }),
  on(fromActions.CheckEmailExistsFailed, (state, { error }) => {
    return {
      ...state,
      CheckEmailExistsState: ActionStateCreator.onError(error),
    };
  }),
  on(fromActions.RequestInvitationCode, (state, { requestInvitationCode }) => {
    return {
      ...state,
      requestInvitationCode,
    };
  }),
  on(fromActions.RequestInvitationCodeSuccess, (state, { response }) => {
    return {
      ...state,
      requestInvitationCodeState: ActionStateCreator.onSuccess(),
      requestInvitationCodeResponse: response,
    };
  }),
  on(fromActions.RequestInvitationCodeFailed, (state, { error }) => {
    return {
      ...state,
      requestInvitationCodeState: ActionStateCreator.onError(error),
    };
  }),
  on(fromActions.ClearInvitationCodeState, state => {
    return {
      ...state,
      checkInvitationCodeState: ActionStateCreator.create(),
      checkInvitationCodeResponse: null,
    };
  }),
  on(fromActions.ClearCheckEmailExistsState, state => {
    return {
      ...state,
      checkEmailExistsState: ActionStateCreator.create(),
      checkEmailExistsResponse: null,
    };
  }),
  on(fromActions.ClearRegistrationFormState, state => {
    return {
      ...state,
      registrationForm: null,
      checkInvitationCodeState: ActionStateCreator.create(),
    };
  })
);

export const getRegisterResidentActionState = (state: IRegistrationState) =>
  state.registerResidentActionState;

export const getRegisterResidentResponse = (state: IRegistrationState) =>
  state.registerResidentResponse;

export const getTriggerRegisterConfirmEmailActionState = (state: IRegistrationState) =>
  state.triggerRegisterConfirmEmailActionState;

export const getVerifyRegistrationEmailActionState = (state: IRegistrationState) =>
  state.verifyRegistrationEmailActionState;

export const getResetPasswordActionState = (state: IRegistrationState) =>
  state.resetPasswordActionState;

export const getInitResetPasswordActionState = (state: IRegistrationState) =>
  state.initResetPasswordActionState;

export const getVerifyContractAndPartnerNumberActionState = (state: IRegistrationState) =>
  state.verifyContractAndPartnerNumberActionState;

export const getVerifyContractAndPartnerNumberResponse = (state: IRegistrationState) =>
  state.verifyContractAndPartnerNumberResponse;

export const getInvitationCodeResponse = (state: IRegistrationState) =>
  state.checkInvitationCodeResponse;

export const getInvitationCodeState = (state: IRegistrationState) => state.checkInvitationCodeState;

export const getCheckEmailExistsResponse = (state: IRegistrationState) =>
  state.checkEmailExistsResponse;

export const getCheckEmailExistsState = (state: IRegistrationState) => state.checkEmailExistsState;

export const getRequestInvitationCodeResponse = (state: IRegistrationState) =>
  state.requestInvitationCodeResponse;

export const getRequestInvitationCodeState = (state: IRegistrationState) =>
  state.requestInvitationCodeState;

export const getRegistrationForm = (state: IRegistrationState) => state.registrationForm;
