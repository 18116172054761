<div class="password">
  <input
    [type]="showPasswordAsText() ? 'text' : 'password'"
    [formControl]="passwordControl.control"
    [placeholder]="'general.password' | translate"
    class="form-control"
    (focus)="showBox.set(true)"
    (blur)="showBox.set(false)"
  />
  <span class="password__eye">
    @if (showPasswordAsText()) {
      <rs-web-icon
        [icon]="ImmomioIconName.EyeOpen"
        (tapped)="showPasswordAsText.set(false)"
      ></rs-web-icon>
    } @else {
      <rs-web-icon
        [icon]="ImmomioIconName.EyeClosed"
        (tapped)="showPasswordAsText.set(true)"
      ></rs-web-icon>
    }
  </span>

  @if (!this.hideValidatorBox()) {
    <div
      class="password-required__box mt10"
      [class.d-block]="showBox()"
      [class.d-none]="!showBox()"
      [class.password-required__box--has-errors]="
        passwordControl.value.length > 0 && !passwordControl.valid
      "
      [class.password-required__box--is-valid]="passwordControl.valid"
    >
      <div class="password-required__box-title d-flex flex-row align-items-center mb10">
        <p class="m0 pl15">
          {{ 'password.required.info_l' | translate }}
        </p>
      </div>
      <div class="password-required__box-labels">
        @for (validatorContext of validatorContexts; track validatorContext.name) {
          <ng-container
            *ngTemplateOutlet="validatorText; context: { validatorParams: validatorContext }"
          ></ng-container>
        }
      </div>
    </div>
  }
</div>

<ng-template #validatorText let-validatorParams="validatorParams">
  <label
    class="col p0 d-flex flex-row align-items-center"
    [ngClass]="passwordControl.hasError(validatorParams.name) ? 'negative' : 'positive'"
  >
    @if (passwordControl.value.length === 0) {
      <span class="password-required__box-empty-check me-1"></span>
    } @else if (passwordControl.value.length > 0) {
      @if (passwordControl.hasError(validatorParams.name)) {
        <div class="icon_wrapper cross me-1">
          <div class="circle_background">
            <div class="icon_cross">&times;</div>
          </div>
        </div>
      } @else {
        <div class="icon_wrapper check me-1">
          <div class="circle_background">
            <div class="icon_check"></div>
          </div>
        </div>
      }
    }
    <span
      [class.password-required__box-label--has-errors]="
        passwordControl.value.length > 0 && passwordControl.hasError(validatorParams.name)
      "
      [class.password-required__box-label--is-valid]="
        passwordControl.value.length > 0 && !passwordControl.hasError(validatorParams.name)
      "
      >{{ validatorParams.text | translate }}</span
    >
  </label>
</ng-template>
