import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ModalContentComponent } from '../modal-content/modal-content.component';
import {
  AddressChangePageBaseComponent,
  ButtonTypeEnum,
  ImmomioIconName,
} from '@resident-nx/shared';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ROUTE } from '../../../../models';
import { ImageWebComponent } from '../../../atoms/image/image.component';
import { IconWebComponent } from '../../../atoms/icon/icon.component';
import { ButtonWebComponent } from '../../../atoms/button/button.component';

@Component({
  selector: 'rs-web-new-address-modal',
  templateUrl: './new-address.modal.component.html',
  styleUrls: ['./new-address.modal.component.scss'],
  imports: [
    ImageWebComponent,
    TranslateModule,
    ModalContentComponent,
    IconWebComponent,
    ButtonWebComponent,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewAddressWebModalComponent extends AddressChangePageBaseComponent {
  public immomioIconName = ImmomioIconName;
  public ButtonTypeEnum = ButtonTypeEnum;
  private router = inject(Router);
  private ngbActiveModal = inject(NgbActiveModal);

  public goToTicketsCreation() {
    this.setMovingAddressSent(this.contractId);
    this.closeModal();

    this.router.navigate([ROUTE.TICKETS, 'requests']);
  }

  public setMovingAddressDone() {
    this.setMovingAddressSent(this.contractId);
    this.closeModal();
  }

  public closeModal() {
    this.ngbActiveModal.close();
  }
}
