export interface IAuthToken {
  accessToken: string;
  accessTokenExpiration: Date;
  idToken: string;
  idTokenExpiration: Date;
  refreshToken: string;
  refreshTokenExpiration: Date;
  scope?: string;
  sessionState?: string;
  tokenType?: string;
  access_token?: string;
}
