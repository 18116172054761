import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ButtonTypeEnum, LoadFederatedLoginData } from '@resident-nx/shared';
import {
  ButtonWebComponent,
  FormControlErrorsDirective,
  FormFieldComponent,
  getNonceAndState,
  getRedirectUrl,
  HeadlineWebComponent,
} from '@resident-nx/web';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rs-web-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormControlErrorsDirective,
    FormFieldComponent,
    HeadlineWebComponent,
    ButtonWebComponent,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class LoginComponent {
  private router = inject(Router);
  private fb = inject(FormBuilder);
  private store = inject(Store);

  public emailControl = this.fb.control('', {
    validators: [Validators.required, Validators.email],
  });
  public buttonTypes = ButtonTypeEnum;

  public navigateToRegistration() {
    void this.router.navigate(['/registration']);
  }

  public submit() {
    const { nonce, state } = getNonceAndState();
    const redirectUri = getRedirectUrl(window.location.toString(), '/auth', {
      pathAfterAuth: '/home',
    });

    const email = this.emailControl.value;
    this.store.dispatch(LoadFederatedLoginData({ email, nonce, state, redirectUri, isWeb: true }));
  }

  public navigateToRequestResetPasswordPage() {
    void this.router.navigate(['/request-reset-password']);
  }
}
