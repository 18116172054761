import tinycolor from 'tinycolor2';
import { HexColorCode } from '../models';

export const normalizeColor = (color: string): HexColorCode => {
  return tinycolor(color).toHexString();
};

export const sensitiveHoverColor = (color: string, amount: number): HexColorCode => {
  return isLightEnoughToShade(color) ? shadeColor(color, amount) : lightenColor(color, amount);
};

export const isLightEnoughToShade = (color: HexColorCode): boolean => {
  return tinycolor(color).getLuminance() > 0.049;
};

export const shadeColor = (color: string, amount: number): HexColorCode => {
  return tinycolor.mix(color, '#000', amount).toHexString();
};

export const lightenColor = (color: string, amount: number): HexColorCode => {
  return tinycolor.mix(color, '#fff', amount).toHexString();
};

export const shadeAndSaturateColor = (color: string, shadeAmount, saturateAmount) => {
  // need to be seperated steps. Chaining lead to a slightly different output
  const shadedColor = tinycolor.mix(color, '#000', shadeAmount).toHexString();
  return tinycolor(shadedColor).saturate(saturateAmount).toHexString();
};

export const getButtonTextColor = (
  defaultTextColor: string,
  backgroundColor: string,
  alternativeColor: string
): HexColorCode => {
  const tDefaultTextColor = tinycolor(defaultTextColor).toHexString();
  const tBackgroundColor = tinycolor(backgroundColor).toHexString();
  const tAlternativeColor = tinycolor(alternativeColor).toHexString();

  return tinycolor.isReadable(tBackgroundColor, tDefaultTextColor)
    ? tDefaultTextColor
    : tAlternativeColor;
};

/***
 * getSimilarColor will generate a list of colors based on one initial color for
 * pieCharts etc
 *
 * first color will be the base color followed by darker or lighter version
 * depending on how light the baseColor is. Next color will move the baseColor
 * in the color circle by variationIntensity be a different one
 * from the base and then again darken/lighten the next and so
 * on
 *
 * info: tinycolor functions analogous or monochromatic couldn't fit for the
 * pieChar scenario
 *
 * @param baseColor base color to generate the others
 * @param amount how many colors should be generated
 * @param variationIntensity how much the colors should devide to each other
 * @return HexColorCode[] it returns a list of similar colors as HexColorCode
 */
export const getSimilarColors = (
  baseColor: string | HexColorCode,
  amount = 4,
  lightenIntensity = 30,
  variationIntensity = 40
): HexColorCode[] => {
  let analogColors: HexColorCode[] = [];

  let i = 0;
  while (i < amount) {
    const genColor =
      i === 0
        ? tinycolor(baseColor)
        : i % 2 === 1
          ? tinycolor(sensitiveHoverColor(analogColors[0], lightenIntensity))
          : tinycolor(analogColors[i - 1])
              .brighten(lightenIntensity)
              .spin(variationIntensity);
    analogColors = [genColor.toHexString(), ...analogColors];
    i++;
  }

  return analogColors.reverse();
};
