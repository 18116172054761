import { Component } from '@angular/core';
import { TabBarBaseComponent } from '@resident-nx/shared';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rs-web-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class TabBarWebComponent extends TabBarBaseComponent {}
