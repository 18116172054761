<rs-web-archive-tickets-card
  [buttonText]="'general.open' | translate"
  [titleText]="'general.archive' | translate"
  [icon]="immomioIconName.Archive"
  (expanded)="loadMoreArchiveTicket({ offset: 0, limit: 20 })"
>
  <rs-web-tickets
    archive-content
    [tickets]="archiveTickets$ | async"
    [ticketsActionState]="archiveTicketsActionState$ | async"
    [hasNextPage]="hasNextPage$ | async"
    (openTicket)="openTicket.emit($event)"
    (loadMoreTickets)="loadMoreArchiveTicket()"
    [txtNoTickets]="txtNoTickets"
  ></rs-web-tickets>
</rs-web-archive-tickets-card>
