import { createAction, props } from '@ngrx/store';
import { ISharedContentParams, ISharedFolderContentResponse } from '../../models';

export const GetSharedContent = createAction(
  '[Shared Content] Get Shared Content',
  props<{ params: ISharedContentParams; forced?: boolean }>()
);
export const GetSharedContentSuccess = createAction(
  '[Shared Content] Get Shared Content Success',
  props<{ response: ISharedFolderContentResponse }>()
);
export const GetSharedContentFailed = createAction(
  '[Shared Content] Get Shared Content Failed',
  props<{ error: Error }>()
);
