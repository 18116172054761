import { createSelector } from '@ngrx/store';
import { getEmergencyContactsState } from '../../reducers';
import * as fromReducer from './emergency-contacts.reducer';

export const getEmergencyContactsActionState = createSelector(
  getEmergencyContactsState,
  fromReducer.getEmergencyContactsActionState
);
export const getEmergencyContactsByContract = createSelector(
  getEmergencyContactsState,
  fromReducer.getEmergencyContactsByContract
);

export const getActiveRequests = createSelector(
  getEmergencyContactsState,
  fromReducer.getActiveRequests
);

export const emergencyContactsExists = createSelector(
  getEmergencyContactsState,
  fromReducer.emergencyContactsExists
);
