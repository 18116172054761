export abstract class LoggingService {
  abstract error(log: string): void;

  abstract info(log: string): void;

  abstract warn(log: string): void;

  abstract debug(log: string): void;

  abstract verbose(log: string): void;
}
