import { FeedbackEffects } from './feedback';
import { AppSettingsEffects } from './app-settings';

export * from './feedback';
export * from './app-settings';
export * from './sortings';
export * from './welcome_screen';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GENERAL_EFFECTS: any[] = [FeedbackEffects, AppSettingsEffects];
