import { Component } from '@angular/core';

import { HeaderBaseComponent } from '@resident-nx/shared';

@Component({
  selector: 'rs-web-header-old',
  templateUrl: 'header-old.component.html',
  standalone: true,
})
export class HeaderWebOldComponent extends HeaderBaseComponent {}
