import { Injectable } from '@angular/core';
import { ComparisonStatus, ComparisonType, IConsumption } from '../../models';

export interface ConsumptionComparisonWidths {
  consumption: number;
  pastConsumption: number;
  benchmark: number;
}

@Injectable()
export class ConsumptionComparisonService {
  public calculateBackgroundWidth(data: IConsumption): ConsumptionComparisonWidths {
    switch (this.getLargestComparisonTypeByValue(data)) {
      case ComparisonType.CURRENT: {
        return {
          consumption: 75,
          pastConsumption: this.calculateWidthPercentage(
            data.pastConsumption?.amount,
            data.consumption?.amount,
            75
          ),
          benchmark: this.calculateWidthPercentage(
            data.benchmark?.amount,
            data.consumption?.amount,
            75
          ),
        };
      }

      case ComparisonType.PAST: {
        return {
          consumption: this.calculateWidthPercentage(
            data.consumption?.amount,
            data.pastConsumption?.amount,
            50
          ),
          pastConsumption: 50,
          benchmark: this.calculateWidthPercentage(
            data.benchmark?.amount,
            data.pastConsumption?.amount,
            50
          ),
        };
      }

      case ComparisonType.BENCHMARK: {
        return {
          consumption: this.calculateWidthPercentage(
            data.consumption?.amount,
            data.benchmark?.amount,
            50
          ),
          pastConsumption: this.calculateWidthPercentage(
            data.pastConsumption?.amount,
            data.benchmark?.amount,
            50
          ),
          benchmark: 50,
        };
      }
    }
  }

  public getComparisonStatus(data: IConsumption): ComparisonStatus {
    const largestComparisonType = this.getLargestComparisonTypeByValue(data);

    switch (largestComparisonType) {
      case ComparisonType.CURRENT: {
        return ComparisonStatus.BAD;
      }

      case ComparisonType.PAST: {
        return data.consumption?.amount < data.benchmark?.amount
          ? ComparisonStatus.GOOD
          : ComparisonStatus.MEDIUM;
      }

      case ComparisonType.BENCHMARK: {
        return data.consumption?.amount < data.pastConsumption?.amount
          ? ComparisonStatus.GOOD
          : ComparisonStatus.MEDIUM;
      }
    }
  }

  public getLargestComparisonTypeByValue(data: IConsumption): ComparisonType {
    const pastConsumption = data.pastConsumption?.amount || 0;
    const benchmark = data.benchmark?.amount || 0;
    const max = Math.max(data.consumption?.amount, pastConsumption, benchmark);

    switch (max) {
      case data.consumption?.amount:
        return ComparisonType.CURRENT;
      case pastConsumption:
        return ComparisonType.PAST;
      case benchmark:
      default:
        return ComparisonType.BENCHMARK;
    }
  }

  public calculateWidthPercentage(part = 0, whole = 0, percentage: number): number {
    return Math.floor((part * percentage) / whole);
  }
}
