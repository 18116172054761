import {
  IRootQuestionResponseTicketShortInput,
  TicketIssueType,
  IResidentS3File,
  CategoryId,
} from '../';

export interface ITicketEdit {
  responses: IRootQuestionResponseTicketShortInput;
  parentTicketId: string;
  categoryId: CategoryId;
  propertyId: string;
  issueType: TicketIssueType;
  contractNumber: string;
}

export interface ITicketEditResponse {
  id: string;
  ticketNr: string;
  ticketTitle: string;
  documents: IResidentS3File[];
}

export interface IServiceAppointment {
  name: string;
  time: string;
  checked: boolean;
}
