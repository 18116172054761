import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImmomioIconName, TicketStatus } from '../../models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as fromTicketState from '../../+state/tickets';

@UntilDestroy()
@Directive()
export abstract class MessageInputBaseComponent implements OnInit {
  @Input() ticketState: TicketStatus;
  @Output() send = new EventEmitter();
  public immomioIconName = ImmomioIconName;
  public newMessage: string;
  public sending = false;
  public ticketStatus = TicketStatus;

  constructor(private ticketStore: Store<fromTicketState.ITicketsState>) {}

  ngOnInit() {
    this.ticketStore
      .select(fromTicketState.getNewMessageActionState)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        if (res.done) {
          this.newMessage = '';
        }
        if (res.done || res.error) {
          this.sending = false;
        }
      });
  }

  public sendMessage(): void {
    if (
      this.ticketState !== TicketStatus.CLOSED &&
      this.ticketState !== TicketStatus.CANCELLED &&
      !this.sending &&
      this.newMessage
    ) {
      this.newMessage = this.newMessage.trim();
      this.sending = true;
      this.send.emit(this.newMessage);
    }
  }
}
