import { gql } from 'apollo-angular';

export const newsFields = `
  edges {
    cursor
    node {
      uuid
      id
      title
      excerpt
      text
      href
      imageUrl
      showInHeader
      sorting
      publishedAt
      externalId
    }
  }
  pageInfo {
    hasPreviousPage
    hasNextPage
    startCursor
    endCursor
  }
`;

export const newsQuery = gql`
  query publishedNews($limit: Int, $offset: Int) {
    publishedNews (limit: $limit, offset: $offset) {
      ${newsFields}
    }
  }
`;

export const singleNewsFields = `
  id
  title
  text
  publishedAt
  imageUrl
  href
`;

export const singleNewsQuery = gql`
  query getNews($newsId: String!) {
    getNews (newsId: $newsId) {
      ${singleNewsFields}
    }
  }
`;

export const publishedNewsPublicQuery = gql`
  query publishedNewsPublic($limit: Int, $offset: Int) {
    publishedNewsPublic (limit: $limit, offset: $offset) {
      ${newsFields}
    }
  }
`;
