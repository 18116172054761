import { ICustomerSpecificDataResponse, IEditAction, IEditorContact, IResidentS3File } from '../';

export interface ITicketConversation {
  messages: IConversationMessage[];
}

export interface IConversationMessage {
  created: IEditAction;
  message: string;
  id: string;
  attachments: IResidentS3File[];
  customerSpecificData: ICustomerSpecificDataResponse[];
}

export interface ITicketConversationEdit {
  ticketId: string;
  message: string;
  attachments: IResidentS3File[];
}

export interface IConversationEditResponse {
  createdMessageId: string;
}

export interface ITicketMessageGroup {
  user: IEditorContact;
  userInitials: string;
  isIncoming: boolean;
  messages: ITicketMessage[];
  color?: string;
}

export interface ITicketMessage {
  id: string;
  message: string;
  attachments: IResidentS3File[];
  timestamp: number;
  isIncoming: boolean;
}
