import { Component, Input } from '@angular/core';
import { IPublishedNews, ImmomioIconName } from '@resident-nx/shared';
import { DatePipe } from '@angular/common';
import { IconWebComponent } from '../../atoms/icon/icon.component';
import { LabelWebComponent } from '../../atoms/label/label.component';
import { PictureWebComponent } from '../../atoms/picture/picture.component';

@Component({
  selector: 'rs-web-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss'],
  standalone: true,
  imports: [PictureWebComponent, LabelWebComponent, IconWebComponent, DatePipe],
})
export class NewsCardWebComponent {
  @Input() item: IPublishedNews;
  public immomioIconName = ImmomioIconName;
}
