import { gql } from 'apollo-angular';
import { metaFields } from '../common';

export const questionFields = `
  id
  mainQuestionIds
  questions {
    id
    data {
      type
      title
      order
      required
      maxAnswers
      meta {
        key
        value
      }
    }
    answers {
      id
      data {
        type
        order
        title
        multiline
        meta {
          key
          value
        }
      }
      questionIds
    }
  }
  ${metaFields}
`;

export const categoryFields = `
  id
  categoryAnswerSetRelationId
  name
  detailRootQuestion {
    ${questionFields}
  }
`;

export const getTicketCategoryQuestionsQuery = gql`
  query ticketCategoryQuestions($issueType: TicketIssueType, $contractNumber: String) {
    ticketCategoryQuestions(issueType: $issueType, contractNumber: $contractNumber) {
      ${questionFields}
    }
  }
`;

export const getTicketQuestionsQuery = gql`
  query ticketQuestions($categoryResponses: RootQuestionResponseTicketShortBeanInput!, $issueType: TicketIssueType, $contractNumber : String!) {
    ticketQuestions(categoryResponses:$categoryResponses,issueType:$issueType, contractNumber: $contractNumber) {
      ${questionFields}
    }
  }
`;

export const getCategoryQuery = gql`
  query getCategory($categoryResponses: RootQuestionResponseTicketShortBeanInput!, $issueType: TicketIssueType, $contractNumber : String) {
    getCategory(categoryResponses:$categoryResponses,issueType:$issueType, contractNumber: $contractNumber) {
      ${categoryFields}
    }
  }
`;
