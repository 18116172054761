import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import {
  getArchiveDamageTickets,
  getArchiveDamageTicketsActionState,
  LoadArchiveDamageTickets,
} from '../../+state';
import { ITicketResidentOverviewEdge } from '../../models';
import { ResolverCommonService } from './resolver-common.service';
import { CustomerService } from '../customer';

export const DamageTicketsArchiveResolver: ResolveFn<ITicketResidentOverviewEdge[]> = route => {
  const common = inject(ResolverCommonService);
  const customerService = inject(CustomerService);

  /**
   * don't send requests for archived damage tickets if the B&O Damage Tool is used
   */
  const bAndODamageFlow = customerService.showBandODamageFlow();
  if (bAndODamageFlow) {
    return null;
  }
  const { resolverWaitForData } = route.data;
  return common.resolveHandler<ITicketResidentOverviewEdge[]>(
    getArchiveDamageTicketsActionState,
    LoadArchiveDamageTickets({ offset: 0, limit: 20 }),
    getArchiveDamageTickets,
    resolverWaitForData
  );
};
