import { Directive } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromAccountUser from '../../+state/account/user-profile';
import { IChangePasswordPayload } from '../../../shared/models';

@Directive()
export class UserPrivacyPageBaseComponent {
  constructor(private store: Store<fromAccountUser.IUserProfileState>) {}
  changePasswordActionState$ = this.store.select(fromAccountUser.getChangePasswordActionState);

  submit(changePasswordPayload: IChangePasswordPayload) {
    this.store.dispatch(
      fromAccountUser.ChangePassword({
        changePasswordPayload,
      })
    );
  }
}
