import { FetchPolicy } from '@apollo/client/core';
import { IUser } from '../account';
import { IKeyValue } from '../helpers';
import { GQLMockData } from '../helpers/gql-mock-data';

/**
 * Workspace shared environment properties
 */
export interface IEnvironment {
  production?: boolean;
  envName?: string;
  appName?: string;
  appVersion?: string;
  buildNumber?: string;
  commitHash?: string;
  hasModifiedEnvironment?: boolean;
  customerIdent?: string; // mandatory in projects -> TODO: refactor environment structure
  authHeaderName?: string;
  fileDownloadAuthName?: string;
  graphqlServerUri?: string;
  graphqlHost?: string;
  languages?: IKeyValue[];
  defaultLanguage?: IKeyValue;
  filerUrl?: string;
  appUrlHomepage?: string;
  translationKey?: string;
  CUSTOM_TRANSLATIONS?: { key: string; value: string }[];
  deepLinkingScheme?: string;
  loginMethod?: string;
  deepLinkingSchemeAndroid?: string;
  deepLinkingSchemeIOS?: string;
  legalInfoHrefs?: ILegalInfoHref;
  faqReadMoreHref?: string;
  hardcodedUser?: IUser;

  gqlMockData?: GQLMockData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigateTo?: any[];

  features?: IFeatures; // mandatory in projects -> TODO: refactor environment structure
  apolloFetchPolicy?: FetchPolicy;
  cacheDuration?: number; // mandatory in projects for providers and guards -> TODO: refactor environment structure
  newrelicToken?: string;
}

export interface IAuthConfig {
  authority: string;
  tokenEndpointBase: string;
  authorizeEndpoint: string;
  tokenEndpoint: string;
  cookieDomains: string[];
  realm?: string;
  clientId?: string;
}

export interface ILegalInfoHref {
  imprint?: string;
  agb?: string;
  privacy?: string;
}

export interface IFeatures {
  search?: boolean;
}
