import { Action, ActionReducer, createAction } from '@ngrx/store';

export const LOGOUT = createAction('[Account Logout] Logout user');

export function clearState<State>(reducer: ActionReducer<State>): ActionReducer<State> {
  return function clearStateFn(state: State, action: Action) {
    if (action.type === LOGOUT.type) {
      state = undefined;
    }
    return reducer(state, action);
  };
}
