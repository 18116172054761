export interface IChartData {
  label: string;
  set: IChartDataItem[];
  caption?: string;
}

export interface IChartDataItem {
  amount: number;
  label: string;
  color: string;
}

export enum ChartOrientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}
