import { ComponentRef, Directive, inject, OnInit, ViewContainerRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ControlErrorContainerDirective } from './form-control-error-container.directive';
import { FormSubmitDirective } from './form-submit.directive';
import { EMPTY, filter, fromEvent, merge, take } from 'rxjs';
import { FORM_ERRORS } from './error-messages';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormErrorComponent } from '../../components/atoms/form/form-error/form-error.component';

/**
 {@link FormControlErrorsDirective} is used to inject {@link FormErrorComponent} relative to formControl or formControlName directives.

 The way it works as follows:
 1. the directive is assigned to any element having formControl/formControlName attribute
 2. it listens to value changes (when touched) from the control or submit event from the form element (@see {@link FormSubmitDirective} for submit event)
 3. if there is an error, then {@link FormErrorComponent} is injected into the container of the component/ parent container (@see {@link ControlErrorContainerDirective})

 Note: to disable the directive from being applied to specific control, just add hideError attribute to the element.

 @example
 <div>
 <input formControlName="control"/>
 <--- FormErrorComponent injected here
 </div>

 ------------------------------------------
 directive will not run for this control ⬇️

 <div>
 <input formControlName="control" hideError/>
 </div>

 @param {Record<string, string>} errors - application wide errors, injecting {@link FORM_ERRORS} values
 */

@UntilDestroy()
@Directive({
  selector: '([formControl], [formControlName])[:not([hideError])]',
  standalone: true,
})
export class FormControlErrorsDirective implements OnInit {
  private errors = inject(FORM_ERRORS);
  private formSubmitDirective = inject(FormSubmitDirective, { optional: true, host: true });
  private vcr = inject(ViewContainerRef);
  private control = inject(NgControl);
  private controlErrorContainer = inject(ControlErrorContainerDirective, { optional: true });

  private ref!: ComponentRef<FormErrorComponent>;
  private container = this.controlErrorContainer ? this.controlErrorContainer.vcr : this.vcr;
  private submit$ = this.formSubmitDirective ? this.formSubmitDirective.submit$ : EMPTY;
  private blurEvent$ = fromEvent<InputEvent>(this.vcr.element.nativeElement, 'blur').pipe(
    filter(() => this.control.control.dirty),
    take(1)
  );

  ngOnInit() {
    const valueChanges$ = this.control.control.valueChanges.pipe(
      filter(() => this.control.touched)
    );
    merge(this.submit$, this.blurEvent$, valueChanges$)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.checkErrors());
  }

  private checkErrors() {
    const controlErrors = this.control.errors;
    if (controlErrors) {
      const [firstKey] = Object.keys(controlErrors);
      const error = this.getError(this.errors[firstKey]);
      this.setError(error);
    } else if (this.ref) {
      this.setError(null);
    }
  }

  private getError(error: unknown): string {
    if (typeof error === 'string') return error;

    let [value] = Object.values(error);
    while (typeof value === 'object') {
      value = Object.values(value)[0];
    }
    return value;
  }

  private setError(text: string | null) {
    if (!this.ref) {
      this.ref = this.container.createComponent(FormErrorComponent);
    }

    this.ref.setInput('text', text);
  }
}
