import { ITheme, ILandlordCustomerBrandingTheme, IAppSettingsResponse } from '../../../models';
import {
  getButtonTextColor,
  normalizeColor,
  sensitiveHoverColor,
  shadeAndSaturateColor,
  shadeColor,
} from '../../../utils';
import { IAppSettings } from '../../../models/app-settings/app-settings.model';

export const appSettingsConverter = {
  fromThemeDto: (response: ILandlordCustomerBrandingTheme): ITheme => {
    return {
      colorPrimary: normalizeColor(response.primaryColor), // whiteLabel
      colorPrimaryHover: sensitiveHoverColor(response.primaryColor, 20), // inherit from whiteLabel
      colorSecondary: normalizeColor(response.secondaryColor), // whiteLabel
      colorSecondaryHover: sensitiveHoverColor(response.secondaryColor, 20), // inherit from whiteLabel
      colorSurface: normalizeColor(response.cardBackgroundColor), // whiteLabel
      colorSurfaceHover: shadeColor(response.cardBackgroundColor, 5), // inherit from whiteLabel
      colorBackgroundLight: normalizeColor(response.backgroundColor), // whiteLabel
      colorBackgroundMedium: shadeAndSaturateColor(response.backgroundColor, 3, 50), // inherit from whiteLabel
      colorTextPrimary: normalizeColor(response.primaryTextColor), // whiteLabel
      colorTextSecondary: normalizeColor(response.secondaryTextColor), // whiteLabel
      colorTextButtonPrimary: getButtonTextColor(
        '#fff',
        response.primaryColor,
        response.primaryTextColor
      ), // #fffff or primaryTextColor depending on button backgroundColor (primary color)
      colorTextButtonSecondary: getButtonTextColor(
        '#fff',
        response.secondaryColor,
        response.secondaryTextColor
      ), // #fffff or secondaryTextColor depending on button backgroundColor (secondary color)
    };
  },
  fromDto: (response: IAppSettingsResponse): IAppSettings => {
    return {
      theme: appSettingsConverter.fromThemeDto(response.theme),
      logo: response.residentAppBranding?.residentAppLogo || null,
    };
  },
};
