import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TicketCreationService } from '../../../../../services';
import { ControlContainer, ReactiveFormsModule } from '@angular/forms';
import { ImmomioIconName, ScoreQuestionType } from '@resident-nx/shared';
import { NgTemplateOutlet } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormControlErrorsDirective } from '../../../../../directives/form/form-control-errors.directive';
import { FileUploadValueAccessorDirective } from '../../../../../directives/form/file-upload-value-accessor.directive';
import { ControlErrorContainerDirective } from '../../../../../directives/form/form-control-error-container.directive';
import { FileListPipe } from '../../../../../pipes/file-list.pipe';
import { IconWebComponent } from '../../../../atoms/icon/icon.component';
import { ImageWebComponent } from '../../../../atoms/image/image.component';
import { DatePickerComponent } from '../../../../atoms/date-picker/date-picker.component';
import { ImageBorderRadiusEnum } from '../../../../atoms/image/image.model';

@Component({
  selector: 'rs-web-ticket-detail-questions',
  standalone: true,
  templateUrl: './ticket-detail-questions.component.html',
  styleUrl: './ticket-detail-questions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
  imports: [
    NgTemplateOutlet,
    ReactiveFormsModule,
    FormControlErrorsDirective,
    FileUploadValueAccessorDirective,
    ControlErrorContainerDirective,
    IconWebComponent,
    ImageWebComponent,
    FileListPipe,
    DatePickerComponent,
    TranslateModule,
  ],
})
export class TicketDetailQuestionsComponent {
  #ticketCreationService = inject(TicketCreationService);
  public detailQuestionsResponse = this.#ticketCreationService.detailQuestionsResponse;
  public formArray = this.#ticketCreationService.detailQuestionsFormArray;
  protected readonly ScoreQuestionType = ScoreQuestionType;
  protected readonly ImmomioIconName = ImmomioIconName;
  protected readonly ImageBorderRadiusEnum = ImageBorderRadiusEnum;
}
