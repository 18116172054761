@if ((categoriesActionState | async)?.pending === false) {
  @if (currentCategoryFormControl.value?.answers?.length) {
    <div class="ticket-categories row">
      <div class="ticket-categories-header d-flex gap-2 mb-3 font-bold">
        @if (this.step() > 0) {
          <rs-web-icon
            [icon]="ImmomioIcons.ArrowLeft"
            [size]="20"
            [cursor]="'pointer'"
            (tapped)="goToPreviousCategory()"
          ></rs-web-icon>
        }
        <span>{{ currentCategoryFormControl.value?.data?.title }}</span>
      </div>
      <div class="ticket-categories-answers">
        @for (answer of currentCategoryFormControl.value?.answers; track answer.id) {
          <label class="form-check form-check-label">
            <input
              class="form-check-input"
              type="radio"
              name="answer"
              [value]="answer"
              [formControl]="categoriesFormRecord.controls[currentCategoryFormControl.value.id]"
            />
            {{ answer.data.title }}
          </label>
        }
      </div>
    </div>
  } @else {
    <rs-web-tickets-no-catalogue></rs-web-tickets-no-catalogue>
  }
} @else {
  <rs-web-loader></rs-web-loader>
}
