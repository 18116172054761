import { Directive, Inject, Input } from '@angular/core';
import { RouterService } from '../../services';
import { ActivatedRoute } from '@angular/router';
import { IEnvironment, WhitelabelImage, WhitelabelImageType } from '../../models';
import { Store } from '@ngrx/store';
import * as fromRegistrationStore from '../../../shared';

@Directive()
export abstract class ConfirmationEmailInfoBaseComponent {
  @Input() imagePath? = '~/assets/shared/images/register-success.png';
  @Input() meta: string;
  public whitelabelImage = WhitelabelImage;
  public whitelabelImageType = WhitelabelImageType;
  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private router: RouterService,
    private activeRoute: ActivatedRoute,
    private registerStore: Store<fromRegistrationStore.RegistrationState>
  ) {
    this.meta = this.environment.production
      ? ''
      : `App Version: ${this.environment.appVersion}
Build: ${this.environment.buildNumber}

        `;
  }

  goToLogin(): void {
    this.router.navigate(['../'], { relativeTo: this.activeRoute });
    this.registerStore.dispatch(fromRegistrationStore.ClearRegistrationFormState());
  }
}
