import { Directive } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { InitResetPassword } from '../../../shared/+state';

@Directive()
export abstract class PasswordForgotFormBaseComponent {
  form = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });

  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {}

  sendResetLink() {
    const { email } = this.form.value;
    this.store.dispatch(
      InitResetPassword({
        email,
      })
    );
  }
}
