import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { IContactPersonResponse } from '../../models';

@Directive()
export abstract class ContactBaseComponent {
  @Input() contact: IContactPersonResponse;

  @Input() emergencyFlow = false;

  @Output() tapped = new EventEmitter();
}
