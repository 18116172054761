import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StatusBadgeBaseComponent } from '@resident-nx/shared';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rs-web-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class StatusBadgeWebComponent extends StatusBadgeBaseComponent {}
