import { gql } from 'apollo-angular';

export const consumptionFields = `
  consumptionList {
    periodStart
    periodStartValue
    periodEnd
    periodEndValue
    consumptionType
    amount
    unit
  }
`;

export const consumptionInfoFields = `
  meters {
    deviceId
    currentValue
    defaultAggregation
    updateInterval
    type
    measurementUnit
    max
    firstEntry
    lastEntry
  }
`;

export const getConsumptionDataForMeterQuery = gql`
  query getConsumptionDataForMeter($input: MeterConsumptionInputBean!) {
    getConsumptionDataForMeter (input: $input) {
      ${consumptionFields}
    }
  }
`;

export const getConsumptionInfoQuery = gql`
  query getConsumptionInfo($contractNumber: String!) {
    getConsumptionInfo (contractNumber: $contractNumber) {
      ${consumptionInfoFields}
    }
  }
`;

export const getBenchmarkDataForMeterQuery = gql`
  query getConsumptionBenchmarksForMeter($input: ConsumptionBenchmarkInputBean!) {
    getConsumptionBenchmarksForMeter(input: $input) {
      benchmarks {
        amount
        periodStart
        periodEnd
        consumptionType
        unit
        meterId
      }
    }
  }
`;
