import { Directive } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromAnnouncementsState from '../../+state/news/announcements';
import * as fromNewsState from '../../+state/news/news';
import * as fromOffersState from '../../+state/news/offers';

@Directive()
export abstract class NewsOverviewBaseComponent {
  news$ = this.store.select(fromNewsState.getLatestNews);
  newsActionState$ = this.store.select(fromNewsState.getNewsActionState);
  offers$ = this.store.select(fromOffersState.getLatestOffers);
  offersActionState$ = this.store.select(fromOffersState.getOffersActionState);
  announcements$ = this.store.select(fromAnnouncementsState.getLatestAnnouncements);
  announcementsActionState$ = this.store.select(fromAnnouncementsState.getAnnouncementsActionState);
  public pagination = { offset: 0, limit: 10 };

  constructor(private store: Store) {}
}
