import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import {
  ContractIconPipe,
  ContractMenuBaseComponent,
  ContractNamePipe,
  ImmomioIconName,
} from '@resident-nx/shared';
import { ElevationDirective } from '../../../directives/elevation/elevation.directive';
import { IconWebComponent } from '../../atoms/icon/icon.component';
import { ChipWebComponent } from '../../atoms/chip/chip.component';

export enum ContractMenuDesign {
  DROPDOWN = 'dropdown',
  CHIP = 'chip',
  SELECT = 'select',
}

@Component({
  selector: 'rs-web-contract-menu',
  templateUrl: './contract-menu.component.html',
  styleUrls: ['./contract-menu.component.scss'],
  imports: [
    NgbDropdownModule,
    ContractNamePipe,
    IconWebComponent,
    TranslateModule,
    ChipWebComponent,
    AsyncPipe,
    ElevationDirective,
    ContractIconPipe,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractMenuWebComponent extends ContractMenuBaseComponent {
  @Input() contractMenuDesign = ContractMenuDesign.DROPDOWN;
  @Input() immomioIconName = ImmomioIconName.Pen;
  @Input() showContract = true;
  @Input() showIcon = false;
  @Input() iconSize = 12;
  @Input() dropdownLeft = false;
  public design = ContractMenuDesign;
}
