import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import {
  BreadcrumbItem,
  ButtonTypeEnum,
  CurrentNewsDetailsBaseComponent,
  INewsState,
} from '@resident-nx/shared';
import { filter } from 'rxjs';
import { ROUTE } from '../../../models';
import { DateTransformPipe } from '../../../pipes/date.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { LoaderWebComponent } from '../../atoms/loader/loader.component';
import { ButtonWebComponent } from '../../atoms/button/button.component';
import { ImageWebComponent } from '../../atoms/image/image.component';
import { BreadcrumbsWebComponent } from '../../atoms/breadcrumbs/breadcrumbs.component';

@UntilDestroy()
@Component({
  selector: 'rs-web-current-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.scss'],
  standalone: true,
  imports: [
    BreadcrumbsWebComponent,
    ImageWebComponent,
    ButtonWebComponent,
    LoaderWebComponent,
    AsyncPipe,
    TranslateModule,
    DateTransformPipe,
  ],
})
export class CurrentNewsDetailsWebComponent
  extends CurrentNewsDetailsBaseComponent
  implements OnInit
{
  public buttonTypeEnum = ButtonTypeEnum;
  public breadcrumbs: BreadcrumbItem[] = [
    {
      label: 'main_navigation_news_l',
      url: ROUTE.NEWS,
    },
    {
      label: 'news.current_news_l',
      url: `${ROUTE.NEWS}/${ROUTE.CURRENT_NEWS}`,
    },
  ];
  constructor(
    store: Store<INewsState>,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(store);
  }

  public ngOnInit(): void {
    this.news$
      .pipe(
        filter(n => n !== null),
        untilDestroyed(this)
      )
      .subscribe(news => {
        this.breadcrumbs.push({ label: news.title });
      });
  }

  public onNavigateBack() {
    this.router.navigate([ROUTE.NEWS, ROUTE.CURRENT_NEWS]);
  }

  public onNavigate(url: string) {
    this.router.navigate([url]);
  }
}
