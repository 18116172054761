import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ImmomioIconName, IPaymentPositionResident } from '../../models';
import { IconFontVariant } from '../atoms';

@Directive()
export abstract class PaymentRequestsInfoCardBaseComponent {
  @Input() nextDuePaymentRequest: IPaymentPositionResident;
  @Input() activePaymentRequestsCount: number;
  @Output() tapped = new EventEmitter();
  public immomioIconName = ImmomioIconName;
  public iconFontVariant = IconFontVariant;
}
