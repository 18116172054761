import { IAddress, ICustomerSpecificDataResponse, PreferredWayOfContact } from '../';

export interface IMasterDataUser {
  id: string;
  externalId: string;
  firstName: string;
  lastName: string;
  address: IAddress;
  phone: string;
  mobile: string;
  email: string;
  preferredWayOfContact: PreferredWayOfContact;
  mainContractId: string;
  lastContractEnd: string;
  birthDate: string;
  customerSpecificData: ICustomerSpecificDataResponse[] | null;
  initials: string;
}

export interface IMasterDataResponse {
  residentUser: IMasterDataUserDto;
}

export interface IMasterDataUserDto
  extends Omit<IMasterDataUser, 'id' | 'firstName' | 'lastContractEnd'> {
  id: string | null;
  firstName: string | null;
  lastContractEnd: string | null;
}
