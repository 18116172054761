import { createSelector } from '@ngrx/store';

import { getPermissionsState } from '../../reducers';
import * as fromReducer from './permissions.reducer';

export const getPermissionsActionState = createSelector(
  getPermissionsState,
  fromReducer.getPermissionsActionState
);
export const getPermissions = createSelector(getPermissionsState, fromReducer.getPermissions);
export const getResidentAppSettings = createSelector(
  getPermissionsState,
  fromReducer.getResidentAppSettings
);
