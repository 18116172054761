<div class="chat-container">
  @if (
    showUpdateBanner &&
    ticketState !== ticketStatus.CLOSED &&
    ticketState !== ticketStatus.CANCELLED
  ) {
    <div class="chat-update-banner">
      <rs-web-icon
        [icon]="icons.Close"
        [color]="'white'"
        [size]="12"
        (tapped)="showUpdateBanner = false"
      ></rs-web-icon>
      <div class="d-flex justify-content-between px-4 py-1 align-items-center">
        <div>{{ 'update_available' | translate }}</div>
        <rs-web-icon
          [icon]="icons.Refresh"
          [color]="'white'"
          [size]="15"
          (tapped)="refreshChat.emit()"
        ></rs-web-icon>
      </div>
    </div>
  }

  <div class="offcanvas-body">
    @if (!loading) {
      @for (messageGroup of ticketChat(); track messageGroup) {
        <div
          class="chat flex-row"
          [class.flex-row-reverse]="messageGroup.isIncoming"
          #chatContainer
        >
          <div class="chat__img" [class.chat__img--incoming]="messageGroup.isIncoming">
            {{ messageGroup.userInitials }}
          </div>
          <div class="chat__message-wrapper">
            @for (item of messageGroup.messages; track item.id) {
              <div
                class="chat__message"
                [innerHTML]="item.message"
                [class.chat__message--incoming]="messageGroup.isIncoming"
              ></div>
              @if (item === messageGroup.messages[messageGroup.messages.length - 1]) {
                <time class="chat__timestamp">{{ item.timestamp | timeAgo }}</time>
              }
            }
          </div>
        </div>
      }
    } @else {
      <rs-web-ticket-details-chat-loader />
    }
  </div>
  <div class="chat-footer">
    <div class="d-flex align-items-center">
      <textarea
        cdkTextareaAutosize
        [cdkAutosizeMaxRows]="5"
        class="form-control"
        [class.chat-footer__textarea--disabled]="
          ticketState === ticketStatus.CLOSED || ticketState === ticketStatus.CANCELLED
        "
        [(ngModel)]="message"
        [placeholder]="
          (ticketState === ticketStatus.CANCELLED || ticketState === ticketStatus.CLOSED
            ? 'ticket_chat.ticket_closed_placeholder_l'
            : 'ticket_chat.new_message_placeholder_l'
          ) | translate
        "
        [disabled]="ticketState === ticketStatus.CLOSED || ticketState === ticketStatus.CANCELLED"
      ></textarea>
      <rs-web-button
        [iconLeft]="icons.PaperPlane"
        [elevation]="1"
        [borderRadius]="borderRadius.ROUND"
        [disabled]="!message || loading || sending"
        (tapped)="onSendMessage()"
      />
    </div>
  </div>
</div>
