import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, tap } from 'rxjs';
import { ROUTE } from '../../models';
import { Router } from '@angular/router';
import {
  ContractStatus,
  getContracts,
  getContractsActionState,
  LoadContracts,
} from '@resident-nx/shared';

export function ContractGuard(checkForExpiration?: boolean) {
  return () => {
    const store = inject(Store);
    const router = inject(Router);
    return store.select(getContractsActionState).pipe(
      tap(state => {
        if (!state.pending && !state.done) {
          store.dispatch(LoadContracts({}));
        }
      }),
      filter(state => state.done),
      switchMap(() =>
        store.select(getContracts).pipe(
          map(contracts => {
            if (!contracts.length && !checkForExpiration) {
              router.navigate([ROUTE.NEWS]);
              return true;
            }

            if (
              checkForExpiration &&
              contracts.length &&
              contracts.every(contract => contract.status === ContractStatus.EXPIRED)
            ) {
              router.navigate(['unauthorized-user']);
              return true;
            }

            return true;
          })
        )
      )
    );
  };
}
