import { gql } from 'apollo-angular';

export const getUserRegisteredSaasResidentAppsFields = `
registeredApps {
    customerIdent
    name
}
  
`;

export const getUserRegisteredSaasResidentAppsQuery = gql`
  query getUserRegisteredSaasResidentAppsQuery {
    getUserRegisteredSaasResidentApps {
      ${getUserRegisteredSaasResidentAppsFields}
    }
  }
`;
