<div class="ticket-details-page">
  @if (ticketingVersion$ | async; as version) {
    @if (ticketDetails$ | async; as details) {
      <rs-web-ticket-details-header
        class="offcanvas-header"
        [deleteButton]="hasDeleteButton(version, details, internalTicketingBooked$ | async)"
        [title]="details | ticketTitle: version"
        [state]="details | ticketStatus: version"
        (closeEvent)="!chatOpen ? closeEvent.emit() : (chatOpen = false)"
        (cancelTicketEvent)="onCancelTicket()"
      ></rs-web-ticket-details-header>
      @if (!chatOpen) {
        <div class="offcanvas-body">
          @if (version === ticketingVersion.METADATA) {
            <rs-web-ticket-details-overview-metadata
              [ticketDetails]="ticketDetailsMetadata$ | async"
            ></rs-web-ticket-details-overview-metadata>
          } @else {
            <rs-web-ticket-details-overview-hq
              [ticketDetails]="ticketDetailsHQ$ | async"
            ></rs-web-ticket-details-overview-hq>
          }
          @if (internalTicketingBooked$ | async) {
            <rs-web-ticket-details-chat-overview
              [messageCount]="(ticketDetails$ | async).unreadMessages"
              [hideNewMessageCounter]="hideNewMessageCounter"
              (openChat)="openChat()"
            />
          }
        </div>
      } @else {
        <rs-web-ticket-details-chat
          class="h-100"
          [loading]="(ticketChatActionState$ | async).pending"
          [sending]="(newMessageActionState$ | async).pending"
          [ticketState]="ticketDetails$ | async | ticketStatus: version"
          [ticketChat]="ticketChat$ | async"
          (sendMessage)="sendMessage($event)"
          (refreshChat)="dispatchLoadTicketChatDetails()"
        />
      }
    }
  }
</div>
