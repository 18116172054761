import { Directive, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FeedbackService, RouterService } from '../../services';
import { ResidentMailActionType, ResidentMailStatusType } from '../../models';
import { TranslateService } from '@ngx-translate/core';

@Directive()
export class LandingPageBaseComponent implements OnInit {
  constructor(
    private routerService: RouterService,
    private route: ActivatedRoute,
    private feedbackService: FeedbackService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    const { token, action, verificationCode, status, appRedirectPath } = this.route.snapshot
      .params as {
      token: string;
      verificationCode: string;
      appRedirectPath: string;
      action: string;
      status: string;
    };

    this.handleAction(action, status, token, appRedirectPath, verificationCode);
  }
  private handleAction(
    action: string,
    status: string,
    token: string,
    appRedirectPath: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    verificationCode?: string
  ) {
    if (action === ResidentMailActionType.VERIFY_EMAIL) {
      if (status === ResidentMailStatusType.SUCCESS) {
        const redirect = `auth`;
        void this.feedbackService.success({
          message: this.translate.instant('email_confirmation.success_l'),
        });
        this.goTo(redirect);
      }
    } else if (appRedirectPath === 'app*reset/register') {
      const redirect = `auth/reset-register`;
      this.goTo(redirect);
    }
  }

  private goTo = (route: string) => {
    void this.routerService.navigate([route]);
  };
}
