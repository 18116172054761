import { Pipe, PipeTransform } from '@angular/core';
import { IPaymentPositionResident, PaymentRequestStatus } from '../models';

@Pipe({
  name: 'paymentStatus',
  standalone: true,
})
export class PaymentStatusPipe implements PipeTransform {
  transform(value: IPaymentPositionResident): string | null {
    if (!value) {
      return null;
    }
    switch (value.status) {
      case PaymentRequestStatus.OPEN:
      case PaymentRequestStatus.ERROR:
        return this.checkDueDate(parseInt(value.dueDate));
      case PaymentRequestStatus.OVERDUE:
        return 'payment_requests.status_overdue_l';
      case PaymentRequestStatus.CHECK_MANUALLY:
        return 'payment_requests.status_check_l';
      case PaymentRequestStatus.CANCELED:
        return 'payment_requests.status_canceled_l';
      case PaymentRequestStatus.PAID:
        return 'payment_requests.status_paid_l';
      case PaymentRequestStatus.PAID_OUT:
        return 'payment_requests.status_paid_out_l';
      case PaymentRequestStatus.PENDING:
        return 'payment_requests.status_pending_l';
      case PaymentRequestStatus.PENDING_PAY_OUT:
        return 'payment_requests.status_pending_pay_out_l';
      case PaymentRequestStatus.REFUNDED:
        return 'payment_requests.status_refunded_l';
      default:
        return null;
    }
  }

  checkDueDate(dueDate: number) {
    const newDueDate = new Date(dueDate);
    newDueDate.setDate(newDueDate.getDate() + 1);
    newDueDate.setHours(2, 59, 59, 0);
    if (newDueDate.getTime() < Date.now()) return 'payment_requests.status_overdue_l';
    return 'payment_requests.status_due_l';
  }
}
