import { Directive, Input } from '@angular/core';
import { ELEVATION_DEFAULT_CONFIG, ELEVATION_MAX_VALUE } from './elevation.config';
import { Elevation, ElevationType } from './elevation.model';

const isTouchScreen = (): boolean => 'ontouchstart' in window || navigator.maxTouchPoints > 0;

@Directive({
  selector: '[rsWebElevation]',
  standalone: true,
  host: {
    '[class.mdc-elevation-transition]': 'clickable',
    '[class.mdc-elevation--z0]': 'useElevation(Elevation.ZERO)',
    '[class.mdc-elevation--z1]': 'useElevation(Elevation.ONE)',
    '[class.mdc-elevation--z2]': 'useElevation(Elevation.TWO)',
    '[class.mdc-elevation--z3]': 'useElevation(Elevation.THREE)',
    '[class.mdc-elevation--z4]': 'useElevation(Elevation.FOUR)',
    '[class.mdc-elevation--z5]': 'useElevation(Elevation.FIVE)',
    '[class.mdc-elevation--z6]': 'useElevation(Elevation.SIX)',
    '[class.mdc-elevation--z7]': 'useElevation(Elevation.SEVEN)',
    '[class.mdc-elevation--z8]': 'useElevation(Elevation.EIGHT)',
    '[class.mdc-elevation--z9]': 'useElevation(Elevation.NINE)',
    '[class.mdc-elevation--z10]': 'useElevation(Elevation.TEN)',
    '[class.mdc-elevation--z11]': 'useElevation(Elevation.ELEVEN)',
    '[class.mdc-elevation--z12]': 'useElevation(Elevation.TWELVE)',
    '[class.mdc-elevation--z13]': 'useElevation(Elevation.THIRTEEN)',
    '[class.mdc-elevation--z14]': 'useElevation(Elevation.FOURTEEN)',
    '[class.mdc-elevation--z15]': 'useElevation(Elevation.FIFTEEN)',
    '[class.mdc-elevation--z16]': 'useElevation(Elevation.SIXTEEN)',
    '[class.mdc-elevation--z17]': 'useElevation(Elevation.SEVENTEEN)',
    '[class.mdc-elevation--z18]': 'useElevation(Elevation.EIGHTEEN)',
    '[class.mdc-elevation--z19]': 'useElevation(Elevation.NINETEEN)',
    '[class.mdc-elevation--z20]': 'useElevation(Elevation.TWENTY)',
    '[class.mdc-elevation--z21]': 'useElevation(Elevation.TWENTY_ONE)',
    '[class.mdc-elevation--z22]': 'useElevation(Elevation.TWENTY_TWO)',
    '[class.mdc-elevation--z23]': 'useElevation(Elevation.TWENTY_THREE)',
    '[class.mdc-elevation--z24]': 'useElevation(Elevation.TWENTY_FOUR)',
    '(mouseenter)': 'isHoverActive = true',
    '(mouseleave)': 'isHoverActive = false; isMouseDownActive = false',
    '(mousedown)': 'isMouseDownActive = true',
    '(mouseup)': 'isMouseDownActive = false',
    '(touchstart)': 'isTouchActive = true',
    '(touchmove)': 'isTouchActive = false',
    '(touchend)': 'isTouchActive = false',
  },
})
export class ElevationDirective {
  @Input() elevation = ELEVATION_DEFAULT_CONFIG.elevation;
  @Input() elevationChangeStepOnHover = ELEVATION_DEFAULT_CONFIG.elevationChangeStepOnHover;
  @Input() elevationChangeStepOnClick = ELEVATION_DEFAULT_CONFIG.elevationChangeStepOnClick;
  @Input() elevationChangeStepOnTouch = ELEVATION_DEFAULT_CONFIG.elevationChangeStepOnTouch;
  @Input() clickable = ELEVATION_DEFAULT_CONFIG.clickable; // = with hover & click effect for non-touch devices + touch effect for touch devices

  public isHoverActive = false;
  public isMouseDownActive = false;
  public isTouchActive = false;

  public useElevation(elevation: ElevationType): boolean {
    const baseElevation = this.elevation;
    const elevationIsBaseElevation = baseElevation === elevation;

    if (isTouchScreen()) {
      const touchState = this.clickable && this.isTouchActive;
      if (
        touchState &&
        !(
          elevationIsBaseElevation &&
          elevation + this.elevationChangeStepOnTouch > ELEVATION_MAX_VALUE
        )
      ) {
        return baseElevation === elevation - this.elevationChangeStepOnTouch;
      } else {
        return elevationIsBaseElevation;
      }
    } else {
      const hoverState = this.clickable && this.isHoverActive;
      const clickState = this.clickable && this.isMouseDownActive;
      if (
        clickState &&
        !(
          elevationIsBaseElevation &&
          elevation + this.elevationChangeStepOnClick > ELEVATION_MAX_VALUE
        )
      ) {
        return baseElevation === elevation - this.elevationChangeStepOnClick;
      } else if (
        hoverState &&
        !(
          elevationIsBaseElevation &&
          elevation + this.elevationChangeStepOnHover > ELEVATION_MAX_VALUE
        )
      ) {
        return baseElevation === elevation - this.elevationChangeStepOnHover;
      } else {
        return elevationIsBaseElevation;
      }
    }
  }

  protected readonly Elevation = Elevation;
}
