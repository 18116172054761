<div class="announcement-overview container container--with-spacing">
  <div class="d-flex align-items-center">
    <rs-web-breadcrumbs
      [items]="breadcrumbs"
      (navigateBack)="onNavigate()"
      (navigateToUrl)="onNavigate($event)"
    ></rs-web-breadcrumbs>
  </div>
  <div class="row">
    <span class="title-xxl col announcement-overview__header">{{
      'news.announcements_l' | translate
    }}</span>
  </div>
  <div class="row gy-4">
    @for (announcement of announcements$ | async; track announcement.node.id) {
      <rs-web-content-card
        class="col-sm-6 col-lg-3"
        [id]="announcement.node.id"
        [title]="announcement.node.title"
        [text]="announcement.node.text"
        [date]="announcement.node.publishedAt"
        (tapped)="onTapArticle($event)"
      ></rs-web-content-card>
    }
  </div>
  <rs-web-infinite-scroll (scrolled)="onScroll()"></rs-web-infinite-scroll>
  @if ((announcementsActionState$ | async).pending) {
    <div class="d-flex justify-content-center m-5">
      <rs-web-loader></rs-web-loader>
    </div>
  }
</div>
