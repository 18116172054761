import { inject, Inject, Injectable } from '@angular/core';
import {
  AuthTokenService,
  getSelectedCustomerIdent,
  IEnvironment,
  IResidentS3File,
} from '@resident-nx/shared';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin, Observable, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  #store = inject(Store);
  constructor(
    @Inject(AuthTokenService) private authTokenService: AuthTokenService,
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private http: HttpClient
  ) {}

  uploadTicketAttachments(files: File[]): Observable<IResidentS3File[]> {
    return this.#store.select(getSelectedCustomerIdent).pipe(
      switchMap(customerIdent => {
        const token = this.authTokenService.getToken();
        const url = this.environment.filerUrl + 'v2/upload';
        const headers = new HttpHeaders({
          Authorization: 'Bearer ' + token.accessToken,
          'X-Customer-Ident': customerIdent,
        });

        const requests = files.map(file => {
          const formData = new FormData();
          formData.append('documentType', 'TICKET_ATTACHMENT');
          formData.append('fileType', 'IMG');
          formData.append('file', file, file.name);
          return this.http.post<IResidentS3File>(url, formData, {
            headers,
          });
        });

        return forkJoin(requests);
      })
    );
  }
}
