import { createReducer, on } from '@ngrx/store';

import * as fromActions from './notification-settings.actions';
import { ActionStateCreator, IActionState } from '../../../utils';
import { INotificationEmailSettings } from '../../../models/notifications/notification-emails-settings.model';

export interface INotificationSettingsState {
  notificationSettingsActionState: IActionState;
  notificationSettings: INotificationEmailSettings;
  changenotificationSettingsActionState: IActionState;
}

export const initialState: INotificationSettingsState = {
  notificationSettingsActionState: ActionStateCreator.create(),
  notificationSettings: null,
  changenotificationSettingsActionState: ActionStateCreator.create(),
};

export const notificationSettingsReducer = createReducer(
  initialState,

  on(fromActions.LoadNotificationSettings, state => {
    return {
      ...state,
      notificationSettingsActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.LoadNotificationSettingsSuccess, (state, { success }) => {
    return {
      ...state,
      notificationSettings: success,
      notificationSettingsActionState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.LoadNotificationSettingsFailed, (state, { error }) => {
    return {
      ...state,
      notificationSettings: null,
      notificationSettingsActionState: ActionStateCreator.onError(error),
    };
  }),
  on(fromActions.ChangeNotificationSettings, state => {
    return {
      ...state,
      changenotificationSettingsActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.ChangeNotificationSettingsSuccess, (state, { response }) => {
    return {
      ...state,
      notificationSettings: response,
      changenotificationSettingsActionState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.ChangeNotificationSettingsFailed, (state, { error }) => {
    return {
      ...state,
      changenotificationSettingsActionState: ActionStateCreator.onError(error),
    };
  })
);

export const getNotificationSettingsActionState = (state: INotificationSettingsState) =>
  state.notificationSettingsActionState;

export const getNotificationSettings = (state: INotificationSettingsState) =>
  state.notificationSettings;

export const getChangeNotificationSettingsActionState = (state: INotificationSettingsState) =>
  state.changenotificationSettingsActionState;
