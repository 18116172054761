import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as fromActions from './login.actions';
import { catchError, map, switchMap } from 'rxjs';
import { StorageService } from '../../../infrastructure/storage/storage.service';
import { ErrorHandlerService, LoginFacade, RouterService } from '../../../services';
import { IFederatedLoginEnvironmentVariables } from '../../../models';
import { Router } from '@angular/router';

@Injectable()
export class LoginEffects {
  constructor(
    private actions$: Actions,
    private loginFacade: LoginFacade,
    private storage: StorageService,
    private routerService: RouterService,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) {}

  getLoginData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadFederatedLoginData),
      switchMap(({ email, nonce, state, redirectUri, isWeb }) => {
        return this.loginFacade.getFederatedLoginData(email, nonce, state, redirectUri).pipe(
          map(federatedLoginData => {
            const { nonce, state, redirectUrl, realm, clientId, baseUrl, path } =
              federatedLoginData;

            this.storage.setItem(IFederatedLoginEnvironmentVariables.NONCE, nonce); // TODO: unused, remove?
            this.storage.setItem(IFederatedLoginEnvironmentVariables.STATE, state); // TODO: unused, remove?
            this.storage.setItem(IFederatedLoginEnvironmentVariables.REALM, realm);
            this.storage.setItem(IFederatedLoginEnvironmentVariables.CLIENT_ID, clientId);
            this.storage.setItem(IFederatedLoginEnvironmentVariables.BASE_URL_AUTH, baseUrl);
            this.storage.setItem(
              IFederatedLoginEnvironmentVariables.AUTHORIZE_ENDPOINT,
              path.replace(/^\/auth\//, '/')
            );
            this.storage.setItem(
              IFederatedLoginEnvironmentVariables.TOKEN_ENDPOINT,
              path.replace(/^\/auth\//, '/').replace(/auth$/, 'token')
            );
            this.storage.setItem(IFederatedLoginEnvironmentVariables.COOKIE_DOMAINS, 'immomio.com');

            if (isWeb) {
              // this.router.navigate(['auth']);
              // window.location.replace(redirectUrl);
              this.routerService.navigateToExternalUrl(redirectUrl);
            }

            return fromActions.LoadFederatedLoginDataSuccess({ federatedLoginData });
          }),
          catchError(error => {
            this.errorHandlerService.handleError('(AUTH ERROR) on login.effects.ts:', error);
            return [fromActions.LoadFederatedLoginDataFailed(error)];
          })
        );
      })
    )
  );
}
