import { SortOrder } from '../general';

export interface ISharedFolderContentResponse {
  folderId: string;
  folderName: string;
  documents: [ISharedDocumentsResponse];
  folders: [ISharedFoldersResponse];
  breadcrumbs: [ISharedFolderContentBreadcrumb];
}

export interface ISharedDocumentsResponse {
  id: string;
  name: string;
  extension: string;
}

export interface ISharedFolderContentBreadcrumb {
  id: string;
  name: string;
}

export interface ISharedFoldersResponse {
  id: string;
  name: string;
}

export interface ISharedContentParams {
  folderId?: string | null;
  contractId?: string | null;
  sortOrder?: SortOrder | null;
  sortField?: DocumentSortField | null;
}

export enum DocumentSortField {
  NAME = 'NAME',
  SHARED_WITH_ME_DATE = 'SHARED_WITH_ME_DATE',
}
