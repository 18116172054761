import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ImmomioIconName, TicketCardBaseComponent } from '@resident-nx/shared';

import { DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CardWebComponent } from '../../../atoms/card/card.component';
import { StatusBadgeWebComponent } from '../../../atoms/status-badge/status-badge.component';
import { LabelWebComponent } from '../../../atoms/label/label.component';
import { IconWebComponent } from '../../../atoms/icon/icon.component';

@Component({
  selector: 'rs-web-ticket-card',
  standalone: true,
  imports: [
    CardWebComponent,
    StatusBadgeWebComponent,
    LabelWebComponent,
    IconWebComponent,
    DatePipe,
    TranslateModule,
  ],
  templateUrl: './ticket-card.component.html',
  styleUrl: './ticket-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketCardWebComponent extends TicketCardBaseComponent {
  protected readonly immomioIconName = ImmomioIconName;
}
