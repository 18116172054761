import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { getContracts, getContractsActionState, LoadContracts } from '../../+state';
import { IContract } from '../../models';
import { ResolverCommonService } from './resolver-common.service';
import { AuthService } from '../../infrastructure';

export const ContractsResolver: ResolveFn<IContract[]> = route => {
  const common = inject(ResolverCommonService);
  const authService = inject(AuthService);

  if (authService.isGuestMode()) {
    return null;
  }

  const { resolverWaitForData } = route.data;
  return common.resolveHandler<IContract[]>(
    getContractsActionState,
    LoadContracts({}),
    getContracts,
    resolverWaitForData
  );
};
