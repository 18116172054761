import { createReducer, on } from '@ngrx/store';
import { ILegalTexts, IUserData } from '../../../models';
import { ActionStateCreator, IActionState } from '../../../utils';
import * as fromActions from './user-profile.actions';
import { IConsent } from '../../../models/account/consent.model';

export interface IUserProfileState {
  deleteUserActionState: IActionState;
  changePasswordActionState: IActionState;
  changeEmailActionState: IActionState;
  legalTexts: ILegalTexts;
  legalTextsActionState: IActionState;
  addRoommateActionState: IActionState;
  personalData: IUserData;
  personalDataActionState: IActionState;
  consent: IConsent;
  consentActionState: IActionState;
  consentSet: boolean;
  consentSetActionsState: IActionState;
}

export const initialState: IUserProfileState = {
  deleteUserActionState: ActionStateCreator.create(),
  changePasswordActionState: ActionStateCreator.create(),
  changeEmailActionState: ActionStateCreator.create(),
  legalTexts: null,
  legalTextsActionState: ActionStateCreator.create(),
  addRoommateActionState: ActionStateCreator.create(),
  personalData: null,
  personalDataActionState: ActionStateCreator.create(),
  consent: null,
  consentActionState: ActionStateCreator.create(),
  consentSet: false,
  consentSetActionsState: ActionStateCreator.create(),
};

export const userProfileReducer = createReducer(
  initialState,

  on(fromActions.DeleteUser, state => {
    return {
      ...state,
      deleteUserActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.DeleteUserSuccess, state => {
    return {
      ...state,
      deleteUserActionState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.DeleteUserFailed, (state, { error }) => {
    return {
      ...state,
      deleteUserActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.ChangePassword, state => {
    return {
      ...state,
      changePasswordActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.ChangePasswordSuccess, state => {
    return {
      ...state,
      changePasswordActionState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.ChangePasswordFailed, (state, { error }) => {
    return {
      ...state,
      changePasswordActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.ChangeEmail, state => {
    return {
      ...state,
      changeEmailActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.ChangeEmailSuccess, state => {
    return {
      ...state,
      changeEmailActionState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.ChangeEmailFailed, (state, { error }) => {
    return {
      ...state,
      changeEmailActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadLegalTexts, state => {
    return {
      ...state,
      legalTextsActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.LoadLegalTextsSuccess, (state, { legalTexts }) => {
    return {
      ...state,
      legalTexts,
      legalTextsActionState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.LoadLegalTextsFailed, (state, { error }) => {
    return {
      ...state,
      legalTextsActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadPersonalData, state => {
    return {
      ...state,
      personalDataActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.LoadPersonalDataSuccess, (state, { personalData }) => {
    return {
      ...state,
      personalData,
      personalDataActionState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.LoadPersonalDataFailed, (state, { error }) => {
    return {
      ...state,
      personalDataActionState: ActionStateCreator.onError(error),
    };
  }),
  on(fromActions.GetConsent, state => {
    return {
      ...state,
      consentActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.GetConsentSuccess, (state, { response }) => {
    return {
      ...state,
      consent: response,
      consentActionState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.GetConsentFailed, (state, { error }) => {
    return {
      ...state,
      consentActionState: ActionStateCreator.onError(error),
    };
  }),
  on(fromActions.SetConsent, state => {
    return {
      ...state,
      consentSetActionsState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.SetConsentSuccess, (state, { response }) => {
    return {
      ...state,
      consentSet: response,
      consentSetActionsState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.SetConsentFailed, (state, { error }) => {
    return {
      ...state,
      consentSetActionsState: ActionStateCreator.onError(error),
    };
  })
);

export const getDeleteUserActionState = (state: IUserProfileState) => state.deleteUserActionState;

export const getChangePasswordActionState = (state: IUserProfileState) =>
  state.changePasswordActionState;

export const getChangeEmailActionState = (state: IUserProfileState) => state.changeEmailActionState;

export const getLegalTexts = (state: IUserProfileState) => state.legalTexts;

export const getLegalTextsActionState = (state: IUserProfileState) => state.legalTextsActionState;

export const getPersonalData = (state: IUserProfileState) => state.personalData;

export const getPersonalDataActionState = (state: IUserProfileState) =>
  state.personalDataActionState;

export const getConsentState = (state: IUserProfileState) => state.consentActionState;

export const getConsent = (state: IUserProfileState) => state.consent;

export const getConsentSetState = (state: IUserProfileState) => state.consentSetActionsState;

export const getConsentSet = (state: IUserProfileState) => state.consentSet;
