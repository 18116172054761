import {
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  EventEmitter,
  input,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ButtonBorderRadiusEnum,
  ButtonTypeEnum,
  ImmomioIconName,
  ITicketMessageGroup,
  IUserData,
  TicketStatus,
  TimeAgoPipe,
} from '@resident-nx/shared';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TicketDetailsChatLoaderComponent } from './ticket-details-chat-loader/ticket-details-chat-loader.component';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { ButtonWebComponent } from '../../../../atoms/button/button.component';
import { IconWebComponent } from '../../../../atoms/icon/icon.component';

@Component({
  selector: 'rs-web-ticket-details-chat',
  templateUrl: './ticket-details-chat.component.html',
  styleUrl: './ticket-details-chat.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslateModule,
    FormsModule,
    ButtonWebComponent,
    IconWebComponent,
    TicketDetailsChatLoaderComponent,
    CdkTextareaAutosize,
    TimeAgoPipe,
  ],
})
export class TicketDetailsChatComponent {
  protected readonly buttonType = ButtonTypeEnum;
  protected readonly icons = ImmomioIconName;
  protected readonly borderRadius = ButtonBorderRadiusEnum;
  protected readonly ticketStatus = TicketStatus;
  @Input() loading = false;
  @Input() sending = false;
  @Input() userData: IUserData;
  @Input() ticketState: TicketStatus;
  @Output() sendMessage = new EventEmitter<string>();
  @Output() refreshChat = new EventEmitter();
  @ViewChild('chatContainer') chatContainer: ElementRef;
  ticketChat = input<ITicketMessageGroup[]>([]);

  constructor() {
    effect(() => {
      this.ticketChat();
      this.chatContainer?.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    });
  }

  public message = '';
  public showUpdateBanner = true;

  public onSendMessage(): void {
    if (
      this.ticketState !== TicketStatus.CLOSED &&
      this.ticketState !== TicketStatus.CANCELLED &&
      !this.sending
    ) {
      this.sendMessage.emit(this.message.trim());
      this.message = '';
    }
  }
}
