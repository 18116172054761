import { DocumentSortField } from '../shared-content/';
import { TicketSortField } from '../tickets';

export interface ISort<T = DocumentSortField | TicketSortField> {
  key: string;
  value: T;
  direction: SortOrder;
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export class Sort<T = DocumentSortField | TicketSortField> implements ISort<T> {
  key: string;
  value: T;
  direction: SortOrder;

  constructor(key: string, value: T, direction: SortOrder) {
    this.key = key;
    this.value = value;
    this.direction = direction;
  }
}

export const DocumentSortings: Sort<DocumentSortField>[] = [
  new Sort<DocumentSortField>(
    'sorting.docs.sorting_name_asc_l',
    DocumentSortField.NAME,
    SortOrder.ASC
  ),
  new Sort<DocumentSortField>(
    'sorting.docs.sorting_name_desc_l',
    DocumentSortField.NAME,
    SortOrder.DESC
  ),
  new Sort<DocumentSortField>(
    'sorting.docs.sorting_date_asc_l',
    DocumentSortField.SHARED_WITH_ME_DATE,
    SortOrder.ASC
  ),
  new Sort<DocumentSortField>(
    'sorting.docs.sorting_date_desc_l',
    DocumentSortField.SHARED_WITH_ME_DATE,
    SortOrder.DESC
  ),
];

export const TicketSortings: Sort<TicketSortField>[] = [
  new Sort<TicketSortField>(
    'sorting.tickets.creation_date_asc_l',
    TicketSortField.CREATED,
    SortOrder.ASC
  ),
  new Sort<TicketSortField>(
    'sorting.tickets.creation_date_desc_l',
    TicketSortField.CREATED,
    SortOrder.DESC
  ),
  new Sort<TicketSortField>(
    'sorting.tickets.update_date_asc_l',
    TicketSortField.UPDATED,
    SortOrder.ASC
  ),
  new Sort<TicketSortField>(
    'sorting.tickets.update_date_desc_l',
    TicketSortField.UPDATED,
    SortOrder.DESC
  ),
];

export enum SortType {
  DOCUMENTS = 'DOCUMENTS',
  TICKETS = 'TICKETS',
}
