<div class="contact-container">
  <div class="round-icon-container">
    <rs-web-icon [icon]="immomioIconName.User"></rs-web-icon>
  </div>
  <div class="d-flex flex-column justify-content-between flex-grow-1">
    <div class="fs-6 text-dark fw-semibold">{{ contact.position }}</div>
    <div class="text-secondary">{{ contact.name }}</div>
  </div>
  @if (showSelectionArrowIndicator) {
    <rs-web-icon
      class="d-flex pb-1"
      [icon]="immomioIconName.ArrowRight"
      [color]="'black'"
      [size]="24"
    ></rs-web-icon>
  }
</div>
