import { Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive()
export abstract class RadioButtonBaseComponent {
  @Input() className?: string;
  @Input() isChecked = false;
  @Output() tapped?: EventEmitter<boolean> = new EventEmitter();

  public checkButton(event): void {
    if (!this.isChecked) {
      this.isChecked = true;
      this.tapped.emit(event);
    }
  }
}
