import {
  IAddress,
  IAgent,
  IPayment,
  IProperty,
  ISepaMandate,
  IResidentS3File,
  IAccountBalance,
  IChartData,
  ContractStatus,
} from '../';
import { Milliseconds } from '../../utils';

export type ExternalContractId = string;
export type ContractId = string;

export interface IContractResponse {
  contracts: IContract[];
}

export interface IContract {
  externalId?: string;
  id?: string;
  type: string;
  sepaMandate?: ISepaMandate;
  address: IAddress;
  contractStart?: string;
  contractEnd?: string;
  status: ContractStatus;
  property?: IProperty;
  payments: IPayment[];
  agents: IAgent[];
  conditions?: { type: string; value: number }[];
  accountBalance?: IAccountBalance;
  contractStartTime?: Milliseconds;
  contractEndTime?: Milliseconds;
  movingAddressSent?: boolean;
}

export interface IContractDocumentsListResponse {
  documents: IResidentS3File[];
}
export interface EnhancedContract extends IContract {
  chartData: IChartData;
  totalRent?: number;
}
