import { inject } from '@angular/core';
import { IFederatedLoginEnvironmentVariables, StorageService } from '@resident-nx/shared';
import { AngularKeycloakService } from './angular-keycloak.service';

export const initializeKeycloak = (keycloak: AngularKeycloakService): (() => Promise<boolean>) => {
  const clientId = inject(StorageService).getItem(IFederatedLoginEnvironmentVariables.CLIENT_ID);

  const fnToCall: () => Promise<boolean> = () =>
    clientId ? keycloak.initializeKeycloak() : new Promise(res => res(false));

  return () => fnToCall();
};
