import { createReducer, on } from '@ngrx/store';
import { addMonths, addYears, endOfMonth, startOfMonth, subMonths, subYears } from 'date-fns';
import * as fromActions from './consumption.actions';
import {
  ActionStateCreator,
  endOfMonthMilliseconds,
  endOfYearMilliseconds,
  IActionState,
  startOfMonthMilliseconds,
  startOfYearMilliseconds,
} from '../../utils';
import {
  ByDeviceId,
  ChartMonths,
  ConsumptionsByContractR,
  ConsumptionTimeframe,
  IConsumption,
  IConsumptionBenchmarkItemResponse,
  IConsumptionInfo,
  IConsumptionInfoItem,
  IConsumptionItem,
  IConsumptionsByPeriod,
  IContract,
  IMonth,
  IPeriod,
  MetersByContractR,
  PeriodType,
} from '../../models';
import {
  calculateSelectedConsumption,
  consumptionCalculator,
  findSavedConsumptionDataForContract,
  findSavedConsumptionDataForDevice,
  isSelectedMeterAllowedAndAvailable,
  splitPeriodToMonths,
} from '../../services';

const canShowPreviousArrow = (
  contract: IContract,
  selectedPeriod: IPeriod,
  selectedMeter: IConsumptionInfoItem
): boolean => {
  if (!contract?.contractStartTime) {
    return false;
  }

  // check month start dates to determine if the arrow can be shown
  // selectedPeriod.start is already start of month
  const contractStartMonth = startOfMonth(contract.contractStartTime).getTime();
  const selectedMeterFirstEntryMonth = startOfMonth(selectedMeter.firstEntry).getTime();

  return (
    contractStartMonth < selectedPeriod.start && selectedMeterFirstEntryMonth < selectedPeriod.start
  );
};

const canShowNextArrow = (
  contract: IContract,
  selectedPeriod: IPeriod,
  selectedMeter: IConsumptionInfoItem
): boolean => {
  // check month end dates to determine if the arrow can be shown
  // selectedPeriod.end is already end of month
  const today: number = new Date().getTime();
  const selectedMeterLastEntryMonth = endOfMonth(selectedMeter.lastEntry).getTime();
  if (contract.contractEndTime) {
    const contractEndMonth: number = endOfMonth(contract.contractEndTime).getTime();
    return (
      today < contractEndMonth &&
      contractEndMonth > selectedPeriod.end &&
      selectedMeterLastEntryMonth > selectedPeriod.end
    );
  } else {
    return today > selectedPeriod.end && selectedMeterLastEntryMonth > selectedPeriod.end;
  }
};

export interface IConsumptionState {
  metersByContract: MetersByContractR;
  metersByContractActionState: IActionState;
  selectedContract: IContract;
  availableMeters: IConsumptionInfo;
  selectedMeters: IConsumptionInfoItem[];
  selectedPeriodsForRequests: ByDeviceId<IPeriod>;
  selectedPeriodsForCharts: ByDeviceId<IPeriod>;
  chartViewMonthsCount: ChartMonths;
  chartsMonths: ByDeviceId<IMonth[]>;
  showPreviousArrow: ByDeviceId<boolean>;
  showNextArrow: ByDeviceId<boolean>;
  selectedConsumptions: ByDeviceId<IConsumption>;

  selectedPeriodConsumptions: ByDeviceId<IConsumptionItem[]>;
  selectedPeriodConsumptionActionStates: ByDeviceId<IActionState>;
  selectedPeriodPastConsumptions: ByDeviceId<IConsumptionItem[]>;
  selectedPeriodPastConsumptionActionStates: ByDeviceId<IActionState>;
  selectedPeriodConsumptionBenchmarks: ByDeviceId<IConsumptionBenchmarkItemResponse[]>;
  selectedPeriodConsumptionBenchmarkActionStates: ByDeviceId<IActionState>;
  currentMonthConsumption: IConsumption;
  currentMonthConsumptionActionState: IActionState;

  savedConsumptionData: ConsumptionsByContractR;

  dashboardPeriodsForCharts: ByDeviceId<IPeriod>;
  dashboardChartsMonths: ByDeviceId<IMonth[]>;

  selectedConsumptionTimeframe: ByDeviceId<ConsumptionTimeframe>;
}

export const initialState: IConsumptionState = {
  availableMeters: null,
  selectedContract: null,
  metersByContract: {},
  metersByContractActionState: ActionStateCreator.create(),
  selectedMeters: [],
  selectedPeriodsForRequests: {},
  selectedPeriodsForCharts: {},
  chartViewMonthsCount: ChartMonths.QUARTER,
  chartsMonths: {},
  showPreviousArrow: {},
  showNextArrow: {},
  selectedConsumptions: {},

  selectedPeriodConsumptions: {},
  selectedPeriodConsumptionActionStates: {},
  selectedPeriodPastConsumptions: {},
  selectedPeriodPastConsumptionActionStates: {},
  selectedPeriodConsumptionBenchmarks: {},
  selectedPeriodConsumptionBenchmarkActionStates: {},
  currentMonthConsumption: null,
  currentMonthConsumptionActionState: ActionStateCreator.create(),

  savedConsumptionData: {},

  dashboardPeriodsForCharts: {},
  dashboardChartsMonths: {},

  selectedConsumptionTimeframe: {},
};

export const consumptionReducer = createReducer(
  initialState,

  on(fromActions.LoadMetersByContract, (state): IConsumptionState => {
    return {
      ...state,
      metersByContractActionState: ActionStateCreator.onStart(),
    };
  }),

  on(fromActions.LoadMetersBySelectedContractFailed, (state, { error }): IConsumptionState => {
    return {
      ...state,
      metersByContractActionState: ActionStateCreator.onError(error),
    };
  }),

  on(
    fromActions.LoadMetersBySelectedContractSuccess,
    (state, { consumptionInfo, contract }): IConsumptionState => {
      const allowedMeters = consumptionInfo?.meters?.filter(meter =>
        isSelectedMeterAllowedAndAvailable(contract, meter)
      );
      const availableMeters = allowedMeters.length !== 0 ? { meters: allowedMeters } : null;

      return {
        ...state,
        availableMeters,
        selectedContract: contract,
        metersByContract: { ...state.metersByContract, [contract.id]: availableMeters },
        metersByContractActionState: ActionStateCreator.onSuccess(),
      };
    }
  ),

  on(fromActions.SelectMeters, (state, { meters }): IConsumptionState => {
    const selectedPeriodConsumptionActionStates: IConsumptionState['selectedPeriodConsumptionActionStates'] =
      {};
    const selectedPeriodPastConsumptionActionStates: IConsumptionState['selectedPeriodPastConsumptionActionStates'] =
      {};
    const selectedPeriodConsumptionBenchmarkActionStates: IConsumptionState['selectedPeriodConsumptionBenchmarkActionStates'] =
      {};

    const selectedConsumptionTimeframe: IConsumptionState['selectedConsumptionTimeframe'] = {};

    for (const meter of meters) {
      selectedPeriodConsumptionActionStates[meter.deviceId] = ActionStateCreator.create();
      selectedPeriodPastConsumptionActionStates[meter.deviceId] = ActionStateCreator.create();
      selectedPeriodConsumptionBenchmarkActionStates[meter.deviceId] = ActionStateCreator.create();
      selectedConsumptionTimeframe[meter.deviceId] = ConsumptionTimeframe.YEAR;
    }

    return {
      ...state,
      selectedMeters: meters,
      selectedPeriodConsumptionActionStates,
      selectedPeriodPastConsumptionActionStates,
      selectedPeriodConsumptionBenchmarkActionStates,
      selectedConsumptionTimeframe,
    };
  }),

  on(fromActions.SelectLatestAvailablePeriod, (state, { contract }): IConsumptionState => {
    const selectedPeriodsForCharts: IConsumptionState['selectedPeriodsForCharts'] = {};
    const selectedPeriodsForRequests: IConsumptionState['selectedPeriodsForRequests'] = {};
    const chartsMonths: IConsumptionState['chartsMonths'] = {};
    const dashboardPeriodsForCharts: IConsumptionState['dashboardPeriodsForCharts'] = {};
    const dashboardChartsMonths: IConsumptionState['dashboardChartsMonths'] = {};

    if (!contract?.contractStartTime) {
      return {
        ...state,
        selectedPeriodsForCharts,
        selectedPeriodsForRequests,
        chartsMonths,
        dashboardPeriodsForCharts,
        dashboardChartsMonths,
      };
    }

    if (contract?.contractStartTime) {
      const contractEndTime: number = contract.contractEndTime || Date.now();

      for (const meter of state.selectedMeters) {
        const periodForChart: IPeriod = { start: null, end: null };
        const periodForRequest: IPeriod = { start: null, end: null };
        // lastEntry can be after contractEnd, in that case we only want data until lastEntry
        if (meter.lastEntry <= contractEndTime) {
          periodForChart.end = endOfYearMilliseconds(meter.lastEntry);
          periodForRequest.end = meter.lastEntry;
        } else {
          periodForChart.end = endOfYearMilliseconds(contractEndTime);
          periodForRequest.end = contractEndTime;
        }

        periodForChart.start = startOfYearMilliseconds(periodForChart.end);
        if (meter.firstEntry >= contract.contractStartTime) {
          periodForRequest.start = Math.max(periodForChart.start, meter.firstEntry);
        }
        // firstEntry can be before contractStart, get later date between contractStartTime and start of selectedChartPeriod
        else {
          periodForRequest.start = Math.max(periodForChart.start, contract.contractStartTime);
        }

        const dashboardPeriodForChart: IPeriod = {
          start: startOfMonthMilliseconds(subMonths(periodForRequest.end, 2).getTime()),
          end: endOfMonthMilliseconds(periodForRequest.end),
        };

        const { deviceId } = meter;
        chartsMonths[deviceId] = splitPeriodToMonths(periodForChart);
        selectedPeriodsForCharts[deviceId] = periodForChart;
        selectedPeriodsForRequests[deviceId] = periodForRequest;
        dashboardPeriodsForCharts[deviceId] = dashboardPeriodForChart;
        dashboardChartsMonths[deviceId] = splitPeriodToMonths(dashboardPeriodForChart);
      }
    }

    return {
      ...state,
      selectedPeriodsForCharts,
      selectedPeriodsForRequests,
      chartsMonths,
      dashboardPeriodsForCharts,
      dashboardChartsMonths,
    };
  }),

  on(fromActions.ClearSelectedConsumption, (state): IConsumptionState => {
    return {
      ...state,
      selectedConsumptions: {},
    };
  }),

  on(fromActions.SelectPreviousPeriod, (state, { deviceId }): IConsumptionState => {
    /**
     * if the select previous period is shown to the user we know it's allowed by contract and available on device
     */
    const currentPeriodForChart = state.selectedPeriodsForCharts[deviceId];

    const newPeriodForChart: IPeriod = { start: null, end: null };
    const timeframe = state.selectedConsumptionTimeframe[deviceId];
    if (timeframe === ConsumptionTimeframe.YEAR) {
      newPeriodForChart.start = startOfYearMilliseconds(
        subYears(currentPeriodForChart.start, 1).getTime()
      );
      newPeriodForChart.end = endOfYearMilliseconds(
        subYears(currentPeriodForChart.end, 1).getTime()
      );
    } else if (timeframe === ConsumptionTimeframe.MONTH) {
      newPeriodForChart.start = startOfMonthMilliseconds(
        subMonths(currentPeriodForChart.start, 1).getTime()
      );
      newPeriodForChart.end = endOfMonthMilliseconds(
        subMonths(currentPeriodForChart.end, 1).getTime()
      );
    }
    const selectedPeriodsForCharts = {
      ...state.selectedPeriodsForCharts,
      [deviceId]: newPeriodForChart,
    };

    // we take the latest date between contractStartTime, firstEntry and selectedPeriodForChart.start
    // to prevent requesting data for not allowed or unavailable period
    const meter = state.selectedMeters.find(meter => meter.deviceId === deviceId);

    const selectedPeriodsForRequests = {
      ...state.selectedPeriodsForRequests,
      [deviceId]: {
        start: Math.max(
          state.selectedContract.contractStartTime,
          meter.firstEntry,
          newPeriodForChart.start
        ),
        end: newPeriodForChart.end,
      },
    };

    const chartsMonths = {
      ...state.chartsMonths,
    };
    if (timeframe === ConsumptionTimeframe.YEAR) {
      chartsMonths[deviceId] = splitPeriodToMonths(newPeriodForChart);
    }

    return {
      ...state,
      selectedPeriodsForCharts,
      selectedPeriodsForRequests,
      chartsMonths,
    };
  }),

  on(fromActions.SelectNextPeriod, (state, { deviceId }): IConsumptionState => {
    /**
     * if the select next period is shown to the user we know it's allowed by contract and available on device     */
    const currentPeriodForChart = state.selectedPeriodsForCharts[deviceId];
    const newPeriodForChart: IPeriod = { start: null, end: null };
    const timeframe = state.selectedConsumptionTimeframe[deviceId];
    if (timeframe === ConsumptionTimeframe.YEAR) {
      newPeriodForChart.start = startOfYearMilliseconds(
        addYears(currentPeriodForChart.start, 1).getTime()
      );
      newPeriodForChart.end = endOfYearMilliseconds(
        addYears(currentPeriodForChart.end, 1).getTime()
      );
    } else if (timeframe === ConsumptionTimeframe.MONTH) {
      newPeriodForChart.start = startOfMonthMilliseconds(
        addMonths(currentPeriodForChart.start, 1).getTime()
      );
      newPeriodForChart.end = endOfMonthMilliseconds(
        addMonths(currentPeriodForChart.end, 1).getTime()
      );
    }
    const selectedPeriodsForCharts = {
      ...state.selectedPeriodsForCharts,
      [deviceId]: newPeriodForChart,
    };

    const meter = state.selectedMeters.find(meter => meter.deviceId === deviceId);
    // we just need to check if the data for meter is available for the last day of chart period
    const selectedPeriodsForRequests = {
      ...state.selectedPeriodsForRequests,
      [deviceId]: {
        start: newPeriodForChart.start,
        end: meter.lastEntry >= newPeriodForChart.end ? newPeriodForChart.end : meter.lastEntry,
      },
    };

    const chartsMonths = {
      ...state.chartsMonths,
      [deviceId]: splitPeriodToMonths(newPeriodForChart),
    };

    return {
      ...state,
      selectedPeriodsForCharts,
      selectedPeriodsForRequests,
      chartsMonths,
    };
  }),

  on(fromActions.SelectPeriod, (state, { deviceId, start, end }): IConsumptionState => {
    // chart period is 3 months after
    // TODO ask Michael Antemann what is meant with this comment above
    const newPeriodForChart: IPeriod = {
      start: startOfMonthMilliseconds(start.getTime()),
      end: endOfMonthMilliseconds(end.getTime()),
    };
    const selectedPeriodsForCharts = {
      ...state.selectedPeriodsForCharts,
      [deviceId]: newPeriodForChart,
    };

    const meter = state.selectedMeters.find(meter => meter.deviceId === deviceId);
    // we just need to check if the data for meter is available for the last day of chart period
    const selectedPeriodsForRequests = {
      ...state.selectedPeriodsForRequests,
      [deviceId]: {
        start:
          meter.firstEntry <= newPeriodForChart.start ? newPeriodForChart.start : meter.firstEntry,
        end: meter.lastEntry >= newPeriodForChart.end ? newPeriodForChart.end : meter.lastEntry,
      },
    };

    const chartsMonths = {
      ...state.chartsMonths,
      [deviceId]: splitPeriodToMonths(newPeriodForChart),
    };

    return {
      ...state,
      selectedPeriodsForCharts,
      selectedPeriodsForRequests,
      chartsMonths,
    };
  }),

  on(fromActions.LoadConsumptionForMeters, (state, { deviceIds }): IConsumptionState => {
    const selectedPeriodConsumptionActionStates = {
      ...state.selectedPeriodConsumptionActionStates,
    };
    for (const deviceId of deviceIds) {
      selectedPeriodConsumptionActionStates[deviceId] = ActionStateCreator.onStart();
    }
    return {
      ...state,
      selectedPeriodConsumptionActionStates,
    };
  }),

  /***
   * save data for selected period in store
   * also create or add to the existing record ConsumptionsByContractR
   */
  on(
    fromActions.LoadConsumptionForMetersSuccess,
    (state, { responses, contract, deviceIds }): IConsumptionState => {
      const showPreviousArrow = { ...state.showPreviousArrow };
      const showNextArrow = { ...state.showNextArrow };
      const chartsMonths = { ...state.chartsMonths };
      const selectedConsumptions = { ...state.selectedConsumptions };
      const selectedPeriodConsumptions = { ...state.selectedPeriodConsumptions };
      const selectedPeriodConsumptionActionStates = {
        ...state.selectedPeriodConsumptionActionStates,
      };
      const savedConsumptionDataByContract = { ...state.savedConsumptionData };

      if (!state.availableMeters) {
        return state;
      }

      for (const deviceId of deviceIds) {
        const selectedPeriod = state.selectedPeriodsForCharts[deviceId];
        const meter = state.selectedMeters.find(meter => meter.deviceId === deviceId);

        showPreviousArrow[deviceId] = canShowPreviousArrow(contract, selectedPeriod, meter);
        showNextArrow[deviceId] = canShowNextArrow(contract, selectedPeriod, meter);

        const response = responses[deviceId].filter(el => el.consumptionType === meter.type);

        const savedConsumptionDataForContract = findSavedConsumptionDataForContract(
          savedConsumptionDataByContract,
          contract.id
        );

        const savedConsumptionByMeter = findSavedConsumptionDataForDevice(
          savedConsumptionDataForContract,
          deviceId
        );

        const periodIndex = savedConsumptionByMeter.findIndex(
          el =>
            el.selectedPeriod.start === selectedPeriod.start &&
            el.selectedPeriod.end === selectedPeriod.end
        );
        let savedConsumptionByMeterForPeriod: IConsumptionsByPeriod;
        if (periodIndex < 0) {
          savedConsumptionByMeterForPeriod = {
            selectedPeriod,
            consumption: response,
            pastConsumption: [],
            benchmark: [],
          };
          savedConsumptionByMeter[savedConsumptionByMeter.length] =
            savedConsumptionByMeterForPeriod;
        } else {
          savedConsumptionByMeterForPeriod = {
            ...savedConsumptionByMeter[periodIndex],
            consumption: response,
          };
          savedConsumptionByMeter[periodIndex] = savedConsumptionByMeterForPeriod;
        }
        savedConsumptionDataForContract[deviceId] = savedConsumptionByMeter;
        savedConsumptionDataByContract[contract.id] = savedConsumptionDataForContract;
        let selectedMonth;
        if (chartsMonths[deviceId] && chartsMonths[deviceId].length) {
          selectedMonth = chartsMonths[deviceId].find(el => el.selected);
        }
        chartsMonths[deviceId] = splitPeriodToMonths(selectedPeriod);

        selectedConsumptions[deviceId] = calculateSelectedConsumption(
          savedConsumptionByMeterForPeriod,
          state.selectedConsumptionTimeframe[deviceId],
          selectedMonth
        );
        if (selectedConsumptions[deviceId]) {
          const month = new Date(
            selectedConsumptions[deviceId]?.consumption?.period?.start
          ).getMonth();
          chartsMonths[deviceId].find(m => m.month === month) &&
            (chartsMonths[deviceId].find(m => m.month === month).selected = true);
        }

        selectedPeriodConsumptions[deviceId] = response;
        selectedPeriodConsumptionActionStates[deviceId] = ActionStateCreator.onSuccess();
      }

      return {
        ...state,
        selectedPeriodConsumptionActionStates,
        selectedPeriodConsumptions,
        savedConsumptionData: savedConsumptionDataByContract,
        showPreviousArrow,
        showNextArrow,
        chartsMonths,
        selectedConsumptions,
      };
    }
  ),

  on(
    fromActions.LoadConsumptionForMetersFailed,
    (state, { deviceIds, error }): IConsumptionState => {
      const selectedPeriodConsumptionActionStates = {
        ...state.selectedPeriodConsumptionActionStates,
      };
      const selectedPeriodConsumptions = {
        ...state.selectedPeriodConsumptions,
      };

      const showPreviousArrow = { ...state.showPreviousArrow };
      const showNextArrow = { ...state.showNextArrow };

      for (const deviceId of deviceIds) {
        selectedPeriodConsumptionActionStates[deviceId] = ActionStateCreator.onError(error);
        // if fetch failed, remove previous fetched consumption
        delete selectedPeriodConsumptions[deviceId];

        const selectedPeriod = state.selectedPeriodsForCharts[deviceId];
        const meter = state.selectedMeters.find(meter => meter.deviceId === deviceId);
        showPreviousArrow[deviceId] = canShowPreviousArrow(
          state.selectedContract,
          selectedPeriod,
          meter
        );
        showNextArrow[deviceId] = canShowNextArrow(state.selectedContract, selectedPeriod, meter);
      }
      return {
        ...state,
        selectedPeriodConsumptionActionStates,
        selectedPeriodConsumptions,
        showPreviousArrow,
        showNextArrow,
      };
    }
  ),

  on(fromActions.LoadPastConsumptionForMeters, (state, { deviceIds }): IConsumptionState => {
    const selectedPeriodPastConsumptionActionStates = {
      ...state.selectedPeriodPastConsumptionActionStates,
    };
    for (const deviceId of deviceIds) {
      selectedPeriodPastConsumptionActionStates[deviceId] = ActionStateCreator.onStart();
    }
    return {
      ...state,
      selectedPeriodPastConsumptionActionStates,
    };
  }),

  on(
    fromActions.LoadPastConsumptionForMetersSuccess,
    (state, { responses, contract, deviceIds }): IConsumptionState => {
      const selectedPeriodPastConsumptions = { ...state.selectedPeriodPastConsumptions };
      const selectedPeriodPastConsumptionActionStates = {
        ...state.selectedPeriodPastConsumptionActionStates,
      };
      const savedConsumptionDataByContract = { ...state.savedConsumptionData };
      const selectedConsumptions = { ...state.selectedConsumptions };

      for (const deviceId of deviceIds) {
        const meter = state.selectedMeters.find(meter => meter.deviceId === deviceId);
        const response = responses[deviceId].filter(el => el.consumptionType === meter.type);

        const selectedPeriod = state.selectedPeriodsForCharts[deviceId];

        const savedConsumptionDataForContract = findSavedConsumptionDataForContract(
          savedConsumptionDataByContract,
          contract.id
        );

        const savedConsumptionByMeter = findSavedConsumptionDataForDevice(
          savedConsumptionDataForContract,
          deviceId
        );

        const periodIndex = savedConsumptionByMeter.findIndex(
          el =>
            el.selectedPeriod.start === selectedPeriod.start &&
            el.selectedPeriod.end === selectedPeriod.end
        );
        let savedConsumptionByMeterForPeriod: IConsumptionsByPeriod;
        if (periodIndex < 0) {
          savedConsumptionByMeterForPeriod = {
            selectedPeriod,
            consumption: [],
            pastConsumption: response,
            benchmark: [],
          };
          savedConsumptionByMeter[savedConsumptionByMeter.length] =
            savedConsumptionByMeterForPeriod;
        } else {
          savedConsumptionByMeterForPeriod = {
            ...savedConsumptionByMeter[periodIndex],
            pastConsumption: response,
          };
          savedConsumptionByMeter[periodIndex] = savedConsumptionByMeterForPeriod;
        }
        savedConsumptionDataForContract[deviceId] = savedConsumptionByMeter;
        savedConsumptionDataByContract[contract.id] = savedConsumptionDataForContract;
        selectedConsumptions[deviceId] = calculateSelectedConsumption(
          savedConsumptionByMeterForPeriod,
          state.selectedConsumptionTimeframe[deviceId]
        );

        selectedPeriodPastConsumptions[deviceId] = response;
        selectedPeriodPastConsumptionActionStates[deviceId] = ActionStateCreator.onSuccess();
      }

      return {
        ...state,
        selectedPeriodPastConsumptions,
        selectedPeriodPastConsumptionActionStates,
        savedConsumptionData: savedConsumptionDataByContract,
        selectedConsumptions,
      };
    }
  ),

  on(
    fromActions.LoadPastConsumptionForMetersFailed,
    (state, { deviceIds, error }): IConsumptionState => {
      const selectedPeriodPastConsumptionActionStates = {
        ...state.selectedPeriodConsumptionActionStates,
      };
      const selectedPeriodPastConsumptions = {
        ...state.selectedPeriodPastConsumptions,
      };

      for (const deviceId of deviceIds) {
        selectedPeriodPastConsumptionActionStates[deviceId] = ActionStateCreator.onError(error);
        // if failed fetching past consumption, remove (previous) fetched past consumption
        delete selectedPeriodPastConsumptions[deviceId];
      }
      return {
        ...state,
        selectedPeriodPastConsumptionActionStates,
        selectedPeriodPastConsumptions,
      };
    }
  ),

  on(fromActions.LoadConsumptionBenchmarkForMeters, (state, { deviceIds }): IConsumptionState => {
    const selectedPeriodConsumptionBenchmarkActionStates = {
      ...state.selectedPeriodConsumptionBenchmarkActionStates,
    };
    for (const deviceId of deviceIds) {
      selectedPeriodConsumptionBenchmarkActionStates[deviceId] = ActionStateCreator.onStart();
    }
    return {
      ...state,
      selectedPeriodConsumptionBenchmarkActionStates,
    };
  }),

  on(
    fromActions.LoadConsumptionBenchmarkForMetersSuccess,
    (state, { responses, contract, deviceIds }): IConsumptionState => {
      const savedConsumptionDataByContract = { ...state.savedConsumptionData };
      const selectedConsumptions = { ...state.selectedConsumptions };
      const selectedPeriodConsumptionBenchmarks = { ...state.selectedPeriodConsumptionBenchmarks };
      const selectedPeriodConsumptionBenchmarkActionStates = {
        ...state.selectedPeriodConsumptionBenchmarkActionStates,
      };

      for (const deviceId of deviceIds) {
        const savedConsumptionDataForContract = findSavedConsumptionDataForContract(
          savedConsumptionDataByContract,
          contract.id
        );

        const savedConsumptionByMeter = findSavedConsumptionDataForDevice(
          savedConsumptionDataForContract,
          deviceId
        );

        const selectedPeriod = state.selectedPeriodsForCharts[deviceId];
        const response = responses[deviceId];

        const periodIndex = savedConsumptionByMeter.findIndex(
          el =>
            el.selectedPeriod.start === selectedPeriod.start &&
            el.selectedPeriod.end === selectedPeriod.end
        );
        let savedConsumptionByMeterForPeriod: IConsumptionsByPeriod;
        if (periodIndex < 0) {
          savedConsumptionByMeterForPeriod = {
            selectedPeriod,
            consumption: [],
            pastConsumption: [],
            benchmark: response,
          };
          savedConsumptionByMeter[savedConsumptionByMeter.length] =
            savedConsumptionByMeterForPeriod;
        } else {
          savedConsumptionByMeterForPeriod = {
            ...savedConsumptionByMeter[periodIndex],
            benchmark: response,
          };
          savedConsumptionByMeter[periodIndex] = savedConsumptionByMeterForPeriod;
        }
        savedConsumptionDataForContract[deviceId] = savedConsumptionByMeter;
        savedConsumptionDataByContract[contract.id] = savedConsumptionDataForContract;

        selectedConsumptions[deviceId] = calculateSelectedConsumption(
          savedConsumptionByMeterForPeriod,
          state.selectedConsumptionTimeframe[deviceId]
        );
        selectedPeriodConsumptionBenchmarks[deviceId] = response || [];
        selectedPeriodConsumptionBenchmarkActionStates[deviceId] = ActionStateCreator.onSuccess();
      }

      return {
        ...state,
        selectedPeriodConsumptionBenchmarks,
        selectedPeriodConsumptionBenchmarkActionStates,
        savedConsumptionData: savedConsumptionDataByContract,
        selectedConsumptions,
      };
    }
  ),

  on(
    fromActions.LoadConsumptionBenchmarkForMetersFailed,
    (state, { deviceIds, error }): IConsumptionState => {
      const selectedPeriodConsumptionBenchmarkActionStates = {
        ...state.selectedPeriodConsumptionBenchmarkActionStates,
      };
      const selectedPeriodConsumptionBenchmarks = {
        ...state.selectedPeriodConsumptionBenchmarks,
      };
      for (const deviceId of deviceIds) {
        selectedPeriodConsumptionBenchmarkActionStates[deviceId] =
          ActionStateCreator.onError(error);
        delete selectedPeriodConsumptionBenchmarks[deviceId];
      }
      return {
        ...state,
        selectedPeriodConsumptionBenchmarkActionStates,
        selectedPeriodConsumptionBenchmarks,
      };
    }
  ),

  on(fromActions.LoadCurrentMonthConsumption, (state): IConsumptionState => {
    return {
      ...state,
      currentMonthConsumptionActionState: ActionStateCreator.onStart(),
    };
  }),

  on(
    fromActions.LoadCurrentMonthConsumptionSuccess,
    (state, { response, selectedPeriod, selectedMeter }): IConsumptionState => {
      response = response.filter(el => el.consumptionType === selectedMeter.type);
      const { deviceId } = selectedMeter;
      const selectedConsumptions = {
        ...state.selectedConsumptions,
        [deviceId]: calculateSelectedConsumption(
          {
            consumption: response,
            pastConsumption: state.selectedPeriodPastConsumptions[deviceId],
            benchmark: state.selectedPeriodConsumptionBenchmarks[deviceId],
            selectedPeriod,
          },
          state.selectedConsumptionTimeframe[deviceId]
        ),
      };
      return {
        ...state,
        selectedConsumptions,
        currentMonthConsumptionActionState: ActionStateCreator.onSuccess(),
      };
    }
  ),

  on(fromActions.LoadCurrentMonthConsumptionFailed, (state, { error }): IConsumptionState => {
    return {
      ...state,
      currentMonthConsumptionActionState: ActionStateCreator.onError(error),
    };
  }),

  /***
   * on selecting consumption item in chart, depending on the period type (day or month),
   * find and calculate date and value to be shown in the consumption value card
   */

  on(fromActions.SelectConsumption, (state, selected): IConsumptionState => {
    // Find selected period in chart, month or day
    const { deviceId } = selected;
    let chartMonths = [...state.chartsMonths[deviceId]];

    const periodType = selected.periodType;
    const consumptions = state.selectedPeriodConsumptions[deviceId];
    const pastConsumptions = state.selectedPeriodPastConsumptions[deviceId];
    const consumptionBenchmark = state.selectedPeriodConsumptionBenchmarks[deviceId];
    let consumption: IConsumptionItem;
    let pastConsumption: IConsumptionItem;
    let benchmark: IConsumptionBenchmarkItemResponse;

    if (periodType === PeriodType.MONTH) {
      const month = new Date(selected.selectedPeriod.start).getMonth();
      const year = new Date(selected.selectedPeriod.start).getFullYear();

      consumption = consumptionCalculator.monthConsumption(consumptions, month, year);
      chartMonths = chartMonths.map(el => {
        return { ...el, selected: el.month === month };
      });

      if (pastConsumptions) {
        pastConsumption = consumptionCalculator.monthConsumption(pastConsumptions, month, year - 1);
      }
      if (consumptionBenchmark) {
        benchmark = consumptionCalculator.benchmarkMonthConsumption(
          consumptionBenchmark,
          month,
          year
        );
      }
    } else if (periodType === PeriodType.DAY) {
      const date = new Date(selected.selectedPeriod.start).getDate();
      const month = new Date(selected.selectedPeriod.start).getMonth();
      const year = new Date(selected.selectedPeriod.start).getFullYear();
      consumption = consumptionCalculator.dayConsumption(consumptions, date, month, year);

      if (pastConsumptions) {
        pastConsumption = consumptionCalculator.dayConsumption(
          pastConsumptions,
          date,
          month,
          year - 1
        );
      }

      // no benchmark for day
      /*if (consumptionBenchmark) {
        benchmark = consumptionBenchmark[0] || { amount: 0 };
      }*/
    }

    const selectedConsumptions = {
      ...state.selectedConsumptions,
      [deviceId]: { consumption, pastConsumption, benchmark, periodType },
    };
    const chartsMonths = { ...state.chartsMonths, [selected.deviceId]: chartMonths };

    return {
      ...state,
      selectedConsumptions,
      chartsMonths,
    };
  }),

  on(fromActions.SetChartViewMonthsCount, (state, { chartMonthsCount }): IConsumptionState => {
    const chartViewMonthsCount = chartMonthsCount;

    return {
      ...state,
      chartViewMonthsCount,
    };
  }),

  on(fromActions.SetSelectedTimeframe, (state, { timeframe, deviceId }): IConsumptionState => {
    const selectedConsumptionTimeframe = { ...state.selectedConsumptionTimeframe };
    selectedConsumptionTimeframe[deviceId] = timeframe;
    const meter = state.selectedMeters.find(el => el.deviceId === deviceId);
    const selectedConsumption = state.selectedConsumptions[deviceId];
    const selectedPeriodsForRequests = { ...state.selectedPeriodsForRequests };
    const selectedPeriodsForCharts = { ...state.selectedPeriodsForCharts };

    let periodForChart: IPeriod;
    if (timeframe === ConsumptionTimeframe.MONTH) {
      periodForChart = {
        start: startOfMonthMilliseconds(selectedConsumption.consumption.period.start),
        end: endOfMonthMilliseconds(selectedConsumption.consumption.period.end),
      };
    } else if (timeframe === ConsumptionTimeframe.YEAR) {
      periodForChart = {
        start: startOfYearMilliseconds(selectedConsumption.consumption.period.start),
        end: endOfYearMilliseconds(selectedConsumption.consumption.period.end),
      };
    }

    const periodForRequest: IPeriod = {
      start: Math.max(
        state.selectedContract.contractStartTime,
        meter.firstEntry,
        periodForChart.start
      ),
      end: Math.min(
        state.selectedContract.contractEndTime || Date.now(),
        meter.lastEntry,
        periodForChart.end
      ),
    };

    selectedPeriodsForCharts[deviceId] = periodForChart;
    selectedPeriodsForRequests[deviceId] = periodForRequest;
    return {
      ...state,
      selectedConsumptionTimeframe,
      selectedPeriodsForRequests,
      selectedPeriodsForCharts,
    };
  })
);

export const getCurrentMonthConsumption = (state: IConsumptionState) =>
  state.currentMonthConsumption;
export const getCurrentMonthConsumptionActionState = (state: IConsumptionState) =>
  state.currentMonthConsumptionActionState;

export const getSelectedConsumptions = (state: IConsumptionState) => state.selectedConsumptions;

export const getSelectedConsumptionForFirstMeter = (
  selectedConsumptions: ByDeviceId<IConsumption>,
  meter: IConsumptionInfoItem
) => {
  return selectedConsumptions[meter.deviceId];
};

export const getShowPreviousArrowForFirstMeter = (
  state: IConsumptionState,
  meter: IConsumptionInfoItem
) => {
  return state.showPreviousArrow[meter.deviceId];
};
export const getShowNextArrowForFirstMeter = (
  state: IConsumptionState,
  meter: IConsumptionInfoItem
) => {
  return state.showNextArrow[meter.deviceId];
};

export const getMetersByContract = (state: IConsumptionState) => state.metersByContract;
export const getMetersByContractActionState = (state: IConsumptionState) =>
  state.metersByContractActionState;

export const getAvailableMeters = (state: IConsumptionState) => state.availableMeters;
export const getSelectedMeters = (state: IConsumptionState) => state.selectedMeters;
export const getFirstSelectedMeter = (selectedMeters: IConsumptionInfoItem[]) => selectedMeters[0];

export const getSelectedPeriodConsumptions = (state: IConsumptionState) => {
  return state.selectedPeriodConsumptions;
};

export const getSelectedPeriodConsumptionForFirstMeter = (
  selectedPeriodConsumptions: ByDeviceId<IConsumptionItem[]>,
  meter: IConsumptionInfoItem
) => {
  return selectedPeriodConsumptions[meter.deviceId];
};

export const getSelectedPeriodPastConsumptions = (state: IConsumptionState) => {
  return state.selectedPeriodPastConsumptions;
};

export const getSelectedPeriodPastConsumptionForFirstMeter = (
  selectedPeriodPastConsumptions: ByDeviceId<IConsumptionItem[]>,
  meter: IConsumptionInfoItem
) => {
  return selectedPeriodPastConsumptions[meter.deviceId];
};

export const getSelectedPeriodConsumptionBenchmarks = (state: IConsumptionState) => {
  return state.selectedPeriodConsumptionBenchmarks;
};

export const getSelectedPeriodConsumptionBenchmarkForFirstMeter = (
  selectedPeriodConsumptionBenchmarks: ByDeviceId<IConsumptionBenchmarkItemResponse[]>,
  meter: IConsumptionInfoItem
) => {
  return selectedPeriodConsumptionBenchmarks[meter.deviceId];
};

export const getSavedConsumptionData = (state: IConsumptionState) => state.savedConsumptionData;

export const getSelectedPeriodsForCharts = (state: IConsumptionState) =>
  state.selectedPeriodsForCharts;
export const getSelectedPeriodsForRequests = (state: IConsumptionState) =>
  state.selectedPeriodsForRequests;

export const getSelectedPeriodForChartForFirstMeter = (
  state: IConsumptionState,
  meter: IConsumptionInfoItem
) => {
  return state.selectedPeriodsForCharts[meter?.deviceId];
};
export const getSelectedPeriodForRequestForFirstMeter = (
  state: IConsumptionState,
  meter: IConsumptionInfoItem
) => {
  return state.selectedPeriodsForRequests[meter?.deviceId];
};

export const getSavedDataForContractDeviceAndPeriodForFirstMeter = (
  state: IConsumptionState,
  meter: IConsumptionInfoItem
) => {
  let consumptionData = undefined;
  if (state.savedConsumptionData) {
    const contractId = state.selectedContract.id;
    const contractData = state.savedConsumptionData[contractId];
    if (contractData) {
      const deviceData = contractData[meter.deviceId];
      if (deviceData) {
        const periodForChart = state.selectedPeriodsForCharts[meter.deviceId];
        consumptionData = deviceData.find(
          el =>
            el.selectedPeriod.start === periodForChart?.start &&
            el.selectedPeriod.end === periodForChart?.end
        );
      }
    }
  }
  return consumptionData;
};

export const getChartMonthsForFirstMeter = (
  state: IConsumptionState,
  meter: IConsumptionInfoItem
) => {
  return state.chartsMonths[meter.deviceId];
};

export const getDashboardDataForContractDeviceAndPeriodForFirstMeter = (
  state: IConsumptionState,
  meter: IConsumptionInfoItem
) => {
  let consumptionData = undefined;
  if (state.savedConsumptionData) {
    const contractId = state.selectedContract.id;
    const contractData = state.savedConsumptionData[contractId];
    if (contractData) {
      const deviceData = contractData[meter.deviceId];
      if (deviceData) {
        consumptionData = deviceData[0];
      }
    }
  }
  return consumptionData;
};
export const getDashboardChartMonthsForFirstMeter = (
  state: IConsumptionState,
  meter: IConsumptionInfoItem
) => {
  return state.dashboardChartsMonths[meter.deviceId];
};

export const getSelectedPeriodConsumptionActionStates = (state: IConsumptionState) =>
  state.selectedPeriodConsumptionActionStates;

export const getSelectedPeriodConsumptionActionStateForFirstMeter = (
  selectedPeriodConsumptionActionStates: ByDeviceId<IActionState>,
  meter: IConsumptionInfoItem
) => selectedPeriodConsumptionActionStates[meter.deviceId];

export const getSelectedPeriodPastConsumptionActionStates = (state: IConsumptionState) =>
  state.selectedPeriodPastConsumptionActionStates;

export const getSelectedPeriodPastConsumptionActionStateForFirstMeter = (
  selectedPeriodPastConsumptionActionStates: ByDeviceId<IActionState>,
  meter: IConsumptionInfoItem
) => selectedPeriodPastConsumptionActionStates[meter.deviceId];

export const getSelectedPeriodConsumptionBenchmarkActionStates = (state: IConsumptionState) =>
  state.selectedPeriodConsumptionBenchmarkActionStates;

export const getSelectedPeriodConsumptionBenchmarkActionStateForFirstMeter = (
  selectedPeriodConsumptionBenchmarkActionStates: ByDeviceId<IActionState>,
  meter: IConsumptionInfoItem
) => selectedPeriodConsumptionBenchmarkActionStates[meter.deviceId];

export const getSelectedTimeframe = (state: IConsumptionState) =>
  state.selectedConsumptionTimeframe;
