import { Component, Input } from '@angular/core';
import { ExcerptPipe, IPublishedAnnouncement } from '@resident-nx/shared';
import { CardFooterWebComponent } from '../card-footer/card-footer.component';
import { LabelWebComponent } from '../../atoms/label/label.component';

@Component({
  selector: 'rs-web-announcements-card',
  templateUrl: './announcements-card.component.html',
  styleUrls: ['./announcements-card.component.scss'],
  standalone: true,
  imports: [LabelWebComponent, CardFooterWebComponent, ExcerptPipe],
})
export class AnnouncementsCardWebComponent {
  @Input() item: IPublishedAnnouncement;
}
