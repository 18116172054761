import { IContactPersonResponse, IPageInfo } from '../general';

export interface IEmergencyContactExistsResponseBean {
  emergencyContactExists: IEmergencyContactExistsResponse;
}

export interface IEmergencyContactExistsResponse {
  emergencyContactExists: boolean;
}

export interface IEmergencyContactsResponseBean {
  edges: IEmergencyContactsEdge[];
  pageInfo: IPageInfo;
}

export interface IEmergencyContactsEdge {
  node: IContactPersonResponse;
  cursor: string;
}

export interface IEmergencyContactsResponse {
  contacts: IContactPersonResponse[];
  hasNextPage: boolean;
}
