import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { SearchService } from '../../services';
import * as fromActions from './search.actions';

@Injectable()
export class SearchEffects {
  constructor(
    private actions$: Actions,
    private search: SearchService
  ) {}

  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.search),
      map(({ term }) => {
        const results = this.search.search(term);
        if (results) {
          return fromActions.searchSuccess({
            results,
          });
        }
        return fromActions.searchFailNoResults();
      })
    )
  );
}
