import { createAction, props } from '@ngrx/store';
import { DocumentSortField, ISort, TicketSortField } from '../../../models';

export const setDocumentSorting = createAction(
  '[Sorting] Set Document Sorting',
  props<{ sorting: ISort<DocumentSortField> }>()
);

export const setTicketSorting = createAction(
  '[Sorting] Set Ticket Sorting',
  props<{ sorting: ISort<TicketSortField> }>()
);

export const resetDocumentSorting = createAction('[Sorting] Reset Document Sorting');
export const resetTicketSorting = createAction('[Sorting] Reset Ticket Sorting');
