import { Directive, Input, OnInit } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IContract } from '../../models';
import * as fromConsumptionState from '../../+state/consumption';
import { getContractsCount, getSelectedContract } from '../../+state';

@UntilDestroy()
@Directive()
export class SharedDropDownMenuBaseComponent implements OnInit {
  @Input() title = 'shared_content_header_l';
  @Input() showSortIcon = true;
  @Input() showContextIcon = true;

  public selectedContract$: Observable<IContract>;
  public contractsCount$: Observable<number>;

  constructor(private store: Store<fromConsumptionState.IConsumptionState>) {}

  ngOnInit() {
    this.selectedContract$ = this.store.select(getSelectedContract).pipe(untilDestroyed(this));
    this.contractsCount$ = this.store.select(getContractsCount).pipe(untilDestroyed(this));
  }
}
