<div class="d-flex">
  @for (item of tabItems; track item.title; let i = $index) {
    <div
      [id]="'item' + i"
      (click)="select(item.index)"
      class="tab-bar-item h4 d-flex justify-content-center"
      [class.active]="selectedIndex === item.index"
    >
      {{ item.title | translate }}
    </div>
  }
</div>
