import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'rs-web-ticket-details-chat-loader',
  template: `
    <div class="chat-loader">
      @for (i of [].constructor(this.value()); track i) {
        <div class="chat-loader__skeleton">
          <div class="chat-loader__skeleton-img"></div>
          <div class="chat-loader__skeleton-message-wrapper">
            <div class="skeleton-message"></div>
            <div class="skeleton-message"></div>
          </div>
        </div>
      }
    </div>
  `,
  styleUrl: './ticket-details-chat-loader.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketDetailsChatLoaderComponent {
  value = input(3);
}
