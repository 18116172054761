<div class="main-container container-light">
  <rs-web-header
    class="header"
    [userData$]="userData$"
    (profileSettingsToggleClick)="onProfileSettingsToggleClick($event)"
  ></rs-web-header>
  <div class="sidenav-container">
    <rs-web-navigation [navigationItems]="navigationItems" [dark]="true"></rs-web-navigation>
    <div class="sidenav-container__main-content">
      <div class="content-container">
        <router-outlet></router-outlet>
      </div>
    </div>
    <rs-web-navigation
      [navigationItems]="profileSettingsItems"
      [alignment]="navigationAlignment['RIGHT']"
      [overlay]="true"
      [(open)]="profileSettingsOpen"
    >
      <div navigationHeader class="side-navigation-header">
        @if (userData$ | async; as user) {
          <rs-web-avatar
            class="header__profile-settings-toggle"
            [name]="user"
            [size]="avatarSize['MEDIUM']"
            [rounded]="true"
            (click)="onProfileSettingsToggleClick($event)"
          ></rs-web-avatar>
          <span class="primary-text mt-2"
            ><strong>{{ user.firstName }} {{ user.name }}</strong></span
          >
          <span class="d-inline-block w-100 secondary-text text-truncate text-center">{{
            user.email
          }}</span>
        }
      </div>
      <rs-web-footer
        navigationFooter
        [sideNavMode]="true"
        [links]="footerItems"
        (profileSettingsToggleClick)="onProfileSettingsToggleClick($event)"
      ></rs-web-footer>
    </rs-web-navigation>
  </div>
  <rs-web-mobile-navigation
    class="mobile-navigation"
    [navigationItems]="navigationItems"
  ></rs-web-mobile-navigation>
</div>
