import { Directive } from '@angular/core';
import { RouterService } from '../../services';
import { ActivatedRoute } from '@angular/router';
import { AuthService, getContracts, getResidentAppSettings } from '../../../shared';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, take } from 'rxjs';

@Directive()
export class UserPageBaseComponent {
  public hideEditProfileButton$: Observable<boolean>;

  constructor(
    public routerService: RouterService,
    protected activeRoute: ActivatedRoute,
    public authService: AuthService,
    private store: Store
  ) {
    this.hideEditProfileButton$ = combineLatest([
      this.store.select(getContracts),
      this.store.select(getResidentAppSettings),
    ]).pipe(
      map(([contracts, residentAppSettings]) => {
        return (
          contracts?.length === 0 &&
          !residentAppSettings?.allowContractlessTicketCreationForOtherConcerns
        );
      })
    );
  }

  public goToProfile() {
    void this.routerService.navigate(['profile'], { relativeTo: this.activeRoute });
  }

  public goToPrivacy() {
    void this.routerService.navigate(['privacy'], { relativeTo: this.activeRoute });
  }

  public goToDelete() {
    void this.routerService.navigate(['delete'], { relativeTo: this.activeRoute });
  }

  public goToLegal() {
    void this.routerService.navigate(['legal'], { relativeTo: this.activeRoute });
  }

  public goToFaq() {
    void this.routerService.navigate(['faq'], { relativeTo: this.activeRoute });
  }

  public goBack(): void {
    this.routerService.back();
  }
}
