export const CUSTOM_TRANSLATIONS = [
  {
    key: 'registration.success_l',
    value: 'resident_shared.registration_success_l',
  },
  {
    key: 'registration.failed_l',
    value: 'resident_shared.registration_failed_l',
  },
  {
    key: 'check_contract_partner_numbers_success_l',
    value: 'resident_shared.check_contract_partner_numbers_success_l',
  },
  {
    key: 'check_contract_partner_numbers_failed_l',
    value: 'resident_shared.check_contract_partner_numbers_failed_l',
  },
  {
    key: 'registration.resend_confirm_email_success_m',
    value: 'resident_shared.registration.resend_confirm_email_success_m',
  },
  {
    key: 'registration.resend_confirm_email_error_m',
    value: 'resident_shared.registration.resend_confirm_email_error_m',
  },
  {
    key: 'registration.confirmation_success_l',
    value: 'resident_shared.registration.confirmation_success_l',
  },
  {
    key: 'registration.confirmation_error_l',
    value: 'resident_shared.registration.confirmation_error_l',
  },
  {
    key: 'authentication.init_reset_password_error_m',
    value: 'resident_shared.authentication.init_reset_password_error_m',
  },
  {
    key: 'authentication.init_reset_password_success_m',
    value: 'resident_shared.authentication.init_reset_password_success_m',
  },
  {
    key: 'user_profile.change_password_success_m',
    value: 'resident_shared.user_profile.change_password_success_m',
  },
  {
    key: 'user_profile.change_password_error_m',
    value: 'resident_shared.user_profile.change_password_error_m',
  },
  {
    key: 'user-profile.name',
    value: 'resident_shared.user-profile.name',
  },
  {
    key: 'user-profile.address',
    value: 'resident_shared.user-profile.address',
  },
  {
    key: 'mobile_phone',
    value: 'resident_shared.mobile_phone',
  },
  {
    key: 'phone',
    value: 'resident_shared.phone',
  },
  {
    key: 'general.email',
    value: 'resident_shared.general.email',
  },
  {
    key: 'general.save_changes',
    value: 'resident_shared.general.save_changes',
  },
  {
    key: 'general.log_in_now',
    value: 'resident_shared.general.log_in_now',
  },
  {
    key: 'general.password',
    value: 'resident_shared.general.password',
  },
  {
    key: 'sign_in_header',
    value: 'resident_shared.sign_in_header',
  },
  { key: 'navigation.collapse_sidebar_a', value: 'resident_shared.navigation.collapse_sidebar_a' },
  { key: 'navigation.expand_sidebar_a', value: 'resident_shared.navigation.expand_sidebar_a' },
  { key: 'settings.delete_account', value: 'resident_shared.settings.delete_account' },
  {
    key: 'pw-check.error.pw-has_number_special',
    value: 'resident_shared.pw-check.error.pw-has_number_special',
  },
  {
    key: 'pw-check.error.pw-has_lower_upper_case',
    value: 'resident_shared.pw-check.error.pw-has_lower_upper_case',
  },
  {
    key: 'pw-check.error.pw-min_length',
    value: 'resident_shared.pw-check.error.pw-min_length',
  },
  {
    key: 'pw_reset_form.current_pw',
    value: 'resident_shared.pw_reset_form.current_pw',
  },
  {
    key: 'pw_reset_form.pw',
    value: 'resident_shared.pw_reset_form.pw',
  },
  {
    key: 'pw_reset_form.pw_confirm',
    value: 'resident_shared.pw_reset_form.pw_confirm',
  },
  {
    key: 'user_delete.urgent_notice',
    value: 'resident_shared.user_delete.urgent_notice',
  },
  {
    key: 'user_delete.title',
    value: 'resident_shared.user_delete.title',
  },
  {
    key: 'user_delete.body',
    value: 'resident_shared.user_delete.body',
  },
  {
    key: 'user_delete.confirm',
    value: 'resident_shared.user_delete.confirm',
  },
  {
    key: 'user_delete.confirmation_input_l',
    value: 'resident_shared.user_delete.confirmation_input_l',
  },
  {
    key: 'user_delete.confirmation_input_hint',
    value: 'resident_shared.user.lastname',
  },
  {
    key: 'update_masterdata.success_m',
    value: 'resident_shared.update_masterdata.success_m',
  },
  {
    key: 'update_masterdata.error_m',
    value: 'resident_shared.update_masterdata.error_m',
  },
  {
    key: 'pw_reset_form.change_pw',
    value: 'resident_shared.pw_reset_form.change_pw',
  },
  {
    key: 'pw-check.title_l',
    value: 'resident_shared.pw-check.title_l',
  },
  {
    key: 'main_navigation.legal-info_l',
    value: 'resident_shared.main_navigation.legal-info_l',
  },
  {
    key: 'main_navigation.user_faq_l',
    value: 'resident_shared.main_navigation.user_faq_l',
  },
  {
    key: 'main_navigation.dashboard_l',
    value: 'resident_shared.main_navigation.dashboard_l',
  },
  {
    key: 'main_navigation.contracts_l',
    value: 'resident_shared.main_navigation.contracts_l',
  },
  {
    key: 'main_navigation.contract_l',
    value: 'resident_shared.main_navigation.contract_l',
  },
  {
    key: 'legal.agb',
    value: 'resident_shared.legal.agb',
  },
  {
    key: 'legal.privacy_policy',
    value: 'resident_shared.legal.privacy_policy',
  },
  {
    key: 'legal.terms_of_use',
    value: 'resident_shared.legal.terms_of_use',
  },
  {
    key: 'legal.impress',
    value: 'resident_shared.legal.impress',
  },
  {
    key: 'faq.header_title',
    value: 'resident_shared.faq.header_title',
  },
  {
    key: 'user.settings',
    value: 'resident_shared.user.settings',
  },
  {
    key: 'user.more_information',
    value: 'resident_shared.user.more_information',
  },
  {
    key: 'user.personal_data',
    value: 'resident_shared.user.personal_data',
  },
  {
    key: 'user.notifications',
    value: 'resident_shared.user.notifications',
  },
  {
    key: 'user.privacy',
    value: 'resident_shared.user.privacy',
  },
  {
    key: 'user.delete_account',
    value: 'resident_shared.user.delete_account',
  },
  {
    key: 'user.legal_info',
    value: 'resident_shared.user.legal_info',
  },
  {
    key: 'user.faq',
    value: 'resident_shared.user.faq',
  },
  {
    key: 'faq.read_more',
    value: 'resident_shared.faq.read_more',
  },
  {
    key: 'contracts.overview_header',
    value: 'resident_shared.contracts.overview_header',
  },
  {
    key: 'contract.agents.header',
    value: 'resident_shared.contract.agents.header',
  },
  {
    key: 'contract.type.flat',
    value: 'resident_shared.contract.type.flat',
  },
  {
    key: 'contract.payment.type.rent',
    value: 'resident_shared.contract.payment.type.rent',
  },
  {
    key: 'contract.payment.type.others',
    value: 'resident_shared.contract.payment.type.others',
  },
  {
    key: 'contract.payment.type.heating_prepayment',
    value: 'resident_shared.contract.payment.type.heating_prepayment',
  },
  {
    key: 'contract.payment.type.service_payment',
    value: 'resident_shared.contract.payment.type.service_payment',
  },
  {
    key: 'hierarchical_questions.answer_boolean.option_yes_l',
    value: 'hierarchical_questions.answer_boolean.option_yes_l',
  },
  {
    key: 'hierarchical_questions.answer_boolean.option_no_l',
    value: 'hierarchical_questions.answer_boolean.option_no_l',
  },
  {
    key: 'general.offline',
    value: 'resident_shared.general.offline',
  },
  {
    key: 'hierarchical_questions.answer_boolean.option_yes_l',
    value: 'hierarchical_questions.answer_boolean.option_yes_l',
  },
  {
    key: 'hierarchical_questions.answer_boolean.option_no_l',
    value: 'hierarchical_questions.answer_boolean.option_no_l',
  },
  {
    key: 'ticket_details_description.show_less',
    value: 'resident_shared.less',
  },
  {
    key: 'ticket_details_description.description',
    value: 'resident_wankendorfer.demage.description',
  },
  {
    key: 'ticket_details.yes_confirmation',
    value: 'hierarchical_questions.answer_boolean.option_yes_l',
  },
  {
    key: 'ticket_details.no_confirmation',
    value: 'hierarchical_questions.answer_boolean.option_no_l',
  },
  {
    key: 'service.on_document_download_fail_l',
    value: 'resident_shared.service.on_document_download_fail_l',
  },
  {
    key: 'contract.documents.overview_header',
    value: 'resident_shared.contract.documents.overview_header',
  },
  {
    key: 'contract.sepa.issued_at.prefix',
    value: 'resident_shared.contract.sepa.issued_at.prefix',
  },
  {
    key: 'contract.sepa.issued_at.suffix',
    value: 'resident_shared.contract.sepa.issued_at.suffix',
  },
  {
    key: 'contract.sepa.issued_at.suffix',
    value: 'resident_shared.contract.sepa.issued_at.suffix',
  },
  {
    key: 'contract.payment.chart.header',
    value: 'resident_shared.contract.payment.chart.header',
  },
  {
    key: 'contract.payment.chart.total',
    value: 'resident_shared.contract.payment.chart.total',
  },
  {
    key: 'document.type.requested_document',
    value: 'resident_shared.document.type.requested_document',
  },
  {
    key: 'document.type.rental_contract',
    value: 'resident_shared.document.type.rental_contract',
  },
  {
    key: 'document.type.rental_contract_flat',
    value: 'resident_shared.document.type.rental_contract_flat',
  },
  {
    key: 'document.type.rental_contract_garage',
    value: 'resident_shared.document.type.rental_contract_garage',
  },
  {
    key: 'document.type.rental_contract_commercial',
    value: 'resident_shared.document.type.rental_contract_commercial',
  },
  {
    key: 'ticket.meeting.preference',
    value: 'resident_shared.ticket.meeting.preference',
  },
  {
    key: 'ticket.meeting.morning',
    value: 'resident_shared.ticket.meeting.morning',
  },
  {
    key: 'ticket.meeting.afternoon',
    value: 'resident_shared.ticket.meeting.afternoon',
  },
  {
    key: 'ticket.meeting.evening',
    value: 'resident_shared.ticket.meeting.evening',
  },
  {
    key: 'ticket.meeting.full_time',
    value: 'resident_shared.ticket.meeting.full_time',
  },
  {
    key: 'ticket.damage.images',
    value: 'resident_shared.ticket.damage.images',
  },
  {
    key: 'ticket.damage.review.question_completed',
    value: 'resident_shared.ticket.damage.review.question_completed',
  },
  {
    key: 'ticket.state.open',
    value: 'resident_shared.ticket.state.open',
  },
  {
    key: 'ticket.state.closed',
    value: 'resident_shared.ticket.state.closed',
  },
  {
    key: 'ticket.state.in_progress',
    value: 'resident_shared.ticket.state.in_progress',
  },
  {
    key: 'ticket.state.cancelled',
    value: 'resident_shared.ticket.state.cancelled',
  },
  {
    key: 'ticket.state.waiting_for_others',
    value: 'resident_shared.ticket.state.waiting_for_others',
  },
  {
    key: 'ticket.state.unexpected',
    value: 'resident_shared.ticket.state.unexpected',
  },
  {
    key: 'ticket.widget.title',
    value: 'resident_shared.ticket.widget.title',
  },
  {
    key: 'ticket.widget.menu.damage',
    value: 'resident_shared.ticket.widget.menu.damage',
  },
  {
    key: 'ticket.widget.menu.concern',
    value: 'resident_shared.ticket.widget.menu.concern',
  },
  {
    key: 'ticket.widget.menu.question',
    value: 'resident_shared.ticket.widget.menu.question',
  },
  {
    key: 'yes',
    value: 'resident_shared.yes',
  },
  {
    key: 'no',
    value: 'resident_shared.no',
  },
  {
    key: 'next',
    value: 'resident_shared.general.continue_a',
  },
  {
    key: 'utilities.tv',
    value: 'resident_shared.utilities.tv',
  },
  {
    key: 'utilities.electricity',
    value: 'resident_shared.utilities.electricity',
  },
  {
    key: 'utilities.cold_water',
    value: 'resident_shared.utilities.cold_water',
  },
  {
    key: 'utilities.hot_water',
    value: 'resident_shared.utilities.hot_water',
  },
  {
    key: 'utilities.heating',
    value: 'resident_shared.utilities.heating',
  },
  {
    key: 'utilities.gas',
    value: 'resident_shared.utilities.gas',
  },
  {
    key: 'utilities.phone',
    value: 'resident_shared.utilities.phone',
  },
  {
    key: 'miscellaneous',
    value: 'resident_shared.miscellaneous',
  },
  {
    key: 'building',
    value: 'resident_shared.building',
  },
  {
    key: 'outdoor_facility',
    value: 'resident_shared.outdoor_facility',
  },
  {
    key: 'garage_parking_space',
    value: 'resident_shared.garage_parking_space',
  },
  {
    key: 'step',
    value: 'resident_shared.step',
  },
  {
    key: 'street',
    value: 'resident_shared.street',
  },
  {
    key: 'number_short',
    value: 'resident_shared.number_short',
  },
  {
    key: 'apartment_number',
    value: 'resident_shared.apartment_number',
  },
  {
    key: 'floor',
    value: 'resident_shared.floor',
  },
  {
    key: 'outdoor_facilities.street_side',
    value: 'resident_shared.outdoor_facilities.street_side',
  },
  {
    key: 'outdoor_facilities.back',
    value: 'resident_shared.outdoor_facilities.back',
  },
  {
    key: 'outdoor_facilities.gable_end',
    value: 'resident_shared.outdoor_facilities.gable_end',
  },
  {
    key: 'outdoor_facilities.patio',
    value: 'resident_shared.outdoor_facilities.patio',
  },
  {
    key: 'outdoor_facilities_detailed.playground',
    value: 'resident_shared.outdoor_facilities_detailed.playground',
  },
  {
    key: 'outdoor_facilities_detailed.bicycle_parking_space',
    value: 'resident_shared.outdoor_facilities_detailed.bicycle_parking_space',
  },
  {
    key: 'outdoor_facilities_detailed.garbage_stand',
    value: 'resident_shared.outdoor_facilities_detailed.garbage_stand',
  },
  {
    key: 'outdoor_facilities_detailed.laundry_place',
    value: 'resident_shared.outdoor_facilities_detailed.laundry_place',
  },
  {
    key: 'outdoor_facilities_detailed.entrance_area',
    value: 'resident_shared.outdoor_facilities_detailed.entrance_area',
  },
  {
    key: 'outdoor_facilities_detailed.green_space',
    value: 'resident_shared.outdoor_facilities_detailed.green_space',
  },
  {
    key: 'outdoor_facilities_detailed.traffic_area',
    value: 'resident_shared.outdoor_facilities_detailed.traffic_area',
  },
  {
    key: 'outdoor_facilities_detailed.property_boundary',
    value: 'resident_shared.outdoor_facilities_detailed.property_boundary',
  },
  {
    key: 'describe_damage_title',
    value: 'resident_shared.describe_damage_title',
  },
  {
    key: 'parking_space_type.above_ground',
    value: 'resident_shared.parking_space_type.above_ground',
  },
  {
    key: 'parking_space_type.underground',
    value: 'resident_shared.parking_space_type.underground',
  },
  {
    key: 'parking_space_type.garage',
    value: 'resident_shared.parking_space_type.garage',
  },
  {
    key: 'parking_space_type.roadway',
    value: 'resident_shared.parking_space_type.roadway',
  },
  {
    key: 'parking_space_type.driveway',
    value: 'resident_shared.parking_space_type.driveway',
  },
  {
    key: 'pitch_number',
    value: 'resident_shared.pitch_number',
  },
  {
    key: 'garage_number',
    value: 'resident_shared.garage_number',
  },
  {
    key: 'apartament_place.bathroom',
    value: 'resident_shared.apartament_place.bathroom',
  },
  {
    key: 'apartament_place.living_room',
    value: 'resident_shared.apartament_place.living_room',
  },
  {
    key: 'apartament_place.bedroom',
    value: 'resident_shared.apartament_place.bedroom',
  },
  {
    key: 'apartament_place.childrens_study_room',
    value: 'resident_shared.apartament_place.childrens_study_room',
  },
  {
    key: 'apartament_place.corridor',
    value: 'resident_shared.apartament_place.corridor',
  },
  {
    key: 'apartament_place.storage_room_2',
    value: 'resident_shared.apartament_place.storage_room_2',
  },
  {
    key: 'apartament_place.kitchen',
    value: 'resident_shared.apartament_place.kitchen',
  },
  {
    key: 'apartament_place.balcony_terrace_loggia',
    value: 'resident_shared.apartament_place.balcony_terrace_loggia',
  },
  {
    key: 'damaged_text',
    value: 'resident_shared.damaged_text',
  },
  {
    key: 'appointment_preferences',
    value: 'resident_shared.appointment_preferences',
  },
  {
    key: 'personal_information_text',
    value: 'resident_shared.personal_information_text',
  },
  {
    key: 'general.done',
    value: 'resident_shared.general.done',
  },
  {
    key: 'general.open',
    value: 'resident_shared.general.open',
  },
  {
    key: 'general.close',
    value: 'resident_shared.general.close',
  },
  {
    key: 'archive.damage_header_text',
    value: 'resident_shared.archive.damage_header_text',
  },
  {
    key: 'archive.concern_header_text',
    value: 'resident_shared.archive.concern_header_text',
  },
  {
    key: 'general.archive',
    value: 'resident_shared.general.archive',
  },
  {
    key: 'general.back_a',
    value: 'resident_shared.general.back_a',
  },
  {
    key: 'archive_damage.no_data',
    value: 'resident_shared.archive_damage.no_data',
  },
  {
    key: 'active_damage.no_data',
    value: 'resident_shared.active_damage.no_data',
  },
  {
    key: 'archive_request.no_data',
    value: 'resident_shared.archive_request.no_data',
  },
  {
    key: 'active_request.no_data',
    value: 'resident_shared.active_request.no_data',
  },
  {
    key: 'search.category.news',
    value: 'resident_shared.search.category.news',
  },
  {
    key: 'search.category.offer',
    value: 'resident_shared.search.category.offer',
  },
  {
    key: 'search.category.announcement',
    value: 'resident_shared.search.category.announcement',
  },
  {
    key: 'search.category.damage_ticket',
    value: 'resident_shared.search.category.damage_ticket',
  },
  {
    key: 'search.category.request_ticket',
    value: 'resident_shared.search.category.request_ticket',
  },
  {
    key: 'search.category.contract',
    value: 'resident_shared.search.category.contract',
  },
  {
    key: 'search.category.function',
    value: 'resident_shared.search.category.function',
  },
  {
    key: 'consent.start',
    value: 'resident_shared.consent.start',
  },
  {
    key: 'consent.privacy-policy',
    value: 'resident_shared.consent.privacy-policy',
  },
  {
    key: 'consent.combining-filler',
    value: 'resident_shared.consent.combining-filler',
  },
  {
    key: 'consent.start_with_contact_name',
    value: 'resident_shared.consent.start_with_contact_name',
  },
  {
    key: 'consent.conditions',
    value: 'resident_shared.consent.conditions',
  },
  {
    key: 'consent.end',
    value: 'resident_shared.consent.end',
  },
  {
    key: 'user_profile.delete_user_success_m',
    value: 'resident_shared.user_profile.delete_user_success_m',
  },
  {
    key: 'user_profile.load_legal_texts_error_m',
    value: 'resident_shared.user_profile.load_legal_texts_error_m',
  },
  {
    key: 'user_profile.change_email_error_m',
    value: 'resident_shared.user_profile.change_email_error_m',
  },
  {
    key: 'user_profile.delete_user_error_m',
    value: 'resident_shared.user_profile.delete_user_error_m',
  },
  {
    key: 'general.error_undefined',
    value: 'resident_shared.general.error_undefined',
  },
  {
    key: 'general.error_undefined_title',
    value: 'resident_shared.general.error_undefined_title',
  },
  {
    key: 'auth.error_wrong_credentials',
    value: 'resident_shared.auth.error_wrong_credentials',
  },
  {
    key: 'unexpected_error_m',
    value: 'resident_shared.unexpected_error_m',
  },
  {
    key: 'ticket_active_concerns_l',
    value: 'resident_shared.ticket_active_concerns_l',
  },
  {
    key: 'ticket_active_damages_l',
    value: 'resident_shared.ticket_active_damages_l',
  },
  {
    key: 'ticket_concerns_l',
    value: 'resident_shared.ticket_concerns_l',
  },
  {
    key: 'ticket_damages_l',
    value: 'resident_shared.ticket_damages_l',
  },
  {
    key: 'user_profile.date_of_birth_l',
    value: 'resident_shared.user_profile.date_of_birth_l',
  },
  {
    key: 'user_profile.contact_details_l',
    value: 'resident_shared.user_profile.contact_details_l',
  },
  {
    key: 'user_profile.escrowed_account_l',
    value: 'resident_shared.user_profile.escrowed_account_l',
  },
  {
    key: 'user_profile.payment_information_l',
    value: 'resident_shared.user_profile.payment_information_l',
  },
  {
    key: 'user_profile.address_l',
    value: 'resident_shared.user_profile.address_l',
  },
  {
    key: 'user_profile.sepa_direct_debit_mandate_l',
    value: 'resident_shared.user_profile.sepa_direct_debit_mandate_l',
  },
  {
    key: 'user_profile.indefinitely_since_l',
    value: 'resident_shared.user_profile.indefinitely_since_l',
  },
  {
    key: 'user_profile.lastname',
    value: 'resident_shared.user_profile.lastname',
  },
  {
    key: 'user_profile.request_change_a',
    value: 'resident_shared.user_profile.request_change_a',
  },
  {
    key: 'user_profile.load_personal_data_error_m',
    value: 'resident_shared.user_profile.load_personal_data_error_m',
  },
  {
    key: 'general.in_l',
    value: 'resident_shared.general.in_l',
  },
  {
    key: 'register.request_invitation_invalid_input',
    value: 'resident_shared.register.request_invitation_invalid_input',
  },
  {
    key: 'general.reset_password',
    value: 'resident_shared.general.reset_password',
  },
  {
    key: 'general.reset_password_a',
    value: 'resident_shared.general.reset_password_a',
  },
  {
    key: 'guest_mode.no_access_a',
    value: 'resident_shared.guest_mode.no_access_a',
  },
  {
    key: 'settings.logout',
    value: 'resident_shared.settings.logout',
  },
  {
    key: 'auth.forgot_password',
    value: 'resident_shared.auth.forgot_password',
  },
  {
    key: 'service.send_reset_pw_link',
    value: 'resident_shared.service.send_reset_pw_link',
  },
  {
    key: 'tickets.report_damage',
    value: 'resident_shared.tickets.report_damage',
  },
  {
    key: 'tickets.report_concern',
    value: 'resident_shared.tickets.report_concern',
  },
  {
    key: 'select_hint_l',
    value: 'resident_shared.select_hint_l',
  },
  {
    key: 'no_access_please_login',
    value: 'resident_shared.no_access_please_login',
  },
  {
    key: 'to_login_a',
    value: 'resident_shared.to_login_a',
  },
  {
    key: 'ticket.b_and_o_title_l',
    value: 'resident_shared.ticket.b_and_o_title_l',
  },
  {
    key: 'ticket.b_and_o_short_description_l',
    value: 'resident_shared.ticket.b_and_o_short_description_l',
  },
  {
    key: 'ticket.b_and_o_button',
    value: 'resident_shared.ticket.b_and_o_button',
  },
  {
    key: 'ticket.b_and_o_header_l',
    value: 'resident_shared.ticket.b_and_o_header_l',
  },
  {
    key: 'apartments',
    value: 'resident_shared.apartments',
  },
  {
    key: 'to_apartments',
    value: 'resident_shared.to_apartments',
  },
  {
    key: 'location',
    value: 'resident_shared.location',
  },
  {
    key: 'zip',
    value: 'resident_shared.zip',
  },
  {
    key: 'user.profile_details_l',
    value: 'resident_shared.user.profile_details_l',
  },
  {
    key: 'account.edit_data_a',
    value: 'resident_shared.account.edit_data_a',
  },
  {
    key: 'tab.contracts_l',
    value: 'resident_shared.tab.contracts_l',
  },
  {
    key: 'tab.documents_l',
    value: 'resident_shared.tab.documents_l',
  },
  {
    key: 'payment_request.for_l',
    value: 'resident_shared.payment_request.for_l',
  },
  {
    key: 'payment_request.due_l',
    value: 'resident_shared.payment_request.due_l',
  },
  {
    key: 'payment_request.overdue_l',
    value: 'resident_shared.payment_request.overdue_l',
  },
  {
    key: 'payment_request.status_error_l',
    value: 'resident_shared.payment_request.status_error_l',
  },
  {
    key: 'payment_request.open_request_l',
    value: 'resident_shared.payment_request.open_request_l',
  },
  {
    key: 'navigation.collapse_sidebar_a',
    value: 'resident_shared.navigation.collapse_sidebar_a',
  },
  {
    key: 'navigation.expand_sidebar_a',
    value: 'resident_shared.navigation.expand_sidebar_a',
  },
  {
    key: 'payment_request.paid_l',
    value: 'resident_shared.payment_request.paid_l',
  },
  {
    key: 'consumption_detail.month_january_l',
    value: 'resident_shared.consumption_detail.month_january_l',
  },
  {
    key: 'consumption_detail.month_february_l',
    value: 'resident_shared.consumption_detail.month_february_l',
  },
  {
    key: 'consumption_detail.month_march_l',
    value: 'resident_shared.consumption_detail.month_march_l',
  },
  {
    key: 'consumption_detail.month_april_l',
    value: 'resident_shared.consumption_detail.month_april_l',
  },
  {
    key: 'consumption_detail.month_may_l',
    value: 'resident_shared.consumption_detail.month_may_l',
  },
  {
    key: 'consumption_detail.month_june_l',
    value: 'resident_shared.consumption_detail.month_june_l',
  },
  {
    key: 'consumption_detail.month_july_l',
    value: 'resident_shared.consumption_detail.month_july_l',
  },
  {
    key: 'consumption_detail.month_august_l',
    value: 'resident_shared.consumption_detail.month_august_l',
  },
  {
    key: 'consumption_detail.month_september_l',
    value: 'resident_shared.consumption_detail.month_september_l',
  },
  {
    key: 'consumption_detail.month_october_l',
    value: 'resident_shared.consumption_detail.month_october_l',
  },
  {
    key: 'consumption_detail.month_november_l',
    value: 'resident_shared.consumption_detail.month_november_l',
  },
  {
    key: 'consumption_detail.month_december_l',
    value: 'resident_shared.consumption_detail.month_december_l',
  },
  {
    key: 'unauthorized_user.header_l',
    value: 'resident_shared.unauthorized_user.header_l',
  },
  {
    key: 'unauthorized_user.title_l',
    value: 'resident_shared.unauthorized_user.title_l',
  },
  {
    key: 'unauthorized_user.description_l',
    value: 'resident_shared.unauthorized_user.description_l',
  },
  {
    key: 'unauthorized_user.button_a',
    value: 'resident_shared.unauthorized_user.button_a',
  },
  {
    key: 'contracts.choose_contract_l',
    value: 'resident_shared.contracts.choose_contract_l',
  },
  {
    key: 'payment_requests.archive_requests_l',
    value: 'resident_shared.payment_requests.archive_requests_l',
  },
  {
    key: 'payment_requests.archive_no_requests_l',
    value: 'resident_shared.payment_requests.archive_no_requests_l',
  },
  {
    key: 'contracts.account_balance_title',
    value: 'resident_shared.contracts.account_balance_title',
  },
  {
    key: 'contracts.account_balance_negative_l',
    value: 'resident_shared.contracts.account_balance_negative_l',
  },
  {
    key: 'contracts.account_balance_info_title',
    value: 'resident_shared.contracts.account_balance_info_title',
  },
  {
    key: 'contract_id_l',
    value: 'resident_shared.contract_id_l',
  },
  {
    key: 'valid_from_l',
    value: 'resident_shared.valid_from_l',
  },
  {
    key: 'dialog_alert_title_l',
    value: 'resident_shared.dialog_alert_title_l',
  },
  {
    key: 'dialog_info_title_l',
    value: 'resident_shared.dialog_info_title_l',
  },
  {
    key: 'dialog_warning_title_l',
    value: 'resident_shared.dialog_warning_title_l',
  },
  {
    key: 'token-expired-info',
    value: 'resident_shared.token-expired-info',
  },
  {
    key: 'dashboard.more_announcements_l',
    value: 'resident_shared.dashboard.more_announcements_l',
  },
  {
    key: 'dashboard.more_tickets_l',
    value: 'resident_shared.dashboard.more_tickets_l',
  },
  {
    key: 'unauthorized_user.header_l',
    value: 'resident_shared.unauthorized_user.header_l',
  },
  {
    key: 'unauthorized_user.description_l',
    value: 'resident_shared.unauthorized_user.description_l',
  },
  {
    key: 'permissions.call',
    value: 'permissions.call',
  },
  {
    key: 'year',
    value: 'resident_shared.year',
  },
  {
    key: 'month',
    value: 'resident_shared.month',
  },
  {
    key: 'ticket_creation.create_ticket_a',
    value: 'resident_shared.ticket_creation.create_ticket_a',
  },
  {
    key: 'user.lastname',
    value: 'resident_shared.user.lastname',
  },
  {
    key: 'logo_fallback',
    value: 'resident_shared.logo_fallback',
  },
  {
    key: 'update_available',
    value: 'resident_shared.update_available',
  },
  {
    key: 'general.cancel',
    value: 'resident_shared.general.cancel',
  },
  {
    key: 'general.accept',
    value: 'resident_shared.general.accept',
  },
  {
    key: 'general.change_password',
    value: 'resident_shared.general.change_password',
  },
  {
    key: 'user.change_password_desc',
    value: 'resident_shared.user.change_password_desc',
  },
  {
    key: 'user.change_password.confirm',
    value: 'resident_shared.user.change_password.confirm',
  },
  {
    key: 'legal.document_receival',
    value: 'resident_shared.legal.document_receival',
  },
  {
    key: 'legal.document_receival_start',
    value: 'resident_shared.legal.document_receival_start',
  },
  {
    key: 'legal.document_receival_link',
    value: 'resident_shared.legal.document_receival_link',
  },
  {
    key: 'legal.document_receival_end',
    value: 'resident_shared.legal.document_receival_end',
  },
  {
    key: 'legal.document_receival_l',
    value: 'resident_shared.legal.document_receival_l',
  },
  {
    key: 'general.offline_header',
    value: 'resident_shared.general.offline_header',
  },
  {
    key: 'general.offline_body',
    value: 'resident_shared.general.offline_body',
  },
  {
    key: 'general.email_address',
    value: 'resident_shared.general.email_address',
  },
  {
    key: 'general.login_email',
    value: 'resident_shared.general.login_email',
  },
  {
    key: 'general.ios_update_banner_title',
    value: 'resident_shared.general.ios_update_banner_title',
  },
  {
    key: 'general.ios_update_banner_body',
    value: 'resident_shared.general.ios_update_banner_body',
  },
  {
    key: 'general.ios_update_banner_update_button',
    value: 'resident_shared.general.ios_update_banner_update_button',
  },
  {
    key: 'general.ios_update_banner_skip_button',
    value: 'resident_shared.general.ios_update_banner_skip_button',
  },
  {
    key: 'contracts.unknown_cost',
    value: 'resident_shared.contracts.unknown_cost',
  },
  {
    key: 'information_notice_description',
    value: 'resident_shared.information_notice_description',
  },
  {
    key: 'information_notice_title_l',
    value: 'resident_shared.information_notice_title_l',
  },
  {
    key: 'ticket_chat_message_status_failed_l',
    value: 'resident_shared.ticket_chat.message_status_failed_l',
  },
  {
    key: 'ticket_chat_message_status_retried_l',
    value: 'resident_shared.ticket_chat.message_status_retried_l',
  },
  {
    key: 'ticket_chat_message_status_pending_l',
    value: 'resident_shared.ticket_chat.message_status_pending_l',
  },
];
