import { Directive, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromGeneralState from '../../+state/general';
import { DocumentSortings, IEnvironment, ISort, SortType, TicketSortings } from '../../models';
import { DocumentSortField } from '../../models/shared-content/shared-content.model';
import { TicketSortField } from '../../models/tickets/ticket.model';

@Directive()
export abstract class SortingMenuBaseComponent implements OnInit {
  @Input() title = 'sorting_l';
  @Input() topSpacing = 0;
  @Input() type: SortType;

  @Output() showSortingEmitter = new EventEmitter<boolean>();

  public labels: ISort[];

  public selectedSorting$: Observable<ISort>;

  constructor(
    @Inject('ENVIRONMENT') public environment: IEnvironment,
    public store: Store<fromGeneralState.ISortingState>,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.labels = this.getLabelsByType();
    this.selectedSorting$ = this.getSelectedSortingByType();
  }

  private getLabelsByType(): ISort[] {
    switch (this.type) {
      case SortType.DOCUMENTS:
        return DocumentSortings;
      case SortType.TICKETS:
        return TicketSortings;
      default:
        return DocumentSortings;
    }
  }

  private getSelectedSortingByType(): Observable<ISort> {
    switch (this.type) {
      case SortType.DOCUMENTS:
        return this.store.select(fromGeneralState.getDocumentSorting);
      case SortType.TICKETS:
        return this.store.select(fromGeneralState.getTicketSorting);
      default:
        return this.store.select(fromGeneralState.getDocumentSorting);
    }
  }

  public selectSorting(sorting: ISort): void {
    switch (this.type) {
      case SortType.DOCUMENTS:
        this.store.dispatch(
          fromGeneralState.setDocumentSorting({ sorting: sorting as ISort<DocumentSortField> })
        );
        break;
      case SortType.TICKETS:
        this.store.dispatch(
          fromGeneralState.setTicketSorting({ sorting: sorting as ISort<TicketSortField> })
        );
        break;
    }
    this.showSortingEmitter.emit(false);
  }
}
