import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import {
  getActiveRequestTickets,
  getActiveRequestTicketsActionState,
  LoadActiveRequestTickets,
} from '../../+state';
import { ITicketResidentOverviewEdge } from '../../models';
import { ResolverCommonService } from './resolver-common.service';

export const RequestTicketsActiveResolver: ResolveFn<ITicketResidentOverviewEdge[]> = route => {
  const common = inject(ResolverCommonService);

  const { resolverWaitForData } = route.data;
  return common.resolveHandler<ITicketResidentOverviewEdge[]>(
    getActiveRequestTicketsActionState,
    LoadActiveRequestTickets({ offset: 0, limit: 20 }),
    getActiveRequestTickets,
    resolverWaitForData
  );
};
