import { Directive, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { ImmomioIconName } from '../../../models';
import { CustomerService, RouterService } from '../../../services';
import {
  getSelectedContract,
  getTicketSorting,
  LoadActiveDamageTicketsForSelectedContract,
  LoadArchiveDamageTicketsForSelectedContract,
} from '../../../+state';
import { combineLatest, take, tap } from 'rxjs';

@Directive()
export abstract class TicketsOverviewDamagePageBaseComponent implements OnInit {
  public showBandODamageFlow = false;
  public archiveViewOpened = false;
  public immomioIconName = ImmomioIconName;

  constructor(
    protected routerService: RouterService,
    protected route: ActivatedRoute,
    protected store: Store,
    protected customerService: CustomerService
  ) {}

  ngOnInit() {
    this.showBandODamageFlow = this.customerService.showBandODamageFlow();
  }

  protected reloadTickets(): void {
    // TODO: IMPROVEMENT check pagination before reloading
    combineLatest([this.store.select(getSelectedContract), this.store.select(getTicketSorting)])
      .pipe(
        tap(([, sort]) => {
          this.store.dispatch(
            LoadActiveDamageTicketsForSelectedContract({ offset: 0, limit: 20, sort })
          );
          if (this.archiveViewOpened)
            this.store.dispatch(
              LoadArchiveDamageTicketsForSelectedContract({
                offset: 0,
                limit: 20,
                sort,
              })
            );
        }),
        take(1)
      )
      .subscribe();
  }
}
