import { Directive } from '@angular/core';

import { Store } from '@ngrx/store';

import * as fromNewsState from '../../+state/news/news';

// libs

@Directive()
export abstract class CurrentNewsDetailsBaseComponent {
  news$ = this.newsStore.select(fromNewsState.getSingleNews);
  newsActionState$ = this.newsStore.select(fromNewsState.getSingleNewsActionState);

  protected constructor(private newsStore: Store<fromNewsState.INewsState>) {}
}
