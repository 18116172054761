import { createSelector } from '@ngrx/store';
import { getNewsState } from '../../reducers';
import * as fromReducer from './news.reducer';

export const getNews = createSelector(getNewsState, fromReducer.getNews);
export const hasNextPageNews = createSelector(getNewsState, fromReducer.hasNextPage);
export const getLatestNews = createSelector(getNewsState, fromReducer.getLatestNews);
export const getNewsActionState = createSelector(getNewsState, fromReducer.getNewsActionState);
export const getSingleNews = createSelector(getNewsState, fromReducer.getSingleNews);
export const getSingleNewsActionState = createSelector(
  getNewsState,
  fromReducer.getSingleNewsActionState
);
export const getNewsPageInfo = createSelector(getNewsState, fromReducer.getNewsPageInfo);
