@switch (level) {
  @case (1) {
    <h1 class="headline headline--h1">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </h1>
  }
  @case (2) {
    <h2 class="headline headline--h2">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </h2>
  }
  @case (3) {
    <h3 class="headline headline--h3">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </h3>
  }
}

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
