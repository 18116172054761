import { Directive, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromRegistrationStore from '../../+state/account/registration';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { IActionState } from '../../utils';
import { RouterService } from '../../services';

@UntilDestroy()
@Directive()
export abstract class RequestInvitationCodeFormBaseComponent implements OnInit {
  public requestInvitationCodeResponse$: Observable<boolean>;
  public requestInvitationCodeResponseState$: Observable<IActionState>;

  fg: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    surname: ['', [Validators.required]],
    street: ['', [Validators.required]],
    houseNumber: ['', [Validators.required]],
    zipcode: ['', [Validators.required]],
    city: ['', [Validators.required]],
  });

  constructor(
    private registerStore: Store<fromRegistrationStore.RegistrationState>,
    private fb: FormBuilder,
    protected router: RouterService
  ) {}

  ngOnInit(): void {
    this.requestInvitationCodeResponseState$ = this.registerStore.select(
      fromRegistrationStore.getRegisterResidentActionState
    );
  }

  public submit(): void {
    const { street, houseNumber } = this.fg.value;
    const requestInvitationCode = {
      ...this.fg.value,
      street: street + ' ' + houseNumber,
    };
    delete requestInvitationCode.houseNumber;

    this.registerStore.dispatch(
      fromRegistrationStore.RequestInvitationCode({ requestInvitationCode })
    );
    this.requestInvitationCodeResponse$ = this.registerStore.select(
      fromRegistrationStore.getRequestInvitationCodeResponse
    );
    this.requestInvitationCodeResponse$.pipe(untilDestroyed(this)).subscribe(res => {
      if (res) {
        this.onRequestCodeSuccess();
      }
    });
  }

  public onRequestCodeSuccess() {
    this.goToLogin();
  }

  public goToLogin() {
    this.router.navigate(['auth']);
  }
}
