import { gql } from 'apollo-angular';

export const announcementsFields = `
  edges {
    cursor
    node {
      id
      title
      text
      publishedAt
    }
  }
  pageInfo {
    hasPreviousPage
    hasNextPage
    startCursor
    endCursor
  }
`;

export const announcementFields = `
  id
  title
  description
  publishedAt
  publishedUntil
  createdAt
  attachments {
    title
    name
    type
    identifier
    extension
    encrypted
    url
    residentFileType
    location
    atlasMeta {
      key
      value
    }
  }
`;

export const announcementsQuery = gql`
  query publishedAnnouncements($limit: Int, $offset: Int) {
    publishedAnnouncements (limit: $limit, offset: $offset) {
      ${announcementsFields}
    }
  }
`;

export const announcementQuery = gql`
  query getAnnouncement($announcementId: Int!) {
    getAnnouncement (announcementId: $announcementId) {
      ${announcementFields}
    }
  }
`;
