import {
  IConversationMessage,
  ITicketMessageGroup,
  IMasterDataUser,
  ITicketMessage,
  ITicketConversationEdit,
  ProfileButtonColors,
} from '../../models';

export const ticketChatConverter = {
  fromDto: (
    ticketMessages: IConversationMessage[],
    user: IMasterDataUser
  ): ITicketMessageGroup[] => {
    let messageGroups: ITicketMessageGroup[] = [];
    const colors = Object.values(ProfileButtonColors);
    const idToColorMap: { [id: string]: string } = {};

    if (ticketMessages?.length > 0) {
      messageGroups = ticketMessages.reduce((result, current) => {
        const currentName = current.created.editor.name;
        const msg: ITicketMessage = {
          id: current.id,
          message: current.message,
          attachments: current.attachments,
          timestamp: Number(current.created.date),
          isIncoming: !(
            current.created.editor.id === user.id ||
            user.firstName + ' ' + user.lastName === currentName ||
            user.firstName === currentName ||
            user.lastName === currentName
          ),
        };
        const lastGroup = result[result.length - 1];
        if (lastGroup && lastGroup.user.id === current.created.editor.id) {
          lastGroup.messages.push(msg);
        } else {
          if (msg.isIncoming) {
            // eslint-disable-next-line no-prototype-builtins
            if (!idToColorMap.hasOwnProperty(current.created.editor.id)) {
              idToColorMap[current.created.editor.id] =
                colors[Object.keys(idToColorMap).length % colors.length];
            }
          }
          result.push({
            user: current.created.editor,
            userInitials: msg.isIncoming
              ? current.created.editor?.name
                  ?.split(' ')
                  .map(word => word[0])
                  .join('')
                  .substring(0, 2) || ''
              : user.initials.substring(0, 2),
            isIncoming: msg.isIncoming,
            messages: [msg],
            color: idToColorMap[current.created.editor.id],
          });
        }

        return result;
      }, []);
    }
    return messageGroups;
  },
  toNewMessage: (
    sentMessageId: string,
    sentMessage: ITicketConversationEdit,
    ticketMessages: ITicketMessageGroup[],
    user: IMasterDataUser
  ): ITicketMessageGroup[] => {
    if (ticketMessages?.length > 0) {
      const lastMessageGroup = {
        ...ticketMessages[ticketMessages.length - 1],
        messages: [...ticketMessages[ticketMessages.length - 1].messages],
      };
      if (!lastMessageGroup.isIncoming) {
        lastMessageGroup.messages.push({
          id: sentMessageId,
          message: sentMessage.message,
          attachments: sentMessage.attachments,
          timestamp: Date.now(),
          isIncoming: false,
        });
        ticketMessages[ticketMessages.length - 1] = lastMessageGroup;
      } else {
        ticketMessages.push(
          ticketChatConverter.toNewMessageGroup(user, sentMessage, sentMessageId)
        );
      }
    } else {
      ticketMessages = [ticketChatConverter.toNewMessageGroup(user, sentMessage, sentMessageId)];
    }
    return ticketMessages;
  },
  toNewMessageGroup: (
    user: IMasterDataUser,
    message: ITicketConversationEdit,
    messageId: string
  ): ITicketMessageGroup => {
    return {
      user: { id: user.id, name: user.firstName + user.lastName },
      userInitials: user.initials,
      isIncoming: false,
      messages: [
        {
          id: messageId,
          message: message.message,
          attachments: message.attachments,
          timestamp: Date.now(),
          isIncoming: false,
        },
      ],
    };
  },
};
