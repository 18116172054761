import { IPageInfo } from '../general';

export interface IPublishedOffersResponse {
  edges: IPublishedOffersEdge[];
  pageInfo: IPageInfo;
}

export interface IPublishedOffersEdge {
  cursor: string;
  node: IPublishedOffer;
}
export interface IPublishedOffer {
  uuid: string | null;
  id: number;
  title: string;
  excerpt: string;
  body: string;
  href: string;
  imageUrl: string | null;
  showInHeader: boolean | null;
  sorting: number | null;
  publishedUntil: string | null;
  publishedAt: string | null;
}

export interface IResidentOfferContent {
  id: string;
  title: string;
  text: string;
  publishedAt: string;
  publishedUntil: string;
  imageUrl: string;
  href: string;
}
