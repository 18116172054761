import { gql } from 'apollo-angular';

const AppSettingsFields = `
  residentAppBranding {
    residentAppLogo {
      title
      name
      type
      identifier
      extension
      encrypted
      url
      residentFileType
      location
      atlasMeta {
        key
        value
      }
    }
  }
  theme {
    name
    primaryColor
    primaryTextColor
    secondaryColor
    secondaryTextColor
    buttonTextColor
    backgroundColor
    cardBackgroundColor
    active
  }
`;

export const appSettingsQuery = gql`
    query getAppSettings {
        getAppSettings {
            ${AppSettingsFields}
        }
    }
`;
