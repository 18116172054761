import { ChangeDetectionStrategy, Component, inject, TemplateRef } from '@angular/core';
import { FeedbackWebService } from '../../../services/general/feedback.service';
import { NgbToast } from '@ng-bootstrap/ng-bootstrap';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'rs-web-toast',
  standalone: true,
  templateUrl: './toast.component.html',
  imports: [NgbToast, NgTemplateOutlet],
  host: {
    class: 'toast-container position-fixed bottom-0 m p-3',
    style: 'z-index: 1200',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastsContainerComponent {
  public feedbackService = inject(FeedbackWebService);

  isTemplate(toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
