import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { catchError, map, Observable } from 'rxjs';
import {
  ConsentType,
  IChangePasswordPayload,
  ILegalTexts,
  IPersonalDataResponse,
  IResetPasswordBeanPayload,
} from '../../../models';
import {
  changeEmailMutation,
  changePasswordMutation,
  deleteUserMutation,
  getLegalTextsQuery,
  getPersonalDataQuery,
  initChangeEmailMutation,
  initResetPasswordMutation,
  resetPasswordMutation,
} from '../../../queries';
import { IConsent } from '../../../models/account/consent.model';
import { getConsentQuery, setConsentMutation } from '../../../queries/account/consent.queries';

@Injectable()
/**
 * Service including functions related to the account tab
 */
export class AccountUserFacade {
  constructor(private apollo: Apollo) {}

  public deleteUser(): Observable<boolean> {
    return this.apollo
      .mutate<boolean>({
        mutation: deleteUserMutation,
      })
      .pipe(
        map(result => result && result.data),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }

  public initChangeEmail(payload: { email: string }): Observable<boolean> {
    return this.apollo
      .mutate<{ success: boolean }>({
        mutation: initChangeEmailMutation,
        variables: {
          payload,
        },
      })
      .pipe(
        map(response => {
          return response.data.success;
        })
      );
  }

  public changeEmail(payload: { token: string }): Observable<boolean> {
    return this.apollo
      .mutate<{ success: boolean }>({
        mutation: changeEmailMutation,
        variables: {
          payload,
        },
      })
      .pipe(
        map(response => {
          return response.data.success;
        }),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }

  public changePassword(payload: IChangePasswordPayload): Observable<boolean> {
    return this.apollo
      .mutate<boolean>({
        mutation: changePasswordMutation,
        variables: {
          payload,
        },
      })
      .pipe(
        map(response => {
          return response.data;
        }),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }

  public initResetPassword(email: string): Observable<boolean> {
    const payload = {
      email,
    };
    return this.apollo
      .mutate<boolean>({
        mutation: initResetPasswordMutation,
        variables: {
          payload,
        },
      })
      .pipe(
        map(response => {
          return response.data;
        }),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }

  public resetPassword(payload: IResetPasswordBeanPayload): Observable<boolean> {
    return this.apollo
      .mutate<boolean>({
        mutation: resetPasswordMutation,
        variables: {
          payload,
        },
      })
      .pipe(
        map(response => {
          return response.data;
        }),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }

  public getLegalTexts(): Observable<ILegalTexts> {
    return this.apollo
      .query<{ legalTexts: ILegalTexts }>({
        query: getLegalTextsQuery,
        errorPolicy: 'all',
      })
      .pipe(
        map(response => response?.data?.legalTexts),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }

  public getPersonalData(): Observable<IPersonalDataResponse> {
    return this.apollo
      .query<{ getPersonalData: IPersonalDataResponse }>({
        query: getPersonalDataQuery,
        errorPolicy: 'all',
      })
      .pipe(
        map(response => response?.data?.getPersonalData),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }

  public getConsent(consentType: ConsentType): Observable<IConsent> {
    return this.apollo
      .query<{ getConsent: IConsent }>({
        query: getConsentQuery,
        variables: {
          consentType,
        },
        errorPolicy: 'all',
      })
      .pipe(map(response => response?.data?.getConsent));
  }

  public setConsent(consentType: ConsentType, consentId: number): Observable<boolean> {
    return this.apollo
      .mutate<boolean>({
        mutation: setConsentMutation,
        variables: {
          consentType,
          consentId,
        },
      })
      .pipe(map(response => response.data));
  }
}
