import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  CancelTicket,
  IHQTicketDetailsResponse,
  IMetadataTicketDetailsResponse,
  TicketDetailsPageBaseComponent,
  TicketingVersion,
  TicketStatusPipe,
  TicketTitlePipe,
} from '@resident-nx/shared';
import { TicketDetailsOverviewHQWebComponent } from '../ticket-details-overview-hq/ticket-details-overview-hq.component';
import { TicketDetailsOverviewMetadataWebComponent } from '../ticket-details-overview-metadata/ticket-details-overview-metadata.component';
import { CommonModule } from '@angular/common';
import { TicketDetailsHeaderWebComponent } from '../ticket-details-header/ticket-details-header.component';
import { TicketDetailsChatComponent } from '../ticket-details-chat/ticket-details-chat.component';
import { TicketDetailsChatOverviewComponent } from '../ticket-details-chat/ticket-details-chat-overview/ticket-details-chat-overview.component';
import { ButtonWebComponent } from '../../../../atoms/button/button.component';
import { TicketCancelModalWebComponent } from '../../../../molecules/ticket/ticket-cancel-modal/ticket-cancel-modal.component';
import { ModalService } from '../../../../molecules/modal/modal.service';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rs-web-ticket-details-page',
  templateUrl: './ticket-details-page.component.html',
  styleUrls: ['./ticket-details-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TicketDetailsOverviewHQWebComponent,
    TicketDetailsOverviewMetadataWebComponent,
    TicketDetailsHeaderWebComponent,
    TicketDetailsChatOverviewComponent,
    TicketDetailsChatComponent,
    ButtonWebComponent,
    CommonModule,
    TicketTitlePipe,
    TicketStatusPipe,
  ],
})
export class TicketDetailsPageWebComponent extends TicketDetailsPageBaseComponent {
  private modalService = inject(ModalService);
  private ngbActiveOffcanvas = inject(NgbActiveOffcanvas);
  public hideNewMessageCounter = false;
  public chatOpen = false;

  public hasDeleteButton(
    version: TicketingVersion,
    details: IHQTicketDetailsResponse | IMetadataTicketDetailsResponse,
    internalTicketingBooked: boolean
  ): boolean {
    if (version === TicketingVersion.HIERARCHICAL) {
      return ['OPEN', 'IN_PROGRESS', 'WAITING_FOR_OTHERS'].includes(
        (details as IHQTicketDetailsResponse).state
      );
    } else if (version === TicketingVersion.METADATA) {
      // clients with external metadata ticketing can't cancel tickets
      if (!internalTicketingBooked) {
        return false;
      }
      return ['OPEN', 'IN_PROGRESS', 'WAITING_FOR_OTHERS'].includes(
        (details as IMetadataTicketDetailsResponse).status
      );
    }
    return false;
  }

  public onCancelTicket() {
    const modalRef = this.modalService.open(TicketCancelModalWebComponent, { centered: true });
    modalRef.onClose().subscribe(() => {
      this.store.dispatch(
        CancelTicket({
          ticketId: this.ticketId,
          ticketIssueType: this.ticketIssueType,
        })
      );
      this.ngbActiveOffcanvas.close();
    });
  }

  public onClose() {
    this.ngbActiveOffcanvas.close();
  }

  public openChat() {
    this.chatOpen = true;
    this.hideNewMessageCounter = true;
  }
}
