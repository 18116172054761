<header class="header ios-padding-left ios-padding-right" [elevation]="4" rsWebElevation>
  <rs-web-button
    class="header__navigation-toggle"
    [type]="contextMenu"
    [ghost]="true"
    (click)="onNavigationToggleClick($event)"
    [iconLeft]="navigationOpen ? 'close' : 'context'"
  >
  </rs-web-button>

  <rs-web-logo></rs-web-logo>

  <nav class="header__nav">
    <div class="header__nav-item header__nav-item--search">
      <ng-content select="[search-field]"></ng-content>
    </div>

    <div class="header__nav-item header__nav-item--user-menu">
      @if (userData$ | async; as user) {
        <rs-web-avatar
          class="header__profile-settings-toggle"
          [name]="user"
          [size]="avatarSize['SMALL']"
          [rounded]="true"
          (click)="onProfileSettingsToggleClick($event)"
        ></rs-web-avatar>
      } @else {
        <rs-web-button
          class="header__profile-settings-toggle"
          [type]="contextMenu"
          [ghost]="true"
          (click)="onProfileSettingsToggleClick($event)"
          [iconLeft]="profileSettingsOpen ? 'close' : 'user'"
        >
        </rs-web-button>
      }
    </div>
  </nav>
</header>
