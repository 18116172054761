import { Inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { filter, map, switchMap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';

import {
  getContracts,
  getContractsActionState,
  getSelectedCustomerIdent,
  LoadContracts,
  SetSelectedCustomerIdent,
} from '../../+state';
import { StorageService } from '../../infrastructure';
import { RouterService } from '../general';
import { contractStatusConverter } from '../account';
import { IEnvironment } from '../../models';

@Injectable()
export class CustomerGuard implements CanActivate {
  constructor(
    private router: RouterService,
    private userAppsStore: Store,
    private storageService: StorageService,
    @Inject('ENDPOINTS') private endpoints,
    @Inject('ENVIRONMENT') private environment: IEnvironment
  ) {}

  canActivate() {
    if (this.environment.customerIdent) {
      this.userAppsStore.dispatch(
        SetSelectedCustomerIdent({ selectedCustomerIdent: this.environment.customerIdent })
      );
    } else {
      void this.router.navigate([this.endpoints.CUSTOMER_SELECTION]);
      return false;
    }

    this.userAppsStore.dispatch(LoadContracts({}));
    return this.userAppsStore.select(getContractsActionState).pipe(
      filter(state => state.done || state?.error !== null),
      switchMap(() =>
        this.userAppsStore.select(getSelectedCustomerIdent).pipe(
          withLatestFrom(this.userAppsStore.select(getContracts)),
          map(([selectedCustomerIdent, contracts]) => {
            if (selectedCustomerIdent) {
              const allContractsExpired = contractStatusConverter.allContractsExpired(contracts);
              if (allContractsExpired && contracts.length > 0) {
                void this.router.navigate(['unauthorized-user']);
                return false;
              }

              const activeContractFound =
                contractStatusConverter.isAnyContractActive(contracts) ||
                contractStatusConverter.isAnyContractEnded(contracts);
              const alreadyShownNonActiveContractInfoPage =
                this.storageService.getItem('noContractInfoPageShown');

              if (!activeContractFound && !alreadyShownNonActiveContractInfoPage) {
                void this.router.navigate(['no-contract-info']);
                return false;
              } else {
                return true;
              }
            } else {
              console.error('No selected customer ident');
              void this.router.navigate([this.endpoints.CUSTOMER_SELECTION]);
              return false;
            }
          })
        )
      )
    );
  }
}
