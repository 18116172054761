import { Injectable } from '@angular/core';
import { RequestService } from '@resident-nx/shared';

@Injectable()
export class RequestWebService extends RequestService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request(_options: any) {
    //TODO
    return;
  }
}
