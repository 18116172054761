export interface ILoginBean {
  email: string;
  redirectUri: string;
  prompt: string;
  nonce: string;
  state: string;
  loginMethod: LoginMethod;
}

export interface IFederatedLoginResponse {
  clientId: string;
  realm: string;
  baseUrl: string;
  host: string;
  port: number;
  scheme: string;
  path: string;
  redirectUri: string;
  nonce: string;
  state: string;
  responseType: string;
  responseMode: string;
  scope: string;
  kcIdpHint: string;
  loginHint: string;
  prompt: string;
  redirectUrl: string;
}

export enum LoginMethod {
  DEFAULT = 'DEFAULT',
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  APPLE = 'APPLE',
}

export interface IStateAfterAuth {
  pathAfterAuth: string;
  socialLogin?: boolean;
  queryParams?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
}

export enum IFederatedLoginEnvironmentVariables {
  NONCE = 'nonce',
  STATE = 'state',
  REALM = 'realm',
  CLIENT_ID = 'clientId',
  BASE_URL_AUTH = 'baseUrlAuth',
  AUTHORIZE_ENDPOINT = 'authorizeEndpoint',
  TOKEN_ENDPOINT = 'tokenEndpoint',
  COOKIE_DOMAINS = 'cookieDomains',
}
