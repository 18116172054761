import { Directive, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { TicketIssueType } from '../../../models';
import { CancelTicket, getCancelTicketActionState } from '../../../+state';

@Directive()
export abstract class TicketCancelBaseComponent {
  @Input() ticketId: string;
  @Input() ticketIssueType: TicketIssueType;
  cancelResponse$ = this.store.select(getCancelTicketActionState);

  constructor(public store: Store) {}

  public dispatchCancelTicket(ticketId: string, ticketIssueType: TicketIssueType): void {
    this.store.dispatch(CancelTicket({ ticketId, ticketIssueType }));
  }
}
