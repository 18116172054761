import { Directive } from '@angular/core';
import { ImmomioIconName } from '../../models';
import { RouterService } from '../../services';
import { StorageService } from '../../infrastructure';

@Directive()
export class NoContractInfoPageBaseComponent {
  public imagePath = '~/assets/shared/images/coming-soon-growth.png';
  public imagePath2 = '~/assets/shared/images/easter-egg.png';
  public immomioIconName = ImmomioIconName;

  constructor(
    private routerService: RouterService,
    private storageService: StorageService
  ) {}

  public continue(): void {
    this.storageService.setItem('noContractInfoPageShown', 'true');
    void this.routerService.navigate(['content']);
  }
}
