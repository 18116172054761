import { gql } from 'apollo-angular';
import { contactPersonResponseFields } from '../common';

export const getEmergencyContactsQuery = gql`
  query getEmergencyContacts($contractId: String!, $offset: Int, $limit: Int) {
    getEmergencyContacts (contractId: $contractId, offset: $offset, limit: $limit) {
      edges {
        cursor
        node {
          ${contactPersonResponseFields}
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

export const emergencyContactsExistsQuery = gql`
  query emergencyContactExists {
    emergencyContactExists {
      emergencyContactExists
    }
  }
`;
