import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as fromActions from './announcements.actions';
import { AnnouncementsFacade, ErrorHandlerService } from '../../../services';
import { IPublishedAnnouncement, IPublishedAnnouncementsResponse } from '../../../models';
import { announcementConverter } from './announcement-convert';
import { Store } from '@ngrx/store';
import { of, withLatestFrom } from 'rxjs';
import {
  getAnnouncementPageInfo,
  getAnnouncements,
  getAnnouncementsActionState,
} from './announcements.selectors';
import { differenceInMinutes } from 'date-fns';

@Injectable()
export class AnnouncementsEffects {
  loadAnnouncements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadAnnouncements),
      withLatestFrom(
        this.store.select(getAnnouncements),
        this.store.select(getAnnouncementsActionState),
        this.store.select(getAnnouncementPageInfo)
      ),
      switchMap(([action, data, actionState, pageInfo]) => {
        const noData = !data || data?.length === 0;
        const doneTimeIsOld =
          actionState.doneTime && differenceInMinutes(new Date(), actionState.doneTime) > 30;
        if (noData || doneTimeIsOld || action.forced) {
          return this.announcementsFacade.getAnnouncements(action.offset, action.limit).pipe(
            map((response: IPublishedAnnouncementsResponse) =>
              fromActions.LoadAnnouncementsSuccess({ response })
            ),
            catchError(error => {
              this.errorHandlerService.handleError(
                '(REQUEST ERROR) on announcements.effects.ts:loadAnnouncements$:',
                error
              );
              return [fromActions.LoadAnnouncementsFailed({ error })];
            })
          );
        } else {
          return of(
            fromActions.LoadAnnouncementsSuccess({
              response: {
                edges: data,
                pageInfo: pageInfo,
              },
              oldState: true,
            })
          );
        }
      })
    )
  );
  loadAnnouncement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadAnnouncement),
      switchMap(action =>
        this.announcementsFacade.getAnnouncement(action.announcementId).pipe(
          map(announcementConverter.fromDto),
          map((response: IPublishedAnnouncement) =>
            fromActions.LoadAnnouncementSuccess({
              response,
            })
          ),
          catchError((error: Error) => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on announcements.effects.ts:loadAnnouncement$:',
              error
            );
            return [fromActions.LoadAnnouncementFailed({ error })];
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private announcementsFacade: AnnouncementsFacade,
    private store: Store,
    private errorHandlerService: ErrorHandlerService
  ) {}
}
