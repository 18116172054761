import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';

import { NavigationItem } from '../../../../../models';
import { NavigationDrawerService } from '../navigation-drawer.service';
import { AsyncPipe } from '@angular/common';
import { NavigationDrawerItemComponent } from '../navigation-drawer-item/navigation-drawer-item.component';

@Component({
  selector: 'rs-web-navigation-drawer-list',
  templateUrl: './navigation-drawer-list.component.html',
  styleUrls: ['./navigation-drawer-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [forwardRef(() => NavigationDrawerItemComponent), AsyncPipe],
})
export class NavigationDrawerListComponent {
  @Input() items: NavigationItem[];
  @Input() isSubList = false;

  constructor(public navigationDrawer: NavigationDrawerService) {}
}
