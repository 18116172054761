import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'rs-web-infinite-scroll',
  template: '<ng-content></ng-content><div #anchor></div>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class InfiniteScrollWebComponent implements OnInit, OnDestroy {
  @Input() options: IntersectionObserverInit = {};
  @Output() scrolled = new EventEmitter();
  @ViewChild('anchor', { static: true }) anchor: ElementRef<HTMLElement>;
  private observer: IntersectionObserver;

  constructor(private host: ElementRef<HTMLElement>) {}

  ngOnInit() {
    const options: IntersectionObserverInit = {
      root: this.isHostScrollable() ? this.host.nativeElement : null,
      rootMargin: '50px',
      threshold: 0,
      ...this.options,
    };

    this.observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) this.scrolled.emit();
    }, options);

    this.observer.observe(this.anchor.nativeElement);
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }

  private isHostScrollable() {
    const style = window.getComputedStyle(this.host.nativeElement);

    return (
      style.getPropertyValue('overflow') === 'auto' ||
      style.getPropertyValue('overflow-y') === 'scroll'
    );
  }
}
