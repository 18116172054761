import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IPublishedNews, IPublishedNewsResponse } from '../../../models';
import { ErrorHandlerService, NewsFacade } from '../../../services';
import * as fromActions from './news.actions';
import { newsConverter } from './news-converter';
import { of, withLatestFrom } from 'rxjs';
import { getNews, getNewsActionState, getNewsPageInfo } from './news.selectors';
import { Store } from '@ngrx/store';
import { differenceInMinutes } from 'date-fns';

@Injectable()
export class NewsEffects {
  loadNews$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.LoadNews),
      withLatestFrom(
        this.store.select(getNews),
        this.store.select(getNewsActionState),
        this.store.select(getNewsPageInfo)
      ),
      switchMap(([action, data, actionState, pageInfo]) => {
        const noData = !data || data?.length === 0;
        const doneTimeIsOld =
          actionState.doneTime && differenceInMinutes(new Date(), actionState.doneTime) > 30;
        if (noData || doneTimeIsOld || action.forced) {
          return this.newsFacade.getNews(action.offset, action.limit).pipe(
            map((response: IPublishedNewsResponse) =>
              fromActions.LoadNewsSuccess({
                response,
              })
            ),
            catchError((error: Error) => {
              this.errorHandlerService.handleError(
                '(REQUEST ERROR) on news.effects.ts:loadNews$:',
                error
              );
              return [fromActions.LoadNewsFailed({ error })];
            })
          );
        } else {
          return of(
            fromActions.LoadNewsSuccess({
              response: {
                edges: data,
                pageInfo: pageInfo,
              },
              oldState: true,
            })
          );
        }
      })
    );
  });
  loadSingleNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadSingleNews),
      switchMap(action =>
        this.newsFacade.getSingleNews(action.newsId).pipe(
          map(newsConverter.fromDto),
          map((response: IPublishedNews) => fromActions.LoadSingleNewsSuccess({ response })),
          catchError((error: Error) => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on news.effects.ts:loadSingleNews$:',
              error
            );
            return [fromActions.LoadSingleNewsFailed({ error })];
          })
        )
      )
    )
  );
  loadPublicNewsPublished$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.LoadPublishedNewsPublic),
      switchMap(action =>
        this.newsFacade.getPublishedNewsPublic(action.offset, action.limit).pipe(
          map((response: IPublishedNewsResponse) =>
            fromActions.LoadPublishedNewsPublicSuccess({
              response,
            })
          ),
          catchError((error: Error) => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on news.effects.ts:loadPublicNewsPublished$:',
              error
            );
            return [fromActions.LoadPublishedNewsPublicFailed({ error })];
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private newsFacade: NewsFacade,
    private store: Store,
    private errorHandlerService: ErrorHandlerService
  ) {}
}
