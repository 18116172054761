import { RedirectCommand, Router } from '@angular/router';
import { inject } from '@angular/core';
import { getInvitationCodeResponse } from '@resident-nx/shared';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

export const registrationGuard = () => {
  const invitationCodeResponse$ = inject(Store).select(getInvitationCodeResponse);
  const router = inject(Router);

  return invitationCodeResponse$.pipe(
    map(response =>
      response ? true : new RedirectCommand(router.parseUrl('/registration/check-credentials'))
    )
  );
};
