import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { catchError, map, Observable } from 'rxjs';

import { ICustomerAppPermissionsResponse } from '../../../models';
import { getPermissionsQuery } from '../../../queries';

@Injectable()
export class PermissionsFacade {
  constructor(private apollo: Apollo) {}

  public getPermissions(): Observable<ICustomerAppPermissionsResponse> {
    return this.apollo
      .query<{ getCustomerAppPermissions: ICustomerAppPermissionsResponse }>({
        query: getPermissionsQuery,
        errorPolicy: 'all',
      })
      .pipe(
        map(response => response?.data?.getCustomerAppPermissions),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }
}
