import { IKeyValue } from './key-value.model';

/**
 * for each operation name, assign either a simple response that will
 * be returned in any case, or assign an array with a set of request variables
 * that have to be matched in order to return the response
 */
export type GQLMockData = {
  [key: string]:
    | {
        response: IKeyValue;
        force?: boolean;
      }
    | {
        requestVariables: IKeyValue;
        response: IKeyValue;
        force?: boolean;
      }[];
};
