import { Directive } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  getOffers,
  getOffersActionState,
  hasNextPageOffers,
  LoadOffers,
} from '../../+state/news/offers';
import { getHomepageModuleToken } from '../../+state/homepage-module';

@Directive()
export abstract class OffersOverviewBaseComponent {
  public offers$ = this.store.select(getOffers);
  public hasNextPage$ = this.store.select(hasNextPageOffers);
  public offersActionState$ = this.store.select(getOffersActionState);
  public token$ = this.store.select(getHomepageModuleToken);

  public pageSize;
  public pagination;

  constructor(
    protected store: Store,
    pageSize = 10
  ) {
    this.pageSize = pageSize;
    this.pagination = { offset: 0, limit: this.pageSize };
  }

  public loadMoreOffers(): void {
    this.pagination = { offset: this.pagination.offset + this.pageSize, limit: this.pageSize };
    this.store.dispatch(LoadOffers(this.pagination));
  }
}
