import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { IContactPersonResponse, SelectContactBaseComponent } from '@resident-nx/shared';
import { LabelWebComponent } from '../../../atoms/label/label.component';
import { ContactPreviewComponent } from '../../contact-preview/contact-preview.component';

@UntilDestroy()
@Component({
  selector: 'rs-web-select-contact',
  standalone: true,
  imports: [AsyncPipe, ContactPreviewComponent, TranslateModule, LabelWebComponent],
  templateUrl: './select-contact.component.html',
  styleUrl: './select-contact.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectContactComponent extends SelectContactBaseComponent {
  @Output() contactSelected: EventEmitter<IContactPersonResponse> =
    new EventEmitter<IContactPersonResponse>();
}
