import { IKeyValue } from '..';

export type RegisterResidentInput =
  | IRegisterResident
  | IRegisterExistingResident
  | IRegisterNewResident;

export interface IRegisterResident
  extends IRegisterResidentEmail,
    IRegisterResidentPassword,
    IRegisterHasConsentedToDigitalDocumentsReceival {
  identifiers?: IKeyValue<string>[];
  invitationCode?: string;
  customerSpecificData?: IKeyValue<string>[];
}

export interface IRegisterExistingResident
  extends IRegisterResidentEmail,
    IRegisterResidentInvitationCode,
    IRegisterHasConsentedToDigitalDocumentsReceival {}

export interface IRegisterNewResident
  extends IRegisterResidentEmail,
    IRegisterResidentPassword,
    IRegisterResidentInvitationCode,
    IRegisterHasConsentedToDigitalDocumentsReceival {}

export interface IRegisterResidentEmail {
  email: string;
}

export interface IRegisterResidentPassword {
  password: string;
  passwordConfirmation: string;
}

export interface IRegisterResidentInvitationCode {
  invitationCode: string;
}

export interface IVerifyEmailInput {
  token: string;
  verificationCode?: string;
}

export interface IRegisterResidentResponse {
  status: string;
  accessToken: string;
  sessionState: string;
}

export interface ICheckNumbersResponse {
  exists: boolean;
  error: ICheckNumbersError;
}

export enum ICheckNumbersError {
  CONTRACT_PARTNER_NUMBER_MISMATCH = 'CONTRACT_PARTNER_NUMBER_MISMATCH',
  TRANSPORT_ERROR = 'TRANSPORT_ERROR',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
}

export enum RequestInvitationCodeError {
  EntityNotFoundError = 'EntityNotFoundError',
}

export interface IRegisterDeviceInput {
  identifier: string;
  firebaseToken: string;
  data: IResidentDeviceData;
}

export interface IResidentDeviceData {
  os: string;
  deviceType: string;
  language: string;
  manufacturer: string;
  model: string;
  osVersion: string;
  region: string;
  sdkVersion: string;
}

export interface ICheckInvitationCode {
  firstName: string;
  name: string;
  customerIdent: string;
  customerName: string;
  email: string;
}

export interface ICheckEmailExists {
  userExists: boolean;
}

export interface IUserExistsByEmail {
  email: string;
  invitationCode: string;
}

export interface IRegistrationForm {
  invitationCode: string;
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  consent: boolean;
  hasConsentedToDigitalDocumentsReceival?: boolean;
}

export interface IRequestInvitationCodeForm {
  name: string;
  surname: string;
  street: string;
  zipcode: string;
  city: string;
}

export interface IRegisterHasConsentedToDigitalDocumentsReceival {
  hasConsentedToDigitalDocumentsReceival?: boolean;
}
