import { Directive, inject, ViewContainerRef } from '@angular/core';

/**
 {@link ControlErrorContainerDirective} is used to inject {@link FormErrorComponent} relative to specific container rather than elements with formControl or formControlName directives.

 Note: {@link FormControlErrorsDirective} has to be imported in the same component/module in order for {@link ControlErrorContainerDirective} to work

 The way it works as follows:
 1. the directive is assigned to parent element for an element with directives formControl/formControlName

 @example
 <div controlErrorContainer>
   <input formControlName="control"/>
    <--- FormErrorComponent is not injected here ❌
   <span>Dummy span</span>
    <--- FormErrorComponent injected here ✅
 </div>

 */

@Directive({
  selector: '[controlErrorContainer]',
  standalone: true,
})
export class ControlErrorContainerDirective {
  public vcr = inject(ViewContainerRef);
}
