import { Directive, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Directive()
export abstract class LoginFormBaseComponent {
  @Output() loginTapped = new EventEmitter();
  @Output() loginSuccess = new EventEmitter();
  @Output() forgotTapped = new EventEmitter();
  @Output() formSubmit = new EventEmitter();

  fg: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
  });

  constructor(private fb: FormBuilder) {}

  submit(fg: FormGroup) {
    if (fg.valid) {
      this.formSubmit.next(fg.value);
    }
  }
}
