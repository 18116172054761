import { Directive, Input } from '@angular/core';

@Directive()
export class TicketsBAndOInformationScreenBaseComponent {
  @Input() imagePath? = '~/assets/shared/images/order-placed.png';
  @Input() title: string;
  @Input()
  bAndODamageFlowDescription: string;
  @Input() navigateToButton: string;
}
