import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
@Pipe({
  name: 'displayCurrency',
  standalone: true,
})
export class DisplayCurrencyPipe extends CurrencyPipe implements PipeTransform {
  override transform(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    currencyCode?: string,
    symbolDisplay?: boolean,
    digitsInfo?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any {
    const currency = super.transform(value || 0, currencyCode, symbolDisplay, digitsInfo);
    return currency;
  }
}
