import { gql } from 'apollo-angular';

export const permissionsFields = `
  whitelabelCustomerPortal
  consumptionDataVisualization
  internalTicketing
  payment
`;

export const residentAppSettingsFields = `
  ticketingVersion
  consumptionDataVersion
  documentSortingSupported
  allowContractlessTicketCreationForDamages
  allowContractlessTicketCreationForOtherConcerns
`;

export const getPermissionsQuery = gql`
    query getAppPermissions {
        getCustomerAppPermissions {
            bookedModulesResponseBean {
                ${permissionsFields}
            }
            residentAppSettings {
                ${residentAppSettingsFields}
            }
        }
    }
`;
