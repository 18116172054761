import { createSelector } from '@ngrx/store';
import { getUserProfileState } from '../../reducers';
import * as fromReducer from './user-profile.reducer';

export const getDeleteUserActionState = createSelector(
  getUserProfileState,
  fromReducer.getDeleteUserActionState
);

export const getChangePasswordActionState = createSelector(
  getUserProfileState,
  fromReducer.getChangePasswordActionState
);

export const getChangeEmailActionState = createSelector(
  getUserProfileState,
  fromReducer.getChangeEmailActionState
);

export const getLegalTexts = createSelector(getUserProfileState, fromReducer.getLegalTexts);

export const getLegalTextsActionState = createSelector(
  getUserProfileState,
  fromReducer.getLegalTextsActionState
);

export const getPersonalData = createSelector(getUserProfileState, fromReducer.getPersonalData);

export const getPersonalDataActionState = createSelector(
  getUserProfileState,
  fromReducer.getPersonalDataActionState
);

export const getConsent = createSelector(getUserProfileState, fromReducer.getConsent);
export const getConsentState = createSelector(getUserProfileState, fromReducer.getConsentState);

export const getConsentSet = createSelector(getUserProfileState, fromReducer.getConsentSet);

export const getConsentSetState = createSelector(
  getUserProfileState,
  fromReducer.getConsentSetState
);
