import { createSelector } from '@ngrx/store';
import { getMasterDataState } from '../../reducers';
import * as fromReducer from './masterdata.reducer';

export const getUser = createSelector(getMasterDataState, fromReducer.getUser);
export const getUserActionState = createSelector(
  getMasterDataState,
  fromReducer.getUserActionState
);

export const getContracts = createSelector(getMasterDataState, fromReducer.getContracts);
export const getContractsActionState = createSelector(
  getMasterDataState,
  fromReducer.getContractsActionState
);

export const getSelectedContract = createSelector(
  getMasterDataState,
  fromReducer.getSelectedContract
);

export const getUpdateMasterDataActionState = createSelector(
  getMasterDataState,
  fromReducer.getUpdateMasterDataActionState
);

export const getSelectedContractDocuments = createSelector(
  getMasterDataState,
  fromReducer.getSelectedContractDocuments
);

export const getSelectedContractDocumentsActionState = createSelector(
  getMasterDataState,
  fromReducer.getSelectedContractDocumentsActionState
);

export const getSelectedContractSelectedDocument = createSelector(
  getMasterDataState,
  fromReducer.getSelectedContractSelectedDocument
);

export const getContractsCount = createSelector(getMasterDataState, fromReducer.getContractsCount);
