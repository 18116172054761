import { gql } from 'apollo-angular';
import { ITranslations } from '../../models';

export interface TranslationResponse {
  getAllTranslations: {
    translations: ITranslations[];
  };
}

export const TranslationFields = `
  translations {
    key
    translation
  }
`;

export const allTranslationsQuery = gql`
  query getAllTranslations ($locale: String!, $project: PhraseAppProject!) {
    getAllTranslations (locale: $locale, project:$project) {
      ${TranslationFields}
    }
  }
`;

export const translationsQuery = gql`
  query getTranslations ($keys: [String!]!, $locale: String!, $project: PhraseAppProject!) {
    getTranslations (keys: $keys, locale: $locale, project:$project) {
      ${TranslationFields}
    }
  }
`;
