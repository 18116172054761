import { DatePipe, NgIfContext, NgTemplateOutlet } from '@angular/common';
import { Component, Input, TemplateRef } from '@angular/core';
import { IUserData } from '@resident-nx/shared';
import { AvatarSizeEnum, AvatarWebComponent } from '../../atoms/avatar/avatar.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rs-web-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
  standalone: true,
  imports: [AvatarWebComponent, NgTemplateOutlet, DatePipe, TranslateModule],
})
export class ProfileDetailsWebComponent {
  @Input() userData: IUserData;
  @Input() noData: TemplateRef<NgIfContext<string>>;
  public avatarSize = AvatarSizeEnum;
}
