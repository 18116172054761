import { createReducer, on } from '@ngrx/store';

import { ActionStateCreator, IActionState } from '../../utils';
import * as fromActions from './homepage-module.actions';
import { IHomepageModule } from '../../models';

export interface IHomepageModelState {
  tokenActionState: IActionState;
  token: IHomepageModule;
}

export const initialState: IHomepageModelState = {
  tokenActionState: ActionStateCreator.create(),
  token: null,
};

export const hompageModuleReducer = createReducer(
  initialState,

  on(fromActions.GetHomepageModuleToken, state => {
    return {
      ...state,
      token: null,
      tokenActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.GetHomepageModuleTokenSuccess, (state, { response }) => {
    return {
      ...state,
      tokenActionState: ActionStateCreator.onSuccess(),
      token: response,
    };
  }),
  on(fromActions.GetHomepageModuleTokenFailed, (state, { error }) => {
    return {
      ...state,

      tokenActionState: ActionStateCreator.onError(error),
    };
  })
);

export const getHomepageModuleTokenActionState = (state: IHomepageModelState) =>
  state.tokenActionState;

export const getHomepageModuleToken = (state: IHomepageModelState) => state.token;
