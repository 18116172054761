import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { FileDownloadWebService } from '../../../services/file/download.service';
import { PictureWebComponent } from '../picture/picture.component';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { LoaderWebComponent } from '../loader/loader.component';
@Component({
  selector: 'rs-web-ticket-image-viewer',
  standalone: true,
  imports: [PictureWebComponent, AsyncPipe, LoaderWebComponent],
  template: `
    @if (imageUrl$ | async; as imageUrl) {
      @if (imageUrl !== 'pending') {
        <rs-web-picture [className]="'image-preview'" [src]="imageUrl"></rs-web-picture>
      } @else {
        <rs-web-loader></rs-web-loader>
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketImageViewerComponent implements OnInit {
  @Input() ticketId: string;
  @Input() attachmentIdentifier: string;
  private downloadService = inject(FileDownloadWebService);
  public imageUrl$: Observable<string>;

  ngOnInit(): void {
    this.imageUrl$ = this.downloadService.downloadTicketAttachment(
      this.ticketId,
      this.attachmentIdentifier
    );
  }
}
