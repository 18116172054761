import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DisplayCurrencyPipe, HexColorCode, PieChartBaseComponent } from '@resident-nx/shared';
import {
  ApexChart,
  ApexDataLabels,
  ApexLegend,
  ApexPlotOptions,
  ApexStates,
  ApexTooltip,
  ChartComponent,
  NgApexchartsModule,
} from 'ng-apexcharts';

@Component({
  selector: 'rs-web-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
  standalone: true,
  imports: [NgApexchartsModule, TranslateModule, DisplayCurrencyPipe],
})
export class PieChartWebComponent extends PieChartBaseComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: {
    series: number[];
    chart: ApexChart;
    legend: ApexLegend;
    dataLabels: ApexDataLabels;
    colors: HexColorCode[];
    tooltip: ApexTooltip;
    plotOptions: ApexPlotOptions;
    states: ApexStates;
  };

  constructor(
    private displayCurrency: DisplayCurrencyPipe,
    private translate: TranslateService
  ) {
    super();
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    const series = this.data.set.map(s => s.amount);
    const colors = this.data.set.map(s => s.color);
    const areAllLabelNull = this.data.set.map(s => s.label).every(label => label === null);
    this.chartOptions = {
      series,
      colors,
      dataLabels: {
        enabled: false,
      },
      chart: {
        type: 'donut',
      },
      legend: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          filter: {
            type: 'none',
          },
        },
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '60%',
            labels: {
              show: true,
              name: {
                offsetY: 6,
              },
              total: {
                show: true,
                showAlways: true,
                fontSize: areAllLabelNull ? '15px' : '20px',
                fontWeight: 600,
                label: `${
                  areAllLabelNull
                    ? this.translate.instant('contracts.unknown_cost')
                    : this.displayCurrency.transform(
                        series.reduce((x, y) => x + y, 0),
                        'EUR'
                      )
                }`,
                color: 'var(--color-text-primary,#050b13)',
                formatter: () => {
                  return '';
                },
              },
            },
          },
        },
      },
    };
  }
}
