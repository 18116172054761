import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { getLegalTexts, getLegalTextsActionState, LoadLegalTexts } from '../../+state';
import { ILegalTexts } from '../../models';
import { ResolverCommonService } from './resolver-common.service';

export const LegalTextsResolver: ResolveFn<ILegalTexts> = route => {
  const common = inject(ResolverCommonService);

  const { resolverWaitForData } = route.data;
  return common.resolveHandler<ILegalTexts>(
    getLegalTextsActionState,
    LoadLegalTexts(),
    getLegalTexts,
    resolverWaitForData
  );
};
