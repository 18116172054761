import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { FeedbackPosition, FeedbackType } from '../../../models/general/feedback.model';
import { FeedbackService } from '../../../services/general/feedback.base-service';
import * as fromActions from './feedback.actions';

@Injectable()
export class FeedbackEffects {
  constructor(
    private actions$: Actions,
    private translate: TranslateService,
    private feedback: FeedbackService
  ) {}

  showInfo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.ShowFeedbackInfo),
        tap(({ message, position, title, duration }) => {
          this.feedback.show({
            title: title ? (this.translate.instant(title) as string) : undefined,
            message: this.translate.instant(message) as string,
            position: position || FeedbackPosition.Top,
            type: FeedbackType.Info,
            duration: duration || 3000,
          });
        })
      ),
    { dispatch: false }
  );

  showSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.ShowFeedbackSuccess),
        tap(({ message, position, title, duration }) => {
          this.feedback.show({
            title: title ? (this.translate.instant(title) as string) : undefined,
            message: this.translate.instant(message) as string,
            position: position || FeedbackPosition.Top,
            type: FeedbackType.Success,
            duration: duration || 3000,
          });
        })
      ),
    { dispatch: false }
  );

  showWarning$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.ShowFeedbackWarning),
        tap(({ message, position, title }) => {
          this.feedback.show({
            title: title ? (this.translate.instant(title) as string) : undefined,
            message: this.translate.instant(message) as string,
            position: position || FeedbackPosition.Top,
            type: FeedbackType.Warning,
            duration: 3000,
          });
        })
      ),
    { dispatch: false }
  );

  showError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.ShowFeedbackError),
        tap(({ message, position, backgroundColor, title, duration }) => {
          if (message instanceof Error) {
            message = this.translate.instant('general.error_undefined', {
              error: message.message,
            }) as string;
          }
          this.feedback.show({
            title: title ? (this.translate.instant(title) as string) : undefined,
            message: this.translate.instant(message) as string,
            position: position || FeedbackPosition.Top,
            type: FeedbackType.Error,
            duration: duration || 3000,
            backgroundColor: backgroundColor || '#DD6E55',
          });
        })
      ),
    { dispatch: false }
  );
}
