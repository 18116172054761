export interface IAppleStoreResult {
  bundleId: string;
  trackId: number;
  version: string;
  trackViewUrl: string;
}

export interface IAppleStoreInfos {
  resultCount: number;
  results: IAppleStoreResult[];
}

export interface IStoreUpdateConfig {
  majorUpdateAlertType: number;
  minorUpdateAlertType: number;
  patchUpdateAlertType: number;
  revisionUpdateAlertType: number;
  countryCode?: string;
}

export const AlertTypesConstants = {
  FORCE: 1,
  NONE: 3,
  OPTION: 2,
};

export const UpdateTypesConstants = {
  MAJOR: 1,
  MINOR: 2,
  PATCH: 3,
  REVISION: 4,
};

export const AppStoreConstants = {
  ITUNES_BASE_URL: 'https://itunes.apple.com',
};

export const LAST_VERSION_SKIPPED_KEY = 'lastVersionSkipped';
