import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ImmomioIconName } from '@resident-nx/shared';
import { IconWebComponent } from '../../../atoms/icon/icon.component';
import { LabelWebComponent } from '../../../atoms/label/label.component';

@Component({
  selector: 'rs-web-emergency-contact-flow-header',
  standalone: true,
  imports: [IconWebComponent, LabelWebComponent, TranslateModule],
  templateUrl: './emergency-contact-flow-header.component.html',
  styleUrl: './emergency-contact-flow-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmergencyContactFlowHeaderComponent {
  @Input() bannerDescription: string;
  @Input() bannerTitle: string;
  public immomioIconName = ImmomioIconName;
}
