import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { INavigationItem } from '@resident-nx/shared';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'rs-web-list-navigation',
  templateUrl: './list-navigation.component.html',
  styleUrls: ['./list-navigation.component.scss'],
  standalone: true,
  imports: [TranslateModule, RouterLink, RouterLinkActive],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListNavigationWebComponent {
  @Input() navConfig: INavigationItem[];
  @Output() navSelect = new EventEmitter<string>();

  onNavSelect(tab: INavigationItem) {
    this.navSelect.emit(tab.url);
  }
}
