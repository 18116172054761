import { createSelector } from '@ngrx/store';
import { getTicketsState } from '../reducers';
import * as fromReducer from './tickets.reducer';

export const getDamageTickets = createSelector(getTicketsState, fromReducer.getDamageTickets);
export const hasNextPageDamageTickets = createSelector(
  getTicketsState,
  fromReducer.hasNextPageDamage
);
export const getDamageTicketsActionState = createSelector(
  getTicketsState,
  fromReducer.getDamageTicketsActionState
);

export const getRequestTickets = createSelector(getTicketsState, fromReducer.getRequestTickets);
export const hasNextPageRequestTickets = createSelector(
  getTicketsState,
  fromReducer.hasNextPageRequest
);
export const getRequestTicketsActionState = createSelector(
  getTicketsState,
  fromReducer.getRequestTicketsActionState
);

export const getDamageCategories = createSelector(getTicketsState, fromReducer.getDamageCategories);
export const getDamageCategoriesActionState = createSelector(
  getTicketsState,
  fromReducer.getDamageCategoriesActionState
);

export const getConcernCategories = createSelector(
  getTicketsState,
  fromReducer.getConcernCategories
);
export const getConcernCategoriesActionState = createSelector(
  getTicketsState,
  fromReducer.getConcernCategoriesActionState
);

export const getDamageQuestions = createSelector(getTicketsState, fromReducer.getDamageQuestions);
export const getDamageQuestionsActionState = createSelector(
  getTicketsState,
  fromReducer.getDamageQuestionsActionState
);

export const getConcernQuestions = createSelector(getTicketsState, fromReducer.getConcernQuestions);
export const getConcernQuestionsActionState = createSelector(
  getTicketsState,
  fromReducer.getConcernQuestionsActionState
);

export const getActiveDamageTickets = createSelector(
  getTicketsState,
  fromReducer.getActiveDamageTickets
);
export const hasNextPageActiveDamageTickets = createSelector(
  getTicketsState,
  fromReducer.hasNextPageActiveDamage
);
export const getActiveDamageTicketsActionState = createSelector(
  getTicketsState,
  fromReducer.getActiveDamageTicketsActionState
);

export const getActiveDamageTicketsForSelectedContract = createSelector(
  getTicketsState,
  fromReducer.getActiveDamageTicketsForSelectedContract
);
export const hasNextPageActiveDamageTicketsForSelectedContract = createSelector(
  getTicketsState,
  fromReducer.hasNextPageActiveDamageForSelectedContract
);
export const getActiveDamageTicketsForSelectedContractActionState = createSelector(
  getTicketsState,
  fromReducer.getActiveDamageTicketsForSelectedContractActionState
);

export const getArchiveDamageTickets = createSelector(
  getTicketsState,
  fromReducer.getArchiveDamageTickets
);
export const hasNextPageArchiveDamageTickets = createSelector(
  getTicketsState,
  fromReducer.hasNextPageArchiveDamage
);
export const getArchiveDamageTicketsActionState = createSelector(
  getTicketsState,
  fromReducer.getArchiveDamageTicketsActionState
);

export const getArchiveDamageTicketsForSelectedContract = createSelector(
  getTicketsState,
  fromReducer.getArchiveDamageTicketsForSelectedContract
);
export const hasNextPageArchiveDamageTicketsForSelectedContract = createSelector(
  getTicketsState,
  fromReducer.hasNextPageArchiveDamageForSelectedContract
);
export const getArchiveDamageTicketsForSelectedContractActionState = createSelector(
  getTicketsState,
  fromReducer.getArchiveDamageTicketsForSelectedContractActionState
);

export const getActiveRequestTickets = createSelector(
  getTicketsState,
  fromReducer.getActiveRequestTickets
);
export const hasNextPageActiveRequestTickets = createSelector(
  getTicketsState,
  fromReducer.hasNextPageActiveRequest
);
export const getActiveRequestTicketsActionState = createSelector(
  getTicketsState,
  fromReducer.getActiveRequestTicketsActionState
);

export const getActiveRequestTicketsForSelectedContract = createSelector(
  getTicketsState,
  fromReducer.getActiveRequestTicketsForSelectedContract
);
export const hasNextPageActiveRequestTicketsForSelectedContract = createSelector(
  getTicketsState,
  fromReducer.hasNextPageActiveRequestForSelectedContract
);
export const getActiveRequestTicketsForSelectedContractActionState = createSelector(
  getTicketsState,
  fromReducer.getActiveRequestTicketsForSelectedContractActionState
);

export const getArchiveRequestTickets = createSelector(
  getTicketsState,
  fromReducer.getArchiveRequestTickets
);
export const hasNextPageArchiveRequestTickets = createSelector(
  getTicketsState,
  fromReducer.hasNextPageArchiveRequest
);
export const getArchiveRequestTicketsActionState = createSelector(
  getTicketsState,
  fromReducer.getArchiveRequestTicketsActionState
);

export const getArchiveRequestTicketsForSelectedContract = createSelector(
  getTicketsState,
  fromReducer.getArchiveRequestTicketsForSelectedContract
);
export const hasNextPageArchiveRequestTicketsForSelectedContract = createSelector(
  getTicketsState,
  fromReducer.hasNextPageArchiveRequestForSelectedContract
);
export const getArchiveRequestTicketsForSelectedContractActionState = createSelector(
  getTicketsState,
  fromReducer.getArchiveRequestTicketsForSelectedContractActionState
);

export const getTicketDetails = createSelector(getTicketsState, fromReducer.getTicketDetail);
export const getTicketDetailsActionState = createSelector(
  getTicketsState,
  fromReducer.getTicketDetailActionState
);

export const getTicketSummary = createSelector(getTicketsState, fromReducer.getTicketSummary);

export const getNewTicketHQ = createSelector(getTicketsState, fromReducer.getNewTicketHQ);
export const getCreateTicketHQResponse = createSelector(
  getTicketsState,
  fromReducer.getCreateTicketHQResponse
);
export const getCreateTicketHQActionState = createSelector(
  getTicketsState,
  fromReducer.getCreateTicketHQActionState
);
export const getCancelTicketResponse = createSelector(
  getTicketsState,
  fromReducer.getCancelTicketResponse
);
export const getCancelTicketActionState = createSelector(
  getTicketsState,
  fromReducer.getCancelTicketActionState
);

export const getTicketChat = createSelector(getTicketsState, fromReducer.getTicketChat);
export const getTicketChatActionState = createSelector(
  getTicketsState,
  fromReducer.getTicketChatActionState
);
export const getNewMessageActionState = createSelector(
  getTicketsState,
  fromReducer.getNewMessageActionState
);

export const getMetadataTicketDetails = createSelector(
  getTicketsState,
  fromReducer.getMetadataTicketDetail
);
export const getMetadataTicketDetailsActionState = createSelector(
  getTicketsState,
  fromReducer.getMetadataTicketDetailActionState
);

export const getNewTicketMetadata = createSelector(
  getTicketsState,
  fromReducer.getNewTicketMetadata
);
export const getCreateTicketMetadataResponse = createSelector(
  getTicketsState,
  fromReducer.getCreateTicketMetadataResponse
);
export const getCreateTicketMetadataActionState = createSelector(
  getTicketsState,
  fromReducer.getCreateTicketMetadataActionState
);

export const getDirectAssignmentContacts = createSelector(
  getTicketsState,
  fromReducer.getDirectAssignmentContacts
);
export const getDirectAssignmentContactsActionState = createSelector(
  getTicketsState,
  fromReducer.getDirectAssignmentContactsActionState
);
