import { Directive, HostListener } from '@angular/core';
import { ImmomioIconName } from '../../models';
import { Store } from '@ngrx/store';
import * as fromMasterDataState from '../../+state/account/masterdata';
import { Subscription } from 'rxjs';
import { InitResetPassword } from '../../+state';

@Directive()
export class UserChangePasswordPageBaseComponent {
  public userEmail: string;
  protected readonly immomioIconName = ImmomioIconName;
  private userSub: Subscription;

  constructor(private store: Store) {
    this.userSub = store
      .select(fromMasterDataState.getUser)
      .pipe()
      .subscribe(s => (this.userEmail = s.email));
  }

  @HostListener('unloaded')
  pageDestroy() {
    this.userSub?.unsubscribe();
  }

  sendEmail(isWeb?: boolean) {
    if (isWeb) {
      this.store.dispatch(InitResetPassword({ email: this.userEmail, web: true }));
    } else {
      this.store.dispatch(InitResetPassword({ email: this.userEmail }));
    }
  }
}
