// Using object instead of enum because of the hokey pokey TS compilation of numeric enums to JS
//  Issue:    https://www.aaron-powell.com/posts/2020-05-27-the-dangers-of-typescript-enums/#why-did-this-happen
//  Solution: https://www.typescriptlang.org/docs/handbook/enums.html#objects-vs-enums
export const Elevation = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  ELEVEN: 11,
  TWELVE: 12,
  THIRTEEN: 13,
  FOURTEEN: 14,
  FIFTEEN: 15,
  SIXTEEN: 16,
  SEVENTEEN: 17,
  EIGHTEEN: 18,
  NINETEEN: 19,
  TWENTY: 20,
  TWENTY_ONE: 21,
  TWENTY_TWO: 22,
  TWENTY_THREE: 23,
  TWENTY_FOUR: 24,
} as const;
export type ElevationType = (typeof Elevation)[keyof typeof Elevation];

export interface ElevationDefaultConfigType {
  elevation: ElevationType;
  elevationChangeStepOnHover: number;
  elevationChangeStepOnClick: number;
  elevationChangeStepOnTouch: number;
  clickable: boolean;
}
