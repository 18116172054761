import { Directive, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { combineLatest, filter, take, tap } from 'rxjs';

import { getContracts, getResidentAppSettings } from '../../../+state/account/';
import * as fromTicketState from '../../../+state/tickets';
import { CreateTicketSummary } from '../../../+state/tickets';
import {
  IHierarchicalQuestionTicketOverview,
  IRootQuestionResponseTicketShortInput,
  IRootQuestionTicketOverview,
  ITicketCreationSummary,
  ITicketResidentCategory,
  Status,
  TicketIssueType,
} from '../../../models';
import { RouterService, ticketHierarchicalQuestionsConverter } from '../../../services';

@UntilDestroy()
@Directive()
export class TicketCreationPageBaseComponent implements OnInit {
  public ticketCreationSummary: ITicketCreationSummary = {
    name: '',
    rootQuestionId: '',
    categoryId: '',
    detailedQuestionId: '',
    responses: [],
    detailedResponses: [],
    directAssignmentContacts: null,
    categoryAnswerSetRelationId: '',
  };
  public step = { index: 0 };
  public headerTitle: string;
  public issueType: TicketIssueType;
  public selectedLocation: string;
  public questions: IHierarchicalQuestionTicketOverview[];
  public questionsForm: FormGroup;
  public showTicketQuestions = false;
  public showTicketSummary = false;
  public categoriesFinished = false;
  private loadDamageTicketDirectAssignments = true;
  private loadConcernTicketDirectAssignments = true;

  constructor(
    public route: ActivatedRoute,
    public routerService: RouterService,
    protected ticketStore: Store<fromTicketState.ITicketsState>
  ) {}

  ngOnInit() {
    this.issueType = this.route.snapshot?.params?.type;
    this.selectedLocation = this.route.snapshot?.queryParams?.contractName;
    if (this.issueType === TicketIssueType.PROPERTY_DAMAGE) {
      this.headerTitle = 'ticket_damage.create_new_l';
      this.ticketStore.dispatch(fromTicketState.LoadDamageCategoryQuestions());
      this.loadDamageCategories();
    } else if (this.issueType === TicketIssueType.CONCERN) {
      this.headerTitle = 'ticket_request.create_new_l';
      this.ticketStore.dispatch(fromTicketState.LoadConcernCategoryQuestions());
      this.loadConcernCategories();
    }
    combineLatest([
      this.ticketStore.select(getResidentAppSettings),
      this.ticketStore.select(getContracts),
    ])
      .pipe(
        take(1),
        tap(([settings, contracts]) => {
          this.loadDamageTicketDirectAssignments = contracts.length
            ? true
            : !settings.allowContractlessTicketCreationForDamages;
          this.loadConcernTicketDirectAssignments = contracts.length
            ? true
            : !settings.allowContractlessTicketCreationForOtherConcerns;
        })
      )
      .subscribe();

    this.showTicketQuestions = true;
    this.showTicketSummary = false;
    this.ticketStore
      .select(fromTicketState.getTicketSummary)
      .pipe(untilDestroyed(this))
      .subscribe(
        summary =>
          (this.ticketCreationSummary = {
            ...summary,
          })
      );
  }

  public handleStepBack(): void {
    if (this.step.index <= 0) {
      return this.routerService.back();
    }

    if (this.showTicketQuestions) {
      if (this.step.index === this.ticketCreationSummary.responses?.length) {
        this.clearDetailedResponses();
      }
      this.step.index -= 1;
    } else if (this.showTicketSummary) {
      this.showTicketSummary = false;
      this.showTicketQuestions = true;
    }
  }

  public loadDetailedQuestions(): void {
    this.clearDetailedResponses();
    this.categoriesFinished = true;

    const questionsRequest: IRootQuestionResponseTicketShortInput =
      ticketHierarchicalQuestionsConverter.formatTicketQuestionsRequest(this.ticketCreationSummary);

    if (this.issueType === TicketIssueType.PROPERTY_DAMAGE) {
      this.ticketStore.dispatch(
        fromTicketState.LoadDamageQuestions({ damageCategoryQuestions: questionsRequest })
      );
      this.loadDamageQuestions();
    } else if (this.issueType === TicketIssueType.CONCERN) {
      this.ticketStore.dispatch(
        fromTicketState.LoadConcernQuestions({ concernCategoryQuestions: questionsRequest })
      );
      this.loadConcernQuestions();
    }
  }

  public createTicket(): void {
    this.ticketCreationSummary.detailedResponses =
      ticketHierarchicalQuestionsConverter.formatFormAnswersToDetailedResponses(
        this.ticketCreationSummary,
        this.questionsForm
      );
    this.showTicketSummary = true;
    this.showTicketQuestions = false;
  }

  private loadDamageCategories(): void {
    this.ticketStore
      .select(fromTicketState.getDamageCategories)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response: IRootQuestionTicketOverview) => this.formatCategoriesResponse(response),
        error: error => console.error(error),
      });
  }

  private loadConcernCategories(): void {
    this.ticketStore
      .select(fromTicketState.getConcernCategories)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response: IRootQuestionTicketOverview) => this.formatCategoriesResponse(response),
        error: error => console.error(error),
      });
  }

  private loadDamageQuestions(): void {
    this.ticketStore
      .select(fromTicketState.getDamageQuestions)
      .pipe(
        filter(res => !!res),
        untilDestroyed(this)
      )
      .subscribe({
        next: (response: ITicketResidentCategory) => {
          if (response) {
            if (response.categoryAnswerSetRelationId != '') {
              this.ticketCreationSummary.categoryAnswerSetRelationId =
                response.categoryAnswerSetRelationId;
            }
            this.ticketCreationSummary.categoryId = response.id;
            this.getDirectAssignmentContacts(response, this.loadDamageTicketDirectAssignments);
            this.ticketCreationSummary.name = response.name;
          }
        },
        error: error => console.error(error),
      });
  }

  private loadConcernQuestions(): void {
    this.ticketStore
      .select(fromTicketState.getConcernQuestions)
      .pipe(
        filter(res => !!res),
        untilDestroyed(this)
      )
      .subscribe({
        next: (response: ITicketResidentCategory) => {
          if (response) {
            if (response.categoryAnswerSetRelationId != '') {
              this.ticketCreationSummary.categoryAnswerSetRelationId =
                response.categoryAnswerSetRelationId;
            }
            this.ticketCreationSummary.categoryId = response.id;
            this.getDirectAssignmentContacts(response, this.loadConcernTicketDirectAssignments);
            this.ticketCreationSummary.name = response.name;
          }
        },
        error: error => console.error(error),
      });
  }

  private formatCategoriesResponse(response: IRootQuestionTicketOverview): void {
    if (response) {
      if (response.mainQuestionIds?.length > 0) {
        this.step.index = 0;
        this.ticketCreationSummary.rootQuestionId = response.id;
        this.questions = response.questions;
        this.ticketCreationSummary.responses = [];
        const firstQuestion: IHierarchicalQuestionTicketOverview =
          ticketHierarchicalQuestionsConverter.getFirstQuestion(response);
        this.ticketCreationSummary.responses[0] =
          ticketHierarchicalQuestionsConverter.formatQuestionToSummaryItem(firstQuestion);
      } else {
        void this.routerService.navigate(['no-ticket-catalogues'], {
          relativeTo: this.route,
        });
      }
    }
  }

  private formatDetailedQuestionsResponse(response: ITicketResidentCategory): void {
    if (response.detailRootQuestion?.questions.length > 0) {
      this.ticketCreationSummary.detailedQuestionId = response.detailRootQuestion.id;
      const questions = [...response.detailRootQuestion.questions];
      questions.sort((a, b) => a.data.order - b.data.order);
      this.ticketCreationSummary.detailedResponses = [];
      questions.forEach(q => {
        this.ticketCreationSummary.detailedResponses.push(
          ticketHierarchicalQuestionsConverter.formatQuestionToSummaryItem(q)
        );
      });
      this.questionsForm = ticketHierarchicalQuestionsConverter.createQuestionsAndAnswersForm(
        this.ticketCreationSummary
      );
    } else {
      // for categories without detailed questions move directly to ticket summary
      this.createTicket();
      // decrease step by one to stay on the last category question
      this.step.index--;
    }
  }

  private clearDetailedResponses(): void {
    this.ticketCreationSummary.categoryId = '';
    this.ticketCreationSummary.detailedResponses = [];
    this.ticketCreationSummary.directAssignmentContacts = [];
  }

  private getDirectAssignmentContacts(
    response: ITicketResidentCategory,
    loadDirectAssignmentContacts: boolean
  ): void {
    const category = this.ticketCreationSummary.categoryId;
    if (category && loadDirectAssignmentContacts) {
      this.ticketStore.dispatch(fromTicketState.LoadDirectAssignmentContacts({ category }));
      this.loadDirectAssignmentContacts(response);
    } else {
      this.formatDetailedQuestionsResponse(response);
    }
  }

  private loadDirectAssignmentContacts(response: ITicketResidentCategory): void {
    this.ticketStore
      .select(fromTicketState.getDirectAssignmentContacts)
      .pipe(
        filter(res => !!res),
        untilDestroyed(this)
      )
      .subscribe({
        next: directAssignments => {
          this.ticketCreationSummary.directAssignmentContacts = directAssignments;
          if (this.ticketCreationSummary.directAssignmentContacts?.length) {
            this.ticketStore.dispatch(
              CreateTicketSummary({ ticketSummary: this.ticketCreationSummary })
            );
            this.routerService.navigate([
              'content',
              'concern',
              'ticket-creation-direct-assignments',
              Status.SUCCESS,
            ]);
          } else {
            this.formatDetailedQuestionsResponse(response);
          }
        },
        error: error => {
          console.error(error);
          this.formatDetailedQuestionsResponse(response);
        },
      });
  }
}
