import { createReducer, on } from '@ngrx/store';
import { ContractId, IEmergencyContactsResponse } from '../../../models';
import { ActionStateCreator, IActionState } from '../../../utils';

import * as fromActions from './emergency-contacts.actions';

export interface IEmergencyContactsState {
  emergencyContactsActionState: Record<ContractId, IActionState>;
  emergencyContactsByContract: Record<ContractId, IEmergencyContactsResponse>;

  emergencyContactExists: boolean;
  activeRequests: ContractId[];
}

export const initialState: IEmergencyContactsState = {
  emergencyContactsActionState: null,
  emergencyContactsByContract: null,
  emergencyContactExists: false,
  activeRequests: [],
};

export const emergencyContactsReducer = createReducer(
  initialState,

  on(fromActions.EmergencyContactsExistsSuccess, (state, { emergencyContactExists }) => ({
    ...state,
    emergencyContactExists,
  })),

  on(fromActions.EmergencyContactsExistsFailed, (state, { error }) => ({
    ...state,
    error,
  })),

  on(fromActions.GetEmergencyContacts, (state, { contract }) => {
    const emergencyContactsActionState = { ...state.emergencyContactsActionState };
    emergencyContactsActionState[contract] = ActionStateCreator.onStart();
    const activeRequests = [...state.activeRequests];
    if (!activeRequests.includes(contract)) {
      activeRequests.push(contract);
    }
    return {
      ...state,
      emergencyContactsActionState,
      activeRequests,
    };
  }),

  on(fromActions.GetEmergencyContactsSuccess, (state, { emergencyContactsResponse, contract }) => {
    const emergencyContactsByContract = { ...state.emergencyContactsByContract };
    const emergencyContactsActionState = { ...state.emergencyContactsActionState };
    emergencyContactsByContract[contract] = emergencyContactsResponse;
    emergencyContactsActionState[contract] = ActionStateCreator.onSuccess();
    let activeRequests = [...state.activeRequests];
    activeRequests = activeRequests.filter(el => el !== contract);
    return {
      ...state,
      emergencyContactsActionState,
      emergencyContactsByContract: { ...emergencyContactsByContract },
      activeRequests,
    };
  }),

  on(fromActions.GetEmergencyContactsFailed, (state, { contract, error }) => {
    const emergencyContactsActionState = { ...state.emergencyContactsActionState };
    emergencyContactsActionState[contract] = ActionStateCreator.onError(error);
    let activeRequests = [...state.activeRequests];
    activeRequests = activeRequests.filter(el => el !== contract);
    return {
      ...state,
      emergencyContactsActionState,
      activeRequests,
    };
  })
);

export const getEmergencyContactsActionState = (state: IEmergencyContactsState) =>
  state.emergencyContactsActionState;
export const getEmergencyContactsByContract = (state: IEmergencyContactsState) =>
  state.emergencyContactsByContract;
export const getActiveRequests = (state: IEmergencyContactsState) => state.activeRequests;

export const emergencyContactsExists = (state: IEmergencyContactsState) =>
  state.emergencyContactExists;
