<div class="side-sheet-info">
  <div class="offcanvas-header banner">
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
    <rs-web-emergency-contact-flow-header
      [bannerTitle]="headerTitle | async"
      [bannerDescription]="headerDescription | async"
    ></rs-web-emergency-contact-flow-header>
  </div>
  <div class="offcanvas-body" [formGroup]="form">
    @switch (step | async) {
      @case (0) {
        <rs-web-select-contract></rs-web-select-contract>
      }
      @case (1) {
        <rs-web-select-contact (contactSelected)="contactSelected($event)"></rs-web-select-contact>
      }
      @case (2) {
        <rs-web-contact-preview [contact]="selectedContact$ | async"></rs-web-contact-preview>
        <rs-web-contact-details [contact]="selectedContact$ | async"></rs-web-contact-details>
      }
    }
  </div>
  <div class="offcanvas-footer">
    @if ((step | async) > 0 && !((contracts$ | async).length === 1 && (step | async) === 1)) {
      <rs-web-button [type]="ButtonTypeEnum.LIGHT_BORDERED" (tapped)="back()"
        >{{ 'general.back_a' | translate }}
      </rs-web-button>
    }
  </div>
</div>
