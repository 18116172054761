import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { IPublishedAnnouncementsResponse, IResidentAnnouncementContent } from '../../models';
import { announcementQuery, announcementsQuery } from '../../queries';

@Injectable()
export class AnnouncementsFacade {
  constructor(private apollo: Apollo) {}

  public getAnnouncements(
    offset: number,
    limit: number
  ): Observable<IPublishedAnnouncementsResponse> {
    return this.apollo
      .query<{ publishedAnnouncements: IPublishedAnnouncementsResponse }>({
        query: announcementsQuery,
        variables: {
          offset,
          limit,
        },
      })
      .pipe(map(response => response?.data?.publishedAnnouncements));
  }

  public getAnnouncement(announcementId: number): Observable<IResidentAnnouncementContent> {
    return this.apollo
      .query<{ getAnnouncement: IResidentAnnouncementContent }>({
        query: announcementQuery,
        variables: {
          announcementId,
        },
      })
      .pipe(map(response => response?.data?.getAnnouncement));
  }
}
