import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Input,
  Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonTypeEnum, ImmomioIconName } from '@resident-nx/shared';
import { ButtonWebComponent } from '../../../../../atoms/button/button.component';

@Component({
  selector: 'rs-web-ticket-details-chat-overview',
  styles: `
    span {
      color: white;
      background-color: red;
      width: 15px;
    }
  `,
  template: `
    <hr />
    <div class="d-flex align-items-start mb-2">
      <strong class="me-1 primary-text"> {{ 'ticket_chat.message_history_l' | translate }}</strong>
      @if (messageCount > 0 && !hideNewMessageCounter()) {
        <span class="title-xs d-flex align-items-center justify-content-center rounded">{{
          messageCount
        }}</span>
      }
    </div>
    <rs-web-button
      class="w-50 d-block"
      [iconLeft]="icons.Chat"
      [type]="buttonType.LIGHT_BORDERED"
      (tapped)="openChat.emit()"
    >
      {{ 'ticket_chat.open_messegner_l' | translate }}
    </rs-web-button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonWebComponent, TranslateModule],
})
export class TicketDetailsChatOverviewComponent {
  protected readonly buttonType = ButtonTypeEnum;
  protected readonly icons = ImmomioIconName;
  hideNewMessageCounter = input(false);
  @Input() messageCount: number;
  @Output() openChat = new EventEmitter();
}
