import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';
import { buildBEMClassNamesByGivenBaseClassAndFlags } from '../../../utils';
import { CenterImageSizeEnum, ImageDefaultConfig, ImageSources, ImageSrcSet } from './image.model';

@Component({
  selector: 'rs-web-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageWebComponent {
  #fileReader: boolean;
  @Input() sources: ImageSources = ImageDefaultConfig.sources;
  @Input() defaultSrc: string | File;
  @Input() alt: string;
  @Input() lazyLoading = ImageDefaultConfig.lazyLoading;
  @Input() maxHeightInPx: number;
  @Input() objectFit = ImageDefaultConfig.objectFit;
  @Input() borderRadius = ImageDefaultConfig.borderRadius;
  @Input() borderStyle = ImageDefaultConfig.borderStyle;
  @Input() centerImage = false;
  @Input() centerImageSize = CenterImageSizeEnum.S;
  @Input() dataTableCellPicture = false;

  @Input() set fileReader(value: boolean) {
    if (value && typeof this.defaultSrc === 'object') {
      this.#fileReader = value;
      const reader = new FileReader();

      reader.onload = event => this.fileReaderFile.set(event.target.result);

      reader.onerror = event => {
        console.error('File could not be read: ' + event.target.error);
      };

      reader.readAsDataURL(this.defaultSrc);
    }
  }

  get fileReader() {
    return this.#fileReader;
  }

  public fileReaderFile = signal<string | ArrayBuffer>('');

  public centerImageSizes = CenterImageSizeEnum;

  public get setMaxHeight(): boolean {
    return !isNaN(this.maxHeightInPx) && Number.isInteger(+this.maxHeightInPx);
  }

  public baseClass = 'image';

  public getClassName(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(this.baseClass, {
      [`object-fit-${this.objectFit}`]: !!this.objectFit,
      [`border-radius-${this.borderRadius}`]: !!this.borderRadius,
      [`border-style-${this.borderStyle}`]: !!this.borderStyle,
    });
  }

  public getMediaQuery(minScreenWidth?: number, maxScreenWidth?: number): string | null {
    let ret = '';
    if (minScreenWidth !== undefined) {
      ret += `(min-width: ${minScreenWidth}px)`;
    }
    if (maxScreenWidth !== undefined) {
      if (ret !== '') ret += ' and ';
      ret += `(max-width: ${maxScreenWidth}px)`;
    }
    return ret || null;
  }

  public getSrcSet(srcSet: ImageSrcSet): string | null {
    if (!srcSet) return null;
    let ret = '';
    let value;
    for (let i = 1; i <= 3; i++) {
      value = srcSet[`x${i}`];
      if (value) {
        if (ret !== '') ret += ', ';
        ret += `${String(value)} ${i}x`;
      }
    }
    return ret || null;
  }
}
