import { Directive, Input } from '@angular/core';
import { ContractSelectionTypes, IContract, ImmomioIconName, ContractStatus } from '../../models';

@Directive()
export abstract class ContractBaseComponent {
  @Input() contract?: IContract | null;
  @Input() iconName?: ImmomioIconName;
  @Input() iconSize?: number;
  @Input() contractSelectionType = ContractSelectionTypes.TICKET;
  public immomioIconName = ImmomioIconName;
  public contractSelectionTypes = ContractSelectionTypes;
  public contractStatus = ContractStatus;
}
