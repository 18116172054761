import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { matchingControl, passwordValidator } from '../../utils/validators/validators';
import { ActivatedRoute } from '@angular/router';
import { IPasswordResetFormValues, IResetPasswordBeanPayload } from '../../../shared/models';

/* the password reset can either be anauthenticaed via reset link or authenticated from inside the app.
The component ca detect if it is a reset link if there is a :token in the params */

@Directive()
export abstract class PasswordResetFormBaseComponent implements OnInit {
  @Input() needConfirmation = true;
  @Input() isResetLink = true;

  @Output() formSubmit = new EventEmitter();

  subs$: Subscription[] = [];
  fg: FormGroup;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.needConfirmation) {
      this.fg = this.fb.group({
        newPassword: this.fb.group(
          {
            password: ['', [Validators.required, passwordValidator]],
            passwordConfirm: ['', [Validators.required]],
          },
          { validators: [matchingControl] }
        ),
      });
    } else {
      this.fg = this.fb.group({
        newPassword: this.fb.group(
          {
            password: ['', [Validators.required, passwordValidator]],
          },
          { validators: [matchingControl] }
        ),
      });
    }

    const token = this.route.snapshot.params['token'];

    if (token) {
      this.isResetLink = true;
      this.fg.addControl('token', this.fb.control(token, [Validators.required]));
    } else {
      this.fg.addControl('oldPassword', this.fb.control('', [Validators.required]));
    }
  }

  submit(fg: FormGroup) {
    if (!fg.valid) {
      fg.markAllAsTouched();
      return;
    }

    const payload = this.formatPayload();
    this.formSubmit.next(payload);
  }

  private formatPayload() {
    const values = this.fg.value;
    if (this.isResetLink && this.needConfirmation) {
      return {
        token: values?.token,
        password: values.newPassword.password,
        confirmedPassword: values.newPassword.passwordConfirm,
      } as IResetPasswordBeanPayload;
    } else if (this.isResetLink && !this.needConfirmation) {
      return {
        token: values?.token,
        password: values.newPassword.password,
        confirmedPassword: values.newPassword.password,
      } as IResetPasswordBeanPayload;
    } else {
      return {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword.password,
        confirmedNewPassword: values.newPassword.passwordConfirm,
      } as IPasswordResetFormValues;
    }
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    this.subs$.forEach(sub => sub?.unsubscribe());
  }
}
