import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { getPersonalData, getPersonalDataActionState, LoadPersonalData } from '../../+state';
import { IPersonalDataResponse } from '../../models';
import { ResolverCommonService } from './resolver-common.service';

export const PersonalDataResolver: ResolveFn<IPersonalDataResponse> = route => {
  const common = inject(ResolverCommonService);

  const { resolverWaitForData } = route.data;

  return common.resolveHandler<IPersonalDataResponse>(
    getPersonalDataActionState,
    LoadPersonalData(),
    getPersonalData,
    resolverWaitForData
  );
};
