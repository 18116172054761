import { inject } from '@angular/core';
import { Routes } from '@angular/router';
import {
  AppSettingsResolver,
  ContractsResolver,
  LegalTextsResolver,
  PermissionsResolver,
  PersonalDataResolver,
  UserResolver,
} from '@resident-nx/shared';
import {
  AuthWebGuard,
  ContractGuard,
  DashboardPageGuard,
  FaqWebComponent,
  LoginPageGuard,
  ROUTE,
  TicketingGuardService,
} from '@resident-nx/web';
import { AuthenticatedComponent } from './features/authentication/components';
import { unauthenticatedRoutes } from './features/unauthenticated/unauthenticated.module';
import { registrationGuard } from './features/unauthenticated/guards';
import { LegalWebComponent } from './features/legal/components';
import { ENDPOINTS } from '../endpoints';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: AuthenticatedComponent,
        resolve: {
          appSettings: AppSettingsResolver,
          user: UserResolver,
          legalTexts: LegalTextsResolver,
          personalData: PersonalDataResolver,
          contracts: ContractsResolver,
          permissions: PermissionsResolver,
        },
        data: {
          resolverWaitForData: true,
        },
        canActivate: [AuthWebGuard, ContractGuard(true)],
        children: [
          {
            path: '',
            redirectTo: ROUTE.DASHBOARD,
            pathMatch: 'full',
          },
          {
            path: ROUTE.HOME,
            loadChildren: () => import('./features/home/home.module').then(mod => mod.routes),
          },
          {
            path: ROUTE.SETTINGS,
            loadChildren: () =>
              import('./features/settings/settings.module').then(mod => mod.routes),
          },
          {
            path: ROUTE.NEWS,
            loadChildren: () =>
              import('./features/news-overview/news-overview.module').then(mod => mod.routes),
          },
          {
            path: ROUTE.TICKETS,
            resolve: {
              permissions: PermissionsResolver,
            },
            canActivate: [() => inject(TicketingGuardService).TicketingGuard()],
            loadChildren: () =>
              import('./features/ticketing').then(routes => routes.TICKETS_ROUTES),
          },
          {
            path: ROUTE.LEGAL,
            loadChildren: () => import('./features/legal/legal.module').then(mod => mod.routes),
          },
          {
            path: ROUTE.FAQ,
            component: FaqWebComponent,
          },
          {
            path: ROUTE.PROFILE_PAGE,
            loadChildren: () =>
              import('./features/profile-page/profile-page.module').then(mod => mod.routes),
          },
          {
            path: ROUTE.DASHBOARD,
            loadChildren: () =>
              import('./features/dashboard/dashboard.module').then(mod => mod.routes),
            canActivate: [DashboardPageGuard()],
          },
          {
            path: ROUTE.CONSUMPTION,
            loadChildren: () =>
              import('./features/consumption/consumption.module').then(mod => mod.routes),
          },
        ],
      },
      {
        path: '',
        canActivate: [LoginPageGuard],
        loadComponent: () =>
          import('./features/unauthenticated/components').then(
            component => component.UnauthenticatedComponent
          ),
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'login',
          },
          {
            path: 'login',
            loadComponent: () =>
              import('./features/authentication/components').then(
                component => component.LoginComponent
              ),
          },
          {
            path: 'registration',
            loadComponent: () =>
              import('./features/unauthenticated/components').then(
                cmp => cmp.SaasWebRegistrationComponent
              ),
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'check-credentials',
              },
              {
                path: 'check-credentials',
                loadComponent: () =>
                  import('./features/unauthenticated/components').then(
                    cmp => cmp.SaasWebRegistrationCheckCredentialsComponent
                  ),
              },
              {
                path: '',
                canActivate: [registrationGuard],
                children: [
                  {
                    path: 'set-consent',
                    loadComponent: () =>
                      import('./features/unauthenticated/components').then(
                        cmp => cmp.SaasWebRegistrationSetConsentComponent
                      ),
                  },
                  {
                    path: 'registration-success',
                    loadComponent: () =>
                      import('./features/unauthenticated/components').then(
                        cmp => cmp.SaasWebRegistrationSuccessComponent
                      ),
                  },
                ],
              },
            ],
          },
          {
            path: 'legal-information',
            component: LegalWebComponent,
            resolve: {
              legalTexts: LegalTextsResolver,
            },
          },
          {
            path: 'request-reset-password',
            loadComponent: () =>
              import('./features/unauthenticated/components').then(
                component => component.RequestResetPasswordWebComponent
              ),
          },
          {
            path: ENDPOINTS.PASSWORD_RESET_EMAIL_SENT_PAGE,
            loadComponent: () =>
              import('./features/unauthenticated/components').then(
                component => component.RequestResetPasswordSuccessWebComponent
              ),
          },
          {
            path: 'reset-password/:token',
            loadComponent: () =>
              import('./features/unauthenticated/components').then(
                component => component.ResetPasswordWebComponent
              ),
            data: { needConfirmation: false, isResetLink: true },
          },
          {
            path: ENDPOINTS.PASSWORD_RESET_SUCCESS_PAGE,
            loadComponent: () =>
              import('./features/unauthenticated/components').then(
                component => component.ResetPasswordSuccessWebComponent
              ),
          },
        ],
      },
    ],
  },
  {
    path: ROUTE.LOGOUT,
    loadComponent: () =>
      import('./features/logout/logout.component').then(component => component.LogoutComponent),
  },
  ...unauthenticatedRoutes,
];
