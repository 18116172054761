import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingType, TicketsBaseComponent } from '@resident-nx/shared';
import { TicketCardWebComponent } from '../ticket-card/ticket-card.component';
import { CardWebComponent } from '../../../atoms/card/card.component';
import { IconWebComponent } from '../../../atoms/icon/icon.component';
import { LoaderWebComponent } from '../../../atoms/loader/loader.component';
import { LabelWebComponent } from '../../../atoms/label/label.component';
import { NoDataWebComponent } from '../../../atoms/no-data/no-data.component';
import { LoadingSkeletonWebComponent } from '../../../atoms/loading-skeleton/loading-skeleton.component';

@Component({
  selector: 'rs-web-tickets',
  standalone: true,
  imports: [
    TicketCardWebComponent,
    CardWebComponent,
    TranslateModule,
    IconWebComponent,
    LoaderWebComponent,
    LabelWebComponent,
    NoDataWebComponent,
    LoadingSkeletonWebComponent,
  ],
  templateUrl: './tickets.component.html',
  styleUrl: './tickets.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketsWebComponent extends TicketsBaseComponent {
  @Input() createButtonText: string;
  @Input() hasActiveContracts: boolean;
  @Output() createTicket = new EventEmitter();
  protected readonly LoadingType = LoadingType;
}
