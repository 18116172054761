import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import {
  BreadcrumbItem,
  ButtonTypeEnum,
  IOffersState,
  OfferDetailsBaseComponent,
} from '@resident-nx/shared';
import { filter } from 'rxjs';
import { ROUTE } from '../../../models';
import { DateTransformPipe } from '../../../pipes/date.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { LoaderWebComponent } from '../../atoms/loader/loader.component';
import { ButtonWebComponent } from '../../atoms/button/button.component';
import { ImageWebComponent } from '../../atoms/image/image.component';
import { BreadcrumbsWebComponent } from '../../atoms/breadcrumbs/breadcrumbs.component';

@UntilDestroy()
@Component({
  selector: 'rs-web-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.scss'],
  standalone: true,
  imports: [
    BreadcrumbsWebComponent,
    ImageWebComponent,
    ButtonWebComponent,
    LoaderWebComponent,
    AsyncPipe,
    TranslateModule,
    DateTransformPipe,
  ],
})
export class OfferDetailsWebComponent extends OfferDetailsBaseComponent implements OnInit {
  public buttonTypeEnum = ButtonTypeEnum;
  public breadcrumbs: BreadcrumbItem[] = [
    {
      label: 'main_navigation_news_l',
      url: ROUTE.NEWS,
    },
    {
      label: 'news.offers_l',
      url: `${ROUTE.NEWS}/${ROUTE.OFFERS}`,
    },
  ];
  constructor(
    private store: Store<IOffersState>,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.offer$
      .pipe(
        filter(o => o !== null),
        untilDestroyed(this)
      )
      .subscribe(offer => {
        this.breadcrumbs.push({ label: offer.title });
      });
  }

  public onNavigateBack() {
    this.router.navigate([ROUTE.NEWS, ROUTE.OFFERS]);
  }

  public onNavigate(event: string) {
    this.router.navigate([event]);
  }
}
