import { Directive } from '@angular/core';
import { WhitelabelImage, WhitelabelImageType } from '../../models';

@Directive()
export abstract class UnauthorizedUserBaseComponent {
  public imagePath = '~/assets/shared/images/guard-dog.png';
  public whitelabelImage = WhitelabelImage;
  public whitelabelImageType = WhitelabelImageType;

  abstract backToLogin(): void;
}
