import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromMasterDataState from '../../../+state/account/masterdata';

import * as fromTicketsState from '../../../+state/tickets';
import {
  ISort,
  ITicketResidentOverviewEdge,
  ImmomioIconName,
  TicketSortField,
} from '../../../models';
import { IActionState } from '../../../utils';

@Directive()
export abstract class TicketsOverviewArchivedBaseComponent implements OnInit {
  @Input() archiveViewOpened = false;
  @Input() txtLoadMore = '';
  @Input() archiveType: 'damage' | 'request' = 'damage';
  @Output() openTicket = new EventEmitter();
  @Output() toggleView = new EventEmitter();
  public archiveTickets$: Observable<ITicketResidentOverviewEdge[]>;
  public hasNextPage$: Observable<boolean>;
  public archiveTicketsActionState$: Observable<IActionState>;
  public immomioIconName = ImmomioIconName;
  public txtNoTickets: string;
  public pagination = {
    offset: 0,
    limit: 20,
  };
  public sort: ISort<TicketSortField>;

  constructor(
    public ticketsStore: Store<fromTicketsState.ITicketsState>,
    public masterDataStore: Store<fromMasterDataState.IMasterDataState>
  ) {}

  ngOnInit(): void {
    switch (this.archiveType) {
      case 'damage':
        this.selectArchiveDamageTickets();
        this.txtNoTickets = 'archive_damage.no_data';
        break;
      case 'request':
        this.selectArchiveRequestTickets();
        this.txtNoTickets = 'archive_request.no_data';
        break;
    }
  }

  public loadMoreArchiveTicket(pagination?: { offset: number; limit: number }): void {
    switch (this.archiveType) {
      case 'damage':
        this.loadMoreArchiveDamageTickets(pagination);
        break;
      case 'request':
        this.loadMoreArchiveRequestTickets(pagination);
        break;
    }
  }

  private loadMoreArchiveDamageTickets(pagination?: { offset: number; limit: number }): void {
    this.pagination = pagination || { offset: this.pagination.offset + 20, limit: 20 };
    this.ticketsStore.dispatch(
      fromTicketsState.LoadArchiveDamageTicketsForSelectedContract({
        ...this.pagination,
        sort: this.sort,
      })
    );
  }

  private selectArchiveDamageTickets(): void {
    this.archiveTickets$ = this.ticketsStore.select(
      fromTicketsState.getArchiveDamageTicketsForSelectedContract
    );
    this.hasNextPage$ = this.ticketsStore.select(
      fromTicketsState.hasNextPageArchiveDamageTicketsForSelectedContract
    );
    this.archiveTicketsActionState$ = this.ticketsStore.select(
      fromTicketsState.getArchiveDamageTicketsForSelectedContractActionState
    );
  }

  private loadMoreArchiveRequestTickets(pagination?: { offset: number; limit: number }): void {
    this.pagination = pagination || { offset: this.pagination.offset + 20, limit: 20 };
    this.ticketsStore.dispatch(
      fromTicketsState.LoadArchiveRequestTicketsForSelectedContract({
        ...this.pagination,
        sort: this.sort,
      })
    );
  }

  private selectArchiveRequestTickets(): void {
    this.archiveTickets$ = this.ticketsStore.select(
      fromTicketsState.getArchiveRequestTicketsForSelectedContract
    );
    this.hasNextPage$ = this.ticketsStore.select(
      fromTicketsState.hasNextPageArchiveRequestTicketsForSelectedContract
    );
    this.archiveTicketsActionState$ = this.ticketsStore.select(
      fromTicketsState.getArchiveRequestTicketsForSelectedContractActionState
    );
  }
}
