<div
  class="avatar"
  [class.avatar--tiny]="size === avatarSizeEnum.TINY"
  [class.avatar--small]="size === avatarSizeEnum.SMALL"
  [class.avatar--medium]="size === avatarSizeEnum.MEDIUM"
  [class.avatar--large]="size === avatarSizeEnum.LARGE"
  [class.rounded-circle]="rounded"
>
  <div class="avatar__placeholder">{{ name | userInitials }}</div>
  <div class="avatar__placeholder-mask" [class.rounded-circle]="rounded"></div>
</div>
