import { createAction, props } from '@ngrx/store';

export const ShowFeedbackSuccess = createAction(
  '[Resident Feedback] Show Feedback Success',
  props<{
    message: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    position?: any;
    backgroundColor?: string;
    title?: string;
    duration?: number;
  }>()
);

export const ShowFeedbackInfo = createAction(
  '[Resident Feedback] Show Feedback Info',
  props<{
    message: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    position?: any;
    backgroundColor?: string;
    title?: string;
    duration?: number;
  }>()
);

export const ShowFeedbackError = createAction(
  '[Resident Feedback] Show Feedback Error',
  props<{
    message: string | Error;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    position?: any;
    backgroundColor?: string;
    title?: string;
    duration?: number;
  }>()
);

export const ShowFeedbackWarning = createAction(
  '[Resident Feedback] Show Feedback Warning',
  props<{
    message: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    position?: any;
    backgroundColor?: string;
    title?: string;
  }>()
);
