import { Directive, Inject, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { IKeyValue, ISaasResidentLandlordCustomerResponse } from '../../models';
import { IActionState } from '../../utils';
import { RouterService } from '../../services';
import {
  getRegisteredUserApps,
  getRegisteredUserAppsActionState,
  IUserAppsState,
} from '../../+state';

@Directive()
export class UserAppsSelectionPageBaseComponent implements OnInit {
  public userApps$: Observable<ISaasResidentLandlordCustomerResponse[]>;
  public userAppsActionState$: Observable<IActionState>;

  constructor(
    protected routerService: RouterService,
    protected store: Store<IUserAppsState>,
    @Inject('ENDPOINTS') protected endpoints: IKeyValue
  ) {}

  ngOnInit() {
    this.userApps$ = this.store.select(getRegisteredUserApps);
    this.userAppsActionState$ = this.store.select(getRegisteredUserAppsActionState);
  }

  protected navigateToContent(): void {
    this.routerService.navigate([this.endpoints.CONTENT]);
  }
}
