import { Directive, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';
import { RouterService } from '../../services';
import * as fromDocumentsStore from '../../../shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';

import { getDocumentSorting, getSelectedContract, ISort } from '../../../shared';

@Directive()
@UntilDestroy()
export class HomeErrorScreenPageBaseComponent implements OnInit {
  private contractId: string;
  private selectedSort: ISort<fromDocumentsStore.DocumentSortField>;

  public id: string;
  public url: string;
  public homeUrl = '../';

  constructor(
    private routerService: RouterService,
    private activeRoute: ActivatedRoute,
    private store: Store
  ) {}

  ngOnInit() {
    this.id = this.activeRoute.snapshot?.params?.id;
    this.url = this.activeRoute.snapshot?.params?.url;

    combineLatest([this.store.select(getSelectedContract), this.store.select(getDocumentSorting)])
      .pipe(untilDestroyed(this))
      .subscribe(([selectedContract, selectedSort]) => {
        this.contractId = selectedContract.id;
        this.selectedSort = selectedSort;
      });
  }

  public tryAgain() {
    this.store.dispatch(
      fromDocumentsStore.GetSharedContent({
        params: {
          folderId: this.id || null,
          contractId: this.contractId || null,
          sortOrder: this.selectedSort.direction || null,
          sortField: this.selectedSort.value || null,
        },
      })
    );
    this.routerService.navigate([this.url]);
  }

  public goToHomeOverview() {
    this.routerService.navigate([this.homeUrl], { relativeTo: this.activeRoute });
  }
}
