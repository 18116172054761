import { IStateAfterAuth } from '@resident-nx/shared';
import { v4 } from 'uuid';

export const getNonceAndState = () => {
  const randomV4 = v4();
  return { nonce: randomV4, state: randomV4 };
};

export function getRedirectUrl(
  currentWindowLocation: string,
  authRoute: string,
  stateAfterAuth?: IStateAfterAuth
): string {
  function turnIntoString(queryParamsObject: IStateAfterAuth) {
    const pathAfterAuth = `pathAfterAuth=${encodeURIComponent(queryParamsObject.pathAfterAuth)}`;
    let params = '';
    if (queryParamsObject.queryParams) {
      Object.keys(queryParamsObject.queryParams).forEach(key => {
        params += `&${key}=${encodeURIComponent(queryParamsObject.queryParams[key])}`;
      });
    }
    return `${pathAfterAuth}${params}`;
  }

  const urlWithBaseHref = currentWindowLocation.split('?')[0].split('/').splice(0, 3).join('/');

  const queryParamsString = stateAfterAuth ? `?${turnIntoString(stateAfterAuth)}` : '?';
  return `${urlWithBaseHref}${authRoute}${queryParamsString}`;
}
