import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, tap } from 'rxjs';
import { ROUTE } from '../../models';
import { Router } from '@angular/router';
import {
  ActionStateCreator,
  getPermissions,
  getPermissionsActionState,
  LoadPermissions,
} from '@resident-nx/shared';

export const PermissionsGuard = (permissions: string[]) => {
  return () => {
    const store = inject(Store);
    const router = inject(Router);
    return store.select(getPermissionsActionState).pipe(
      tap(state => {
        if (ActionStateCreator.isInitialState(state)) {
          store.dispatch(LoadPermissions());
        }
      }),
      filter(state => state.done),
      switchMap(() =>
        store.select(getPermissions).pipe(
          map(settings => {
            const hasAllPermissions = permissions.every(
              permission => settings?.[permission] === true
            );
            if (!hasAllPermissions) {
              return router.navigate([ROUTE.HOME]);
            }

            return true;
          })
        )
      )
    );
  };
};
