import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { IPublishedOffersResponse, IResidentOfferContent } from '../../models';
import { offerQuery, offersQuery, publishedOffersPublicQuery } from '../../queries';

@Injectable()
export class OffersFacade {
  constructor(private apollo: Apollo) {}

  public getOffers(offset: number, limit: number): Observable<IPublishedOffersResponse> {
    return this.apollo
      .query<{ publishedOffers: IPublishedOffersResponse }>({
        query: offersQuery,
        variables: {
          offset,
          limit,
        },
      })
      .pipe(
        map(response => {
          return response?.data?.publishedOffers;
        })
      );
  }

  public getOffer(offerId: string): Observable<IResidentOfferContent> {
    return this.apollo
      .query<{ getOffer: IResidentOfferContent }>({
        query: offerQuery,
        variables: {
          offerId,
        },
      })
      .pipe(map(response => response?.data?.getOffer));
  }

  public getPublishedOffersPublic(
    offset: number,
    limit: number
  ): Observable<IPublishedOffersResponse> {
    return this.apollo
      .query<{ publishedOffersPublic: IPublishedOffersResponse }>({
        query: publishedOffersPublicQuery,
        variables: {
          offset,
          limit,
        },
      })
      .pipe(map(response => response?.data?.publishedOffersPublic));
  }
}
