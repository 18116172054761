import { createReducer, on } from '@ngrx/store';
import { ActionStateCreator, IActionState } from '../../../utils';
import * as fromActions from './popup-notifications.actions';

export interface IPopupNotificationsState {
  showAddressChangePopup: boolean;
  showAddressChangePopupState: IActionState;
}

export const initialState: IPopupNotificationsState = {
  showAddressChangePopup: true,
  showAddressChangePopupState: ActionStateCreator.create(),
};

export const popupNotificationsReducer = createReducer(
  initialState,
  on(fromActions.setShowAddressChangePopup, (state, { showAddressChangePopup }) => {
    return {
      ...state,
      showAddressChangePopup,
      showAddressChangePopupState: ActionStateCreator.onSuccess(),
    };
  })
);

export const getShowAddressChangePopup = (state: IPopupNotificationsState) =>
  state?.showAddressChangePopup;
export const getShowAddressChangePopupActionState = (state: IPopupNotificationsState) =>
  state?.showAddressChangePopupState;
