import { Directive, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRegistrationStore from '../../+state/account/registration';
import { RouterService } from '../../services';
import {
  ErrorOrigin,
  IRegisterExistingResident,
  IRegisterNewResident,
  IRegistrationForm,
  WhitelabelImage,
  WhitelabelImageType,
} from '../../models';

@UntilDestroy()
@Directive()
export class RegistrationErrorScreenBaseComponent implements OnInit {
  public error: string;
  public whitelabelImage = WhitelabelImage;
  public whitelabelImageType = WhitelabelImageType;
  private registrationForm: IRegistrationForm;
  private errorOrigin: string;

  constructor(
    private routerService: RouterService,
    private activeRoute: ActivatedRoute,
    private registerStore: Store<fromRegistrationStore.RegistrationState>
  ) {}

  ngOnInit() {
    this.registerStore
      .select(fromRegistrationStore.getRegistrationForm)
      .pipe(untilDestroyed(this), take(1))
      .subscribe(registrationForm => (this.registrationForm = registrationForm));
    if (this.activeRoute.snapshot.paramMap) {
      this.errorOrigin = this.activeRoute.snapshot.paramMap.get('origin');
      this.error = this.activeRoute.snapshot.paramMap.get('error');
    }
  }

  public tryAgain(): void {
    if (this.errorOrigin === ErrorOrigin.REGISTRATION_INVITATION_CODE) {
      this.checkInvitationCodeAgain();
    } else if (this.errorOrigin === ErrorOrigin.REGISTRATION_PASSWORD_AND_CONSENT) {
      this.setPasswordAndConsentAgain();
    } else if (this.errorOrigin === ErrorOrigin.REGISTRATION_CONSENT) {
      this.setConsentAgain();
    }
  }

  private checkInvitationCodeAgain(): void {
    const invitationCode = this.registrationForm.invitationCode;
    this.registerStore.dispatch(fromRegistrationStore.ClearRegistrationFormState());
    this.registerStore.dispatch(fromRegistrationStore.CheckInvitationCode({ invitationCode }));
    this.registerStore
      .select(fromRegistrationStore.getInvitationCodeResponse)
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        if (data) {
          void this.routerService.navigate(['../register-page2-email-insert'], {
            relativeTo: this.activeRoute,
          });
        }
      });
    this.registerStore
      .select(fromRegistrationStore.getInvitationCodeState)
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        if (state.error) {
          void this.routerService.navigate(['../register-page1-invitation-code'], {
            relativeTo: this.activeRoute,
          });
        } else if (state.done) {
          void this.routerService.navigate(['../register-page2-email-insert'], {
            relativeTo: this.activeRoute,
          });
        }
      });
  }

  private setPasswordAndConsentAgain(): void {
    const registerResidentInput: IRegisterExistingResident = {
      email: this.registrationForm.email,
      invitationCode: this.registrationForm.invitationCode,
      hasConsentedToDigitalDocumentsReceival:
        this.registrationForm.hasConsentedToDigitalDocumentsReceival,
    };
    this.registerAgain(registerResidentInput);
  }

  private setConsentAgain(): void {
    const registerResidentInput: IRegisterNewResident = {
      email: this.registrationForm.email,
      invitationCode: this.registrationForm.invitationCode,
      password: this.registrationForm.password,
      passwordConfirmation: this.registrationForm.password,
      hasConsentedToDigitalDocumentsReceival:
        this.registrationForm.hasConsentedToDigitalDocumentsReceival,
    };
    this.registerAgain(registerResidentInput);
  }

  private registerAgain(
    registerResidentInput: IRegisterNewResident | IRegisterExistingResident
  ): void {
    this.registerStore.dispatch(fromRegistrationStore.RegisterResident({ registerResidentInput }));
    this.registerStore
      .select(fromRegistrationStore.getRegisterResidentActionState)
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        if (response.done) {
          void this.routerService.navigate(['../register-page4-confirmation-email-info'], {
            relativeTo: this.activeRoute,
          });
        } else if (response.error) {
          console.error(response.error);
          // TODO failed again
        }
      });
  }
}
