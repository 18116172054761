import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { Store } from '@ngrx/store';
import { EMPTY, first, of, switchMap } from 'rxjs';

import {
  getContractsActionState,
  getSelectedContract,
  LoadContracts,
  SelectContract,
} from '../../+state';
import { IContract } from '../../models';
import { ResolverCommonService } from './resolver-common.service';

export const ContractResolver: ResolveFn<IContract> = route => {
  const common = inject(ResolverCommonService);
  const store = inject(Store);

  return common.resolveHandler(getContractsActionState, LoadContracts({})).pipe(
    switchMap(state => {
      if (!!state && !state.error && state.done) {
        store.dispatch(SelectContract({ id: route.params['contractId'] }));
        return store.select(getSelectedContract);
      }
      if (state.error) {
        return of(null);
      }
      return EMPTY;
    }),
    first()
  );
};
