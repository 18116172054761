import { Directive, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  ITicketEdit,
  ITicketEditResponse,
  ITicketMetadataCreate,
  TicketingVersion,
  TicketIssueType,
} from '../../../models';
import { RouterService } from '../../../services';
import * as fromTicketsState from '../../../+state/tickets';
import { getResidentAppSettings } from '../../../+state';
import { switchMap } from 'rxjs/operators';
import { IActionState } from '../../../utils';

@UntilDestroy()
@Directive()
export class TicketCreationErrorPageBaseComponent implements OnInit {
  public createTicketResponse$: Observable<ITicketEditResponse>;
  public newTicket$: Observable<ITicketEdit | ITicketMetadataCreate>;
  public issueType: TicketIssueType | undefined;
  public headerTitle: string;
  public ticketIssueType = TicketIssueType;
  public cancellationUrl = '../..';
  public ticketingVersion$: Observable<TicketingVersion> = this.store
    .select(getResidentAppSettings)
    .pipe(
      map(response => response?.ticketingVersion),
      filter(response => response !== null),
      untilDestroyed(this)
    );

  constructor(
    private routerService: RouterService,
    private route: ActivatedRoute,
    private store: Store<fromTicketsState.ITicketsState>
  ) {}

  ngOnInit() {
    this.createTicketResponse$ = this.ticketingVersion$.pipe(
      switchMap(version => {
        if (version === TicketingVersion.METADATA) {
          return this.store.select(fromTicketsState.getCreateTicketMetadataResponse);
        } else {
          return this.store.select(fromTicketsState.getCreateTicketHQResponse);
        }
      })
    );

    this.newTicket$ = this.ticketingVersion$.pipe(
      switchMap(version => {
        if (version === TicketingVersion.METADATA) {
          return this.store.select(fromTicketsState.getNewTicketMetadata);
        } else {
          return this.store.select(fromTicketsState.getNewTicketHQ);
        }
      })
    );

    this.issueType = this.route.snapshot?.params?.type as TicketIssueType;
    if (this.issueType === TicketIssueType.PROPERTY_DAMAGE) {
      this.headerTitle = 'ticket_creation.error.damage_header_l';
    } else if (this.issueType === TicketIssueType.CONCERN) {
      this.headerTitle = 'ticket_creation.error.concern_header_l';
    }
  }

  public goToTicketOverview() {
    this.store.dispatch(fromTicketsState.ClearTicketCreation());
    this.routerService.navigate([this.cancellationUrl], { relativeTo: this.route });
  }

  public tryAgain(newTicket: ITicketEdit | ITicketMetadataCreate) {
    this.ticketingVersion$.pipe(untilDestroyed(this)).subscribe(version => {
      if (version === TicketingVersion.METADATA) {
        this.store.dispatch(
          fromTicketsState.CreateTicketMetadata({
            newTicketMetadata: newTicket as ITicketMetadataCreate,
          })
        );
        this.handleCreateTicketMetadataResponse();
      } else {
        this.store.dispatch(
          fromTicketsState.CreateTicketHQ({ newTicketHQ: newTicket as ITicketEdit })
        );
        this.handleCreateTicketHQResponse();
      }
    });
  }

  private handleCreateTicketHQResponse(): void {
    this.store
      .select(fromTicketsState.getCreateTicketHQActionState)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.redirectAfterRequest(res);
      });
  }

  private handleCreateTicketMetadataResponse(): void {
    this.store
      .select(fromTicketsState.getCreateTicketMetadataActionState)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.redirectAfterRequest(res);
      });
  }

  private redirectAfterRequest(state: IActionState): void {
    if (state.done) {
      void this.routerService.navigate(['../../ticket-creation-success', this.issueType], {
        relativeTo: this.route,
      });
    } else if (state.error) {
      void this.routerService.navigate(['../../ticket-creation-error', this.issueType], {
        relativeTo: this.route,
      });
    }
  }
}
