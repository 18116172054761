import { gql } from 'apollo-angular';

export const responseFields = `
  status
`;

export const registerUserMutation = gql`
  mutation registerResident($input: RegisterResidentInputBean!) {
    registerResident(input: $input) {
      status
      accessToken
      sessionState
    }
  }
`;

export const verifyEmailMutation = gql`
  mutation verifyEmail($input: ResidentVerifyEmailInputBean!) {
    verifyEmail(input: $input)
  }
`;

// using tunnel
export const triggerConfirmEmailQuery = gql`
  query _tunnelResendVerificationEmail($contractNumber: String!, $partnerNumber: String!) {
    masterdata {
      resendEmailVerification {
        resendVerificationCode(contractNumber: $contractNumber, partnerNumber: $partnerNumber)
      }
    }
  }
`;

export const verifyContractAndPartnerNumberQuery = gql`
  query _tunnelVerifyContractAndPartnerNumber($contractNumber: String!, $partnerNumber: String!) {
    masterdata {
      checkNumbers(contractNumber: $contractNumber, partnerNumber: $partnerNumber) {
        checkNumbers {
          error
          exists
        }
      }
    }
  }
`;

export const responseInvitationCode = `
firstName,
name,
customerIdent,
customerName
email
`;

export const validateResidentInvitationCode = gql`
  query validateResidentInvitationCode($input: String!) {
    validateResidentInvitationCode (input: $input) {
      ${responseInvitationCode}
    }
  }
`;

export const responseEmailCheck = `
userExists
`;

export const userExistsByEmail = gql`
  query userExistsByEmail($input: ResidentUserExistsByEmailInputBean!) {
    userExistsByEmail (input: $input) {
      ${responseEmailCheck}
    }
  }
`;

export const requestInvitationCodeMutation = gql`
  mutation _tunnelGenerateRegistrationCode(
    $name: String!
    $surname: String!
    $street: String!
    $zipcode: String!
    $city: String!
  ) {
    generateRegistrationCode {
      generateRegCode(
        name: $name
        surname: $surname
        street: $street
        zipcode: $zipcode
        city: $city
      )
    }
  }
`;
