import { Component, inject, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  getContracts,
  getHideWelcomeScreen,
  getPersonalData,
  getResidentAppSettings,
  ImmomioIconName,
  IUserData,
  setHideWelcomeScreen,
} from '@resident-nx/shared';
import {
  AvatarSizeEnum,
  AvatarWebComponent,
  FooterComponent,
  FooterLink,
  HeaderComponent,
  LocalStorageService,
  MobileNavigationComponent,
  NavigationAlignment,
  NavigationComponent,
  NavigationItem,
  NavigationItemStateEnum,
  ROUTE,
} from '@resident-nx/web';
import { ENDPOINTS } from '../../../../../endpoints';
import { AsyncPipe } from '@angular/common';
import { RouterOutlet } from '@angular/router';

const navigationItems = [
  {
    name: 'dashboard',
    link: ROUTE.DASHBOARD,
    label: 'main_navigation_dashboard_l',
    icon: ImmomioIconName.Dashboard,
  },
  {
    name: 'news',
    link: ROUTE.NEWS,
    label: 'main_navigation_news_l',
    icon: ImmomioIconName.News,
  },
  {
    name: 'tickets',
    link: ROUTE.TICKETS,
    label: 'main_navigation_concerns_l',
    icon: ImmomioIconName.Ticket,
  },
  {
    name: 'home',
    link: ROUTE.HOME,
    label: 'main_navigation_my_home_l',
    icon: ImmomioIconName.House,
  },
];
const footerItems = [
  {
    link: 'imprint',
    name: 'Impressum',
  },
  {
    link: 'privacy',
    name: 'Datenschutz',
  },
  {
    link: 'agb',
    name: 'AGB',
  },
];

const profileSettingsItems = [
  {
    name: 'profile page',
    link: ROUTE.PROFILE_PAGE,
    label: 'user.personal_data',
    icon: ImmomioIconName.User,
  },
  {
    name: 'settings',
    link: ENDPOINTS.SETTINGS,
    label: 'main_navigation.settings_l',
    icon: ImmomioIconName.Gear,
  },
  {
    name: 'notifications',
    link: `${ENDPOINTS.SETTINGS}/${ENDPOINTS.NOTIFICATIONS}`,
    label: 'main_navigation.notifications_l',
    icon: ImmomioIconName.News,
  },
  {
    name: 'Faq',
    link: ROUTE.FAQ,
    label: 'main_navigation.user_faq_l',
    icon: ImmomioIconName.QuestionOutlined,
  },
  {
    name: 'logout',
    link: ENDPOINTS.LOGOUT,
    label: 'Logout',
    icon: ImmomioIconName.Logout,
    state: NavigationItemStateEnum.WARNING,
  },
];

@UntilDestroy()
@Component({
  selector: 'rs-web-authenticated',
  templateUrl: './authenticated.component.html',
  styleUrls: ['./authenticated.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    AsyncPipe,
    HeaderComponent,
    NavigationComponent,
    AvatarWebComponent,
    FooterComponent,
    MobileNavigationComponent,
  ],
})
export class AuthenticatedComponent implements OnInit {
  public navigationItems: NavigationItem[];
  public profileSettingsItems: NavigationItem[];
  public footerItems: FooterLink[];
  public profileSettingsOpen = false;
  public navigationAlignment = NavigationAlignment;
  public userData$: Observable<IUserData>;
  public avatarSize = AvatarSizeEnum;
  private localStorageService = inject(LocalStorageService);

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(
      setHideWelcomeScreen({
        hideWelcomeScreen: this.localStorageService.getItem('hideWelcomeScreen') === true,
      })
    );
    combineLatest([
      this.store.select(getContracts),
      this.store.select(getResidentAppSettings),
      this.store.select(getHideWelcomeScreen),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([contracts, residentAppSettings, hideDashboardNavigation]) => {
        const ticketCreationWithoutContractAllowed =
          residentAppSettings.allowContractlessTicketCreationForDamages ||
          residentAppSettings.allowContractlessTicketCreationForOtherConcerns;
        this.navigationItems =
          contracts?.length || ticketCreationWithoutContractAllowed
            ? navigationItems
            : navigationItems.filter(item => item.name !== ROUTE.TICKETS);
        this.navigationItems = navigationItems.filter(item => {
          if (
            !contracts?.length &&
            !ticketCreationWithoutContractAllowed &&
            item.name === ROUTE.TICKETS
          ) {
            return false;
          }
          if (item.link === ROUTE.DASHBOARD && !contracts?.length && hideDashboardNavigation) {
            return false;
          }
          return true;
        });
      });

    this.profileSettingsItems = profileSettingsItems;
    this.footerItems = footerItems;
    this.userData$ = this.store.select(getPersonalData);
  }

  public onProfileSettingsToggleClick(event: Event) {
    event.stopPropagation();
    this.profileSettingsOpen = !this.profileSettingsOpen;
  }
}
