import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { getNews, getNewsActionState, LoadNews, LoadPublishedNewsPublic } from '../../+state';
import { IPublishedNewsEdge } from '../../models';
import { ResolverCommonService } from './resolver-common.service';

export const CurrentNewsResolver: ResolveFn<IPublishedNewsEdge[]> = route => {
  const common = inject(ResolverCommonService);
  const { resolverWaitForData, isPublicContent, pageSize } = route.data;
  const newsPayload = {
    limit: pageSize ?? 10,
    offset: 0,
  };

  return common.resolveHandler<IPublishedNewsEdge[]>(
    getNewsActionState,
    isPublicContent ? LoadPublishedNewsPublic(newsPayload) : LoadNews(newsPayload),
    getNews,
    resolverWaitForData
  );
};
