import { createReducer, on } from '@ngrx/store';

import { IPermissions, IResidentAppSettings } from '../../../models';
import { IActionState, ActionStateCreator } from '../../../utils';
import * as fromActions from './permissions.actions';

export interface IPermissionsState {
  permissionsActionState: IActionState;
  permissions: IPermissions;
  residentAppSettings: IResidentAppSettings;
}

export const initialState: IPermissionsState = {
  permissionsActionState: ActionStateCreator.create(),
  permissions: null,
  residentAppSettings: null,
};

export const permissionsReducer = createReducer(
  initialState,

  on(fromActions.LoadPermissions, state => {
    return {
      ...state,
      permissionsActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.LoadPermissionsSuccess, (state, { response }) => {
    return {
      ...state,
      permissionsActionState: ActionStateCreator.onSuccess(),
      permissions: response.permissions,
      residentAppSettings: response.residentAppSettings,
    };
  }),
  on(fromActions.LoadPermissionsFailed, (state, { error }) => {
    return {
      ...state,
      permissionsActionState: ActionStateCreator.onError(error),
    };
  })
);

export const getPermissionsActionState = (state: IPermissionsState) => state.permissionsActionState;
export const getPermissions = (state: IPermissionsState) => state.permissions;
export const getResidentAppSettings = (state: IPermissionsState) => state.residentAppSettings;
