import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  ICheckEmailExists,
  ICheckInvitationCode,
  ICheckNumbersResponse,
  IRegisterResidentResponse,
  IRequestInvitationCodeForm,
  IUserExistsByEmail,
  IVerifyEmailInput,
  RegisterResidentInput,
} from '../../../models';
import {
  registerUserMutation,
  requestInvitationCodeMutation,
  triggerConfirmEmailQuery,
  userExistsByEmail,
  validateResidentInvitationCode,
  verifyContractAndPartnerNumberQuery,
  verifyEmailMutation,
} from '../../../queries';

@Injectable()
export class AccountRegistrationFacade {
  constructor(private apollo: Apollo) {}

  public registerUser(input: RegisterResidentInput): Observable<IRegisterResidentResponse> {
    return this.apollo
      .mutate<{ registerResident: IRegisterResidentResponse }>({
        mutation: registerUserMutation,
        variables: {
          input,
        },
      })
      .pipe(map(result => result?.data?.registerResident));
  }

  public verifyContractAndPartnerNumber(
    contractNumber: string,
    partnerNumber: string
  ): Observable<ICheckNumbersResponse> {
    return this.apollo
      .mutate<{
        masterdata: { checkNumbers: { checkNumbers: ICheckNumbersResponse } };
      }>({
        mutation: verifyContractAndPartnerNumberQuery,
        variables: {
          contractNumber,
          partnerNumber,
        },
      })
      .pipe(
        map(result => result && result.data.masterdata.checkNumbers.checkNumbers),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }

  public verifyEmail(input: IVerifyEmailInput): Observable<boolean> {
    return this.apollo
      .mutate<boolean>({
        mutation: verifyEmailMutation,
        variables: {
          input,
        },
      })
      .pipe(map(result => result && result.data));
  }

  public triggerConfirmEmail(contractNumber: string, partnerNumber: string): Observable<boolean> {
    return this.apollo
      .query<boolean>({
        query: triggerConfirmEmailQuery,
        variables: {
          contractNumber,
          partnerNumber,
        },
      })
      .pipe(
        map(result => result && result.data),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }

  /*  public registerDevice(): Observable<boolean> {
    const deviceData: IRegisterDeviceInput = {
      identifier: device.uuid,
      firebaseToken: appSettings.getString('firebaseToken'),
      data: {
        os: device.os,
        deviceType: device.deviceType,
        language: device.language,
        manufacturer: device.manufacturer,
        model: device.model,
        osVersion: device.osVersion,
        region: device.region,
        sdkVersion: device.sdkVersion,
      },
    };
    return this.apollo
      .mutate<boolean>({
        mutation: registerDeviceMutation,
        variables: {
          input: deviceData,
        },
      })
      .pipe(map(result => result && result.data));
  } */

  public checkInvitationCode(input: string): Observable<ICheckInvitationCode> {
    return this.apollo
      .query<{ validateResidentInvitationCode: ICheckInvitationCode }>({
        query: validateResidentInvitationCode,
        variables: {
          input,
        },
      })
      .pipe(
        map(response => {
          if (response?.data?.validateResidentInvitationCode) {
            return response.data.validateResidentInvitationCode;
          } else if (response?.errors) {
            throw new Error(response.errors[0].message);
          }
          throw new Error();
        })
      );
  }

  public checkEmailExists(input: IUserExistsByEmail): Observable<ICheckEmailExists> {
    return this.apollo
      .query<{ userExistsByEmail: ICheckEmailExists }>({
        query: userExistsByEmail,
        variables: {
          input,
        },
      })
      .pipe(map(response => response?.data?.userExistsByEmail));
  }

  public requestInvitationCode(input: IRequestInvitationCodeForm): Observable<boolean> {
    const { name, surname, street, zipcode, city } = input;
    return this.apollo
      .mutate<{ generateRegistrationCode: { generateRegCode: boolean } }>({
        mutation: requestInvitationCodeMutation,
        variables: {
          name,
          surname,
          street,
          zipcode,
          city,
        },
      })
      .pipe(
        map(response => {
          return response?.data?.generateRegistrationCode?.generateRegCode === null;
        })
      );
  }
}
