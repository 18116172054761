import { Directive, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import * as fromRegistrationStore from '../../+state/account/registration';
import { ErrorOrigin, ICheckInvitationCode } from '../../models';
import { IActionState } from '../../utils';
import { RouterService } from '../../services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive()
export abstract class InvitationFormBaseComponent implements OnInit {
  @Output() loginTapped = new EventEmitter();
  @Output() loginSuccess = new EventEmitter();
  @Output() forgotTapped = new EventEmitter();
  @Output() formSubmit = new EventEmitter();
  public checkInvitationCode$: Observable<ICheckInvitationCode>;
  public checkInvitationCodeState$: Observable<IActionState>;
  public invitationCode: string;
  public errorMessage: string;
  public codeValid = true;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private activeRoute: ActivatedRoute,
    public registerStore: Store<fromRegistrationStore.RegistrationState>,
    private routerService: RouterService
  ) {}

  fg: FormGroup = this.fb.group({
    invitationCode: ['', [Validators.required]],
  });

  ngOnInit() {
    this.checkInvitationCode$ = this.registerStore.select(
      fromRegistrationStore.getInvitationCodeResponse
    );
    this.checkInvitationCodeState$ = this.registerStore.select(
      fromRegistrationStore.getInvitationCodeState
    );
    this.registerStore
      .select(fromRegistrationStore.getRegistrationForm)
      .pipe(untilDestroyed(this))
      .subscribe(registrationForm => {
        if (registrationForm?.invitationCode) {
          this.fg.patchValue({ invitationCode: registrationForm.invitationCode });
          this.registerStore.dispatch(fromRegistrationStore.ClearCheckEmailExistsState());
          // TODO clear step 3 state
        }
      });
    this.registerStore
      .select(fromRegistrationStore.getInvitationCodeState)
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        if (state.error) {
          if (state.error.message === 'INVITATION_CODE_NOT_EXIST_L') {
            this.errorMessage = this.translate.instant('registration_wrong_invitation_code_l');
            this.codeValid = false;
          } else if (state.error.message === 'INVITATION_CODE_INVALID_L') {
            this.errorMessage = this.translate.instant('registration_outdated_invitation_code_l');
            this.codeValid = false;
          } else {
            void this.routerService.navigate(
              [
                '../register-page-registration-error-screen',
                { error: state.error, origin: ErrorOrigin.REGISTRATION_INVITATION_CODE },
              ],
              {
                relativeTo: this.activeRoute,
              }
            );
          }
        }
      });
  }

  public checkInvitationCode(fg: FormGroup): void {
    const invitationCode = fg.get('invitationCode').value;
    this.registerStore.dispatch(fromRegistrationStore.CheckInvitationCode({ invitationCode }));

    this.checkInvitationCode$.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {
        this.goToEmailForm();
      }
    });
  }

  public goToEmailForm(): void {
    void this.routerService.navigate(['../register-page2-email-insert'], {
      relativeTo: this.activeRoute,
    });
  }
}
