import { createReducer, on } from '@ngrx/store';
import {
  IContactPersonResponse,
  IHQTicketDetailsResponse,
  IMetadataTicketDetailsResponse,
  IRootQuestionTicketOverview,
  ISort,
  ITicketCreationSummary,
  ITicketEdit,
  ITicketEditResponse,
  ITicketMessageGroup,
  ITicketMetadataCreate,
  ITicketResidentCategory,
  ITicketResidentOverviewEdge,
  SortOrder,
  TicketIssueType,
  TicketSortField,
  TicketStatus,
  TicketingVersion,
} from '../../models';
import { ticketConverter } from '../../services';
import { ActionStateCreator, IActionState } from '../../utils';
import * as fromActions from './tickets.actions';

export interface ITicketsState {
  damageTickets: ITicketResidentOverviewEdge[];
  damageTicketsActionState: IActionState;
  hasNextPageDamage: boolean;
  requestTickets: ITicketResidentOverviewEdge[];
  requestTicketsActionState: IActionState;
  hasNextPageRequest: boolean;
  damageCategories: IRootQuestionTicketOverview;
  damageCategoriesActionState: IActionState;
  concernCategories: IRootQuestionTicketOverview;
  concernCategoriesActionState: IActionState;
  damageQuestions: ITicketResidentCategory;
  damageQuestionsActionState: IActionState;
  concernQuestions: ITicketResidentCategory;
  concernQuestionsActionState: IActionState;
  activeDamageTickets: ITicketResidentOverviewEdge[];
  activeDamageTicketsActionState: IActionState;
  hasNextPageActiveDamage: boolean;
  activeDamageTicketsForSelectedContract: ITicketResidentOverviewEdge[];
  activeDamageTicketsForSelectedContractActionState: IActionState;
  hasNextPageActiveDamageForSelectedContract: boolean;
  archiveDamageTickets: ITicketResidentOverviewEdge[];
  archiveDamageTicketsActionState: IActionState;
  hasNextPageArchiveDamage: boolean;
  archiveDamageTicketsForSelectedContract: ITicketResidentOverviewEdge[];
  archiveDamageTicketsForSelectedContractActionState: IActionState;
  hasNextPageArchiveDamageForSelectedContract: boolean;
  activeRequestTickets: ITicketResidentOverviewEdge[];
  activeRequestTicketsActionState: IActionState;
  hasNextPageActiveRequest: boolean;
  activeRequestTicketsForSelectedContract: ITicketResidentOverviewEdge[];
  activeRequestTicketsForSelectedContractActionState: IActionState;
  hasNextPageActiveRequestForSelectedContract: boolean;
  archiveRequestTickets: ITicketResidentOverviewEdge[];
  archiveRequestTicketsActionState: IActionState;
  hasNextPageArchiveRequest: boolean;
  archiveRequestTicketsForSelectedContract: ITicketResidentOverviewEdge[];
  archiveRequestTicketsForSelectedContractActionState: IActionState;
  hasNextPageArchiveRequestForSelectedContract: boolean;
  ticketDetails: IHQTicketDetailsResponse;
  ticketDetailsActionState: IActionState;
  ticketSummary: ITicketCreationSummary;
  newTicketHQ: ITicketEdit;
  createTicketHQResponse: ITicketEditResponse;
  createTicketHQActionState: IActionState;
  cancelTicketResponse: boolean;
  cancelTicketActionState: IActionState;
  ticketMessageGroups: ITicketMessageGroup[];
  ticketChatActionState: IActionState;
  newMessageActionState: IActionState;
  metadataTicketDetails: IMetadataTicketDetailsResponse;
  metadataTicketDetailsActionState: IActionState;
  newTicketMetadata: ITicketMetadataCreate;
  createTicketMetadataResponse: ITicketEditResponse;
  createTicketMetadataActionState: IActionState;
  directAssignmentContacts: IContactPersonResponse[];
  directAssignmentContactsActionState: IActionState;
}

export const initialState: ITicketsState = {
  damageTickets: [],
  damageTicketsActionState: ActionStateCreator.create(),
  hasNextPageDamage: false,
  requestTickets: [],
  requestTicketsActionState: ActionStateCreator.create(),
  hasNextPageRequest: false,
  damageCategories: null,
  damageCategoriesActionState: ActionStateCreator.create(),
  concernCategories: null,
  concernCategoriesActionState: ActionStateCreator.create(),
  damageQuestions: null,
  damageQuestionsActionState: ActionStateCreator.create(),
  concernQuestions: null,
  concernQuestionsActionState: ActionStateCreator.create(),
  activeDamageTickets: [],
  activeDamageTicketsActionState: ActionStateCreator.create(),
  hasNextPageActiveDamage: false,
  activeDamageTicketsForSelectedContract: [],
  activeDamageTicketsForSelectedContractActionState: ActionStateCreator.create(),
  hasNextPageActiveDamageForSelectedContract: false,
  archiveDamageTickets: [],
  archiveDamageTicketsActionState: ActionStateCreator.create(),
  hasNextPageArchiveDamage: false,
  archiveDamageTicketsForSelectedContract: [],
  archiveDamageTicketsForSelectedContractActionState: ActionStateCreator.create(),
  hasNextPageArchiveDamageForSelectedContract: false,
  activeRequestTickets: [],
  activeRequestTicketsActionState: ActionStateCreator.create(),
  hasNextPageActiveRequest: false,
  activeRequestTicketsForSelectedContract: [],
  activeRequestTicketsForSelectedContractActionState: ActionStateCreator.create(),
  hasNextPageActiveRequestForSelectedContract: false,
  archiveRequestTickets: [],
  archiveRequestTicketsActionState: ActionStateCreator.create(),
  hasNextPageArchiveRequest: false,
  archiveRequestTicketsForSelectedContract: [],
  archiveRequestTicketsForSelectedContractActionState: ActionStateCreator.create(),
  hasNextPageArchiveRequestForSelectedContract: false,
  ticketDetails: null,
  ticketDetailsActionState: ActionStateCreator.create(),
  ticketSummary: {
    name: '',
    rootQuestionId: '',
    categoryId: '',
    detailedQuestionId: '',
    responses: [],
    detailedResponses: [],
    directAssignmentContacts: null,
    categoryAnswerSetRelationId: '',
  },
  newTicketHQ: null,
  createTicketHQResponse: null,
  createTicketHQActionState: ActionStateCreator.create(),
  cancelTicketResponse: null,
  cancelTicketActionState: ActionStateCreator.create(),
  ticketMessageGroups: [],
  ticketChatActionState: ActionStateCreator.create(),
  newMessageActionState: ActionStateCreator.create(),
  metadataTicketDetails: null,
  metadataTicketDetailsActionState: ActionStateCreator.create(),
  newTicketMetadata: null,
  createTicketMetadataResponse: null,
  createTicketMetadataActionState: ActionStateCreator.create(),
  directAssignmentContacts: null,
  directAssignmentContactsActionState: ActionStateCreator.create(),
};

export const ticketsReducer = createReducer(
  initialState,

  on(fromActions.LoadDamageTickets, state => {
    return {
      ...state,
      damageTicketsActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.LoadDamageTicketsSuccess, (state, { response }) => {
    return {
      ...state,
      damageTicketsActionState: ActionStateCreator.onSuccess(),
      damageTickets: response.pageInfo?.hasPreviousPage
        ? state.damageTickets.concat(response.edges)
        : response.edges,
      hasNextPageDamage: response.pageInfo?.hasNextPage,
    };
  }),
  on(fromActions.LoadDamageTicketsFailed, (state, { error }) => {
    return {
      ...state,
      damageTicketsActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadRequestTickets, state => {
    return {
      ...state,
      requestTicketsActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.LoadRequestTicketsSuccess, (state, { response }) => {
    return {
      ...state,
      requestTicketsActionState: ActionStateCreator.onSuccess(),
      requestTickets: response.pageInfo?.hasPreviousPage
        ? state.requestTickets.concat(response.edges)
        : response.edges,
      hasNextPageRequest: response.pageInfo?.hasNextPage,
    };
  }),
  on(fromActions.LoadRequestTicketsFailed, (state, { error }) => {
    return {
      ...state,
      requestTicketsActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadDamageCategoryQuestions, state => {
    return {
      ...state,
      damageCategoriesActionState: ActionStateCreator.onStart(),
      directAssignmentContacts: null,
      directAssignmentContactsActionState: ActionStateCreator.create(),
    };
  }),
  on(fromActions.LoadDamageCategoryQuestionsSuccess, (state, { damageCategories }) => {
    return {
      ...state,
      damageCategoriesActionState: ActionStateCreator.onSuccess(),
      damageCategories,
    };
  }),
  on(fromActions.LoadDamageCategoryQuestionsFailed, (state, { error }) => {
    return {
      ...state,
      damageCategoriesActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadConcernCategoryQuestions, state => {
    return {
      ...state,
      concernCategoriesActionState: ActionStateCreator.onStart(),
      directAssignmentContacts: null,
      directAssignmentContactsActionState: ActionStateCreator.create(),
    };
  }),
  on(fromActions.LoadConcernCategoryQuestionsSuccess, (state, { concernCategories }) => {
    return {
      ...state,
      concernCategoriesActionState: ActionStateCreator.onSuccess(),
      concernCategories,
    };
  }),
  on(fromActions.LoadConcernCategoryQuestionsFailed, (state, { error }) => {
    return {
      ...state,
      concernCategoriesActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadDamageQuestions, state => {
    return {
      ...state,
      damageQuestions: null,
      damageQuestionsActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.LoadDamageQuestionsSuccess, (state, { damageQuestions }) => {
    return {
      ...state,
      damageQuestionsActionState: ActionStateCreator.onSuccess(),
      damageQuestions,
    };
  }),
  on(fromActions.LoadDamageQuestionsFailed, (state, { error }) => {
    return {
      ...state,
      damageQuestionsActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadConcernQuestions, state => {
    return {
      ...state,
      concernQuestions: null,
      concernQuestionsActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.LoadConcernQuestionsSuccess, (state, { concernQuestions }) => {
    return {
      ...state,
      concernQuestionsActionState: ActionStateCreator.onSuccess(),
      concernQuestions,
    };
  }),
  on(fromActions.LoadConcernQuestionsFailed, (state, { error }) => {
    return {
      ...state,
      concernQuestionsActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadActiveDamageTickets, state => {
    return {
      ...state,
      activeDamageTicketsActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.LoadActiveDamageTicketsSuccess, (state, { response, sort }) => {
    const hasNextPageActiveDamage = response.some(el => el?.pageInfo?.hasNextPage);
    let activeDamageTickets: ITicketResidentOverviewEdge[];
    activeDamageTickets = response.some(el => el?.pageInfo?.hasPreviousPage)
      ? [...state.activeDamageTickets]
      : [];
    response.forEach(el => {
      el && (activeDamageTickets = activeDamageTickets.concat(el.edges));
    });

    activeDamageTickets = removeDuplicates(activeDamageTickets);
    activeDamageTickets.sort((a: ITicketResidentOverviewEdge, b: ITicketResidentOverviewEdge) =>
      byDateHelper(a, b, sort)
    );
    return {
      ...state,
      activeDamageTicketsActionState: ActionStateCreator.onSuccess(),
      activeDamageTickets: activeDamageTickets,
      hasNextPageActiveDamage: hasNextPageActiveDamage,
    };
  }),
  on(fromActions.LoadActiveDamageTicketsFailed, (state, { error }) => {
    return {
      ...state,
      activeDamageTicketsActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadActiveDamageTicketsForSelectedContract, state => {
    return {
      ...state,
      activeDamageTicketsForSelectedContractActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.LoadActiveDamageTicketsForSelectedContractSuccess, (state, { response, sort }) => {
    const hasNextPageActiveDamage = response.some(el => el?.pageInfo?.hasNextPage);
    let activeDamageTickets: ITicketResidentOverviewEdge[];
    activeDamageTickets = response.some(el => el?.pageInfo?.hasPreviousPage)
      ? [...state.activeDamageTicketsForSelectedContract]
      : [];
    response.forEach(el => {
      el && (activeDamageTickets = activeDamageTickets.concat(el.edges));
    });

    activeDamageTickets = removeDuplicates(activeDamageTickets);
    activeDamageTickets.sort((a: ITicketResidentOverviewEdge, b: ITicketResidentOverviewEdge) =>
      byDateHelper(a, b, sort)
    );
    return {
      ...state,
      activeDamageTicketsForSelectedContractActionState: ActionStateCreator.onSuccess(),
      activeDamageTicketsForSelectedContract: activeDamageTickets,
      hasNextPageActiveDamageForSelectedContract: hasNextPageActiveDamage,
    };
  }),
  on(fromActions.LoadActiveDamageTicketsForSelectedContractFailed, (state, { error }) => {
    return {
      ...state,
      activeDamageTicketsForSelectedContractActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadArchiveDamageTickets, state => {
    return {
      ...state,
      archiveDamageTicketsActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.LoadArchiveDamageTicketsSuccess, (state, { response, sort }) => {
    const hasNextPageArchiveDamage = response.some(el => el?.pageInfo?.hasNextPage);
    let archiveDamageTickets: ITicketResidentOverviewEdge[];
    archiveDamageTickets = response.some(el => el?.pageInfo?.hasPreviousPage)
      ? [...state.archiveDamageTickets]
      : [];
    response.forEach(el => {
      el && (archiveDamageTickets = archiveDamageTickets.concat(el.edges));
    });

    archiveDamageTickets = removeDuplicates(archiveDamageTickets);
    archiveDamageTickets.sort((a: ITicketResidentOverviewEdge, b: ITicketResidentOverviewEdge) =>
      byDateHelper(a, b, sort)
    );
    return {
      ...state,
      archiveDamageTicketsActionState: ActionStateCreator.onSuccess(),
      archiveDamageTickets: archiveDamageTickets,
      hasNextPageArchiveDamage: hasNextPageArchiveDamage,
    };
  }),
  on(fromActions.LoadArchiveDamageTicketsFailed, (state, { error }) => {
    return {
      ...state,
      archiveDamageTicketsActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadArchiveDamageTicketsForSelectedContract, state => {
    return {
      ...state,
      archiveDamageTicketsForSelectedContractActionState: ActionStateCreator.onStart(),
    };
  }),
  on(
    fromActions.LoadArchiveDamageTicketsForSelectedContractSuccess,
    (state, { response, sort }) => {
      const hasNextPageArchiveDamage = response.some(el => el?.pageInfo?.hasNextPage);
      let archiveDamageTickets: ITicketResidentOverviewEdge[];
      archiveDamageTickets = response.some(el => el?.pageInfo?.hasPreviousPage)
        ? [...state.archiveDamageTicketsForSelectedContract]
        : [];
      response.forEach(el => {
        el && (archiveDamageTickets = archiveDamageTickets.concat(el.edges));
      });

      archiveDamageTickets = removeDuplicates(archiveDamageTickets);
      archiveDamageTickets.sort((a: ITicketResidentOverviewEdge, b: ITicketResidentOverviewEdge) =>
        byDateHelper(a, b, sort)
      );
      return {
        ...state,
        archiveDamageTicketsForSelectedContractActionState: ActionStateCreator.onSuccess(),
        archiveDamageTicketsForSelectedContract: archiveDamageTickets,
        hasNextPageArchiveDamageForSelectedContract: hasNextPageArchiveDamage,
      };
    }
  ),
  on(fromActions.LoadArchiveDamageTicketsForSelectedContractFailed, (state, { error }) => {
    return {
      ...state,
      archiveDamageTicketsForSelectedContractActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadActiveRequestTickets, state => {
    return {
      ...state,
      activeRequestTicketsActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.LoadActiveRequestTicketsSuccess, (state, { response, sort }) => {
    const hasNextPageActiveRequest = response.some(el => el?.pageInfo?.hasNextPage);
    let activeRequestTickets: ITicketResidentOverviewEdge[];
    activeRequestTickets = response.some(el => el?.pageInfo?.hasPreviousPage)
      ? [...state.activeRequestTickets]
      : [];
    response.forEach(el => {
      el && (activeRequestTickets = activeRequestTickets.concat(el.edges));
    });

    activeRequestTickets = removeDuplicates(activeRequestTickets);
    activeRequestTickets.sort((a: ITicketResidentOverviewEdge, b: ITicketResidentOverviewEdge) =>
      byDateHelper(a, b, sort)
    );
    return {
      ...state,
      activeRequestTicketsActionState: ActionStateCreator.onSuccess(),
      activeRequestTickets: activeRequestTickets,
      hasNextPageActiveRequest: hasNextPageActiveRequest,
    };
  }),
  on(fromActions.LoadActiveRequestTicketsFailed, (state, { error }) => {
    return {
      ...state,
      activeRequestTicketsActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadActiveRequestTicketsForSelectedContract, state => {
    return {
      ...state,
      activeRequestTicketsForSelectedContractActionState: ActionStateCreator.onStart(),
    };
  }),
  on(
    fromActions.LoadActiveRequestTicketsForSelectedContractSuccess,
    (state, { response, sort }) => {
      const hasNextPageActiveRequest = response.some(el => el?.pageInfo?.hasNextPage);
      let activeRequestTickets: ITicketResidentOverviewEdge[];
      activeRequestTickets = response.some(el => el?.pageInfo?.hasPreviousPage)
        ? [...state.activeRequestTicketsForSelectedContract]
        : [];
      response.forEach(el => {
        el && (activeRequestTickets = activeRequestTickets.concat(el.edges));
      });

      activeRequestTickets = removeDuplicates(activeRequestTickets);
      activeRequestTickets.sort((a: ITicketResidentOverviewEdge, b: ITicketResidentOverviewEdge) =>
        byDateHelper(a, b, sort)
      );
      return {
        ...state,
        activeRequestTicketsForSelectedContractActionState: ActionStateCreator.onSuccess(),
        activeRequestTicketsForSelectedContract: activeRequestTickets,
        hasNextPageActiveRequestForSelectedContract: hasNextPageActiveRequest,
      };
    }
  ),
  on(fromActions.LoadActiveRequestTicketsForSelectedContractFailed, (state, { error }) => {
    return {
      ...state,
      activeRequestTicketsForSelectedContractActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadArchiveRequestTickets, state => {
    return {
      ...state,
      archiveRequestTicketsActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.LoadArchiveRequestTicketsSuccess, (state, { response, sort }) => {
    const hasNextPageArchiveRequest = response.some(el => el?.pageInfo?.hasNextPage);
    let archiveRequestTickets: ITicketResidentOverviewEdge[];
    archiveRequestTickets = response.some(el => el?.pageInfo?.hasPreviousPage)
      ? [...state.archiveRequestTickets]
      : [];
    response.forEach(el => {
      el && (archiveRequestTickets = archiveRequestTickets.concat(el.edges));
    });

    archiveRequestTickets = removeDuplicates(archiveRequestTickets);
    archiveRequestTickets.sort((a: ITicketResidentOverviewEdge, b: ITicketResidentOverviewEdge) =>
      byDateHelper(a, b, sort)
    );
    return {
      ...state,
      archiveRequestTicketsActionState: ActionStateCreator.onSuccess(),
      archiveRequestTickets: archiveRequestTickets,
      hasNextPageArchiveRequest: hasNextPageArchiveRequest,
    };
  }),
  on(fromActions.LoadArchiveRequestTicketsFailed, (state, { error }) => {
    return {
      ...state,
      archiveRequestTicketsActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadArchiveRequestTicketsForSelectedContract, state => {
    return {
      ...state,
      archiveRequestTicketsForSelectedContractActionState: ActionStateCreator.onStart(),
    };
  }),
  on(
    fromActions.LoadArchiveRequestTicketsForSelectedContractSuccess,
    (state, { response, sort }) => {
      const hasNextPageArchiveRequest = response.some(el => el?.pageInfo?.hasNextPage);
      let archiveRequestTickets: ITicketResidentOverviewEdge[];
      archiveRequestTickets = response.some(el => el?.pageInfo?.hasPreviousPage)
        ? [...state.archiveRequestTicketsForSelectedContract]
        : [];
      response.forEach(el => {
        el && (archiveRequestTickets = archiveRequestTickets.concat(el.edges));
      });

      archiveRequestTickets = removeDuplicates(archiveRequestTickets);
      archiveRequestTickets.sort((a: ITicketResidentOverviewEdge, b: ITicketResidentOverviewEdge) =>
        byDateHelper(a, b, sort)
      );
      return {
        ...state,
        archiveRequestTicketsForSelectedContractActionState: ActionStateCreator.onSuccess(),
        archiveRequestTicketsForSelectedContract: archiveRequestTickets,
        hasNextPageArchiveRequestForSelectedContract: hasNextPageArchiveRequest,
      };
    }
  ),
  on(fromActions.LoadArchiveRequestTicketsForSelectedContractFailed, (state, { error }) => {
    return {
      ...state,
      archiveRequestTicketsForSelectedContractActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadTicketDetails, state => {
    return {
      ...state,
      ticketDetails: null,
      ticketDetailsActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.LoadTicketDetailsSuccess, (state, { response }) => {
    let activeDamageTickets = [...state.activeDamageTickets];
    let activeRequestTickets = [...state.activeRequestTickets];
    let archiveDamageTickets = [...state.archiveDamageTickets];
    let archiveRequestTickets = [...state.archiveRequestTickets];
    if (response.issueType === TicketIssueType.PROPERTY_DAMAGE) {
      const updatedObjects = ticketConverter.updateTicketInStore(
        activeDamageTickets,
        archiveDamageTickets,
        response,
        TicketingVersion.HIERARCHICAL
      );
      activeDamageTickets = updatedObjects.activeTickets;
      archiveDamageTickets = updatedObjects.archiveTickets;
    } else if (response.issueType === TicketIssueType.CONCERN) {
      const updatedObjects = ticketConverter.updateTicketInStore(
        activeRequestTickets,
        archiveRequestTickets,
        response,
        TicketingVersion.HIERARCHICAL
      );
      activeRequestTickets = updatedObjects.activeTickets;
      archiveRequestTickets = updatedObjects.archiveTickets;
    }
    return {
      ...state,
      ticketDetailsActionState: ActionStateCreator.onSuccess(),
      ticketDetails: response,
      activeDamageTickets,
      archiveDamageTickets,
      activeRequestTickets,
      archiveRequestTickets,
    };
  }),
  on(fromActions.LoadTicketDetailsFailed, (state, { error }) => {
    return {
      ...state,
      ticketDetailsActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.CreateTicketSummary, (state, { ticketSummary }) => {
    return {
      ...state,
      ticketSummary,
    };
  }),
  on(fromActions.CancelTicketCreation, state => {
    const ticketSummary = {
      name: '',
      rootQuestionId: '',
      categoryId: '',
      detailedQuestionId: '',
      responses: [],
      detailedResponses: [],
      directAssignmentContacts: null,
      categoryAnswerSetRelationId: '',
    };

    return {
      ...state,
      ticketSummary: { ...ticketSummary },
    };
  }),

  on(fromActions.CreateTicketHQ, (state, { newTicketHQ }) => {
    return {
      ...state,
      newTicketHQ,
      createTicketHQActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.CreateTicketHQSuccess, (state, { createTicketResponse }) => {
    return {
      ...state,
      createTicketResponse,
      newTicketHQ: null,
      createTicketHQActionState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.CreateTicketHQFailed, (state, { error }) => {
    return {
      ...state,
      createTicketHQActionState: ActionStateCreator.onError(error),
    };
  }),
  on(fromActions.ClearTicketCreation, state => {
    const ticketSummary = {
      name: '',
      rootQuestionId: '',
      categoryId: '',
      detailedQuestionId: '',
      responses: [],
      detailedResponses: [],
      directAssignmentContacts: null,
      categoryAnswerSetRelationId: '',
    };
    return {
      ...state,
      newTicketHQ: null,
      newTicketMetadata: null,
      createTicketResponse: null,
      ticketSummary: { ...ticketSummary },
    };
  }),
  on(fromActions.CancelTicket, state => {
    return {
      ...state,
      cancelTicketActionState: ActionStateCreator.onStart(),
    };
  }),
  on(
    fromActions.CancelTicketSuccess,
    (state, { cancelTicketResponse, ticketId, ticketIssueType }) => {
      /**
       * removing the cancelled ticket from active tickets, set status manually to CANCELLED and put it in archived tickets
       */
      const activeDamageTickets = [...state.activeDamageTickets];
      const activeRequestTickets = [...state.activeRequestTickets];
      const archiveDamageTickets = [...state.archiveDamageTickets];
      const archiveRequestTickets = [...state.archiveRequestTickets];
      if (ticketIssueType === TicketIssueType.PROPERTY_DAMAGE) {
        const ticketIndex = activeDamageTickets.findIndex(el => el.node.id === ticketId);
        const ticket = { ...activeDamageTickets[ticketIndex] };
        ticket.node = { ...ticket.node, state: TicketStatus.CANCELLED };
        archiveDamageTickets.unshift(ticket);
        activeDamageTickets.splice(ticketIndex, 1);
      } else if (ticketIssueType === TicketIssueType.CONCERN) {
        const ticketIndex = activeRequestTickets.findIndex(el => el.node.id === ticketId);
        const ticket = { ...activeRequestTickets[ticketIndex] };
        ticket.node = { ...ticket.node, state: TicketStatus.CANCELLED };
        archiveRequestTickets.unshift(ticket);
        activeRequestTickets.splice(ticketIndex, 1);
      }

      return {
        ...state,
        cancelTicketResponse,
        cancelTicketActionState: ActionStateCreator.onSuccess(),
        activeDamageTickets,
        archiveDamageTickets,
        activeRequestTickets,
        archiveRequestTickets,
      };
    }
  ),
  on(fromActions.CancelTicketFailed, (state, { error }) => {
    return {
      ...state,
      cancelTicketActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadTicketChat, state => {
    return {
      ...state,
      ticketChatActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.LoadTicketChatSuccess, (state, { ticketMessageGroups }) => {
    return {
      ...state,
      ticketMessageGroups,
      ticketChatActionState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.LoadTicketChatFailed, (state, { error }) => {
    return {
      ...state,
      ticketChatActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.AddMessageToTicket, state => {
    return {
      ...state,
      newMessageActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.AddMessageToTicketSuccess, (state, { ticketMessageGroups }) => {
    return {
      ...state,
      ticketMessageGroups,
      newMessageActionState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.AddMessageToTicketFailed, (state, { error }) => {
    return {
      ...state,
      newMessageActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadMetadataTicketDetails, state => {
    return {
      ...state,
      metadataTicketDetails: null,
      metadataTicketDetailsActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.LoadMetadataTicketDetailsSuccess, (state, { response }) => {
    let activeDamageTickets = [...state.activeDamageTickets];
    let activeRequestTickets = [...state.activeRequestTickets];
    let archiveDamageTickets = [...state.archiveDamageTickets];
    let archiveRequestTickets = [...state.archiveRequestTickets];
    if (response.issueType === TicketIssueType.PROPERTY_DAMAGE) {
      const updatedObjects = ticketConverter.updateTicketInStore(
        activeDamageTickets,
        archiveDamageTickets,
        response,
        TicketingVersion.METADATA
      );
      activeDamageTickets = updatedObjects.activeTickets;
      archiveDamageTickets = updatedObjects.archiveTickets;
    } else if (response.issueType === TicketIssueType.CONCERN) {
      const updatedObjects = ticketConverter.updateTicketInStore(
        activeRequestTickets,
        archiveRequestTickets,
        response,
        TicketingVersion.METADATA
      );
      activeRequestTickets = updatedObjects.activeTickets;
      archiveRequestTickets = updatedObjects.archiveTickets;
    }
    return {
      ...state,
      metadataTicketDetailsActionState: ActionStateCreator.onSuccess(),
      metadataTicketDetails: response,
      activeDamageTickets,
      archiveDamageTickets,
      activeRequestTickets,
      archiveRequestTickets,
    };
  }),
  on(fromActions.LoadMetadataTicketDetailsFailed, (state, { error }) => {
    return {
      ...state,
      metadataTicketDetailsActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.CreateTicketMetadata, (state, { newTicketMetadata }) => {
    return {
      ...state,
      newTicketMetadata,
      createTicketMetadataActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.CreateTicketMetadataSuccess, (state, { createTicketResponse }) => {
    return {
      ...state,
      createTicketResponse,
      newTicketMetadata: null,
      createTicketMetadataActionState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.CreateTicketMetadataFailed, (state, { error }) => {
    return {
      ...state,
      createTicketMetadataActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadDirectAssignmentContacts, state => {
    return {
      ...state,
      directAssignmentContacts: null,
      directAssignmentContactsActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.LoadDirectAssignmentContactsSuccess, (state, { directAssignmentContacts }) => {
    return {
      ...state,
      directAssignmentContacts,
      directAssignmentContactsActionState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.LoadDirectAssignmentContactsError, (state, { error }) => {
    return {
      ...state,
      directAssignmentContactsActionState: ActionStateCreator.onError(error),
    };
  })
);

export const getDamageTickets = (state: ITicketsState) => state.damageTickets;
export const getDamageTicketsActionState = (state: ITicketsState) => state.damageTicketsActionState;
export const hasNextPageDamage = (state: ITicketsState) => state.hasNextPageDamage;

export const getRequestTickets = (state: ITicketsState) => state.requestTickets;
export const getRequestTicketsActionState = (state: ITicketsState) =>
  state.requestTicketsActionState;
export const hasNextPageRequest = (state: ITicketsState) => state.hasNextPageRequest;

export const getDamageCategories = (state: ITicketsState) => state.damageCategories;
export const getDamageCategoriesActionState = (state: ITicketsState) =>
  state.damageCategoriesActionState;

export const getConcernCategories = (state: ITicketsState) => state.concernCategories;
export const getConcernCategoriesActionState = (state: ITicketsState) =>
  state.concernCategoriesActionState;

export const getDamageQuestions = (state: ITicketsState) => state.damageQuestions;
export const getDamageQuestionsActionState = (state: ITicketsState) =>
  state.damageQuestionsActionState;

export const getConcernQuestions = (state: ITicketsState) => state.concernQuestions;
export const getConcernQuestionsActionState = (state: ITicketsState) =>
  state.concernQuestionsActionState;

export const getActiveDamageTickets = (state: ITicketsState) => state.activeDamageTickets;
export const getActiveDamageTicketsActionState = (state: ITicketsState) =>
  state.activeDamageTicketsActionState;
export const hasNextPageActiveDamage = (state: ITicketsState) => state.hasNextPageActiveDamage;

export const getActiveDamageTicketsForSelectedContract = (state: ITicketsState) =>
  state.activeDamageTicketsForSelectedContract;
export const getActiveDamageTicketsForSelectedContractActionState = (state: ITicketsState) =>
  state.activeDamageTicketsForSelectedContractActionState;
export const hasNextPageActiveDamageForSelectedContract = (state: ITicketsState) =>
  state.hasNextPageActiveDamageForSelectedContract;

export const getArchiveDamageTickets = (state: ITicketsState) => state.archiveDamageTickets;
export const getArchiveDamageTicketsActionState = (state: ITicketsState) =>
  state.archiveDamageTicketsActionState;
export const hasNextPageArchiveDamage = (state: ITicketsState) => state.hasNextPageArchiveDamage;

export const getArchiveDamageTicketsForSelectedContract = (state: ITicketsState) =>
  state.archiveDamageTicketsForSelectedContract;
export const getArchiveDamageTicketsForSelectedContractActionState = (state: ITicketsState) =>
  state.archiveDamageTicketsForSelectedContractActionState;
export const hasNextPageArchiveDamageForSelectedContract = (state: ITicketsState) =>
  state.hasNextPageArchiveDamageForSelectedContract;

export const getActiveRequestTickets = (state: ITicketsState) => state.activeRequestTickets;
export const getActiveRequestTicketsActionState = (state: ITicketsState) =>
  state.activeRequestTicketsActionState;
export const hasNextPageActiveRequest = (state: ITicketsState) => state.hasNextPageActiveRequest;
export const getActiveRequestTicketsForSelectedContract = (state: ITicketsState) =>
  state.activeRequestTicketsForSelectedContract;
export const getActiveRequestTicketsForSelectedContractActionState = (state: ITicketsState) =>
  state.activeRequestTicketsForSelectedContractActionState;
export const hasNextPageActiveRequestForSelectedContract = (state: ITicketsState) =>
  state.hasNextPageActiveRequestForSelectedContract;

export const getArchiveRequestTickets = (state: ITicketsState) => state.archiveRequestTickets;
export const getArchiveRequestTicketsActionState = (state: ITicketsState) =>
  state.archiveRequestTicketsActionState;
export const hasNextPageArchiveRequest = (state: ITicketsState) => state.hasNextPageArchiveRequest;

export const getArchiveRequestTicketsForSelectedContract = (state: ITicketsState) =>
  state.archiveRequestTicketsForSelectedContract;
export const getArchiveRequestTicketsForSelectedContractActionState = (state: ITicketsState) =>
  state.archiveRequestTicketsForSelectedContractActionState;
export const hasNextPageArchiveRequestForSelectedContract = (state: ITicketsState) =>
  state.hasNextPageArchiveRequestForSelectedContract;

export const getTicketDetail = (state: ITicketsState) => state.ticketDetails;
export const getTicketDetailActionState = (state: ITicketsState) => state.ticketDetailsActionState;

export const getTicketSummary = (state: ITicketsState) => state.ticketSummary;

export const getNewTicketHQ = (state: ITicketsState) => state.newTicketHQ;
export const getCreateTicketHQResponse = (state: ITicketsState) => state.createTicketHQResponse;
export const getCreateTicketHQActionState = (state: ITicketsState) =>
  state.createTicketHQActionState;

export const getCancelTicketResponse = (state: ITicketsState) => state.cancelTicketResponse;
export const getCancelTicketActionState = (state: ITicketsState) => state.cancelTicketActionState;

export const getTicketChat = (state: ITicketsState) => state.ticketMessageGroups;
export const getTicketChatActionState = (state: ITicketsState) => state.ticketChatActionState;
export const getNewMessageActionState = (state: ITicketsState) => state.newMessageActionState;

export const getMetadataTicketDetail = (state: ITicketsState) => state.metadataTicketDetails;
export const getMetadataTicketDetailActionState = (state: ITicketsState) =>
  state.metadataTicketDetailsActionState;

export const getNewTicketMetadata = (state: ITicketsState) => state.newTicketMetadata;
export const getCreateTicketMetadataResponse = (state: ITicketsState) =>
  state.createTicketMetadataResponse;
export const getCreateTicketMetadataActionState = (state: ITicketsState) =>
  state.createTicketMetadataActionState;

export const getDirectAssignmentContacts = (state: ITicketsState) => state.directAssignmentContacts;
export const getDirectAssignmentContactsActionState = (state: ITicketsState) =>
  state.directAssignmentContactsActionState;

function byDateHelper(
  a: ITicketResidentOverviewEdge,
  b: ITicketResidentOverviewEdge,
  sort?: ISort<TicketSortField>
) {
  const getDate = (edge: ITicketResidentOverviewEdge, field?: TicketSortField) => {
    if (field === TicketSortField.CREATED) {
      return edge.node?.created?.date ? new Date(edge.node.created.date).getTime() : null;
    } else {
      return edge.node?.updated?.date ? new Date(edge.node.updated.date).getTime() : null;
    }
  };

  const dateA = getDate(a, sort?.value);
  const dateB = getDate(b, sort?.value);

  if (dateA !== null && dateB !== null) {
    if (sort?.direction === SortOrder.ASC) {
      return dateA - dateB;
    } else {
      return dateB - dateA;
    }
  }
  return 1;
}

function removeDuplicates(tickets: ITicketResidentOverviewEdge[]): ITicketResidentOverviewEdge[] {
  return tickets.filter(
    (obj, index) => tickets.findIndex(item => item.node.id === obj.node.id) === index
  );
}
