import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ImmomioIconName } from '@resident-nx/shared';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbInputDatepicker,
} from '@ng-bootstrap/ng-bootstrap';
import { IconWebComponent } from '../icon/icon.component';
import { DateParserFormatter, DateStringAdapter } from '../../../services/date';
import { ReactiveFormsModule } from '@angular/forms';
import {
  injectNgControl,
  NoopValueAccessorDirective,
} from '../../../directives/form/noop-value-accessor';

@Component({
  selector: 'rs-web-date-picker',
  standalone: true,
  templateUrl: './date-picker.component.html',
  styleUrl: './date-picker.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'form-control',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [NoopValueAccessorDirective],
  imports: [NgbInputDatepicker, IconWebComponent, ReactiveFormsModule],
  providers: [
    {
      provide: NgbDateAdapter,
      useClass: DateStringAdapter,
    },
    {
      provide: NgbDateParserFormatter,
      useClass: DateParserFormatter,
    },
  ],
})
export class DatePickerComponent {
  public ngControl = injectNgControl();
  protected readonly ImmomioIconName = ImmomioIconName;
}
