import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { getAppSettings, getAppSettingsActionState, LoadAppSettings } from '../../+state';
import { IAppSettings } from '../../models';
import { ResolverCommonService } from './resolver-common.service';

// TODO: be careful to use it inside nativescript apps it seems something is not 100% correct
export const AppSettingsResolver: ResolveFn<IAppSettings> = route => {
  const common = inject(ResolverCommonService);

  const { resolverWaitForData } = route.data;
  return common.resolveHandler<IAppSettings>(
    getAppSettingsActionState,
    LoadAppSettings(),
    getAppSettings,
    resolverWaitForData
  );
};
