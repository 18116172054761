import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import { DefaultOptions, from, InMemoryCache, Observable } from '@apollo/client/core';
import { IEnvironment, IInfrastructureConfig } from '../../models';
import { onError } from '@apollo/client/link/error';

export interface IHeaders {
  'X-Customer-Ident'?: string | undefined;
  'X-Web-Portal-Domain'?: string | undefined;
  Accept: string | undefined;
  Host?: string | undefined;
  Authorization?: string | undefined;
}

export function createDefaultApolloConnection(
  httpLink: HttpLink,
  config: IInfrastructureConfig,
  environment: IEnvironment
) {
  const auth = setContext((_, { headers }) => {
    const allHeaders: IHeaders = {
      ...headers,
      Accept: 'application/json',
    };
    return {
      headers: allHeaders,
    };
  });

  const errorLink = onError(error => {
    const { graphQLErrors, networkError, forward, operation } = error;

    if (graphQLErrors) {
      // in case of blocked user continue the operation, it will be handled in the HttpInterceptor
      if (graphQLErrors.some(e => e.message.includes('blocked'))) {
        return forward(operation);
      }

      return new Observable(observer => {
        observer.error(new Error(graphQLErrors.map(({ message }) => message).join(', ')));
        observer.complete();
      });
    }

    if (networkError) {
      return new Observable(observer => {
        observer.error(networkError.message);
        observer.complete();
      });
    }

    return forward(operation);
  });

  const link = from([
    auth,
    errorLink,
    httpLink.create({ uri: config.environment.graphqlServerUri }),
  ]);

  const cache = new InMemoryCache({
    addTypename: true,
  });

  const fetchPolicy = environment.apolloFetchPolicy || 'no-cache';
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy,
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy,
      errorPolicy: 'all',
    },
  };

  return {
    link,
    cache,
    defaultOptions,
  };
}
