import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  signal,
  ViewEncapsulation,
} from '@angular/core';

import {
  ConsumptionTimeframe,
  ConsumptionType,
  getSelectedPeriodConsumptionForMeter,
  getSelectedPeriodPastConsumptionForMeter,
  IConsumptionInfoItem,
  IConsumptionItem,
  IConsumptionState,
  NameDisplayMode,
  NgChanges,
  PeriodType,
  SelectConsumption,
} from '@resident-nx/shared';
import { ConsumptionChartOptions } from './types/consumption-chart-options';
import { ConsumptionChartService } from './consumption-chart.service';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { ConsumptionChartYearWebComponent } from '../../atoms/consumption-chart-year/consumption-chart-year.component';
import { ConsumptionChartQuarterWebComponent } from '../../atoms/consumption-chart-quarter/consumption-chart-quarter.component';
import { ConsumptionChartMonthWebComponent } from '../../atoms/consumption-chart-month/consumption-chart-month.component';
import { ConsumptionChartAdditionalInfoWebComponent } from './consumption-chart-additional-info/consumption-chart-additional-info.component';
import { CardWebComponent } from '../../atoms/card/card.component';

@Component({
  selector: 'rs-web-consumption-chart',
  templateUrl: './consumption-chart.component.html',
  styleUrls: ['./consumption-chart.component.scss'],
  providers: [ConsumptionChartService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CardWebComponent,
    ConsumptionChartAdditionalInfoWebComponent,
    ConsumptionChartMonthWebComponent,
    ConsumptionChartQuarterWebComponent,
    ConsumptionChartYearWebComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class ConsumptionChartWebComponent implements OnInit, OnChanges {
  public readonly ConsumptionType = ConsumptionType;
  public readonly ConsumptionTimeframe = ConsumptionTimeframe;
  public readonly NameDisplayMode = NameDisplayMode;

  private destroyRef = inject(DestroyRef);

  @Input() meter: IConsumptionInfoItem;
  @Input() timeframe: ConsumptionTimeframe = ConsumptionTimeframe.MONTH;
  @Input() height = 'auto';
  @Input() showAdditionalInfos = false; // show consumption type title, icon and selected value

  public consumption$: Observable<IConsumptionItem[]>;
  public pastConsumption$: Observable<IConsumptionItem[]>;
  public showZnNumber: boolean;

  public isDataPresent = signal(true);

  public options: ConsumptionChartOptions;

  constructor(
    private chartService: ConsumptionChartService,
    private store: Store<IConsumptionState>
  ) {}

  ngOnInit() {
    combineLatest([this.consumption$, this.pastConsumption$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([consumption, pastConsumption]) => {
        if (consumption?.length > 0) {
          const lastIndex = consumption.length - 1;
          this.selectConsumptionByDataPointIndex(lastIndex);
        }

        this.isDataPresent.set(consumption?.length > 0 || pastConsumption?.length > 0);
      });

    /***
     todo: refactor on consumption rebuild -> only if uvi api cause the ZN doesn't make any sense to the user it's only
     a database reference
     */
    this.showZnNumber = this.meter.deviceId.split('+').length === 1;
  }

  ngOnChanges(changes: NgChanges<ConsumptionChartWebComponent>): void {
    if (changes.meter || changes.height) {
      this.options = this.chartService.createOptions({
        height: this.height,
        consumptionUnit: this.meter.measurementUnit,
        onDataPointSelection: index => this.selectConsumptionByDataPointIndex(index),
      });
    }

    if (changes.meter) {
      this.consumption$ = this.store.select(
        getSelectedPeriodConsumptionForMeter(this.meter.deviceId)
      );
      this.pastConsumption$ = this.store.select(
        getSelectedPeriodPastConsumptionForMeter(this.meter.deviceId)
      );
    }
  }

  selectConsumptionByItem(item: IConsumptionItem): void {
    const deviceId = this.meter.deviceId;
    const { period: selectedPeriod } = item;
    const periodType =
      this.timeframe === ConsumptionTimeframe.MONTH ? PeriodType.DAY : PeriodType.MONTH;

    this.store.dispatch(SelectConsumption({ deviceId, selectedPeriod, periodType }));
  }

  private selectConsumptionByDataPointIndex(dataPointIndex: number): void {
    this.consumption$.pipe(take(1)).subscribe(consumption => {
      const selectedEntry = consumption ? consumption[dataPointIndex] : null;
      if (!selectedEntry) {
        return;
      }
      const deviceId = this.meter.deviceId;
      const { period: selectedPeriod } = selectedEntry;
      const periodType =
        this.timeframe === ConsumptionTimeframe.MONTH ? PeriodType.DAY : PeriodType.MONTH;

      this.store.dispatch(SelectConsumption({ deviceId, selectedPeriod, periodType }));
    });
  }
}
