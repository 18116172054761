import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';

export interface ModalOptions extends NgbModalOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: { [key: string]: any };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class ModalRef<_T, R = any> extends NgbModalRef {
  onClose: () => Observable<R>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDismiss: () => Observable<any>;
}

@Injectable()
export class ModalService {
  // @Optional() used to fix test cases only.
  constructor(private ngbModal: NgbModal) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  open<T, R = any>(content: T | any, options: ModalOptions = {}) {
    const onCloseSubject = new Subject<R>();
    const onDismissSubject = new Subject();

    const modalRef = this.ngbModal.open(content, options) as ModalRef<T>;

    if (modalRef && modalRef.componentInstance && options.data) {
      Object.assign(modalRef.componentInstance, options.data);
    }

    modalRef.onClose = () => onCloseSubject.asObservable().pipe(take(1));
    modalRef.onDismiss = () => onDismissSubject.asObservable().pipe(take(1));

    modalRef.result
      .then((result: R) => {
        onCloseSubject.next(result);
        onCloseSubject.complete();
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((reason: any) => {
        onDismissSubject.next(reason);
        onDismissSubject.complete();
      });

    return modalRef;
  }

  /***
   * Fix for Angular Bug related to creating dynamic views in lifecycle hooks.
   * Doing that causes ExpressionHasBeenChangedAfterItWasChecked errors.
   * Using setTimeout is simple and acceptable workaround for this.
   * See https://github.com/angular/angular/issues/15634 for more details.
   * Use it only in lifecycle hooks (ngOnInit, ngOnChanges and so on)
   * For every normal case, use normal .open() method
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  promisifyOpen<T, R = any>(content: T | any, options: ModalOptions = {}): Promise<ModalRef<T, R>> {
    return new Promise(resolve => setTimeout(() => resolve(this.open(content, options))));
  }

  openConfirmation(options: ModalOptions = {}) {
    return this.open<ConfirmationDialogComponent>(ConfirmationDialogComponent, options);
  }
}
