import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromMasterDataState from '../../+state/account/masterdata';
import { IMasterDataUser, ImmomioIconName } from '../../models';
import { getAppSettings } from '../../+state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Directive()
@UntilDestroy()
export abstract class HeaderBaseComponent implements OnInit {
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() generalTab?: boolean;
  @Input() altLeftButton = false;
  @Input() profileButton: boolean;
  @Input() searchButton: boolean;
  @Input() customButton: ImmomioIconName | null;
  @Input() centerHeader = false;
  @Input() hasBackButton = true;
  @Input() emergency = false;
  @Output() tappedCustom: EventEmitter<boolean> = new EventEmitter();
  @Output() tappedProfile: EventEmitter<boolean> = new EventEmitter();
  @Output() tappedLeft: EventEmitter<boolean> = new EventEmitter();
  public user$: Observable<IMasterDataUser>;
  public immomioIconName = ImmomioIconName;
  public logoUrl: string;
  public tabUrl: string;

  constructor(
    private masterDataStore: Store<fromMasterDataState.IMasterDataState>,
    private store: Store
  ) {
    this.store
      .select(getAppSettings)
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        if (response) {
          this.logoUrl = response.logo?.url;
        }
      });
  }

  ngOnInit() {
    this.user$ = this.masterDataStore.select(fromMasterDataState.getUser);
    if (this.tabUrl === 'dashboard' && this.logoUrl && !this.emergency) {
      this.title = '';
    }
  }

  openSearch() {
    return null;
  }
}
