import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, withLatestFrom } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromActions from './offers.actions';
import { offerConverter } from './offer-converter';
import { ErrorHandlerService, OffersFacade } from '../../../services';
import { IPublishedOffer, IPublishedOffersResponse } from '../../../models';
import { Store } from '@ngrx/store';
import { getOffers, getOffersActionState, getOffersPageInfo } from './offers.selectors';
import { differenceInMinutes } from 'date-fns';

@Injectable()
export class OffersEffects {
  loadOffers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadOffers),
      withLatestFrom(
        this.store.select(getOffers),
        this.store.select(getOffersActionState),
        this.store.select(getOffersPageInfo)
      ),
      switchMap(([action, data, actionState, pageInfo]) => {
        const noData = !data || data?.length === 0;
        const doneTimeIsOld =
          actionState?.doneTime && differenceInMinutes(new Date(), actionState.doneTime) > 30;
        if (noData || doneTimeIsOld || action.forced) {
          return this.offersFacade.getOffers(action.offset, action.limit).pipe(
            map((response: IPublishedOffersResponse) =>
              fromActions.LoadOffersSuccess({
                response,
              })
            ),
            catchError((error: Error) => {
              this.errorHandlerService.handleError(
                '(REQUEST ERROR) on offers.effects.ts:loadOffers$:',
                error
              );
              return [fromActions.LoadOffersFailed({ error })];
            })
          );
        } else {
          return of(
            fromActions.LoadOffersSuccess({
              response: {
                edges: data,
                pageInfo: pageInfo,
              },
              oldState: true,
            })
          );
        }
      })
    )
  );

  loadOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadOffer),
      switchMap(action =>
        this.offersFacade.getOffer(action.offerId).pipe(
          map(offerConverter.fromDto),
          map((response: IPublishedOffer) => fromActions.LoadOfferSuccess({ response })),
          catchError((error: Error) => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on offers.effects.ts:loadOffer$:',
              error
            );
            return [fromActions.LoadOfferFailed({ error })];
          })
        )
      )
    )
  );
  loadPublishedOffersPublic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadPublishedOffersPublic),
      switchMap(action =>
        this.offersFacade.getPublishedOffersPublic(action.offset, action.limit).pipe(
          map((response: IPublishedOffersResponse) =>
            fromActions.LoadPublishedOffersPublicSuccess({
              response,
            })
          ),
          catchError((error: Error) => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on offers.effects.ts:loadPublishedOffersPublic$:',
              error
            );
            return [fromActions.LoadPublishedOffersPublicFailed({ error })];
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private offersFacade: OffersFacade,
    private store: Store,
    private errorHandlerService: ErrorHandlerService
  ) {}
}
