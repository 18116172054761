import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { INotificationEmailSettings } from '../../models';
import { emailSettingsMutation, emailSettingsQuery } from '../../queries';

@Injectable()
export class EmailSettingsFacade {
  constructor(private apollo: Apollo) {}

  public getEmailSettings(): Observable<INotificationEmailSettings> {
    return this.apollo
      .query<{ getEmailNotificationSettings: INotificationEmailSettings }>({
        query: emailSettingsQuery,
        errorPolicy: 'all',
      })
      .pipe(map(response => response?.data?.getEmailNotificationSettings));
  }

  public updateEmailSettings(input: INotificationEmailSettings) {
    return this.apollo
      .mutate<{ updateEmailNotificationSettings: INotificationEmailSettings }>({
        mutation: emailSettingsMutation,
        variables: {
          input,
        },
      })
      .pipe(map(response => response.data?.updateEmailNotificationSettings));
  }
}
