import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { ThemeCssObj, ThemeService } from '@resident-nx/shared';

@Injectable({
  providedIn: 'root',
})
export class ThemeWebService extends ThemeService {
  constructor(
    protected override store: Store,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(store);
  }

  protected applyTheme(themeObj: ThemeCssObj): void {
    Object.keys(themeObj).map(key => this.document.body.style.setProperty(`${key}`, themeObj[key]));
  }
}
