import { Component } from '@angular/core';
import { ConsumptionWidgetBaseComponent, ImmomioIconName, LoadingType } from '@resident-nx/shared';
import {
  ContractMenuDesign,
  ContractMenuWebComponent,
} from '../contract-menu/contract-menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { ConsumptionDashboardCardsWebComponent } from '../consumption-dashboard-cards/consumption-dashboard-cards.component';
import { LoadingSkeletonWebComponent } from '../../atoms/loading-skeleton/loading-skeleton.component';

@Component({
  selector: 'rs-web-consumption-widget',
  templateUrl: './consumption-widget.component.html',
  styleUrls: ['./consumption-widget.component.scss'],
  standalone: true,
  imports: [
    LoadingSkeletonWebComponent,
    ContractMenuWebComponent,
    ConsumptionDashboardCardsWebComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class ConsumptionWidgetWebComponent extends ConsumptionWidgetBaseComponent {
  public contractMenuDesign = ContractMenuDesign;
  protected readonly ImmomioIconName = ImmomioIconName;
  protected readonly LoadingType = LoadingType;
}
