<div class="list-navigation__container">
  <div class="list-navigation d-flex">
    @for (nav of navConfig; track nav.label; let i = $index) {
      <div class="nav-item" [routerLinkActive]="'active'" (click)="onNavSelect(nav)">
        <div [routerLink]="nav.url" class="nav-item__name">
          {{ nav.label | translate }}
        </div>
      </div>
    }
  </div>
</div>
