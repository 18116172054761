@if ((newsActionState$ | async).pending) {
  <rs-web-loading-skeleton
    [show]="true"
    [types]="[LoadingType.HEADER, LoadingType.MULTIPLECARD]"
  ></rs-web-loading-skeleton>
}
<div class="news-widget">
  <rs-web-cards-container-header
    (tapped)="onTapOverview()"
    [buttonText]="'general.overview_a'"
    [buttonTypeEnum]="buttonTypeEnum.LIGHT_BORDERED"
    [icon]="icon"
    [title]="title"
  ></rs-web-cards-container-header>
  <div class="news-widget__overflow">
    <div class="news-widget__content">
      @for (general of news$ | async; track general.node.id; let i = $index) {
        @if (newsLimit - 1 > i) {
          <rs-web-content-card
            (tapped)="onTap($event)"
            [date]="general.node.publishedAt"
            [id]="general.node.id"
            [image]="general.node.imageUrl"
            [preset]="contentCardPreset.NEWS"
            [text]="general.node.text"
            [title]="general.node.title"
            class="news-widget__content-card"
          ></rs-web-content-card>
        } @else {
          @if (newsLimit - 1 === i) {
            <rs-web-card
              (click)="onTapOverview()"
              [elevation]="3"
              [hover]="true"
              class="more-news-card"
            >
              <div class="news-widget__extra-card">{{ buttonText | translate }}</div>
            </rs-web-card>
          }
        }
      }
    </div>
  </div>
</div>
