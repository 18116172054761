import { Component } from '@angular/core';
import { CardContainerContentBaseComponent } from '@resident-nx/shared';

@Component({
  selector: 'rs-web-cards-container-content',
  templateUrl: './cards-container-content.component.html',
  styleUrls: ['./cards-container-content.component.scss'],
  standalone: true,
})
export class CardsContainerContentWebComponent extends CardContainerContentBaseComponent {}
