import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IEnvironment } from '../../models';
import { deepEqual } from '../../utils';
import { HttpLoaderService } from './http-loader.service';
import { AuthService } from '../../infrastructure';
import { LOGOUT } from '../../+state';
import { Store } from '@ngrx/store';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private httpLoaderService: HttpLoaderService,
    @Optional() @Inject('ENVIRONMENT') private environment: IEnvironment,
    private authService: AuthService,
    private store: Store
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.onStart();

    return next.handle(req).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map((event: HttpEvent<any>) => {
        // check whether we want to mock data for this response
        if (
          this.environment &&
          this.environment.production == false &&
          this.environment.gqlMockData &&
          event instanceof HttpResponse
        ) {
          const operationName = (req.body && (req.body['operationName'] as string)) ?? false;
          if (operationName && this.environment.gqlMockData[operationName]) {
            const mockData = this.environment.gqlMockData[operationName];
            const requestVariables = (req.body['variables'] as object) ?? {};
            let responseData = (event.body?.data ?? {}) as object;
            let mockResponseData = {};
            if (Array.isArray(mockData)) {
              mockResponseData =
                mockData.find(el => deepEqual(el.requestVariables, requestVariables))?.response ??
                {};
            } else {
              mockResponseData = mockData.response;
            }
            if (!Array.isArray(mockData) && mockData.force == true) {
              responseData = mockResponseData;
            } else {
              for (const key in mockResponseData) {
                if (!responseData[key]) {
                  responseData[key] = mockResponseData[key];
                }
              }
            }
            if (Object.keys(responseData).length > 0) {
              // return mocked data, if existent
              return event.clone({
                body: { data: responseData },
                status: 200,
              });
            }
          }
        }

        return event;
      }),
      tap({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.onEnd();
            if (req.url.indexOf('/graphql') > -1) {
              if (event.body?.errors) {
                const match: [] = event.body.errors.filter(e => {
                  return e.message.includes('blocked');
                });
                if (match && match.length > 0) {
                  void this.authService.logout('appProvider', 'unauthorized');
                  this.store.dispatch(LOGOUT());
                }
              }
            }
          }
        },
        error: (err: Error) => {
          console.error(err);
          this.onEnd();
        },
      })
    );
  }

  private onStart() {
    this.httpLoaderService.onRequestStart();
  }

  private onEnd(): void {
    this.httpLoaderService.onRequestEnd();
  }
}
