import { AccountUserFacade } from './user/user.facade';
import { AccountRegistrationFacade } from './registration/registration.facade';
import { AccountMasterDataFacade } from './masterdata/masterdata.facade';
import { PermissionsFacade } from './permissions/permissions.facade';
import { UserAppsFacade } from './user-apps';
import { LoginFacade } from './login';
import { EmergencyContactsFacade } from './emergency-contacts';

export * from './registration';
export * from './user';
export * from './masterdata';
export * from './permissions';
export * from './user-apps';
export * from './login';
export * from './emergency-contacts';

export const ACCOUNT_FACADES = [
  AccountUserFacade,
  AccountRegistrationFacade,
  AccountMasterDataFacade,
  PermissionsFacade,
  UserAppsFacade,
  LoginFacade,
  EmergencyContactsFacade,
];
