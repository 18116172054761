import { DocumentFileType, FileType, ICustomerSpecificDataResponse, ResidentFileType } from './';

export interface IResidentS3File {
  title: string;
  name: string;
  type: FileType;
  documentFileType: DocumentFileType;
  documentType: DocumentType;
  identifier: string;
  extension: string;
  encrypted: boolean;
  url: string;
  residentFileType: ResidentFileType;
  location: string;
  atlasMeta: ICustomerSpecificDataResponse[];
}
