@if ((combinedTickets$ | async).length === 0) {
  <div class="m-t-16">
    <rs-web-loading-skeleton
      [show]="true"
      [types]="[LoadingType.HEADER, LoadingType.MULTIPLECARD]"
    ></rs-web-loading-skeleton>
  </div>
}

<div class="tickets-widget">
  <rs-web-cards-container-header
    (tapped)="overviewTapped.emit()"
    [buttonText]="'general.overview_a'"
    [buttonTypeEnum]="ButtonEnumTypes.LIGHT_BORDERED"
    [icon]="icon"
    [title]="title"
  ></rs-web-cards-container-header>
  <div class="tickets-widget__overflow">
    <div class="tickets-widget__content">
      @for (ticket of combinedTickets$ | async; track ticket.node.id; let i = $index) {
        @if (ticketsLimit > i) {
          <rs-web-ticket-card
            [ticket]="ticket.node"
            (click)="ticketNodeTabbed(ticket.node)"
          ></rs-web-ticket-card>
        } @else if (ticketsLimit === i) {
          <rs-web-card [hover]="true" [elevation]="3" (click)="overviewTapped.emit()">
            <div class="tickets-widget__extra-card">+ {{ buttonText | translate }}</div>
          </rs-web-card>
        }
      }
      @if ((combinedTickets$ | async).length < 1) {
        <rs-web-no-data [text]="'active_request.no_data'"></rs-web-no-data>
      }
    </div>
  </div>
  @if ((requestTicketsState$ | async).pending || (damageTicketsState$ | async).pending) {
    <div class="d-flex justify-content-center m-5">
      <rs-web-loader></rs-web-loader>
    </div>
  }
</div>
