import { IResidentS3File, ISharedDocumentsResponse } from '../../models';
import { BehaviorSubject } from 'rxjs';
import { IActionState } from '../../utils';

export abstract class FileDownloadService {
  abstract documentDownloadActionState$: BehaviorSubject<IActionState>;
  abstract downloadDocument(document: ISharedDocumentsResponse);
  abstract downloadFile(residentS3File: IResidentS3File);
  abstract downloadTicketAttachment(ticketId: string, identifier: string);
}
