import { createSelector } from '@ngrx/store';
import { getRegistrationState } from '../../reducers';
import * as fromReducer from './registration.reducer';

export const getRegisterResidentActionState = createSelector(
  getRegistrationState,
  fromReducer.getRegisterResidentActionState
);

export const getRegisterResidentResponse = createSelector(
  getRegistrationState,
  fromReducer.getRegisterResidentResponse
);

export const getTriggerRegisterConfirmEmailActionState = createSelector(
  getRegistrationState,
  fromReducer.getTriggerRegisterConfirmEmailActionState
);

export const getVerifyRegistrationEmailActionState = createSelector(
  getRegistrationState,
  fromReducer.getVerifyRegistrationEmailActionState
);

export const getResetPasswordActionState = createSelector(
  getRegistrationState,
  fromReducer.getResetPasswordActionState
);

export const getInitResetPasswordActionState = createSelector(
  getRegistrationState,
  fromReducer.getInitResetPasswordActionState
);

export const getVerifyContractAndPartnerNumberActionState = createSelector(
  getRegistrationState,
  fromReducer.getVerifyContractAndPartnerNumberActionState
);

export const getVerifyContractAndPartnerNumberResponse = createSelector(
  getRegistrationState,
  fromReducer.getVerifyContractAndPartnerNumberResponse
);

export const getInvitationCodeResponse = createSelector(
  getRegistrationState,
  fromReducer.getInvitationCodeResponse
);

export const getInvitationCodeState = createSelector(
  getRegistrationState,
  fromReducer.getInvitationCodeState
);

export const getCheckEmailExistsResponse = createSelector(
  getRegistrationState,
  fromReducer.getCheckEmailExistsResponse
);

export const getCheckEmailExistsState = createSelector(
  getRegistrationState,
  fromReducer.getCheckEmailExistsState
);

export const getRequestInvitationCodeResponse = createSelector(
  getRegistrationState,
  fromReducer.getRequestInvitationCodeResponse
);

export const getRequestInvitationCodeState = createSelector(
  getRegistrationState,
  fromReducer.getRequestInvitationCodeState
);

export const getRegistrationForm = createSelector(
  getRegistrationState,
  fromReducer.getRegistrationForm
);
