<button
  [class]="
    'button button--type-' +
    type +
    ' button--size-' +
    size +
    ' button--border-radius-' +
    borderRadius
  "
  [ngClass]="{
    'h-100': useFullContainerSize,
    'p-0': zeroPadding,
    'button--disable-hover': disableHover,
    'button--ghost': isGhost(),
    'button--space-between-icons': spaceBetweenIcons,
    'button--use-default-cursor': useDefaultCursor,
  }"
  [type]="buttonType"
  rsWebElevation
  [elevation]="getElevation()"
  [clickable]="!isDisabled() && !isGhost() && elevationHoverEffect"
  [disabled]="isDisabled()"
  (click)="click($event)"
>
  <span [class]="baseClass + '__content'">
    @if (iconLeft && !loading) {
      <!-- TODO: replace all icon elements with rs-web-icon -->
      <i [class]="'icon-' + iconLeft"></i>
    }
    <!-- TODO: render `inserted-content` only when no content is given (+ remove SCSS interim fix) -->
    <div [class]="baseClass + '__inserted-content'">
      <ng-content></ng-content>
    </div>
    @if (iconRight && !loading) {
      <i [class]="'icon-' + iconRight"></i>
    }
    @if (loading) {
      <rs-web-loader></rs-web-loader>
    }
  </span>
</button>
