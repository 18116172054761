import { Injectable } from '@angular/core';

@Injectable()
export abstract class StorageService {
  public abstract getItem(key: string): object | string | boolean | null;

  public abstract setItem(key: string, value: object | string | boolean): boolean;

  public abstract removeItem(key: string): boolean;

  public abstract clear(): boolean;
}
