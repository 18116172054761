import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ImmomioIconName } from '../../models';

@Directive()
export class EmergencyContactBannerBaseComponent {
  @Input() isDashboardBanner = true;
  @Input() emergencyHelpDescriptionText: string;
  @Output() tapped = new EventEmitter();
  public immomioIconName = ImmomioIconName;
}
