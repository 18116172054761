<div class="container">
  <div class="row-container">
    <div class="col-4">
      <rs-web-picture [src]="item.imageUrl" className="news-image"> </rs-web-picture>
    </div>
    <div class="col-8" style="margin-left: 10px">
      <div class="row">
        <div class="col-12" style="margin-top: -15px">
          <rs-web-label [text]="item.title" className="h4 text-primary news__title"></rs-web-label>
        </div>
      </div>
      <div class="row padding-20">
        <div class="col-2">
          <rs-web-icon [icon]="immomioIconName.Calendar" [size]="16"></rs-web-icon>
        </div>
        <div class="col-10" style="margin-left: -20px">
          @if (item.publishedAt) {
            <rs-web-label
              [text]="item.publishedAt + '000' | date: 'EEEEEE dd.MM.YYYY'"
              className="body text-secondary"
            ></rs-web-label>
          }
        </div>
      </div>
    </div>
  </div>
</div>
