import { Inject, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';

import {
  AuthTokenService,
  IEnvironment,
  IResidentS3File,
  RequestService,
  ServiceFacade,
} from '@resident-nx/shared';
import { FileUploadService } from '../file/file-upload.service';

@Injectable()
export class ServiceWebFacade extends ServiceFacade {
  constructor(
    apollo: Apollo,
    authTokenService: AuthTokenService,
    request: RequestService,
    private fileUploadService: FileUploadService,
    @Inject('ENVIRONMENT') environment: IEnvironment
  ) {
    super(apollo, authTokenService, request, environment);
  }

  public override uploadFilesWeb(files: File[]): Observable<IResidentS3File[]> {
    return this.fileUploadService.uploadTicketAttachments(files);
  }
}
