import { Directive, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { IConsumptionState } from '../../+state';
import {
  ByDeviceId,
  ConsumptionAggregateType,
  ConsumptionTimeframe,
  DeviceId,
  IConsumptionInfoItem,
} from '../../models';
import * as fromConsumptionState from '../../+state/consumption';

@Directive()
export abstract class ConsumptionTimeframeBaseComponent implements OnInit {
  public selectedTimeframe$: Observable<ByDeviceId<ConsumptionTimeframe>>;
  public selectedMeter$: Observable<IConsumptionInfoItem>;
  public consumptionTimeframe = ConsumptionTimeframe;
  public consumptionAggregateType = ConsumptionAggregateType;

  constructor(private store: Store<IConsumptionState>) {}

  ngOnInit() {
    this.selectedMeter$ = this.store.select(fromConsumptionState.getFirstSelectedMeter);
    this.selectedTimeframe$ = this.store.select(fromConsumptionState.getSelectedTimeframe);
  }

  public selectTimeframe(timeframe: ConsumptionTimeframe, deviceId: DeviceId): void {
    this.store.dispatch(fromConsumptionState.SetSelectedTimeframe({ timeframe, deviceId }));
  }
}
