import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import {
  getArchiveRequestTickets,
  getArchiveRequestTicketsActionState,
  LoadArchiveRequestTickets,
} from '../../+state';
import { ITicketResidentOverviewEdge } from '../../models';
import { ResolverCommonService } from './resolver-common.service';

export const RequestTicketsArchiveResolver: ResolveFn<ITicketResidentOverviewEdge[]> = route => {
  const common = inject(ResolverCommonService);

  const { resolverWaitForData } = route.data;
  return common.resolveHandler<ITicketResidentOverviewEdge[]>(
    getArchiveRequestTicketsActionState,
    LoadArchiveRequestTickets({
      offset: 0,
      limit: 20,
    }),
    getArchiveRequestTickets,
    resolverWaitForData
  );
};
