import { createSelector } from '@ngrx/store';
import { getSupportState } from '../reducers';
import * as fromReducer from './support.reducer';

export const getFaqs = createSelector(getSupportState, fromReducer.getFaqs);
export const getFaqsActionState = createSelector(getSupportState, fromReducer.getFaqsActionState);
export const getOverviewFaqs = createSelector(getSupportState, fromReducer.getOverviewFaqs);
export const getOverviewFaqsActionState = createSelector(
  getSupportState,
  fromReducer.getOverviewFaqsActionState
);
