export const residentS3FileTypeFields = `
  title
  name
  type
  documentFileType
  documentType
  identifier
  extension
  encrypted
  url
  residentFileType
  location
  atlasMeta {
    key
    value
  }
`;
