<div class="document-preview-modal">
  <div class="d-flex flex-column p-3 h-100">
    <div class="d-flex justify-content-between">
      <h6 class="align-self-center mb-0 text-elipsis">{{ documentName }}</h6>
      <div class="d-flex">
        <rs-web-button
          class="m-r-5"
          [ghost]="true"
          [size]="buttonSize['SMALL']"
          [borderRadius]="buttonBorderRadius['BIG']"
          [iconLeft]="'download'"
          [type]="buttonType['EMPTY_LIGHT']"
          (click)="onDownload()"
        ></rs-web-button>
        <rs-web-button
          [ghost]="true"
          [size]="buttonSize['SMALL']"
          [borderRadius]="buttonBorderRadius['BIG']"
          [iconLeft]="'close'"
          [type]="buttonType['EMPTY_LIGHT']"
          (click)="ngbModal.close()"
        ></rs-web-button>
      </div>
    </div>
    @if (!previewLoading) {
      <div class="mt-3 d-flex justify-content-center h-100">
        @if (
          documentType === 'jpg' ||
          documentType === 'jpeg' ||
          documentType === 'png' ||
          documentType === 'img'
        ) {
          <rs-web-image
            [defaultSrc]="previewUrl || ''"
            alt=""
            [maxHeightInPx]="500"
            [borderRadius]="imageBorderRadius['SMALL']"
          ></rs-web-image>
        }
        @if (documentType === 'pdf') {
          <iframe class="h-100 w-100" [src]="previewUrl"></iframe>
        }
      </div>
    } @else {
      <rs-web-loader [centered]="true" [biggerSpinner]="true"></rs-web-loader>
    }
  </div>
</div>
