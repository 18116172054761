import { Directive, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IActionState } from '../../utils';
import * as fromRegistrationStore from '../../+state/account/registration';
import { ICheckEmailExists, ICheckInvitationCode, IUserExistsByEmail } from '../../models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouterService } from '../../services';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Directive()
export abstract class EmailFormBaseComponent implements OnInit {
  public user$: Observable<ICheckInvitationCode>;
  public checkEmailExists$: Observable<ICheckEmailExists>;
  public checkEmailExistsState$: Observable<IActionState>;
  private invitationCode: string;
  public errorMessage: string;
  constructor(
    private registerStore: Store<fromRegistrationStore.RegistrationState>,
    private fb: FormBuilder,
    private routerService: RouterService,
    private activeRoute: ActivatedRoute,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.errorMessage = this.translate.instant('registration_email_valid_l');
    this.user$ = this.registerStore.select(fromRegistrationStore.getInvitationCodeResponse);
    this.registerStore
      .select(fromRegistrationStore.getRegistrationForm)
      .pipe(untilDestroyed(this))
      .subscribe(registrationForm => {
        if (registrationForm) {
          this.invitationCode = registrationForm.invitationCode;
          // TODO clear step 3 state
        }
      });
  }
  fg: FormGroup = this.fb.group({
    email: [
      '',
      [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
      ],
    ],
  });

  public emailCheck(): void {
    const email = this.fg.controls['email'].value;
    const checkEmailExists: IUserExistsByEmail = {
      email: email,
      invitationCode: this.invitationCode,
    };
    this.registerStore.dispatch(fromRegistrationStore.CheckEmailExists({ checkEmailExists }));
    this.checkEmailExists$ = this.registerStore.select(
      fromRegistrationStore.getCheckEmailExistsResponse
    );
    this.checkEmailExists$.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {
        if (data.userExists === true) {
          this.goToConsentForm('../register-page3-set-consent');
        } else if (!data.userExists) {
          this.goToConsentForm('../register-page3-set-password-and-consent');
        } else {
          // TODO handle other options
        }
      } else {
        // TODO handle error
      }
    });
  }

  public goToConsentForm(route: string): void {
    void this.routerService.navigate([route], { relativeTo: this.activeRoute });
  }
}
