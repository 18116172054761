import { Directive, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as fromMasterDataState from '../../../shared/+state/account/masterdata';
import { SetMovingAddressSent } from '../../../shared/+state/account/masterdata';
import { setShowAddressChangePopup } from '../../../shared/+state/notifications';
import {
  IContract,
  TicketIssueType,
  WhitelabelImage,
  WhitelabelImageType,
} from '../../../shared/models';
import { RouterService } from '../../services';

@Directive()
@UntilDestroy()
export class AddressChangePageBaseComponent implements OnInit {
  @Input() title = 'address_change_screen.title_l';
  @Input() description = 'address_change_screen.description_l';
  @Input() checkBoxText = 'address_change_screen.checkbox_l';
  @Input() linkText = 'address_change_screen.link_text_a';
  @Input() goToTicketsText = 'address_change_screen.next_a';
  @Input() alreadyDoneText = 'address_change_screen.done_a';
  @Input() showCancelBtn = true;
  @Input() cancellationText = 'address_change_screen.back_a';
  @Input() headerTitle = 'address_change_screen.header_title_l';
  @Input() showArrowBack = false;
  @Input() hasBackButton = false;
  @Input() contractId: string;
  public whitelabelImage = WhitelabelImage;
  public whitelabelImageType = WhitelabelImageType;
  public contract: IContract;
  public issueType: TicketIssueType;

  constructor(
    public store: Store,
    public routerService: RouterService
  ) {}
  ngOnInit() {
    this.store
      .select(fromMasterDataState.getContracts)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.contract = res.find(x => x.id === this.contractId);
      });
  }
  public selectContract() {
    this.store.dispatch(
      fromMasterDataState.SelectContract({
        id: this.contract.id,
      })
    );
  }

  public setDismissAddressPopupNotificationUntilNextStart() {
    this.store.dispatch(setShowAddressChangePopup({ showAddressChangePopup: false }));
    this.navigateToDashboard();
  }

  public setMovingAddressSent(contractId: string) {
    this.store.dispatch(
      SetMovingAddressSent({
        contractId: contractId,
        movingAddressSent: true,
      })
    );
    this.navigateToDashboard();
  }

  public navigateToDashboard() {
    void this.routerService.navigate(['content', 'dashboard'], {
      queryParams: { tab: 'dashboard' },
    });
  }
}
