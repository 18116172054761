import { Component } from '@angular/core';
import { CardContainerBaseComponent } from '@resident-nx/shared';

@Component({
  selector: 'rs-web-cards-container',
  templateUrl: './cards-container.component.html',
  styleUrls: ['./cards-container.component.scss'],
  standalone: true,
})
export class CardsContainerWebComponent extends CardContainerBaseComponent {}
