@if ((announcementActionState$ | async).done) {
  @if (announcement$ | async; as announcement) {
    <div class="container container--with-spacing">
      <div class="d-flex align-items-center mb-3">
        <rs-web-breadcrumbs
          [items]="breadcrumbs"
          (navigateBack)="onNavigateBack()"
          (navigateToUrl)="onNavigate($event)"
        ></rs-web-breadcrumbs>
      </div>
      <div class="container__content">
        <div class="d-flex justify-content-center">
          <h3>{{ announcement.title }}</h3>
        </div>
        <span class="subtitle">{{ announcement.createdAt | rsWebDate }}</span>
        <hr />
        <p class="primary-text" [innerHtml]="announcement.text"></p>
        @for (attachment of announcement.attachments; track attachment.url) {
          <div rsWebElevation [elevation]="2" class="announcement" (click)="onPreview(attachment)">
            @if (attachment.type === 'IMG') {
              <rs-web-image
                class="announcement__image"
                [defaultSrc]="attachment.url"
                [objectFit]="imageObjectFitEnum['COVER']"
                [borderRadius]="imageBorderRadiusEnum['SMALL']"
              ></rs-web-image>
            } @else {
              <rs-web-icon [icon]="immomioIconName.Document"></rs-web-icon>
            }
            <span class="primary-text">{{ attachment.name }}</span>
          </div>
        }
      </div>
    </div>
  }
} @else {
  <div class="loading-area d-flex justify-content-center">
    <rs-web-loader></rs-web-loader>
  </div>
}
