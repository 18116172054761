<div class="cards-container" [id]="'meter-' + meter.deviceId">
  <div rsWebElevation [elevation]="2" class="chart-container">
    <div class="d-flex flex-column"></div>
    <!-- Datepicker -->
    <div class="date-picker-container">
      <rs-web-consumption-date-picker
        [formControl]="selectedDateControl"
        [selectedTimeframe]="selectedTimeframe$ | async"
        [isMonthAvailable]="meter.defaultAggregation === ConsumptionAggregateType.DAY"
        (timeframeChange)="onTimeframeSelect($event)"
      ></rs-web-consumption-date-picker>
    </div>
    <!-- Chart -->
    <div class="chart">
      <rs-web-consumption-chart
        [showAdditionalInfos]="true"
        [height]="'220px'"
        [timeframe]="selectedTimeframe$ | async"
        [meter]="meter"
      ></rs-web-consumption-chart>
    </div>
  </div>
  <!-- Comparison -->
  <div rsWebElevation [elevation]="2" class="benchmark-container d-flex flex-column">
    <rs-web-consumption-comparison
      [meter]="meter"
      [timeframe]="selectedTimeframe$ | async"
    ></rs-web-consumption-comparison>
  </div>
</div>
