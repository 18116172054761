import { createSelector } from '@ngrx/store';

import { getSortingState } from '../../reducers';
import * as fromReducer from './sorting.reducer';

export const getDocumentSorting = createSelector(getSortingState, fromReducer.getDocumentSorting);
export const getDocumentSortingActionState = createSelector(
  getSortingState,
  fromReducer.getDocumentSortingActionState
);

export const getTicketSorting = createSelector(getSortingState, fromReducer.getTicketSorting);
export const getTicketSortingActionState = createSelector(
  getSortingState,
  fromReducer.getTicketSortingActionState
);
