import { gql } from 'apollo-angular';

export const consentFields = `
  id
  acceptedLatest
  text
`;

export const setConsentMutation = gql`
  mutation setConsent($consentType: ConsentType!, $consentId: Int!) {
    setConsent(consentType: $consentType, consentId: $consentId)
  }
`;

export const getConsentQuery = gql`
  query getConsent($consentType: ConsentType!) {
    getConsent(consentType: $consentType) {
      ${consentFields}
    }
  }
`;
