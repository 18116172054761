import { IPageInfo, IResidentS3File } from '../general';

export interface IPublishedAnnouncementsResponse {
  edges: IPublishedAnnouncementsEdge[];
  pageInfo: IPageInfo;
}

export interface IPublishedAnnouncementsEdge {
  cursor: string;
  node: IPublishedAnnouncement;
}
export interface IPublishedAnnouncement {
  id: number;
  title: string;
  text: string;
  publishedAt: string;
  publishedUntil: string | null;
  createdAt: string | null;
  attachments: IResidentS3File[] | null;
}

export interface IResidentAnnouncementContent {
  id: number;
  title: string;
  description: string;
  publishedAt: string;
  publishedUntil: string;
  createdAt: string;
  attachments: IResidentS3File[];
}
