import { Directive } from '@angular/core';
import { ComparisonStatus, ComparisonType, ConsumptionType, IConsumption } from '../../models';
import { IConsumptionState } from '../../+state';
import { Store } from '@ngrx/store';
import * as fromConsumptionState from '../../+state/consumption';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IActionState } from '../../utils';
import { ConsumptionComparisonService } from '../../services';

@Directive()
export abstract class ConsumptionComparisonBaseComponent {
  public comparisonType = ComparisonType;
  public consumptionType = ConsumptionType;
  public consumptionData$: Observable<IConsumption>;
  public selectedPeriodConsumptionActionState$: Observable<IActionState>;
  public width: { consumption: number; pastConsumption: number; benchmark: number } = {
    consumption: 0,
    pastConsumption: 0,
    benchmark: 0,
  };
  public comparisonStatus: ComparisonStatus;

  constructor(
    private store: Store<IConsumptionState>,
    private comparisonService: ConsumptionComparisonService
  ) {
    this.consumptionData$ = this.store
      .select(fromConsumptionState.getSelectedConsumptionForFirstMeter)
      .pipe(
        tap(res => {
          if (res?.consumption) {
            this.width = this.comparisonService.calculateBackgroundWidth(res);
            this.comparisonStatus = this.comparisonService.getComparisonStatus(res);
          }
        })
      );
    this.selectedPeriodConsumptionActionState$ = this.store.select(
      fromConsumptionState.getSelectedPeriodConsumptionActionStateForFirstMeter
    );
  }
}
