import { Directive, Input, signal } from '@angular/core';
import { TicketStatus } from '../../models';

type ClassAndText = {
  class: string;
  text: string;
};

const StatusClassesAndTextObj: Partial<Record<TicketStatus, ClassAndText>> = {
  [TicketStatus.OPEN]: {
    class: 'bg-status_open',
    text: 'ticket_status.open_l',
  },
  [TicketStatus.IN_PROGRESS]: {
    class: 'bg-status_in-progress',
    text: 'ticket_status.in-progress_l',
  },
  [TicketStatus.WAITING_FOR_OTHERS]: {
    class: 'bg-status_waiting-for-others',
    text: 'ticket_status.waiting-for-others_l',
  },
  [TicketStatus.CLOSED]: {
    class: 'bg-status_closed',
    text: 'ticket_status.closed_l',
  },
  [TicketStatus.CANCELLED]: {
    class: 'bg-status_cancelled',
    text: 'ticket_status.cancelled_l',
  },
};

@Directive()
export abstract class StatusBadgeBaseComponent {
  @Input() set state(status: TicketStatus) {
    this.setClassNameAndText(status);
  }
  public className = signal('nav-text p-x-10 p-y-2 text-white text-uppercase border-radius-small');
  public text = signal('');

  private setClassNameAndText(status: TicketStatus) {
    const value = StatusClassesAndTextObj[status];

    if (value) {
      this.className.update(classes => classes.concat(' ' + value.class));
      this.text.set(value.text);
    }
  }
}
