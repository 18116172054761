@if (
  showUpdateBanner && ticketState !== ticketStatus.CLOSED && ticketState !== ticketStatus.CANCELLED
) {
  <div class="chat-update-banner">
    <rs-web-icon
      [icon]="icons.Close"
      [color]="'white'"
      [size]="12"
      (tapped)="showUpdateBanner = false"
    ></rs-web-icon>
    <div class="d-flex justify-content-between px-4 py-1 align-items-center">
      <div>{{ 'update_available' | translate }}</div>
      <rs-web-icon
        [icon]="icons.Refresh"
        [color]="'white'"
        [size]="15"
        (tapped)="refreshChat.emit()"
      ></rs-web-icon>
    </div>
  </div>
}

<div class="chat-body" #chatContainer>
  @if (!loading) {
    @for (messageGroup of ticketChat(); track messageGroup) {
      <div class="chat" [class]="messageGroup.isIncoming ? 'flex-row' : 'flex-row-reverse'">
        <div class="chat__img" [class.chat__img--outgoing]="!messageGroup.isIncoming">
          {{ messageGroup.userInitials }}
        </div>
        <div class="chat__message-wrapper" [class.align-items-end]="!messageGroup.isIncoming">
          @for (item of messageGroup.messages; track item.id; let isLastItem = $last) {
            <div class="d-flex gap-2">
              @if (item.status === 'FAILED') {
                <rs-web-button
                  [type]="ButtonTypeEnum.NEGATIVE"
                  [iconLeft]="icons.Refresh_1"
                  [elevation]="1"
                  [borderRadius]="borderRadius.ROUND"
                  (tapped)="onRetryMessage(item.id)"
                />
              }
              <div
                class="chat__message"
                [innerHTML]="item.message"
                [class.chat__message--outgoing]="!messageGroup.isIncoming"
                [class.chat__message--bordered]="
                  item.status === 'FAILED' || item.status === 'RETRIED'
                "
              ></div>
            </div>

            <div class="chat__notification">
              @switch (item.status) {
                @case ('FAILED') {
                  <span class="failed">{{
                    'ticket_chat_message_status_failed_l' | translate
                  }}</span>
                }
                @case ('PENDING') {
                  <span>{{ 'ticket_chat_message_status_pending_l' | translate }}</span>
                }
                @case ('RETRIED') {
                  <time class="me-1">{{ item.timestamp | timeAgo }}</time>
                  <span>{{ 'ticket_chat_message_status_retried_l' | translate }}</span>
                }
                @case ('ACKNOWLEDGED') {
                  @if (isLastItem) {
                    <time>{{ item.timestamp | timeAgo }}</time>
                  }
                }
              }
            </div>
          }
        </div>
      </div>
    }
  } @else {
    <rs-web-ticket-details-chat-loader />
  }
</div>
<div class="chat-footer">
  <div class="d-flex align-items-center">
    <textarea
      cdkTextareaAutosize
      [cdkAutosizeMaxRows]="5"
      class="form-control"
      [class.chat-footer__textarea--disabled]="
        ticketState === ticketStatus.CLOSED || ticketState === ticketStatus.CANCELLED
      "
      [(ngModel)]="message"
      [placeholder]="
        (ticketState === ticketStatus.CANCELLED || ticketState === ticketStatus.CLOSED
          ? 'ticket_chat.ticket_closed_placeholder_l'
          : 'ticket_chat.new_message_placeholder_l'
        ) | translate
      "
      [disabled]="ticketState === ticketStatus.CLOSED || ticketState === ticketStatus.CANCELLED"
    ></textarea>
    <rs-web-button
      [iconLeft]="icons.PaperPlane"
      [elevation]="1"
      [borderRadius]="borderRadius.ROUND"
      [disabled]="!message || loading || sending"
      (tapped)="onSendMessage()"
    />
  </div>
</div>
