import { ChangeDetectionStrategy, Component } from '@angular/core';

import { AppWebBaseComponent } from '@resident-nx/web';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'rs-web-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent extends AppWebBaseComponent {}
