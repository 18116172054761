import { LoggingTypes } from '../../models';

export abstract class NewRelicService {
  abstract initialize(): void;

  abstract startInteraction(action: string): string;

  abstract stopInteraction(action: string): void;

  abstract recordHttpFailure(
    url: string,
    httpMethod: string,
    startTime: number,
    endTime: number,
    failureCode: number
  ): void;

  abstract log(log: string, type?: LoggingTypes);
}
