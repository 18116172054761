import { gql } from 'apollo-angular';
import { contactPersonResponseFields } from '../common';

export const getDirectAssignmentsQuery = gql`
  query getDirectAssignments($categoryId: String!, $contractId: String!) {
    getDirectAssignments(categoryId: $categoryId, contractId: $contractId) {
      directAssignmentContacts {
        ${contactPersonResponseFields}
      }
    }
  }
`;
