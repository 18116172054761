import { IPersonalDataResponse, ISepaMandate, IUserData } from '../../../models';
import { obfuscateString } from '../../../utils';

export const userProfileConverter = {
  fromDto: (response: IPersonalDataResponse): IUserData => {
    function obfuscateIban(sepaMandate: ISepaMandate): ISepaMandate {
      return {
        ...sepaMandate,
        iban: sepaMandate.iban ? obfuscateString(sepaMandate.iban) : null,
      };
    }

    return {
      ...response.userDataResponseBean,
      sepaMandate: response?.userDataResponseBean?.sepaMandate
        ? obfuscateIban(response.userDataResponseBean.sepaMandate)
        : null,
    };
  },
};
