import { createAction, props } from '@ngrx/store';

import { IResidentS3File } from '../../models';

export const DownloadDocument = createAction(
  '[Service] Download Document',
  props<{ document: IResidentS3File }>()
);

export const DownloadDocumentSuccess = createAction(
  '[Service] Download Document Success',
  props<{ file: File }>()
);

export const DownloadDocumentFailed = createAction(
  '[Service] Download Document Failed',
  props<{ error: Error }>()
);

export const UploadFiles = createAction('[Service] Upload Files', props<{ filePaths: string[] }>());

export const UploadFilesWeb = createAction(
  '[Service] Upload Files Web',
  props<{ filePaths: File[] }>()
);

export const UploadFilesSuccess = createAction(
  '[Service] Upload Files Success',
  props<{ files: IResidentS3File[] }>()
);

export const UploadFilesFailed = createAction(
  '[Service] Upload Files Failed',
  props<{ error: Error }>()
);

export const ClearUploadedFiles = createAction('[Service] Clear Uploaded Files');
