import { Component } from '@angular/core';
import { ConsumptionRowBaseComponent, ConsumptionUnitTypePipe } from '@resident-nx/shared';
import { DecimalPipe, NgStyle } from '@angular/common';

@Component({
  selector: 'rs-web-consumption-row',
  templateUrl: 'consumption-row.component.html',
  styleUrls: ['consumption-row.component.scss'],
  standalone: true,
  imports: [NgStyle, DecimalPipe, ConsumptionUnitTypePipe],
})
export class ConsumptionRowWebComponent extends ConsumptionRowBaseComponent {}
