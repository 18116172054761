import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';

import { AuthTokenService } from '../../infrastructure';
import { IEnvironment, IResidentS3File } from '../../models';
import { RequestService } from '../general';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class ServiceFacade {
  constructor(
    protected apollo: Apollo,
    protected authTokenService: AuthTokenService,
    protected request: RequestService,
    protected environment: IEnvironment
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public downloadDocument?(document: IResidentS3File): Observable<any>;

  public uploadFiles?(filePaths: string[]): Promise<IResidentS3File[] | Error>;

  public uploadFilesWeb?(files: File[]): Observable<IResidentS3File[]>;
}
