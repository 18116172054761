import { Directive } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromOffersState from '../../+state/news/offers';

@Directive()
export abstract class OfferDetailsBaseComponent {
  offer$ = this.offersStore.select(fromOffersState.getOffer);
  offerActionState$ = this.offersStore.select(fromOffersState.getOfferActionState);

  constructor(private offersStore: Store<fromOffersState.IOffersState>) {}
}
