<div class="p-4 d-flex flex-column align-items-center">
  <div class="modal-header">
    <rs-web-icon
      [icon]="immomioIconName.Close"
      [style.cursor]="'pointer'"
      [size]="16"
      (click)="closeModal()"
    ></rs-web-icon>
  </div>

  <rs-web-image
    [defaultSrc]="'/web/images/png/address-change.png'"
    class="d-block mb-4"
  ></rs-web-image>
  <span class="title-xxl text-center mb-4">{{ title | translate }}</span>
  <p class="mb-4 text-center">{{ description | translate }}</p>
  <rs-web-button
    [type]="ButtonTypeEnum.PRIMARY"
    (click)="goToTicketsCreation()"
    class="d-block mb-4 w-100"
    >{{ goToTicketsText | translate }}</rs-web-button
  >
  <rs-web-button
    [type]="ButtonTypeEnum.LIGHT_BORDERED"
    (click)="setMovingAddressDone()"
    class="d-block mb-4 w-100"
    >{{ alreadyDoneText | translate }}</rs-web-button
  >
  <rs-web-button [type]="ButtonTypeEnum.LINK" (click)="closeModal()">{{
    linkText | translate
  }}</rs-web-button>
</div>
