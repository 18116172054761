import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as fromNewsState from '../../+state/news/news';
import { WidgetBaseComponent } from './widget.base-component';
import { LoadingType } from '../../models';

@UntilDestroy()
@Directive()
export abstract class NewsWidgetBaseComponent extends WidgetBaseComponent {
  @Output() tapped = new EventEmitter();
  @Input() maxLines = 2;
  news$ = this.newsStore.select(fromNewsState.getLatestNews).pipe(untilDestroyed(this));
  newsActionState$ = this.newsStore
    .select(fromNewsState.getNewsActionState)
    .pipe(untilDestroyed(this));
  public loadingTypes: LoadingType[] = [];

  constructor(protected newsStore: Store<fromNewsState.INewsState>) {
    super();
  }
}
