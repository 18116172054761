import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { getAnnouncements, getAnnouncementsActionState, LoadAnnouncements } from '../../+state';
import { IPublishedAnnouncementsEdge } from '../../models';
import { ResolverCommonService } from './resolver-common.service';

export const AnnouncementsResolver: ResolveFn<IPublishedAnnouncementsEdge[]> = route => {
  const common = inject(ResolverCommonService);

  const { resolverWaitForData, pageSize } = route.data;
  const announcementsPayload = {
    limit: pageSize ?? 10,
    offset: 0,
  };

  return common.resolveHandler<IPublishedAnnouncementsEdge[]>(
    getAnnouncementsActionState,
    LoadAnnouncements(announcementsPayload),
    getAnnouncements,
    resolverWaitForData
  );
};
