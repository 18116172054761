export interface DeviceData {
  os: string;
  deviceType: string;
  language: string;
  manufacturer: string;
  model: string;
  osVersion: string;
  region: string;
  sdkVersion: string;
}

export interface RegisterDeviceInput {
  identifier: string;
  firebaseToken: string;
  data: DeviceData;
}

export enum ResidentNotificationActionType {
  NEW_CONTENT = 'NEW_CONTENT',
  DOCUMENT_SHARED = 'DOCUMENT_SHARED',
  TICKET_MESSAGE = 'TICKET_MESSAGE',
  LEGAL_TEXT = 'LEGAL_TEXT',
  TICKET_STATUS_CHANGE = 'TICKET_STATUS_CHANGE',
  PAYMENT_SHARED = 'PAYMENT_SHARED',
  NEW_CONTENT_OFFER = 'NEW_CONTENT_OFFER',
  NEW_CONTENT_NEWS = 'NEW_CONTENT_NEWS',
  CONTRACT_END_NEW_ADDRESS = 'CONTRACT_END_NEW_ADDRESS',
}
