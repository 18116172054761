import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromMasterDataState from '../../+state/account/masterdata';
import {
  ContractId,
  ContractSelectionTypes,
  ContractStatus,
  IContract,
  TicketIssueType,
} from '../../models';
import { map } from 'rxjs';

@Directive()
export abstract class SelectContractBaseComponent implements OnInit {
  @Input() title = 'contracts.select_contract_l';
  @Input() contractSelectionType = ContractSelectionTypes.TICKET;
  contracts$ = this.store
    .select(fromMasterDataState.getContracts)
    .pipe(map(contracts => contracts.filter(contract => contract.status !== ContractStatus.ENDED)));
  @Output() contractSelected = new EventEmitter<{
    issueType: TicketIssueType;
    contractId: ContractId;
  }>();

  contractsActionState$ = this.store.select(fromMasterDataState.getContractsActionState);
  public issueType: TicketIssueType | undefined;

  constructor(
    public store: Store<fromMasterDataState.IMasterDataState>,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    if (this.contractSelectionType === ContractSelectionTypes.TICKET) {
      this.issueType = this.route.snapshot?.params?.type;
    }
  }

  public selectContract(contract: IContract, emitEvent = true): void {
    this.store.dispatch(
      fromMasterDataState.SelectContract({
        id: contract.id,
      })
    );

    if (emitEvent) {
      setTimeout(() => {
        this.issueType
          ? this.contractSelected.emit({ issueType: this.issueType, contractId: contract.id })
          : this.contractSelected.emit();
      }, 100);
    }
  }
}
