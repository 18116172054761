import { createReducer, on } from '@ngrx/store';
import { DocumentSortField, ISort, Sort, SortOrder, TicketSortField } from '../../../models';
import { ActionStateCreator, IActionState } from '../../../utils';
import * as fromActions from './sorting.actions';

export interface ISortingState {
  documentSorting: ISort<DocumentSortField>;
  documentSortingActionState: IActionState;
  ticketSorting: ISort<TicketSortField>;
  ticketSortingActionState: IActionState;
}

export const initialState: ISortingState = {
  documentSorting: new Sort<DocumentSortField>(
    'sorting.docs.sorting_name_asc_l',
    DocumentSortField.NAME,
    SortOrder.ASC
  ),
  documentSortingActionState: ActionStateCreator.create(),
  ticketSorting: new Sort<TicketSortField>(
    'sorting.tickets.creation_date_asc_l',
    TicketSortField.CREATED,
    SortOrder.ASC
  ),
  ticketSortingActionState: ActionStateCreator.create(),
};

export const documentSortingReducer = createReducer(
  initialState,
  on(fromActions.setDocumentSorting, (state, { sorting }) => ({
    ...state,
    documentSorting: sorting,
    documentSortingActionState: ActionStateCreator.onSuccess(),
  })),
  on(fromActions.resetDocumentSorting, state => ({
    ...state,
    documentSorting: null,
    documentSortingActionState: ActionStateCreator.create(),
  })),
  on(fromActions.setTicketSorting, (state, { sorting }) => ({
    ...state,
    ticketSorting: sorting,
    ticketSortingActionState: ActionStateCreator.onSuccess(),
  })),
  on(fromActions.resetTicketSorting, state => ({
    ...state,
    ticketSorting: null,
    ticketSortingActionState: ActionStateCreator.create(),
  }))
);

export const getDocumentSorting = (state: ISortingState) => state?.documentSorting;
export const getDocumentSortingActionState = (state: ISortingState) =>
  state.documentSortingActionState;

export const getTicketSorting = (state: ISortingState) => state?.ticketSorting;
export const getTicketSortingActionState = (state: ISortingState) => state.ticketSortingActionState;
