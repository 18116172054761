<rs-web-card [borderStyle]="'none'">
  @if (!showAdditionalInfos) {
    <div class="d-flex flex-column">
      <span class="title-xs text-secondary">ZN {{ meter.deviceId }}</span>
    </div>
  }

  @if (showAdditionalInfos) {
    <rs-web-consumption-chart-additional-info [meter]="meter">
    </rs-web-consumption-chart-additional-info>
  }
  <div class="consumption-chart-container">
    <!-- Month -->
    @if (timeframe === ConsumptionTimeframe.MONTH) {
      <rs-web-consumption-chart-month
        [consumption]="consumption$ | async"
        [pastConsumption]="pastConsumption$ | async"
        [options]="options"
      ></rs-web-consumption-chart-month>
    }
    <!-- Quarter -->
    @if (timeframe === ConsumptionTimeframe.QUARTER) {
      <rs-web-consumption-chart-quarter
        [consumption]="consumption$ | async"
        [options]="options"
        [consumptionType]="meter.type"
      ></rs-web-consumption-chart-quarter>
    }
    <!-- Year -->
    @if (timeframe === ConsumptionTimeframe.YEAR) {
      <rs-web-consumption-chart-year
        [consumption]="consumption$ | async"
        [pastConsumption]="pastConsumption$ | async"
        [options]="options"
        (consumptionSelected)="selectConsumptionByItem($event)"
      ></rs-web-consumption-chart-year>
    }

    @if (!isDataPresent()) {
      <div class="consumption-chart-no-data secondary-text title-xs">
        {{ 'consumption_comparison.no_data_l' | translate }}
      </div>
    }
  </div>
</rs-web-card>
