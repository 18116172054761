import { ChangeDetectionStrategy, Component, Input, OnChanges, ViewChild } from '@angular/core';

import {
  ConsumptionSeriesIndex,
  ConsumptionType,
  HexColorCode,
  IConsumptionItem,
  NgChanges,
} from '@resident-nx/shared';
import { format, setDate, setHours, setMonth } from 'date-fns';
import { de } from 'date-fns/locale';
import {
  ApexAxisChartSeries,
  ApexFill,
  ApexMarkers,
  ApexStroke,
  ApexTooltip,
  ChartComponent,
  NgApexchartsModule,
} from 'ng-apexcharts';
import { UpdateSeriesParams } from '../../molecules/consumption-chart/consumption-chart.service';
import { ConsumptionChartOptions } from '../../molecules/consumption-chart/types/consumption-chart-options';

@Component({
  selector: 'rs-web-consumption-chart-quarter',
  templateUrl: './consumption-chart-quarter.component.html',
  styleUrls: ['./consumption-chart-quarter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgApexchartsModule],
})
export class ConsumptionChartQuarterWebComponent implements OnChanges {
  @Input() options: ConsumptionChartOptions;
  @Input() consumptionType: ConsumptionType;

  @Input() consumption: IConsumptionItem[];

  @ViewChild(ChartComponent) chart: ChartComponent;

  public series: ApexAxisChartSeries;
  public fill: ApexFill;
  public stroke: ApexStroke;
  public markers: ApexMarkers;
  public tooltip: ApexTooltip;
  public colors: string[];
  public consumptionTypeToColor: Map<ConsumptionType, HexColorCode> =
    this.createConsumptionTypeToColorMap();

  constructor() {
    this.initChart();
  }

  ngOnChanges(changes: NgChanges<ConsumptionChartQuarterWebComponent>): void {
    if (changes.consumptionType) {
      this.fill = this.updateFillWithConsumptionColor(this.fill, this.consumptionType);
    }

    if (changes.options) {
      this.options = this.updateXAxis(this.options);
      this.options = this.updateYAxis(this.options);
      this.options = this.updateChartType(this.options);
    }

    if (changes.consumption) {
      this.series = this.updateSeries({
        series: this.series,
        indexToUpdate: ConsumptionSeriesIndex.CONSUMPTION,
        data: this.consumption,
      });
    }
  }

  private initChart(): void {
    this.series = [
      { name: 'Previous', data: [] },
      { name: 'Selected', data: [] },
    ];
    this.stroke = {
      width: 1,
      curve: 'smooth',
    };

    const initFill: ApexFill = {
      type: 'gradient',
      gradient: {
        type: 'vertical',
        shadeIntensity: 0.5,
        opacityFrom: 0.8,
        opacityTo: 0.2,
        stops: [0, 80, 100],
      },
    } as ApexFill; // typing are broken in current ng-apexcharts, we need to cast until fixed ;
    this.fill = this.updateFillWithConsumptionColor(initFill, this.consumptionType);
    this.markers = { size: 0 };
    this.tooltip = { enabled: false };
  }

  private createConsumptionTypeToColorMap(): Map<ConsumptionType, HexColorCode> {
    return new Map<ConsumptionType, HexColorCode>()
      .set(ConsumptionType.POWER, '#F6BF25')
      .set(ConsumptionType.HEATING, '#884DFF')
      .set(ConsumptionType.COLD_WATER, '#044ADA')
      .set(ConsumptionType.HOT_WATER, '#F4501E')
      .set(ConsumptionType.PHOTOVOLTAIC, '#5FBF89');
  }

  private updateSeries({ series, indexToUpdate, data }: UpdateSeriesParams): ApexAxisChartSeries {
    if (!Array.isArray(data) || data.length === 0) {
      series[indexToUpdate].name = '';
      series[indexToUpdate].data = [];
      return [...series];
    }
    const [firstEntry] = data;
    const year = new Date(firstEntry.period.start).getFullYear();
    series[indexToUpdate].name = `${year}`;
    series[indexToUpdate].type = 'area';

    series[indexToUpdate].data = data.map(({ amount, period }) => {
      const y = parseFloat(amount.toFixed(2));

      const x = new Date(period.start).getMonth();
      return { x, y };
    });
    return [...series];
  }

  private updateChartType(options: ConsumptionChartOptions): ConsumptionChartOptions {
    return {
      ...options,
      chart: {
        ...options.chart,
        type: 'area',
      },
    };
  }

  private updateXAxis(options: ConsumptionChartOptions): ConsumptionChartOptions {
    return {
      ...options,
      xaxis: {
        ...options.xaxis,
        labels: {
          ...options.xaxis.labels,
          style: {
            cssClass: 'consumption-chart-axis-label--xaxis--quarter',
          },
          formatter: (_value, month) => this.formatDateWithMonth(month),
        },
      },
    };
  }

  private updateYAxis(options: ConsumptionChartOptions): ConsumptionChartOptions {
    return {
      ...options,
      yaxis: {
        ...options.yaxis,
        min: 0,
        labels: {
          ...options.yaxis.labels,
          style: {
            cssClass: 'consumption-chart-axis-label--yaxis--quarter',
          },
        },
      },
    };
  }

  private updateFillWithConsumptionColor(
    fill: ApexFill,
    consumptionType: ConsumptionType
  ): ApexFill {
    const color = this.consumptionTypeToColor.get(consumptionType);
    /***
     * consumption type specific colors should be temporarily replaced with the primary color till we have whitelabeled accent colors
     *
     * this.colors = [color] <-> this.colors = ['var(--color-primary)'];
     */

    this.colors = ['var(--color-primary)'];
    return {
      ...fill,
      gradient: {
        ...fill.gradient,
        gradientToColors: [color],
      },
    };
  }

  private formatDateWithMonth = (month: number): string => {
    let date = new Date();
    date = setMonth(date, month);
    date = setDate(date, 1);
    // Dirty hack, um ein seltsames Zeitzonen-Offset-Problem zu beheben
    date = setHours(date, 12);
    return format(date, 'LLL', { locale: de });
  };
}
