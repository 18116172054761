import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { Store } from '@ngrx/store';
import { EMPTY, of, switchMap } from 'rxjs';

import { getOffer, getOfferActionState, LoadOffer } from '../../+state';
import { IPublishedOffer } from '../../models';
import { ResolverCommonService } from './resolver-common.service';

export const OfferResolver: ResolveFn<IPublishedOffer> = route => {
  const common = inject(ResolverCommonService);
  const store = inject(Store);

  const { resolverWaitForData } = route.data;
  const id = route.params?.id;
  if (!id) {
    return EMPTY;
  }
  return store.select(getOffer).pipe(
    switchMap(offer => {
      if (offer && offer.id === id) {
        return of(offer);
      } else {
        return common.resolveHandler<IPublishedOffer>(
          getOfferActionState,
          LoadOffer({ offerId: id }),
          getOffer,
          resolverWaitForData,
          true
        );
      }
    })
  );
};
