import { createReducer, on } from '@ngrx/store';
import * as fromActions from './login.actions';
import { IFederatedLoginResponse } from '../../../models';

export interface ILoginState {
  federatedLoginData: IFederatedLoginResponse;
}

export const initialState: ILoginState = {
  federatedLoginData: null,
};

export const loginReducer = createReducer(
  initialState,

  on(fromActions.LoadFederatedLoginData, state => {
    return {
      ...state,
    };
  }),

  on(fromActions.LoadFederatedLoginDataSuccess, (state, { federatedLoginData }) => {
    return {
      ...state,
      federatedLoginData,
    };
  })
);
