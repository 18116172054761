import {
  FormResponseQuestionType,
  IFormResponseCreate,
  ITicketCreationSummary,
  ITicketCreationSummaryItem,
  ITicketMetadataCreate,
  ScoreQuestionType,
  TicketIssueType,
} from '../../models';
import { getNameFromFilePath } from '../../utils';

export const ticketMetadataConverter = {
  convertScoreQuestionTypeToFormResponseQuestionType: (
    response: ITicketCreationSummaryItem
  ): FormResponseQuestionType => {
    switch (response.type) {
      case ScoreQuestionType.SELECTION:
        return FormResponseQuestionType.SELECTION;
      case ScoreQuestionType.INPUT_TEXT:
        return response.multiline
          ? FormResponseQuestionType.TEXTBOX
          : FormResponseQuestionType.TEXTFIELD;
      case ScoreQuestionType.INPUT_DATE:
        return FormResponseQuestionType.DATE;
      case ScoreQuestionType.INPUT_ATTACHMENTS:
        return FormResponseQuestionType.ATTACHMENTS;
      case ScoreQuestionType.LABEL:
        return FormResponseQuestionType.LABEL;
      default:
        return null;
    }
  },
  formatCreateTicketRequest: (
    ticketSummary: ITicketCreationSummary,
    issueType: TicketIssueType
  ): ITicketMetadataCreate => {
    const formResponses: IFormResponseCreate[] = [];
    ticketSummary.detailedResponses?.forEach((res, index) => {
      const responseType =
        ticketMetadataConverter.convertScoreQuestionTypeToFormResponseQuestionType(res);
      if (responseType !== FormResponseQuestionType.LABEL) {
        const response: IFormResponseCreate = {
          index,
          questionText: res.displaySummary?.questionTitle || '',
          type: responseType,
          responses: [],
          attachments: [],
        };
        if (responseType === FormResponseQuestionType.ATTACHMENTS) {
          const attachment = res.displaySummary?.attachments?.map(item => {
            return {
              name: getNameFromFilePath(item.filePath),
              extension: 'jpg',
              content: item.content,
            };
          });
          if (attachment) {
            response.attachments = attachment;
          }
        } else {
          let answers: string[] = [];
          if (Array.isArray(res.displaySummary?.answerTitle)) {
            answers = res.displaySummary.answerTitle.map(answer =>
              answer !== null && answer !== undefined ? answer : ''
            );
          } else {
            answers.push(
              res.displaySummary?.answerTitle !== null &&
                res.displaySummary?.answerTitle !== undefined
                ? (res.displaySummary?.answerTitle as string)
                : ''
            );
          }
          response.responses.push(...answers);
        }
        formResponses.push(response);
      }
    });

    return {
      formResponses,
      issueType,
      contractNumber: null,
      categoryAnswerSetRelationId: ticketSummary.categoryAnswerSetRelationId,
    };
  },
};
