import { IAuthToken } from '../../models';
export abstract class AuthService {
  protected guestMode = false;
  abstract login(providerType: string, email: string): Promise<IAuthToken>;
  abstract loginViaApiCall(email: string, password: string): Promise<IAuthToken>;
  abstract guestLogin(): boolean;
  abstract logout(providerType: string, redirection?: string): void;
  abstract refresh(providerType: string, token: IAuthToken): Promise<IAuthToken>;
  abstract getToken(): IAuthToken;
  abstract isGuestMode(): boolean;
}
