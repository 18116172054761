import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { of, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';

import {
  getMetersByContract,
  getMetersByContractActionState,
  getPermissions,
  LoadMetersByContract,
} from '../../+state';
import { MetersByContractR } from '../../models';
import { ResolverCommonService } from './resolver-common.service';

export const ConsumptionInfoResolver: ResolveFn<MetersByContractR> = route => {
  const common = inject(ResolverCommonService);
  const store = inject(Store);

  const { resolverWaitForData } = route.data;
  return store.select(getPermissions).pipe(
    switchMap(permissions => {
      if (!permissions?.consumptionDataVisualization) {
        return of(null);
      } else {
        return common.resolveHandler<MetersByContractR>(
          getMetersByContractActionState,
          LoadMetersByContract(),
          getMetersByContract,
          resolverWaitForData
        );
      }
    })
  );
};
