<div class="payment-requests-container">
  <div class="payment-requests-container__title">
    <rs-web-icon [icon]="immomioIconName.Coin" [size]="16" class="me-2"></rs-web-icon>
    <span class="primary-text font-weight-600">{{
      'payment_requests.active_requests_l' | translate
    }}</span>
  </div>

  @if ((paymentRequestsActionState$ | async).pending !== true) {
    @if (paymentRequests$ | async; as payments) {
      @if (payments.length) {
        <div class="payment-container">
          @for (payment of payments; track payment.id) {
            <rs-web-payment-request-card
              [paymentRequest]="payment"
              class="payment-container__card"
            ></rs-web-payment-request-card>
          }
        </div>
      } @else {
        <span class="primary-text font-weight-600">{{
          'payment_requests.no_requests_l' | translate
        }}</span>
      }
    }
  } @else {
    <rs-web-loading-skeleton
      [show]="true"
      [types]="[LoadingType.PAYMENT]"
    ></rs-web-loading-skeleton>
  }
</div>

<hr class="mobile-only-element" />

@if (archivePaymentRequests$ | async; as archivePayments) {
  @if (archivePayments?.length) {
    <div [class.open]="archiveRequestsOpen" class="archive-payments-container">
      <div class="archive-payments-container__title">
        <rs-web-icon [icon]="immomioIconName.Archive" [size]="16" class="me-2"></rs-web-icon>
        <span class="primary-text font-weight-600">{{
          'payment_requests.archive_requests_l' | translate
        }}</span>
        @if (archiveRequestsOpen) {
          <rs-web-icon
            (click)="closeArchivePayments()"
            [icon]="immomioIconName.Close"
            [size]="16"
            class="icon web-only-element"
          ></rs-web-icon>
        }
        @if (!archiveRequestsOpen) {
          <rs-web-button
            (click)="openArchivePayments()"
            [size]="buttonSize.SMALL"
            [type]="buttonType.LIGHT_BORDERED"
            class="mobile-only-element"
          >
            {{ 'general.open' | translate }}
          </rs-web-button>
        }
        @if (archiveRequestsOpen) {
          <rs-web-button
            (click)="closeArchivePayments()"
            [size]="buttonSize.SMALL"
            [type]="buttonType.LIGHT_BORDERED"
            class="mobile-only-element"
          >
            {{ 'general.close' | translate }}
          </rs-web-button>
        }
      </div>
      <div>
        @if (!archiveRequestsOpen) {
          <rs-web-button
            (click)="openArchivePayments()"
            [size]="buttonSize.SMALL"
            [type]="buttonType.LIGHT_BORDERED"
            class="web-only-element"
          >
            {{ 'general.open' | translate }}
          </rs-web-button>
        }
        @if (archiveRequestsOpen) {
          @if ((archivePaymentRequstsActionState$ | async).pending !== true) {
            <div class="payment-container">
              @for (archivePayment of archivePayments; track archivePayment.id) {
                <rs-web-payment-request-card
                  [paymentRequest]="archivePayment"
                ></rs-web-payment-request-card>
              }
            </div>
          } @else {
            <rs-web-loading-skeleton
              [show]="true"
              [types]="[LoadingType.PAYMENT]"
            ></rs-web-loading-skeleton>
          }
        }
      </div>
    </div>
  }
}

<div></div>
