import { createAction, props } from '@ngrx/store';

import { ICustomerAppPermissions } from '../../../models';

export const LoadPermissions = createAction('[Account Permissions] Load Permissions');

export const LoadPermissionsSuccess = createAction(
  '[Account Permissions] Load Permissions Success',
  props<{ response: ICustomerAppPermissions }>()
);

export const LoadPermissionsFailed = createAction(
  '[Account Permissions] Load Permissions Failed',
  props<{ error: Error }>()
);
