import { ErrorHandlerService } from '@resident-nx/shared';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorHandlerWebService extends ErrorHandlerService {
  /**
   * Handle HTTP/GraphQL errors
   *
   * @param prefix e.g. "(AUTH ERROR) on login.effects.ts:"" <-- to now what kind of error and where it occurred
   * @param error
   */
  handleError(prefix: string, error: Error) {
    //TODO change when logs for web are available
    console.error(`${prefix} ERROR: ${error?.message}`);
  }
}
