import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImmomioIconName } from '@resident-nx/shared';
import { IconWebComponent } from '../icon/icon.component';

@Component({
  selector: 'rs-web-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss'],
  standalone: true,
  imports: [IconWebComponent],
})
export class NavigationItemWebComponent {
  @Input() icon!: ImmomioIconName;
  @Input() text!: string;
  @Output() navigateTo = new EventEmitter();
}
