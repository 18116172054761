import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';

import {
  ContractId,
  IEmergencyContactExistsResponse,
  IEmergencyContactExistsResponseBean,
  IEmergencyContactsResponseBean,
} from '../../../models';
import { emergencyContactsExistsQuery, getEmergencyContactsQuery } from '../../../queries';

@Injectable()
export class EmergencyContactsFacade {
  constructor(private apollo: Apollo) {}

  public emgergencyContactsExists(): Observable<IEmergencyContactExistsResponse> {
    return this.apollo
      .query<IEmergencyContactExistsResponseBean>({
        query: emergencyContactsExistsQuery,
      })
      .pipe(
        map(result => {
          if (typeof result.data?.emergencyContactExists?.emergencyContactExists === 'boolean') {
            return result.data.emergencyContactExists;
          } else if (result?.errors) {
            throw new Error(result.errors[0].message);
          }
          throw new Error();
        })
      );
  }

  public getEmergencyContacts(contractId: ContractId): Observable<IEmergencyContactsResponseBean> {
    return this.apollo
      .query<{ getEmergencyContacts: IEmergencyContactsResponseBean }>({
        query: getEmergencyContactsQuery,
        variables: {
          contractId,
          offset: 100,
          limit: 0,
        },
      })
      .pipe(
        map(result => {
          if (result?.data?.getEmergencyContacts) {
            return result.data.getEmergencyContacts;
          } else if (result?.errors) {
            throw new Error(result.errors[0].message);
          }
          throw new Error();
        })
      );
  }
}
