import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ButtonSizeEnum,
  ButtonTypeEnum,
  getActivePaymentRequests,
  getActivePaymentRequestsActionState,
  getArchivePaymentsActionState,
  getArchivePaymentsRequests,
  IActionState,
  ImmomioIconName,
  IPaymentPositionResident,
  LoadArchivePaymentRequests,
  LoadingType,
  LoadPaymentRequests,
} from '@resident-nx/shared';
import { Observable } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { ButtonWebComponent } from '../../atoms/button/button.component';
import { LoadingSkeletonWebComponent } from '../../atoms/loading-skeleton/loading-skeleton.component';
import { PaymentRequestCardWebComponent } from '../../molecules/payment-request-card/payment-request-card.component';
import { IconWebComponent } from '../../atoms/icon/icon.component';

@Component({
  selector: 'rs-web-payments-page',
  templateUrl: './payments-page.component.html',
  styleUrls: ['./payments-page.component.scss'],
  standalone: true,
  imports: [
    IconWebComponent,
    PaymentRequestCardWebComponent,
    LoadingSkeletonWebComponent,
    ButtonWebComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class PaymentsPageWebComponent implements OnInit {
  public paymentRequests$: Observable<IPaymentPositionResident[]>;
  public paymentRequestsActionState$: Observable<IActionState>;

  public archivePaymentRequests$: Observable<IPaymentPositionResident[]>;
  public archivePaymentRequstsActionState$: Observable<IActionState>;

  public archiveRequestsOpen = false;

  public buttonType = ButtonTypeEnum;
  public buttonSize = ButtonSizeEnum;
  public immomioIconName = ImmomioIconName;
  protected readonly LoadingType = LoadingType;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(LoadPaymentRequests({}));
    this.store.dispatch(LoadArchivePaymentRequests({}));

    this.paymentRequests$ = this.store.select(getActivePaymentRequests);
    this.paymentRequestsActionState$ = this.store.select(getActivePaymentRequestsActionState);

    this.archivePaymentRequests$ = this.store.select(getArchivePaymentsRequests);
    this.archivePaymentRequstsActionState$ = this.store.select(getArchivePaymentsActionState);
  }

  public openArchivePayments(): void {
    this.archiveRequestsOpen = true;
  }

  public closeArchivePayments(): void {
    this.archiveRequestsOpen = false;
  }
}
