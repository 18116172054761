import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { IPermissions } from '../../models';
import { ResolverCommonService } from './resolver-common.service';
import { getPermissions, getPermissionsActionState, LoadPermissions } from '../../+state';

export const PermissionsResolver: ResolveFn<IPermissions> = route => {
  const common = inject(ResolverCommonService);

  const { resolverWaitForData } = route.data;
  return common.resolveHandler<IPermissions>(
    getPermissionsActionState,
    LoadPermissions(),
    getPermissions,
    resolverWaitForData
  );
};
