import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'rs-web-headline',
  templateUrl: 'headline.component.html',
  styleUrls: ['headline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgTemplateOutlet],
})
export class HeadlineWebComponent {
  @Input() level!: 1 | 2 | 3;
}
