import * as fromReducer from './shared-content.reducers';
import { getDocumentsState } from '../reducers';
import { createSelector } from '@ngrx/store';

export const getSharedContent = createSelector(getDocumentsState, fromReducer.getSharedContent);

export const getSharedContentActionState = createSelector(
  getDocumentsState,
  fromReducer.getSharedContentActionState
);
