import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavigationItem } from '../../../../models';
import { TranslateModule } from '@ngx-translate/core';
import { UpperCasePipe } from '@angular/common';
import { RouterLinkActive, RouterLink } from '@angular/router';

@Component({
  selector: 'rs-web-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLinkActive, RouterLink, UpperCasePipe, TranslateModule],
})
export class MobileNavigationComponent {
  @Input() navigationItems: NavigationItem[];
}
