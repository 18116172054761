import { gql } from 'apollo-angular';

export const offersFields = `
  edges {
    cursor
    node {
      uuid
      id
      title
      excerpt
      body
      href
      imageUrl
      showInHeader
      sorting
      publishedUntil
    }
  }
  pageInfo {
    hasPreviousPage
    hasNextPage
    startCursor
    endCursor
  }
`;

export const offersQuery = gql`
  query publishedOffers($limit: Int, $offset: Int) {
    publishedOffers (limit: $limit, offset: $offset) {
      ${offersFields}
    }
  }
`;

export const offerFields = `
  id
  title
  text
  publishedAt
  publishedUntil
  imageUrl
  href
`;

export const offerQuery = gql`
  query getOffer($offerId: String!) {
    getOffer (offerId: $offerId) {
      ${offerFields}
    }
  }
`;

export const publishedOffersPublicQuery = gql`
  query publishedOffersPublic($limit: Int, $offset: Int) {
    publishedOffersPublic (limit: $limit, offset: $offset) {
      ${offersFields}
    }
  }
`;
