import { Component } from '@angular/core';
import { FaqPageBaseComponent } from '@resident-nx/shared';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { LoaderWebComponent } from '../../atoms/loader/loader.component';
import {
  NgbAccordionDirective,
  NgbAccordionItem,
  NgbAccordionHeader,
  NgbAccordionToggle,
  NgbAccordionButton,
  NgbCollapse,
  NgbAccordionCollapse,
  NgbAccordionBody,
} from '@ng-bootstrap/ng-bootstrap';
import { IconWebComponent } from '../../atoms/icon/icon.component';

@Component({
  selector: 'rs-web-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  standalone: true,
  imports: [
    IconWebComponent,
    NgbAccordionDirective,
    NgbAccordionItem,
    NgbAccordionHeader,
    NgbAccordionToggle,
    NgbAccordionButton,
    NgbCollapse,
    NgbAccordionCollapse,
    NgbAccordionBody,
    LoaderWebComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class FaqWebComponent extends FaqPageBaseComponent {}
