import { Directive, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';

import {
  ITicketCreationSummary,
  Status,
  WhitelabelImage,
  WhitelabelImageType,
} from '../../../models';
import { RouterService } from '../../../services';
import * as fromTicketsState from '../../../+state/tickets';
import { getTicketSummary } from '../../../+state';

@UntilDestroy()
@Directive()
export class TicketCreationDirectAssignmentsPageBaseComponent implements OnInit {
  public headerTitle: string;
  public whitelabelImage = WhitelabelImage;
  public whitelabelImageType = WhitelabelImageType;
  public status: Status;
  public ticketSummary$: Observable<ITicketCreationSummary> = this.store
    .select(getTicketSummary)
    .pipe(
      filter(response => response !== null),
      untilDestroyed(this)
    );

  constructor(
    private routerService: RouterService,
    private route: ActivatedRoute,
    private store: Store<fromTicketsState.ITicketsState>
  ) {}

  ngOnInit() {
    this.status = this.route.snapshot?.params?.status as Status;
    if (this.status === Status.SUCCESS) {
      this.headerTitle = 'ticket_creation.direct_assignments.success.header_l';
    } else if (this.status === Status.ERROR) {
      this.headerTitle = 'ticket_creation.direct_assignments.error.header_l';
    }
  }

  public goToTicketOverview() {
    this.store.dispatch(fromTicketsState.ClearTicketCreation());
    this.routerService.navigate(['content', 'concern']);
  }
}
