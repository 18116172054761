import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import {
  ButtonSizeEnum,
  ButtonTypeEnum,
  IUserData,
  UserPageBaseComponent,
} from '@resident-nx/shared';
import { ROUTE } from '../../../models';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentDetailsWebComponent } from '../../organisms/payment-details/payment-details.component';
import { UserContactDetailsWebComponent } from '../../organisms/user-contact-details/user-contact-details.component';
import { ProfileDetailsWebComponent } from '../../organisms/profile-details/profile-details.component';
import { ButtonWebComponent } from '../../atoms/button/button.component';
import { TicketingGuardService } from '../../../services';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'rs-web-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ButtonWebComponent,
    ProfileDetailsWebComponent,
    UserContactDetailsWebComponent,
    PaymentDetailsWebComponent,
    TranslateModule,
    AsyncPipe,
  ],
})
export class UserProfileWebComponent extends UserPageBaseComponent {
  @Input() userData: IUserData;
  public buttonType = ButtonTypeEnum;
  public buttonSize = ButtonSizeEnum;
  #ticketingGuard = inject(TicketingGuardService);

  public goToTickets(): void {
    this.#ticketingGuard.setPath(ROUTE.REQUESTS);
    this.routerService.navigate([ROUTE.TICKETS, ROUTE.REQUESTS]);
  }
}
