import { Directive, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { ImmomioIconName } from '../../../models';
import { RouterService } from '../../../services';
import {
  getSelectedContract,
  getTicketSorting,
  LoadActiveRequestTicketsForSelectedContract,
  LoadArchiveRequestTicketsForSelectedContract,
} from '../../../+state';
import { combineLatest, take, tap } from 'rxjs';

@Directive()
export abstract class TicketsOverviewRequestPageBaseComponent {
  public archiveViewOpened = false;
  public immomioIconName = ImmomioIconName;
  @Input() contractId: string;

  constructor(
    protected routerService: RouterService,
    protected route: ActivatedRoute,
    protected store: Store
  ) {}

  protected reloadTickets(): void {
    // TODO: IMPROVEMENT check pagination before reloading
    combineLatest([this.store.select(getSelectedContract), this.store.select(getTicketSorting)])
      .pipe(
        tap(([, sort]) => {
          this.store.dispatch(
            LoadActiveRequestTicketsForSelectedContract({ offset: 0, limit: 20, sort })
          );
          if (this.archiveViewOpened)
            this.store.dispatch(
              LoadArchiveRequestTicketsForSelectedContract({ offset: 0, limit: 20, sort })
            );
        }),
        take(1)
      )
      .subscribe();
  }
}
