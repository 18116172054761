<div class="mobile-navigation-container">
  <div class="d-flex justify-content-around p-t-10 p-b-15">
    @for (item of navigationItems; track item.label) {
      <a
        class="d-flex flex-column align-items-center w-25"
        [routerLink]="item.link"
        routerLinkActive="active-link"
      >
        <i [class]="'d-flex icon icon-' + item.icon"></i>
        <span>{{ item.label | translate | uppercase }}</span>
      </a>
    }
  </div>
</div>
