import { createSelector } from '@ngrx/store';
import { getServiceState } from '../reducers';
import * as fromReducer from './service.reducer';

export const getDownloads = createSelector(getServiceState, fromReducer.getDownloads);
export const getDownloadActionState = createSelector(
  getServiceState,
  fromReducer.getDownloadActionState
);
export const getUploadedFiles = createSelector(getServiceState, fromReducer.getUploadedFiles);
export const getUploadingActionState = createSelector(
  getServiceState,
  fromReducer.getUploadingActionState
);
