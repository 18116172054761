import { Directive, Input } from '@angular/core';
import {
  FormResponseQuestionType,
  ImmomioIconName,
  IMetadataTicketDetailsResponse,
  TicketingVersion,
} from '../../../models';

const DESCRIPTION_MAX_LINES = 2;
const DEFAULT_SHOW_QUESTIONS_COUNT = 3;

@Directive()
export abstract class TicketDetailsOverviewMetadataBaseComponent {
  @Input() ticketDetails: IMetadataTicketDetailsResponse;
  public ticketingVersion = TicketingVersion.METADATA;
  public descriptionMaxLines = DESCRIPTION_MAX_LINES;
  public isImagesLoaded: boolean[] = [];
  public shownAnswersCount = DEFAULT_SHOW_QUESTIONS_COUNT;
  public immomioIconName = ImmomioIconName;
  public formResponseQuestionType = FormResponseQuestionType;

  public showMore(): void {
    this.shownAnswersCount = this.ticketDetails.formResponses?.length;
  }

  public get showMoreButton() {
    return (
      this.ticketDetails.formResponses?.length > DEFAULT_SHOW_QUESTIONS_COUNT &&
      this.shownAnswersCount !== this.ticketDetails.formResponses.length
    );
  }
}
