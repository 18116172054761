import { Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive()
export abstract class NotificationPermissionWidgetBaseComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() toggleState: boolean;
  @Input() showLine?: boolean = false;
  @Input() className?: string;
  @Input() disabled?: boolean = false;
  @Input() isVisisble?: boolean = true;
  @Output() toggleTapped = new EventEmitter<string>();

  abstract tapped(): void;
}
