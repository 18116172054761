import { NgIfContext, NgTemplateOutlet } from '@angular/common';
import { Component, Input, TemplateRef } from '@angular/core';
import { IUserData } from '@resident-nx/shared';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rs-web-user-contact-details',
  templateUrl: './user-contact-details.component.html',
  styleUrls: ['./user-contact-details.component.scss'],
  standalone: true,
  imports: [NgTemplateOutlet, TranslateModule],
})
export class UserContactDetailsWebComponent {
  @Input() userData: IUserData;
  @Input() noData: TemplateRef<NgIfContext<string>>;
}
