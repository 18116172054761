import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { IEnvironment } from '@resident-nx/shared';

@Injectable()
export class NewRelicWebService {
  constructor(
    @Inject(DOCUMENT) private doc: Document,
    @Inject('ENVIRONMENT') private environment: IEnvironment
  ) {}

  public init() {
    const deploymentEnv = this.environment.envName;
    switch (deploymentEnv) {
      case 'integration':
      case 'production': {
        this.injectScript(deploymentEnv);
        break;
      }

      default:
        break;
    }
  }

  private injectScript(path: string) {
    const scriptEl = this.doc.createElement('script');
    scriptEl.type = 'text/javascript';
    scriptEl.src = `new-relic-${path}.js`;
    this.doc.head.appendChild(scriptEl);
  }
}
