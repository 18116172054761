import { createReducer, on } from '@ngrx/store';

import * as fromActions from './notifications.actions';
import { ActionStateCreator, IActionState } from '../../utils';

export interface INotificationsState {
  registerDeviceActionState: IActionState;
  successfulTokenRegistration: boolean;
  unregisterDeviceActionState: IActionState;
}

export const initialState: INotificationsState = {
  registerDeviceActionState: ActionStateCreator.create(),
  successfulTokenRegistration: undefined,
  unregisterDeviceActionState: ActionStateCreator.create(),
};

export const notificationsReducer = createReducer(
  initialState,

  on(fromActions.RegisterDevice, state => {
    return {
      ...state,
      registerDeviceActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.RegisterDeviceSuccess, (state, { success }) => {
    return {
      ...state,
      successfulTokenRegistration: success,
      registerDeviceActionState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.RegisterDeviceFailed, (state, { error }) => {
    return {
      ...state,
      successfulTokenRegistration: false,
      registerDeviceActionState: ActionStateCreator.onError(error),
    };
  }),
  on(fromActions.UnregisterDevice, state => {
    return {
      ...state,
      unregisterDeviceActionState: ActionStateCreator.onStart(),
    };
  }),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  on(fromActions.UnregisterDeviceSuccess, (state, { success }) => {
    return {
      ...state,
      unregisterDeviceActionState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.UnregisterDeviceFailed, (state, { error }) => {
    return {
      ...state,
      unregisterDeviceActionState: ActionStateCreator.onError(error),
    };
  })
);

export const getRegisterDeviceActionState = (state: INotificationsState) =>
  state.registerDeviceActionState;

export const getSuccessfulTokenRegistration = (state: INotificationsState) =>
  state.successfulTokenRegistration;

export const getUnregisterDeviceActionState = (state: INotificationsState) =>
  state.unregisterDeviceActionState;
