import { IResidentNewsContent, IPublishedNews } from '../../../models';

export const newsConverter = {
  fromDto: (response: IResidentNewsContent): IPublishedNews => {
    return {
      uuid: null,
      id: parseInt(response.id),
      title: response.title,
      excerpt: '',
      text: response.text,
      href: response.href,
      imageUrl: response.imageUrl,
      showInHeader: null,
      sorting: null,
      publishedAt: response.publishedAt,
      externalId: null,
    };
  },
};
