<div class="modal-header">
  <strong>{{ 'contracts.account_balance_info_title' | translate }} </strong>
  <rs-web-icon
    (click)="activeModal.dismiss()"
    [icon]="immomioIconName.Close"
    [style.cursor]="'pointer'"
    [size]="16"
  ></rs-web-icon>
</div>
<div class="modal-body">
  <span>{{ text }}</span>
</div>
