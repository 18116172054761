import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { IConsumptionItem } from '../../models';
import { ResolverCommonService } from './resolver-common.service';
import {
  getSelectedPeriodConsumptionForFirstMeter,
  getSelectedPeriodConsumptionActionStateForFirstMeter,
  TriggerLoadConsumptionForAllSelectedMeters,
} from '../../+state';

export const ConsumptionResolver: ResolveFn<IConsumptionItem[]> = route => {
  const common = inject(ResolverCommonService);

  const { resolverWaitForData } = route.data;
  return common.resolveHandler<IConsumptionItem[]>(
    getSelectedPeriodConsumptionActionStateForFirstMeter,
    TriggerLoadConsumptionForAllSelectedMeters(),
    getSelectedPeriodConsumptionForFirstMeter,
    resolverWaitForData
  );
};
