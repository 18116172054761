import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  ICustomerIdentBean,
  ISaasResidentLandlordCustomerResponse,
  IUserSaasResidentAppsResponse,
} from '../../../models';
import { customerIdentQuery, getUserRegisteredSaasResidentAppsQuery } from '../../../queries';

@Injectable()
export class UserAppsFacade {
  constructor(private apollo: Apollo) {}

  public getUserRegisteredSaasResidentApps(): Observable<ISaasResidentLandlordCustomerResponse[]> {
    return this.apollo
      .query<{
        getUserRegisteredSaasResidentApps: IUserSaasResidentAppsResponse;
      }>({
        query: getUserRegisteredSaasResidentAppsQuery,
      })
      .pipe(
        map(response => {
          if (response?.data?.getUserRegisteredSaasResidentApps?.registeredApps) {
            return response.data.getUserRegisteredSaasResidentApps.registeredApps;
          } else if (response?.errors) {
            throw new Error(response.errors[0].message);
          }
          throw new Error();
        }),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }
  public getCustomerIdent(domain: string): Observable<ICustomerIdentBean> {
    return this.apollo
      .query<{ getCustomerIdent: ICustomerIdentBean }>({
        query: customerIdentQuery,
        context: {
          headers: {
            'x-web-portal-domain': domain,
          },
        },
      })
      .pipe(
        map(response => {
          return response?.data?.getCustomerIdent;
        }),
        catchError((error: Error) => {
          throw new Error(error.message);
        })
      );
  }
}
