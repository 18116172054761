/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap } from 'rxjs';

import * as fromActions from './user-profile.actions';
import * as fromFeedbackActions from '../../general/feedback/feedback.actions';
import { AccountUserFacade, ErrorHandlerService, RouterService } from '../../../services';
import { map } from 'rxjs/operators';
import { userProfileConverter } from './user-profile.converter';
import { IConsent } from '../../../models/account/consent.model';

@Injectable()
export class UserProfileEffects {
  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.DeleteUser),
      switchMap(() => {
        return this.userFacade.deleteUser().pipe(
          mergeMap(() => [
            fromActions.DeleteUserSuccess(),
            fromFeedbackActions.ShowFeedbackSuccess({
              message: 'user_profile.delete_user_success_m',
            }),
          ]),
          catchError(error => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on user-profile.effects.ts:deleteUser$:',
              error
            );
            return [
              fromActions.DeleteUserFailed({ error }),
              fromFeedbackActions.ShowFeedbackError({
                message: 'user_profile.delete_user_error_m',
              }),
            ];
          })
        );
      })
    )
  );
  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.ChangePassword),
      switchMap(({ changePasswordPayload }) => {
        return this.userFacade.changePassword(changePasswordPayload).pipe(
          mergeMap(() => {
            this.routerService.navigate(['../']);
            return [
              fromActions.ChangePasswordSuccess(),
              fromFeedbackActions.ShowFeedbackSuccess({
                message: 'user_profile.change_password_success_m',
              }),
            ];
          }),
          catchError(error => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on user-profile.effects.ts:changePassword$:',
              error
            );
            return [
              fromActions.ChangePasswordFailed({ error }),
              fromFeedbackActions.ShowFeedbackError({
                message: 'user_profile.change_password_error_m',
              }),
            ];
          })
        );
      })
    )
  );
  changeEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.ChangeEmail),
      switchMap(({ changeEmailPayload }) => {
        return this.userFacade.changeEmail(changeEmailPayload).pipe(
          mergeMap(() => [
            fromActions.ChangeEmailSuccess(),
            fromFeedbackActions.ShowFeedbackSuccess({
              message: 'user_profile.change_email_success_m',
            }),
          ]),
          catchError(error => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on user-profile.effects.ts:changeEmail$:',
              error
            );
            return [
              fromActions.ChangeEmailFailed({ error }),
              fromFeedbackActions.ShowFeedbackError({
                message: 'user_profile.change_email_error_m',
              }),
            ];
          })
        );
      })
    )
  );
  loadLegalTexts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadLegalTexts),
      switchMap(() => {
        return this.userFacade.getLegalTexts().pipe(
          map(response => fromActions.LoadLegalTextsSuccess({ legalTexts: response })),
          catchError(error => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on user-profile.effects.ts:loadLegalTexts$:',
              error
            );
            return [
              fromActions.LoadLegalTextsFailed({ error }),
              fromFeedbackActions.ShowFeedbackError({
                message: 'user_profile.load_legal_texts_error_m',
              }),
            ];
          })
        );
      })
    )
  );
  loadPersonalData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadPersonalData),
      switchMap(() => {
        return this.userFacade.getPersonalData().pipe(
          map(userProfileConverter.fromDto),
          mergeMap(personalData => [fromActions.LoadPersonalDataSuccess({ personalData })]),
          catchError(error => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on user-profile.effects.ts:loadPersonalData$:',
              error
            );
            return [
              fromActions.LoadPersonalDataFailed({ error }),
              fromFeedbackActions.ShowFeedbackError({
                message: 'user_profile.load_personal_data_error_m',
              }),
            ];
          })
        );
      })
    )
  );
  loadConsent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.GetConsent),
      switchMap(action =>
        this.userFacade.getConsent(action.consentType).pipe(
          map((response: IConsent) => {
            return fromActions.GetConsentSuccess({ response });
          }),
          catchError((error: Error) => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on user-profile.effects.ts:loadConsent$:',
              error
            );
            return [fromActions.GetConsentFailed({ error })];
          })
        )
      )
    )
  );
  setConsent = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.SetConsent),
      switchMap(action =>
        this.userFacade.setConsent(action.consentType, action.consentId).pipe(
          map((response: boolean) => {
            return fromActions.SetConsentSuccess({ response });
          }),
          catchError((error: Error) => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on user-profile.effects.ts:setConsent:',
              error
            );
            return [fromActions.SetConsentFailed({ error })];
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private userFacade: AccountUserFacade,
    private routerService: RouterService,
    private errorHandlerService: ErrorHandlerService
  ) {}
}
