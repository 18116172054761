import { createReducer, on } from '@ngrx/store';
import { IPageInfo, IPublishedOffer, IPublishedOffersEdge } from '../../../models';
import { ActionStateCreator, IActionState, updateStateList } from '../../../utils';
import * as fromActions from './offers.actions';

export interface IOffersState {
  offers: IPublishedOffersEdge[];
  hasNextPage: boolean;
  offersActionState: IActionState;
  offer: IPublishedOffer;
  offerActionState: IActionState;
  pageInfo: IPageInfo;
}

export const initialState: IOffersState = {
  offers: [],
  hasNextPage: false,
  offersActionState: ActionStateCreator.create(),
  offer: null,
  offerActionState: ActionStateCreator.create(),
  pageInfo: null,
};

export const offersReducer = createReducer(
  initialState,

  on(fromActions.LoadOffers, state => {
    return {
      ...state,
      offersActionState: ActionStateCreator.onStart(),
    };
  }),

  on(fromActions.LoadOffersSuccess, (state, { response, oldState }) => {
    if (oldState) {
      return {
        ...state,
        offersActionState: ActionStateCreator.onSuccess(),
        offers: response.edges,
        hasNextPage: response.pageInfo?.hasNextPage,
        pageInfo: response.pageInfo,
      };
    }
    return {
      ...state,
      offersActionState: ActionStateCreator.onSuccess(),
      offers: response.pageInfo?.hasPreviousPage
        ? state.offers.concat(response.edges)
        : response.edges,
      hasNextPage: response.pageInfo?.hasNextPage,
      pageInfo: response.pageInfo,
    };
  }),

  on(fromActions.LoadOffersFailed, (state, { error }) => {
    return {
      ...state,
      offersActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadOffer, state => {
    return {
      ...state,
      offer: null,
      offerActionState: ActionStateCreator.onStart(),
    };
  }),

  on(fromActions.LoadOfferSuccess, (state, { response }) => {
    return {
      ...state,
      offers: updateStateList(state.offers, response),
      offerActionState: ActionStateCreator.onSuccess(),
      offer: response,
    };
  }),

  on(fromActions.LoadOfferFailed, (state, { error }) => {
    return {
      ...state,
      offerActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadPublishedOffersPublic, state => {
    return {
      ...state,
      offersActionState: ActionStateCreator.onStart(),
    };
  }),

  on(fromActions.LoadPublishedOffersPublicSuccess, (state, { response }) => {
    return {
      ...state,
      offersActionState: ActionStateCreator.onSuccess(),
      offers: response.pageInfo?.hasPreviousPage
        ? state.offers.concat(response.edges)
        : response.edges,
      hasNextPage: response.pageInfo?.hasNextPage,
    };
  }),

  on(fromActions.LoadPublishedOffersPublicFailed, (state, { error }) => {
    return {
      ...state,
      offersActionState: ActionStateCreator.onError(error),
    };
  })
);

export const getOffers = (state: IOffersState) => state.offers;
export const hasNextPage = (state: IOffersState) => state.hasNextPage;
export const getLatestOffers = (state: IOffersState) => state.offers.slice(0, 5);
export const getOffersActionState = (state: IOffersState) => state.offersActionState;
export const getOffer = (state: IOffersState) => state.offer;
export const getOfferActionState = (state: IOffersState) => state.offerActionState;
export const getOffersPageInfo = (state: IOffersState) => state.pageInfo;
