import { createSelector } from '@ngrx/store';

import { getHideWelcomeScreenState } from '../../reducers';
import * as fromReducer from './welcome_screen.reducer';

export const getHideWelcomeScreen = createSelector(
  getHideWelcomeScreenState,
  fromReducer.getHideWelcomeScreen
);
export const getHideWelcomeScreenActionState = createSelector(
  getHideWelcomeScreenState,
  fromReducer.getHideWelcomeScreenActionState
);
