import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { LoadingSkeletonBaseComponent, LoadingType } from '@resident-nx/shared';

import { CardWebComponent } from '../card/card.component';

@Component({
  selector: 'rs-web-loading-skeleton',
  standalone: true,
  templateUrl: './loading-skeleton.component.html',
  styleUrl: './loading-skeleton.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CardWebComponent],
})
export class LoadingSkeletonWebComponent extends LoadingSkeletonBaseComponent {
  screenWidth: number = window.innerWidth;
  protected readonly LoadingType = LoadingType;
  protected multipleCardLength = Array(4).fill(1);

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onResize(event: any) {
    this.screenWidth = window.innerWidth;
  }
}
