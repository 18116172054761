import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { INotificationEmailSettings } from '../../models';
import { notificationSettingsMutation, notificationSettingsQuery } from '../../queries';

@Injectable()
export class NotificationSettingsFacade {
  constructor(private apollo: Apollo) {}

  public getNotificationSettings(): Observable<INotificationEmailSettings> {
    return this.apollo
      .query<{ getPushNotificationSettings: INotificationEmailSettings }>({
        query: notificationSettingsQuery,
        errorPolicy: 'all',
      })
      .pipe(
        map(response => {
          return response?.data?.getPushNotificationSettings;
        })
      );
  }

  public updateNotificationSettings(input: INotificationEmailSettings) {
    return this.apollo
      .mutate<{ updatePushNotificationSettings: INotificationEmailSettings }>({
        mutation: notificationSettingsMutation,
        variables: {
          input,
        },
      })
      .pipe(map(response => response.data?.updatePushNotificationSettings));
  }
}
