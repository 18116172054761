import { IResidentS3File } from '../general';

export enum THEME_VARIABLES {
  PRIMARY = '--color-primary',
  PRIMARY_HOVER = '--color-primary-hover',
  SECONDARY = '--color-secondary',
  SECONDARY_HOVER = '--color-secondary-hover',
  SURFACE = '--color-surface',
  SURFACE_HOVER = '--color-surface-hover',
  BACKGROUND_LIGHT = '--color-background-light',
  BACKGROUND_MEDIUM = '--color-background-medium',
  TEXT_PRIMARY = '--color-text-primary',
  TEXT_SECONDARY = '--color-text-secondary',
  TEXT_BUTTON_PRIMARY = '--color-text-button-primary',
  TEXT_BUTTON_SECONDARY = '--color-text-button-secondary',
}

export type HexColorCode = string;

export interface ITheme {
  colorPrimary: HexColorCode; // whiteLabel
  colorPrimaryHover: HexColorCode; // inherit from whiteLabel
  colorSecondary: HexColorCode; // whiteLabel
  colorSecondaryHover: HexColorCode; // inherit from whiteLabel
  colorSurface: HexColorCode; // whiteLabel
  colorSurfaceHover: HexColorCode; // inherit from whiteLabel
  colorBackgroundLight: HexColorCode; // whiteLabel
  colorBackgroundMedium: HexColorCode; // inherit from whiteLabel
  colorTextPrimary: HexColorCode; // whiteLabel
  colorTextSecondary: HexColorCode; // whiteLabel
  colorTextButtonPrimary: HexColorCode; // #fff or primaryTextColor depending on primary color
  colorTextButtonSecondary: HexColorCode; // #fff or primaryTextColor depending on secondary color
}

export interface ILandlordCustomerBrandingTheme {
  primaryColor: HexColorCode;
  secondaryColor: HexColorCode;
  primaryTextColor: HexColorCode;
  secondaryTextColor: HexColorCode;
  buttonTextColor: HexColorCode; // not used
  backgroundColor: HexColorCode;
  cardBackgroundColor: HexColorCode;
}

export interface IAppSettingsResponse {
  residentAppBranding: {
    residentAppLogo: IResidentS3File;
  };
  theme: ILandlordCustomerBrandingTheme;
}

export interface IAppSettings {
  theme: ITheme;
  logo: IResidentS3File | null;
}

export interface ICustomerIdentBean {
  customerIdent: string;
}
