import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { of } from 'rxjs';
import { IFederatedLoginEnvironmentVariables, StorageService } from '@resident-nx/shared';
import { AngularKeycloakService } from '../authentication/angular-keycloak.service';

export const AuthWebGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const sessionStorage = inject(StorageService);
  const keycloakAngularService = inject(AngularKeycloakService);

  sessionStorage.setItem('path-after-auth', {
    pathAfterAuth: state.url.split('?')[0],
    queryParams: route.queryParams,
  });

  const clientId = sessionStorage.getItem(IFederatedLoginEnvironmentVariables.CLIENT_ID);

  if (!clientId) {
    void router.navigate(['login']);
  } else {
    if (!keycloakAngularService.getKeycloakInstance()) {
      return keycloakAngularService.initializeKeycloak();
    }
    return of(true);
  }
  return of(false);
};
