import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { filter, first, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  ImmomioIconName,
  IResidentAppSettings,
  ITicketCreationSummary,
  ScoreQuestionType,
  Status,
  TicketingVersion,
  TicketIssueType,
  TicketStatus,
} from '../../../models';
import { IActionState } from '../../../utils';
import { RouterService, ticketHierarchicalQuestionsConverter } from '../../../services';
import * as fromTicketsState from '../../../+state/tickets';
import * as fromServiceState from '../../../+state/service';
import { CreateTicketSummary, getResidentAppSettings } from '../../../+state';
import { ticketMetadataConverter } from '../../../services/tickets/ticket-metadata.converter';

@UntilDestroy()
@Directive()
export class TicketSummaryBaseComponent implements OnInit {
  @Input() ticketSummary: ITicketCreationSummary;
  @Output() back = new EventEmitter();
  public ticketResponseActionState$: Observable<IActionState>;
  public headerTitle: string;
  public ticketSummaryTitle: string;
  public displayDate = new Date();
  public ticketStatus = TicketStatus;
  public immomioIconName = ImmomioIconName;
  public scoreQuestionType = ScoreQuestionType;
  public issueType: TicketIssueType;
  public uploadingActionState$: Observable<IActionState>;
  public createTicketMetadataActionState$: Observable<IActionState>;
  public settings: IResidentAppSettings;

  constructor(
    public store: Store<fromTicketsState.ITicketsState>,
    public serviceStore: Store<fromServiceState.IServiceState>,
    public routerService: RouterService,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.store
      .select(getResidentAppSettings)
      .pipe(untilDestroyed(this))
      .subscribe(settings => {
        this.settings = settings;
      });
    this.issueType = this.route.snapshot?.params?.type;
    if (this.issueType === TicketIssueType.PROPERTY_DAMAGE) {
      this.headerTitle = 'ticket_damage.create_new_l';
      this.ticketSummaryTitle = 'ticket_summary.details_damage_l';
    } else if (this.issueType === TicketIssueType.CONCERN) {
      this.headerTitle = 'ticket_request.create_new_l';
      this.ticketSummaryTitle = 'ticket_summary.details_request_l';
    }

    this.ticketResponseActionState$ = this.store.select(
      fromTicketsState.getCreateTicketHQActionState
    );

    this.uploadingActionState$ = this.store.select(fromServiceState.getUploadingActionState);
    if (this.settings.ticketingVersion === TicketingVersion.METADATA) {
      this.createTicketMetadataActionState$ = this.store.select(
        fromTicketsState.getCreateTicketMetadataActionState
      );
    }
  }

  public goBack() {
    this.back.emit();
  }

  public createTicket(ticketSummary: ITicketCreationSummary): void {
    this.store.dispatch(CreateTicketSummary({ ticketSummary }));
    if (this.settings?.ticketingVersion === TicketingVersion.METADATA) {
      this.createTicketMetadata(ticketSummary);
    } else {
      this.createTicketHQ(ticketSummary);
    }
  }

  private createTicketHQ(ticketSummary: ITicketCreationSummary): void {
    const hasAttachmentQuestion = ticketSummary.detailedResponses.some(
      el => el.type === ScoreQuestionType.INPUT_ATTACHMENTS && el.displaySummary
    );
    if (hasAttachmentQuestion) {
      this.uploadAttachmentsAndCreateTicket(ticketSummary);
    } else {
      this.createTicketWithoutAttachments(ticketSummary);
    }
  }

  private createTicketMetadata(ticketSummary: ITicketCreationSummary): void {
    const newTicket = ticketMetadataConverter.formatCreateTicketRequest(
      ticketSummary,
      this.issueType
    );
    this.store.dispatch(fromTicketsState.CreateTicketMetadata({ newTicketMetadata: newTicket }));
    this.handleCreateTicketMetadataResponse();
  }

  private uploadAttachmentsAndCreateTicket(ticketSummary: ITicketCreationSummary): void {
    const filePaths = ticketSummary.detailedResponses
      .filter(
        el => el.type === ScoreQuestionType.INPUT_ATTACHMENTS && el.displaySummary?.attachments
      )
      .map(attachmentQuestion =>
        attachmentQuestion.displaySummary.attachments?.map(el => el.filePath)
      )
      .reduce((acc, val) => acc.concat(val), []);

    if (filePaths.length) {
      this.serviceStore.dispatch(fromServiceState.UploadFiles({ filePaths }));

      this.serviceStore
        .select(fromServiceState.getUploadedFiles)
        .pipe(
          untilDestroyed(this),
          filter(files => !!files.length),
          first()
        )
        .subscribe(files => {
          const newTicket = ticketHierarchicalQuestionsConverter.formatCreateTicketRequest(
            ticketSummary,
            this.issueType,
            files
          );
          this.store.dispatch(fromTicketsState.CreateTicketHQ({ newTicketHQ: newTicket }));
          this.handleCreateTicketHQResponse();
        });
    } else {
      // Todo: add a better handling to inform the user/qa thant the configuration of the questions are probably wrong
      console.error('no filepath found -> image(s) could not be uploaded');
      this.createTicketWithoutAttachments(ticketSummary);
    }
  }

  private createTicketWithoutAttachments(ticketSummary: ITicketCreationSummary) {
    const newTicket = ticketHierarchicalQuestionsConverter.formatCreateTicketRequest(
      ticketSummary,
      this.issueType
    );
    this.store.dispatch(fromTicketsState.CreateTicketHQ({ newTicketHQ: newTicket }));
    this.handleCreateTicketHQResponse();
  }

  private handleCreateTicketHQResponse(): void {
    this.store
      .select(fromTicketsState.getCreateTicketHQActionState)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.redirectAfterRequest(res);
      });
  }

  private handleCreateTicketMetadataResponse(): void {
    this.store
      .select(fromTicketsState.getCreateTicketMetadataActionState)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.redirectAfterRequest(res);
      });
  }

  private redirectAfterRequest(state: IActionState): void {
    if (state.done) {
      this.serviceStore.dispatch(fromServiceState.ClearUploadedFiles());

      void this.routerService.navigate(['../../ticket-creation-success', this.issueType], {
        relativeTo: this.route,
      });
    } else if (state.error) {
      void this.routerService.navigate(['../../ticket-creation-error', this.issueType], {
        relativeTo: this.route,
      });
    }
  }
}
