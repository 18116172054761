<div class="offer-overview container container--with-spacing">
  <div class="d-flex align-items-center">
    <rs-web-breadcrumbs
      [items]="breadcrumbs"
      (navigateBack)="onNavigate()"
      (navigateToUrl)="onNavigate($event)"
    ></rs-web-breadcrumbs>
  </div>
  <div class="row">
    <span class="title-xxl col offer-overview__header">{{ 'news.offers_l' | translate }}</span>
  </div>
  <div class="row gy-4">
    @for (offer of offers$ | async; track offer.node.id; let i = $index) {
      @if ((token$ | async) && i === 0) {
        <rs-web-content-card
          class="col-sm-6 col-lg-2"
          [image]="'/web/images/homepage-card.svg'"
          [title]="'apartments'"
          [text]="'to_apartments'"
          [preset]="presets.OFFER"
          [textOverflow]="false"
          (click)="onTapHomepage()"
        >
        </rs-web-content-card>
      }
      <rs-web-content-card
        class="col-sm-6 col-lg-3 col-xl-2"
        [id]="offer.node.id"
        [image]="offer.node.imageUrl"
        [title]="offer.node.title"
        [text]="offer.node.body"
        [preset]="presets.OFFER"
        (tapped)="onTapArticle($event)"
      ></rs-web-content-card>
    }
  </div>
  <rs-web-infinite-scroll (scrolled)="onScroll()"></rs-web-infinite-scroll>
  @if ((offersActionState$ | async).pending) {
    <div class="d-flex justify-content-center m-5">
      <rs-web-loader></rs-web-loader>
    </div>
  }
</div>
