<div class="h-100" [class.d-flex]="centerImage">
  <picture
    [class]="{
      'center-picture': centerImage,
      small: centerImageSize === centerImageSizes.S,
      medium: centerImageSize === centerImageSizes.M,
    }"
  >
    @for (source of sources; track source.srcSet) {
      <source
        [attr.media]="getMediaQuery(source.minScreenWidth, source.maxScreenWidth)"
        [attr.srcset]="getSrcSet(source.srcSet)"
      />
    }
    <img
      [class.center-img]="centerImage"
      [class]="getClassName()"
      [src]="fileReader ? fileReaderFile() : defaultSrc"
      [alt]="alt"
      [attr.loading]="lazyLoading ? 'lazy' : null"
      [style.maxHeight]="setMaxHeight ? maxHeightInPx + 'px' : ''"
      itemprop="image"
      [class.data-table-cell-styles]="dataTableCellPicture"
    />
  </picture>
</div>
