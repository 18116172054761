import { DatePipe } from '@angular/common';
import { Directive, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive()
export abstract class CardFooterBaseComponent {
  @Input() className?: string;

  constructor(
    protected translate: TranslateService,
    protected datePipe: DatePipe
  ) {}

  protected setTimeAgo(publishedAt: number): string {
    const now = new Date().getTime() / 1000;
    const timeDifference = now - publishedAt;
    if (timeDifference < 59) {
      return this.translate.instant('announcements.time_ago_now');
    } else if (timeDifference < 3600) {
      const minutes = Math.floor(timeDifference / 60);
      return (
        this.translate.instant('announcements.time_ago_prefix') +
        ' ' +
        minutes +
        ' ' +
        this.translate.instant('announcements.time_ago_minutes')
      );
    } else if (timeDifference < 86400) {
      const hours = Math.floor(timeDifference / 3600);
      return (
        this.translate.instant('announcements.time_ago_prefix') +
        ' ' +
        hours +
        ' ' +
        this.translate.instant('announcements.time_ago_hours')
      );
    } else if (timeDifference < 604800) {
      const days = Math.floor(timeDifference / 86400);
      return (
        this.translate.instant('announcements.time_ago_prefix') +
        ' ' +
        days +
        ' ' +
        this.translate.instant('announcements.time_ago_days')
      );
    } else {
      return this.datePipe.transform(publishedAt + '000', 'EEEEEE dd.MM.yyyy') || '';
    }
  }
}
