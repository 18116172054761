import { createSelector } from '@ngrx/store';
import { getNotificationPopupsState } from '../../reducers';
import * as fromReducer from './popup-notifications.reducers';

export const getShowAddressChangePopup = createSelector(
  getNotificationPopupsState,
  fromReducer.getShowAddressChangePopup
);
export const getShowAddressChangePopupActionState = createSelector(
  getNotificationPopupsState,
  fromReducer.getShowAddressChangePopupActionState
);
