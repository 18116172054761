<div
  rsWebElevation
  [elevation]="elevation"
  [clickable]="hover"
  [class]="[
    'card',
    borderStyle ? 'card--border-style-' + borderStyle : '',
    borderRadius ? 'card--border-radius-' + borderRadius : '',
    withoutPadding ? 'card--without-padding' : '',
    overflowHidden ? 'card--overflow-hidden' : '',
    hover ? 'card--hover' : '',
  ]"
  (click)="click()"
>
  <ng-content></ng-content>
</div>
