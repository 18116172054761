import { createAction, props } from '@ngrx/store';
import { ContractId, IEmergencyContactsResponse } from '../../../models';

export const EmergencyContactsExists = createAction('[Account Emergency Contacts] Exist');

export const EmergencyContactsExistsSuccess = createAction(
  '[Account Emergency Contacts] Exist Success',
  props<{ emergencyContactExists: boolean }>()
);

export const EmergencyContactsExistsFailed = createAction(
  '[Account Emergency Contacts] Exist Failed',
  props<{ error: Error }>()
);

export const GetEmergencyContacts = createAction(
  '[Account Emergency Contacts] Get Emergency Contacts',
  props<{ contract: ContractId }>()
);

export const GetEmergencyContactsSuccess = createAction(
  '[Account Emergency Contacts] Get Emergency Contacts Success',
  props<{ emergencyContactsResponse: IEmergencyContactsResponse; contract: ContractId }>()
);

export const GetEmergencyContactsFailed = createAction(
  '[Account Emergency Contacts] Get Emergency Contacts Failed',
  props<{ contract: ContractId; error: Error }>()
);
