import { Component, Input } from '@angular/core';
import { IPublishedOffer } from '@resident-nx/shared';
import { LabelWebComponent } from '../../atoms/label/label.component';
import { PictureWebComponent } from '../../atoms/picture/picture.component';

@Component({
  selector: 'rs-web-offers-card',
  templateUrl: './offers-card.component.html',
  styleUrls: ['./offers-card.component.scss'],
  standalone: true,
  imports: [PictureWebComponent, LabelWebComponent],
})
export class OffersCardWebComponent {
  @Input() item: IPublishedOffer;
}
