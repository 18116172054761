import { createReducer, on } from '@ngrx/store';
import { IPageInfo, IPublishedNews, IPublishedNewsEdge } from '../../../models';
import { ActionStateCreator, IActionState, updateStateList } from '../../../utils';
import * as fromActions from './news.actions';

export interface INewsState {
  news: IPublishedNewsEdge[];
  hasNextPage: boolean;
  newsActionState: IActionState;
  singleNews: IPublishedNews;
  singleNewsActionState: IActionState;
  pageInfo: IPageInfo;
}

export const initialState: INewsState = {
  news: [],
  hasNextPage: false,
  newsActionState: ActionStateCreator.create(),
  singleNews: null,
  singleNewsActionState: ActionStateCreator.create(),
  pageInfo: null,
};

export const newsReducer = createReducer(
  initialState,

  on(fromActions.LoadNews, state => {
    return {
      ...state,
      newsActionState: ActionStateCreator.onStart(),
    };
  }),

  on(fromActions.LoadNewsSuccess, (state, { response, oldState }) => {
    if (oldState) {
      return {
        ...state,
        newsActionState: ActionStateCreator.onSuccess(),
        news: response.edges,
        hasNextPage: response.pageInfo?.hasNextPage,
        pageInfo: response.pageInfo,
      };
    }
    return {
      ...state,
      newsActionState: ActionStateCreator.onSuccess(),
      news: response.pageInfo?.hasPreviousPage ? state.news.concat(response.edges) : response.edges,
      hasNextPage: response.pageInfo?.hasNextPage,
      pageInfo: response.pageInfo,
    };
  }),

  on(fromActions.LoadNewsFailed, (state, { error }) => {
    return {
      ...state,
      newsActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadSingleNews, state => {
    return {
      ...state,
      singleNews: null,
      singleNewsActionState: ActionStateCreator.onStart(),
    };
  }),

  on(fromActions.LoadSingleNewsSuccess, (state, { response }) => {
    return {
      ...state,
      news: updateStateList(state.news, response),
      singleNewsActionState: ActionStateCreator.onSuccess(),
      singleNews: response,
    };
  }),

  on(fromActions.LoadSingleNewsFailed, (state, { error }) => {
    return {
      ...state,
      singleNewsActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadPublishedNewsPublic, state => {
    return {
      ...state,
      newsActionState: ActionStateCreator.onStart(),
    };
  }),

  on(fromActions.LoadPublishedNewsPublicSuccess, (state, { response }) => {
    return {
      ...state,
      newsActionState: ActionStateCreator.onSuccess(),
      news: response.pageInfo?.hasPreviousPage ? state.news.concat(response.edges) : response.edges,
      hasNextPage: response.pageInfo?.hasNextPage,
    };
  }),

  on(fromActions.LoadPublishedNewsPublicFailed, (state, { error }) => {
    return {
      ...state,
      newsActionState: ActionStateCreator.onError(error),
    };
  })
);

export const getNews = (state: INewsState) => state.news;
export const hasNextPage = (state: INewsState) => state.hasNextPage;
export const getLatestNews = (state: INewsState) => state.news.slice(0, 3);
export const getNewsActionState = (state: INewsState) => state.newsActionState;
export const getSingleNews = (state: INewsState) => state.singleNews;
export const getSingleNewsActionState = (state: INewsState) => state.singleNewsActionState;
export const getNewsPageInfo = (state: INewsState) => state.pageInfo;
