import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { catchError, map, Observable } from 'rxjs';
import {
  IFaq,
  IFaqOverviewResponseBean,
  IPublishedFaqsResponseBean,
} from '../../models/support/faq.model';
import { getFaqOverviewQuery, publishedFaqsQuery } from '../../queries';

@Injectable()
export class SupportFacade {
  constructor(private apollo: Apollo) {}

  public getPublishedFaqs(): Observable<IFaq[]> {
    return this.apollo
      .query<{ publishedFaqs: IPublishedFaqsResponseBean }>({
        query: publishedFaqsQuery,
      })
      .pipe(
        map(response => {
          return response?.data?.publishedFaqs.edges.map(edge => edge.node);
        }),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }

  public getFaqOverview(): Observable<IFaqOverviewResponseBean> {
    return this.apollo
      .query<{ getFaqOverview: IFaqOverviewResponseBean }>({
        query: getFaqOverviewQuery,
      })
      .pipe(
        map(response => {
          return response?.data?.getFaqOverview;
        }),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }
}
