@if (emergencyContactsByContract$ | async; as emergencyContactsByContract) {
  @if (emergencyContactsByContract[contract]; as emergencyContactsResponse) {
    @if (emergencyContactsResponse?.contacts?.length) {
      <div class="m-t-28">
        <rs-web-cards-container-header
          [icon]="immomioIconName.ContactList"
          [title]="'contract.emergency_contact_l' | translate"
        ></rs-web-cards-container-header>
        <div [closeOthers]="true" class="m-t-16" ngbAccordion>
          @for (contact of emergencyContactsResponse.contacts; track $index) {
            <div class="m-b-16" ngbAccordionItem>
              <div ngbAccordionHeader>
                <button ngbAccordionButton>
                  <div class="d-flex">
                    <div class="icon icon-container">
                      <rs-web-icon [icon]="immomioIconName.User" [size]="24"></rs-web-icon>
                    </div>
                    <div class="contact-container">
                      <div class="primary-text">{{ contact.position }}</div>
                      <div class="secondary-text">{{ contact.name }}</div>
                    </div>
                  </div>
                  <div class="chevron-icon">
                    <rs-web-icon [icon]="immomioIconName.ChevronDown" [size]="12.5"></rs-web-icon>
                  </div>
                </button>
              </div>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <rs-web-contact-details [contact]="contact"></rs-web-contact-details>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    }
  }
}
@if (emergencyContactActionState$ | async; as emergencyContactActionState) {
  @if (emergencyContactActionState[contract]?.pending) {
    <rs-web-loader [centered]="true"></rs-web-loader>
  }
}
