import { Directive, Input, OnInit } from '@angular/core';
import { ChartOrientation, HexColorCode, IChartData, IContract } from '../../models';

@Directive()
export class PieChartBaseComponent implements OnInit {
  @Input() data: IChartData;
  @Input() orientation = ChartOrientation.VERTICAL;
  @Input() caption: string;
  @Input() height = 150;
  @Input() width = 150;
  @Input() valuesAreMoney = false;
  @Input() decimalPipeSettings = {
    digitsInfo: '1.0-2',
    locale: 'de',
  };
  @Input() contract: IContract;
  @Input() totalRent: number = null;

  protected disabledColor: HexColorCode = '#CED2D5';
  public ngOnInit() {
    if (!this.data?.set?.length) {
      // To show empty pie chart
      this.data?.set?.push({
        label: null,
        amount: 1,
        color: this.disabledColor,
      });
    }
  }
}
