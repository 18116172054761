import { IPageInfo } from '../general';

export interface IPublishedNewsResponse {
  edges: IPublishedNewsEdge[];
  pageInfo: IPageInfo;
}

export interface IPublishedNewsEdge {
  cursor: string;
  node: IPublishedNews;
}
export interface IPublishedNews {
  uuid: string | null;
  id: number;
  title: string;
  excerpt: string;
  text: string;
  href: string;
  imageUrl: string;
  showInHeader: boolean;
  sorting: number | null;
  externalId: string | null;
  publishedAt: string | null;
}
export interface IResidentNewsContent {
  id: string;
  title: string;
  text: string;
  publishedAt: string;
  imageUrl: string;
  href: string;
}
