import { Directive, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { filter, map, Observable } from 'rxjs';
import {
  GetEmergencyContacts,
  getEmergencyContactsActionState,
  getEmergencyContactsByContract,
} from '../../+state';
import * as fromMasterDataState from '../../+state/account/masterdata';
import { ContractId, IContactPersonResponse, TicketIssueType } from '../../models';
import { IActionState } from '../../utils';

@Directive()
export abstract class SelectContactBaseComponent implements OnInit {
  public emergencyContacts$: Observable<IContactPersonResponse[]>;
  public emergencyContactsActionState$: Observable<IActionState>;
  public issueType: TicketIssueType | undefined;

  constructor(
    public store: Store<fromMasterDataState.IMasterDataState>,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.issueType = this.route.snapshot?.params?.type;
    const contractId: ContractId = this.route.snapshot?.params?.contractId;

    if (!contractId) {
      this.store.select(fromMasterDataState.getSelectedContract).subscribe(contract => {
        this.store.dispatch(GetEmergencyContacts({ contract: contract.id }));
        this.loadContactsForContract(contract.id);
      });
    } else {
      this.store.dispatch(GetEmergencyContacts({ contract: contractId }));
      this.loadContactsForContract(contractId);
    }
  }

  private loadContactsForContract(contractId: ContractId) {
    this.emergencyContactsActionState$ = this.store.select(getEmergencyContactsActionState).pipe(
      filter(contactResultState => contactResultState !== null),
      map(contactResultState => contactResultState[contractId]),
      untilDestroyed(this)
    );

    this.emergencyContacts$ = this.store.select(getEmergencyContactsByContract).pipe(
      filter(contactsResult => contactsResult !== null),
      map(contactsResult => contactsResult[contractId]?.contacts),
      untilDestroyed(this)
    );
  }
}
