<div class="h-100">
  @if (selectedConsumption$ | async; as selectedConsumption) {
    @if (selectedConsumption.consumption?.amount && selectedConsumption.benchmark?.amount) {
      <div class="h-100 d-flex flex-column">
        <span class="title">
          {{ 'consumption_comparison.title_l' | translate }}
          {{ selectedConsumption.consumption?.period?.start | date: dateFormat }}
        </span>
        <div class="mt-4 d-flex flex-column flex-fill justify-content-between">
          <div class="d-flex flex-column">
            <rs-web-consumption-row
              [title]="'consumption_comparison.current_l' | translate"
              [width]="backgroundWidths.consumption"
              [type]="ComparisonType.CURRENT"
              [amount]="selectedConsumption.consumption?.amount"
              [unit]="meter.type | consumptionUnitTypePipe"
              [consumptionType]="meter.type"
              [status]="comparisonStatus"
            ></rs-web-consumption-row>
            @if (selectedConsumption.pastConsumption?.amount) {
              <rs-web-consumption-row
                [title]="'consumption_comparison.past_l' | translate"
                [width]="backgroundWidths.pastConsumption"
                [type]="ComparisonType.PAST"
                [amount]="selectedConsumption.pastConsumption?.amount"
                [consumptionType]="meter.type"
              ></rs-web-consumption-row>
            }
            <rs-web-consumption-row
              [title]="'consumption_comparison.benchmark_l' | translate"
              [width]="backgroundWidths.benchmark"
              [type]="ComparisonType.BENCHMARK"
              [amount]="selectedConsumption.benchmark?.amount"
              [consumptionType]="meter.type"
            ></rs-web-consumption-row>
          </div>
          <div class="d-flex flex-column">
            <div class="d-flex flex-row align-items-center">
              <div
                [ngClass]="{
                  'legend-bar__year__consumption': timeframe === ConsumptionTimeframe.YEAR,
                  'legend-bar__month__consumption': timeframe === ConsumptionTimeframe.MONTH,
                }"
              ></div>
              <span class="title-xs secondary-text">
                {{ selectedConsumption.consumption?.period?.start | date: 'yyyy' }}
              </span>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div
                [ngClass]="{
                  'legend-bar__year__past-consumption': timeframe === ConsumptionTimeframe.YEAR,
                  'legend-bar__month__past-consumption': timeframe === ConsumptionTimeframe.MONTH,
                }"
              ></div>
              <span class="title-xs secondary-text">
                {{ selectedConsumption.pastConsumption?.period?.start | date: 'yyyy' }}
              </span>
            </div>
          </div>
        </div>
      </div>
    } @else {
      <div class="h-100 d-flex flex-column justify-content-center">
        <p class="text-secondary title-xs text-center">
          {{ 'consumption_comparison.no_data_l' | translate }}
        </p>
      </div>
    }
  } @else {
    <div class="h-100 d-flex flex-column justify-content-center">
      <p class="text-secondary title-xs text-center">
        {{ 'consumption_comparison.no_data_l' | translate }}
      </p>
    </div>
  }
</div>
