export enum LoadingType {
  HEADER = 'HEADER',
  HEADEROPTION = 'HEADEROPTION',
  WIDGET = 'WIDGET',
  COMPONENT = 'COMPONENT',
  BUTTON = 'BUTTON',
  IMAGECARD = 'IMAGECARD',
  CARD = 'CARD',
  MULTIPLECARD = 'MULTIPLECARD',
  CONSUMPTION = 'CONSUMPTION',
  TICKETCARD = 'TICKETCARD',
  CONTACT = 'CONTACT',
  CONTRACT = 'CONTRACT',
  PAYMENT = 'PAYMENT',
  DOCUMENT = 'DOCUMENT',
}
