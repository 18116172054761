import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { IPublishedNewsResponse, IResidentNewsContent } from '../../models';
import { newsQuery, publishedNewsPublicQuery, singleNewsQuery } from '../../queries';

@Injectable()
export class NewsFacade {
  constructor(private apollo: Apollo) {}

  public getNews(offset: number, limit: number): Observable<IPublishedNewsResponse> {
    return this.apollo
      .query<{ publishedNews: IPublishedNewsResponse }>({
        query: newsQuery,
        variables: {
          offset,
          limit,
        },
      })
      .pipe(map(response => response?.data?.publishedNews));
  }

  public getSingleNews(newsId: string): Observable<IResidentNewsContent> {
    return this.apollo
      .query<{ getNews: IResidentNewsContent }>({
        query: singleNewsQuery,
        variables: {
          newsId,
        },
      })
      .pipe(map(response => response?.data?.getNews));
  }

  public getPublishedNewsPublic(offset: number, limit: number): Observable<IPublishedNewsResponse> {
    return this.apollo
      .query<{ publishedNewsPublic: IPublishedNewsResponse }>({
        query: publishedNewsPublicQuery,
        variables: {
          offset,
          limit,
        },
      })
      .pipe(map(response => response?.data?.publishedNewsPublic));
  }
}
