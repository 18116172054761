import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

import { ButtonTypeEnum, IUserData } from '@resident-nx/shared';
import { AsyncPipe } from '@angular/common';
import { AvatarSizeEnum, AvatarWebComponent } from '../../atoms/avatar/avatar.component';
import { LogoComponent } from '../../atoms/logo/logo.component';
import { ButtonWebComponent } from '../../atoms/button/button.component';
import { ElevationDirective } from '../../../directives/elevation/elevation.directive';

@UntilDestroy()
@Component({
  selector: 'rs-web-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ElevationDirective, ButtonWebComponent, LogoComponent, AvatarWebComponent, AsyncPipe],
})
export class HeaderComponent {
  @Input() userData$: Observable<IUserData>;
  @Input() navigationOpen: boolean;
  @Input() profileSettingsOpen: boolean;
  @Output() navigationToggleClick = new EventEmitter<Event>();
  @Output() profileSettingsToggleClick = new EventEmitter<Event>();
  public contextMenu = ButtonTypeEnum.CONTEXT_MENU;
  public avatarSize = AvatarSizeEnum;

  public onNavigationToggleClick(event: Event) {
    this.navigationToggleClick.emit(event);
  }

  public onProfileSettingsToggleClick(event: Event) {
    this.profileSettingsToggleClick.emit(event);
  }
}
