import { Directive, Input } from '@angular/core';

@Directive()
export class TicketCreationBAndOPageBaseComponent {
  @Input() header = '';
  @Input() closeUrl = '';
  baseUrl = '';
  mandatorId = '';
  phoneNumber = '';
}
