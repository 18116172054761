import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
// import { Attachment } from 'libs/web/models';

@Component({
  selector: 'rs-web-navigation-user-profile',
  templateUrl: './navigation-user-profile.component.html',
  styleUrls: ['./navigation-user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class NavigationUserProfileComponent {
  @Input() name: string;
  @Input() email: string;
  // @Input() portrait: Attachment;
  @Input() dark: boolean;
}
