<div class="current-news-overview container container--with-spacing">
  <div class="d-flex align-items-center">
    <rs-web-breadcrumbs
      [items]="breadcrumbs"
      (navigateBack)="onNavigate()"
      (navigateToUrl)="onNavigate($event)"
    ></rs-web-breadcrumbs>
  </div>
  <div class="row">
    <span class="title-xxl col current-news-overview__header">{{
      'news.current_news_l' | translate
    }}</span>
  </div>
  <div class="row gy-4">
    @for (news of news$ | async; track news.node.id) {
      <rs-web-content-card
        class="col-sm-6 col-lg-3"
        [id]="news.node.id"
        [image]="news.node.imageUrl"
        [title]="news.node.title"
        [text]="news.node.text"
        [date]="news.node.publishedAt"
        [preset]="presets.NEWS"
        (tapped)="onTapArticle($event)"
      ></rs-web-content-card>
    }
  </div>
  <rs-web-infinite-scroll (scrolled)="onScroll()"></rs-web-infinite-scroll>
  @if ((newsActionState$ | async).pending) {
    <div class="d-flex justify-content-center m-5">
      <rs-web-loader></rs-web-loader>
    </div>
  }
</div>
