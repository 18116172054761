@if (documents$ | async; as documents) {
  <div class="d-flex flex-column" [class.d-block]="!previewOpen" [class.d-none]="previewOpen">
    @if (documents.breadcrumbs.length > 1) {
      <div class="d-flex align-items-center mb-3">
        <i
          class="icon-arrow-left d-flex title-s"
          (click)="openFolder(documents.breadcrumbs[documents.breadcrumbs.length - 2].id)"
        ></i>
        <div class="flex-grow-1 default-s text-center primary-text">
          {{ documents.breadcrumbs | rsDocumentBreadcrumbs }}
        </div>
      </div>
    }
    @for (folder of documents.folders; track folder.id) {
      <div [elevation]="2" rsWebElevation class="folders" (click)="openFolder(folder.id)">
        <div class="icon me-3">
          <rs-web-icon [icon]="immomioIconName.FolderOutlined"></rs-web-icon>
        </div>
        <span class="primary-text">{{ folder.name }}</span>
      </div>
    }
    @for (document of documents.documents; track document.id) {
      <div [elevation]="2" rsWebElevation class="documents" (click)="openPreview(document)">
        <div class="icon me-3">
          <rs-web-icon [icon]="immomioIconName.Document"></rs-web-icon>
        </div>
        <span class="primary-text">{{ document.name }}</span>
      </div>
    }
  </div>
}

<div class="document-preview" [class.d-block]="previewOpen" [class.d-none]="!previewOpen">
  <div>
    <div class="d-flex justify-content-between">
      <rs-web-button
        [ghost]="true"
        [size]="buttonSize['SMALL']"
        [borderRadius]="buttonBorderRadius['BIG']"
        [iconLeft]="'arrow-left'"
        [type]="buttonType['EMPTY_LIGHT']"
        (click)="closePreview()"
      ></rs-web-button>
      <span class="primary-text document-title">{{ documentName }}</span>
      <rs-web-button
        class="m-r-5"
        [ghost]="true"
        [size]="buttonSize['SMALL']"
        [borderRadius]="buttonBorderRadius['BIG']"
        [iconLeft]="'download'"
        [type]="buttonType['EMPTY_LIGHT']"
        (click)="download(downloadBlob, documentName)"
      ></rs-web-button>
    </div>
  </div>

  @if (!previewLoading) {
    <div class="mt-3 d-flex justify-content-center">
      @if (documentType === 'jpg' || documentType === 'jpeg' || documentType === 'png') {
        <rs-web-image
          [defaultSrc]="previewUrl || ''"
          alt=""
          [maxHeightInPx]="300"
          [borderRadius]="imageBorderRadius['SMALL']"
        ></rs-web-image>
      }
      @if (documentType === 'pdf') {
        <iframe [src]="previewUrl" height="350" width="400"></iframe>
      }
    </div>
  } @else {
    <rs-web-loader [centered]="true" [biggerSpinner]="true"></rs-web-loader>
  }
</div>
