import { Directive, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { IConsumptionState } from '../../+state';
import { ByDeviceId, ConsumptionTimeframe, IConsumptionInfoItem } from '../../models';
import * as fromConsumptionState from '../../+state/consumption';

@Directive()
export abstract class ConsumptionMissingDataBaseComponent implements OnInit {
  public selectedTimeframe$: Observable<ByDeviceId<ConsumptionTimeframe>>;
  public selectedMeter$: Observable<IConsumptionInfoItem>;
  public consumptionTimeframe = ConsumptionTimeframe;

  constructor(private store: Store<IConsumptionState>) {}

  ngOnInit() {
    this.selectedMeter$ = this.store.select(fromConsumptionState.getFirstSelectedMeter);
    this.selectedTimeframe$ = this.store.select(fromConsumptionState.getSelectedTimeframe);
  }
}
