export enum FeedbackPosition {
  Top,
  Bottom,
}

export enum FeedbackType {
  Success,
  Error,
  Warning,
  Info,
  Custom,
}

export interface IFeedbackShowOptions {
  title?: string;
  titleSize?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  titleColor?: any;
  message?: string;
  messageSize?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messageColor?: any;
  duration?: number;
  type?: FeedbackType;
  position?: FeedbackPosition;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  backgroundColor?: string;
  icon?: string;
  onTap?: () => void;
  onHide?: () => void;
  onShow?: (animating?: boolean) => void;
  titleFont?: string;
  messageFont?: string;
  android?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    iconColor?: any;
    iconPulseEnabled?: boolean;
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFeedbackHideOptions {}

export interface IFeedbackApi {
  show(options: IFeedbackShowOptions): Promise<void>;
  hide(options?: IFeedbackHideOptions): Promise<void>;
  success(options: IFeedbackShowOptions): Promise<void>;
  warning(options: IFeedbackShowOptions): Promise<void>;
  error(options: IFeedbackShowOptions): Promise<void>;
  info(options: IFeedbackShowOptions): Promise<void>;
}
