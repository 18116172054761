import { gql } from 'apollo-angular';
import { customerSpecificDataFields } from '../common';

export const ticketConversationFields = `
  messages {
    created {
      editor {
        id
        name
      }
      date
    }
    message
    id
    attachments {
      title
      name
      type
      documentType
      documentFileType
      identifier
      extension
      url
      residentFileType
    }
    ${customerSpecificDataFields}
  }
`;

export const ticketChatQuery = gql`
  query getTicketConversation($ticketId: ID!,) {
    getTicketConversation (ticketId: $ticketId) {
      ${ticketConversationFields}
    }
  }
`;

export const addMessageToTicketMutation = gql`
  mutation addMessageToTicketConversation($input: TicketConversationEditBean!) {
    addMessageToTicketConversation(input: $input) {
      createdMessageId
    }
  }
`;
