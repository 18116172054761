import { Store } from '@ngrx/store';
import * as fromLoginState from '../+state/account/login/login.actions';

export function setEnvironmentVariables(store: Store, email: string, nonce: string): void {
  const redirectUri = '';

  store.dispatch(
    fromLoginState.LoadFederatedLoginData({
      email,
      nonce,
      state: nonce,
      redirectUri,
    })
  );
}
