import { Component } from '@angular/core';
import { ContractsWebComponent } from '../../organisms/contracts/contracts.component';

@Component({
  selector: 'rs-web-contracts-page',
  templateUrl: './contracts-page.component.html',
  styleUrls: ['./contracts-page.component.scss'],
  standalone: true,
  imports: [ContractsWebComponent],
})
export class ContractsPageWebComponent {}
