import { AbstractControl } from '@angular/forms';

export interface IValidationDataSet {
  [key: string]: ValidationDataItem;
}

export class ValidationDataItem {
  id: string;
  text: string;
  control?: AbstractControl;
  valid? = false;
}
