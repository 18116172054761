export interface ImageSrcSet {
  x1?: string;
  x2?: string;
  x3?: string;
}

export interface ImageSource {
  minScreenWidth?: number;
  maxScreenWidth?: number;
  srcSet: ImageSrcSet;
}

export type ImageSources = ImageSource[];

export enum ImageObjectFitEnum {
  DEFAULT = 'default',
  COVER = 'cover',
  CONTAINS = 'contain',
}

export enum ImageBorderRadiusEnum {
  NONE = 'none',
  SMALL = 'small',
  BIG = 'big',
  ROUND = 'round',
}

export enum ImageBorderStyleEnum {
  NONE = 'none',
  NEUTRAL = 'neutral',
  ROUNDED = 'rounded',
}

export enum CenterImageSizeEnum {
  S = 'S',
  M = 'M',
}

export const ImageDefaultConfig = {
  sources: [],
  lazyLoading: true,
  objectFit: ImageObjectFitEnum.DEFAULT,
  borderRadius: ImageBorderRadiusEnum.NONE,
  borderStyle: ImageBorderStyleEnum.NONE,
};

export enum BreakPointsMaxInPx {
  XS = 575,
  SM = 767,
  MD = 991,
  LG = 1199,
}
