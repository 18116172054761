<div class="offcanvas-container">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title">{{ title() | translate }}</h5>
    <button
      type="button"
      class="btn-close text-reset"
      aria-label="Close"
      (click)="dismiss()"
    ></button>
  </div>

  <div class="offcanvas-body">
    @if (!selectedContact()) {
      <div class="d-flex flex-column justify-content-center align-items-center gap-4 mb-4">
        <rs-web-image
          [defaultSrc]="'/web/images/order-placed.png'"
          [maxHeightInPx]="200"
        ></rs-web-image>
        <h1>{{ 'ticket_creation.direct_assignments.title_l' | translate }}</h1>
        <p class="text-center text-balance">
          {{ 'ticket_creation.direct_assignments.text_l' | translate }}
        </p>
      </div>

      <div>
        <div class="d-flex gap-2 mb-3">
          <rs-web-icon [icon]="ImmomioIconName.ContactList" />
          <span>{{ 'contract.emergency_contact_l' | translate }}</span>
        </div>

        @for (contact of contacts(); track contact.id) {
          <div class="d-flex align-items-center gap-3 mb-3 py-2">
            <rs-web-icon class="user-icon" [icon]="ImmomioIconName.User" />
            <div class="d-flex flex-column">
              <span>{{ contact.name }}</span>
              <span class="secondary-text">{{ contact.position }}</span>
            </div>
            <rs-web-button
              class="ms-auto"
              [type]="ButtonTypeEnum.LIGHT_BORDERED"
              (tapped)="selectedContact.set(contact)"
              >{{ 'direct_assignment.contact_details_a' | translate }}
            </rs-web-button>
          </div>
        }
      </div>
    } @else {
      <ng-container [ngTemplateOutlet]="selectedContactData"></ng-container>
    }
  </div>

  <div class="offcanvas-footer">
    @if (selectedContact()) {
      <rs-web-button (tapped)="selectedContact.set(undefined)"
        >{{ 'general.back_a' | translate }}
      </rs-web-button>
    }
    <rs-web-button (tapped)="dismiss()" class="ms-auto"
      >{{ 'ticket_creation.back_to_overview_a' | translate }}
    </rs-web-button>
  </div>
</div>

<ng-template #selectedContactData>
  <div class="d-flex align-items-center gap-3 mb-4">
    <rs-web-icon class="user-icon" [icon]="ImmomioIconName.User" />
    <div class="d-flex flex-column">
      <span>{{ selectedContact().name }}</span>
      <span class="secondary-text">{{ selectedContact().position }}</span>
    </div>
  </div>

  <rs-web-contact-details [contact]="selectedContact()"></rs-web-contact-details>
</ng-template>
