import { AppSettingsFacade } from './app-settings';

export * from './feedback.base-service';
export * from './request.base-service';
export * from './dialog.base-service';
export * from './translate-parser.service';
export * from './theme.base-service';
export * from './router.base-service';
export * from './search.service';

export const GENERAL_FACADES = [AppSettingsFacade];
