import { Injectable } from '@angular/core';

@Injectable()
export abstract class ErrorHandlerService {
  // handle thrown error
  abstract handleError(prefix: string, error: Error): void;

  //get errorCode from graphQL error to trace ID
  getBackendErrorCode?(error: Error): void;

  generateErrorMessage?(error: Error): string;
}
