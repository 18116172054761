import { gql } from 'apollo-angular';

const contactPersonFields = `
name {
  firstName
  lastName
}
businessHotline
emergancyHotline
`;

export const contactPersonQuery = gql`
 query contactPerson {
  contactPerson {
    ${contactPersonFields}
  }
}
`;
