import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChipBaseComponent } from '@resident-nx/shared';

@Component({
  selector: 'rs-web-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipWebComponent extends ChipBaseComponent {
  @Input() selected = false;
}
