export const addressFields = `
  address {
    street
    houseNumber
    zipCode
    additional
    city
    # state
    # country
  }
`;
