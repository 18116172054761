@if (show) {
  @for (type of types; track type) {
    @switch (type) {
      @case (LoadingType.HEADER) {
        <div class="m-b-16">
          <div class="skeleton__header header"></div>
        </div>
      }
      @case (LoadingType.HEADEROPTION) {
        <div class="d-flex align-items-end justify-content-between m-b-16">
          <div class="skeleton__header header"></div>
          <div class="skeleton__header-option header-option"></div>
        </div>
      }
      @case (LoadingType.BUTTON) {
        <div class="d-flex align-items-end justify-content-end m-b-16">
          <div class="skeleton__header-option header-option"></div>
        </div>
      }
      @case (LoadingType.IMAGECARD) {
        <div class="m-b-16">
          <div class="skeleton__image-card image-card"></div>
        </div>
      }
      @case (LoadingType.MULTIPLECARD) {
        <div class="m-b-16 overflow">
          <div class="d-flex align-items-end justify-content-start">
            @for (i of multipleCardLength; track i) {
              <rs-web-card class="skeleton__multiple-card multiple-card m-r-24"></rs-web-card>
            }
          </div>
        </div>
      }
      @case (LoadingType.CONSUMPTION) {
        <div class="m-b-16">
          <div class="skeleton__consumption consumption"></div>
        </div>
      }
      @case (LoadingType.TICKETCARD) {
        <div class="m-b-16">
          @if (screenWidth >= 992) {
            <div class="d-flex align-items-end justify-content-start">
              @for (i of multipleCardLength; track i) {
                <rs-web-card class="skeleton__ticket-card ticket-card p-x-12"></rs-web-card>
              }
            </div>
          } @else if (screenWidth >= 576 && screenWidth <= 992) {
            <div class="row row-gap-1">
              @for (i of multipleCardLength; track i) {
                <rs-web-card
                  class="skeleton__ticket-card ticket-card ticket-card__md col-md-6 col-lg-4 col-xxl-3"
                ></rs-web-card>
              }
            </div>
          } @else {
            <div class="row row-gap-1">
              @for (i of multipleCardLength; track i) {
                <rs-web-card
                  class="skeleton__ticket-card ticket-card ticket-card__sm col-sm-6 col-lg-4 col-xxl-3"
                ></rs-web-card>
                <rs-web-card
                  class="skeleton__ticket-card ticket-card ticket-card__sm col-sm-6 col-lg-4 col-xxl-3"
                ></rs-web-card>
              }
            </div>
          }
        </div>
      }
      @case (LoadingType.PAYMENT) {
        <div class="m-b-16">
          @if (screenWidth >= 992) {
            <div class="d-flex align-items-end justify-content-start">
              @for (i of multipleCardLength; track i) {
                <rs-web-card class="skeleton__payment-card payment-card p-x-12"></rs-web-card>
              }
            </div>
          } @else if (screenWidth >= 576 && screenWidth <= 992) {
            <div class="row row-gap-1">
              @for (i of multipleCardLength; track i) {
                <rs-web-card
                  class="skeleton__payment-card payment-card payment-card__md col-md-6 col-lg-4 col-xxl-3"
                ></rs-web-card>
              }
            </div>
          } @else {
            <div class="row row-gap-1">
              @for (i of multipleCardLength; track i) {
                <rs-web-card
                  class="skeleton__payment-card payment-card payment-card__sm col-sm-6 col-lg-4 col-xxl-3"
                ></rs-web-card>
                <rs-web-card
                  class="skeleton__payment-card payment-card payment-card__sm col-sm-6 col-lg-4 col-xxl-3"
                ></rs-web-card>
              }
            </div>
          }
        </div>
      }
      @case (LoadingType.DOCUMENT) {
        @for (i of multipleCardLength; track i) {
          <div class="skeleton__document-card document-card"></div>
        }
      }
      @default {
        <div class="skeleton__image-card image-card"></div>
      }
    }
  }
}
