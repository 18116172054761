import { createAction, props } from '@ngrx/store';
import { INotificationEmailSettings } from '../../../models/notifications/notification-emails-settings.model';

export const LoadNotificationSettings = createAction(
  '[Resident Notifications Settings] Load Notification Settings'
);

export const LoadNotificationSettingsSuccess = createAction(
  '[Resident Notifications Settings] Load Notification Settings Success',
  props<{ success: INotificationEmailSettings }>()
);

export const LoadNotificationSettingsFailed = createAction(
  '[Resident Notifications Settings] Load Notification Settings Failed',
  props<{ error: Error }>()
);

export const ChangeNotificationSettings = createAction(
  '[Resident Notifications Settings] Change Notification Settings',
  props<{ payload: INotificationEmailSettings }>()
);

export const ChangeNotificationSettingsSuccess = createAction(
  '[Resident Notifications Settings] Change Notification Settings Success',
  props<{ response: INotificationEmailSettings }>()
);

export const ChangeNotificationSettingsFailed = createAction(
  '[Resident Notifications Settings] Change Notification Settings Failed',
  props<{ error: Error }>()
);
