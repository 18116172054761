import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import {
  getHomepageModuleActionState,
  GetHomepageModuleToken,
  getHomepageModuleToken,
} from '../../+state';
import { IHomepageModule } from '../../models';
import { ResolverCommonService } from './resolver-common.service';

export const HomepageModuleResolver: ResolveFn<IHomepageModule> = route => {
  const common = inject(ResolverCommonService);

  const { resolverWaitForData } = route.data;
  return common.resolveHandler<IHomepageModule>(
    getHomepageModuleActionState,
    GetHomepageModuleToken(),
    getHomepageModuleToken,
    resolverWaitForData
  );
};
