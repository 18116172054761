import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ImmomioIconName } from '../../models';

@Directive()
export abstract class WidgetBaseComponent {
  @Input() icon: ImmomioIconName;
  @Input() title: string;
  @Input() showButton: boolean;
  @Input() buttonText: string;
  @Output() overviewTapped = new EventEmitter();

  public pagination = { offset: 0, limit: 10 };
}
