import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import * as fromTicketsState from '@resident-nx/shared';
import {
  TicketsOverviewArchivedBaseComponent,
  getSelectedContract,
  getTicketSorting,
} from '@resident-nx/shared';
import { combineLatest, tap } from 'rxjs';
import { ArchiveTicketsCardWebComponent } from '../archive-tickets-card/archive-tickets-card.component';
import { TicketsWebComponent } from '../tickets/tickets.component';
@UntilDestroy()
@Component({
  selector: 'rs-web-tickets-overview-archived',
  standalone: true,
  imports: [ArchiveTicketsCardWebComponent, AsyncPipe, TicketsWebComponent, TranslateModule],
  templateUrl: './tickets-overview-archived.component.html',
  styleUrl: './tickets-overview-archived.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketsOverviewArchivedWebComponent
  extends TicketsOverviewArchivedBaseComponent
  implements OnInit
{
  override ngOnInit(): void {
    super.ngOnInit();
    combineLatest([
      this.masterDataStore.select(getSelectedContract),
      this.masterDataStore.select(getTicketSorting),
    ])
      .pipe(
        tap(([_, sort]) => {
          this.sort = sort;
          switch (this.archiveType) {
            case 'damage':
              this.ticketsStore.dispatch(
                fromTicketsState.LoadArchiveDamageTicketsForSelectedContract({
                  ...this.pagination,
                  sort: this.sort,
                })
              );
              break;
            case 'request':
              this.ticketsStore.dispatch(
                fromTicketsState.LoadArchiveRequestTicketsForSelectedContract({
                  ...this.pagination,
                  sort: this.sort,
                })
              );
              this.txtNoTickets = 'archive_request.no_data';
              break;
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
