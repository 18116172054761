import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ImmomioIconName } from '../../models';

@Directive()
export abstract class CardsContainerHeaderBaseComponent {
  @Input() icon: ImmomioIconName;
  @Input() title: string;
  @Input() buttonText: string;
  @Input() showButton = false;
  @Output() tapped: EventEmitter<boolean | MouseEvent> = new EventEmitter();
}
