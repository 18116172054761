<div class="mobile-title mb-5 title-m">{{ 'user_profile.payment_information_l' | translate }}</div>
<div class="flex-mobile-column row mb-4">
  <div class="d-flex flex-column col-6 mb-2">
    <span class="column-title">{{ 'user_profile.escrowed_account_l' | translate }}</span>
    @if (userData?.sepaMandate?.iban) {
      <span class="column-desc">{{ userData.sepaMandate.iban }}</span>
    } @else {
      <ng-template [ngTemplateOutlet]="noData"></ng-template>
    }
  </div>
  <div class="d-flex flex-column col-6">
    <span class="column-title">{{ 'user_profile.sepa_direct_debit_mandate_l' | translate }}</span>
    @if (userData?.sepaMandate?.issuedAt || userData?.sepaMandate?.issuedUntil) {
      <span class="column-desc"
        >{{ userData.sepaMandate.issuedAt | rsWebDateRange: userData.sepaMandate.issuedUntil }}
      </span>
    } @else {
      <ng-template [ngTemplateOutlet]="noData"></ng-template>
    }
  </div>
</div>
