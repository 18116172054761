import { Directive, Input } from '@angular/core';
import {
  ComparisonStatus,
  ComparisonType,
  ConsumptionType,
  ConsumptionUnitType,
} from '../../models';

@Directive()
export abstract class ConsumptionRowBaseComponent {
  @Input() title: string;
  @Input() width: number;
  @Input() type: ComparisonType;
  @Input() amount: number;
  @Input() unit: ConsumptionUnitType;
  @Input() consumptionType: ConsumptionType;
  @Input() status?: ComparisonStatus;
  public readonly comparisonType = ComparisonType;
  public readonly comparisonStatus = ComparisonStatus;
}
