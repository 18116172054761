import { HttpClient } from '@angular/common/http';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, map, switchMap, of } from 'rxjs';
import { Apollo } from 'apollo-angular';

import { ITranslationsData, IKeyValue, PhraseAppProject } from '../../models';
import { allTranslationsQuery } from './queries';

export class TransLoader implements TranslateLoader {
  constructor(
    private apollo: Apollo,
    private http: HttpClient
  ) {}

  public getTranslation(locale = 'de'): Observable<IKeyValue> {
    return this.apollo
      .query<{ getAllTranslations: ITranslationsData[] }>({
        query: allTranslationsQuery,
        variables: { locale, project: PhraseAppProject.RESIDENT },
      })
      .pipe(
        switchMap(response => {
          if (
            !response ||
            response.errors ||
            !(response?.data?.getAllTranslations[0]?.translations.length > 0)
          ) {
            const loader = new TranslateHttpLoader(this.http);
            return loader.getTranslation(locale);
          }
          return of(response).pipe(
            map(response => {
              const res = {} as IKeyValue;
              response.data.getAllTranslations[0]?.translations.forEach(
                item => (res[item.key] = item.translation)
              );
              return res;
            })
          );
        })
      );
  }
}
