import { Directive, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ImmomioIcons } from '../../models/general/icon-name.type.generated';
import { CustomerService, contractStatusConverter, RouterService } from '../../services';
import * as fromUserProfileState from '../../+state/account/user-profile';
import * as fromMasterDataState from '../../+state/account/masterdata';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive()
export abstract class UserProfileCardBaseComponent implements OnInit {
  public user$ = this.store.select(fromUserProfileState.getPersonalData);
  public userActionState$ = this.store.select(fromUserProfileState.getPersonalDataActionState);
  public immomioIconName = ImmomioIcons;
  public hideChangeButton = false;
  public appPreviewMode$: Observable<boolean>;
  public hasActiveContracts: boolean;

  @Output() profileUpdate = new EventEmitter();

  constructor(
    protected routerService: RouterService,
    protected store: Store<fromUserProfileState.IUserProfileState>,
    protected customerService: CustomerService,
    protected masterDataStore: Store<fromMasterDataState.IMasterDataState>
  ) {
    this.userActionState$ = this.store.select(fromUserProfileState.getPersonalDataActionState);
    this.masterDataStore
      .select(fromMasterDataState.getContracts)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.hasActiveContracts = res?.length && contractStatusConverter.isAnyContractActive(res);
      });
  }
  ngOnInit() {
    this.hideChangeButton = this.customerService.hideTicketManagement();
    this.appPreviewMode$ = this.customerService.appPreviewMode();
  }

  navigateToRequests() {
    void this.routerService.navigate(['content', 'concern'], { queryParams: { tab: 'concern' } });
  }
}
