import { createReducer, on } from '@ngrx/store';
import { ISearchResult } from '../../services';
import * as fromActions from './search.actions';

export interface ISearchState {
  results: ISearchResult[];
  term: string;
}

export const initialState: ISearchState = {
  results: [],
  term: '',
};

export const searchReducer = createReducer(
  initialState,
  on(fromActions.search, (state, { term }) => ({
    ...state,
    term,
    results: [],
  })),
  on(fromActions.searchSuccess, (state, { results }) => {
    return {
      ...state,
      results,
    };
  })
);

export const getSearchState = (state: ISearchState) => state;
export const getSearchResults = (state: ISearchState) => state.results;
