import { createSelector } from '@ngrx/store';

import { getEmailSettingsState } from '../../reducers';
import * as fromReducer from './email-settings.reducers';

export const getEmailSettingsActionState = createSelector(
  getEmailSettingsState,
  fromReducer.getEmailSettingsActionState
);

export const getEmailSettings = createSelector(getEmailSettingsState, fromReducer.getEmailSettings);

export const getChangeEmailSettingsActionState = createSelector(
  getEmailSettingsState,
  fromReducer.getChangeEmailSettingsActionState
);
