import { gql } from 'apollo-angular';

export const faqFields = `
edges {
  cursor,
  node {
    uuid
    title
    text
    children {
      uuid
      title
      text
    }
  }
}
pageInfo {
  hasPreviousPage
  hasNextPage
  startCursor
  endCursor
}
`;

export const faqOverviewFields = `
faqGroups{
  id
  name
  faqs {
    id
    uuid
    title
    text
    href
  }
}
`;

export const publishedFaqsQuery = gql`
  query publishedFaqs {
    publishedFaqs {
      ${faqFields}
    }
  }
`;

export const getFaqOverviewQuery = gql`
  query getFaqOverview {
    getFaqOverview {
      ${faqOverviewFields}
    }
  }
`;
