import { createSelector } from '@ngrx/store';

import { getPaymentRequestsState } from '../reducers';
import * as fromReducer from './payment.reducer';

export const getActivePaymentRequestsActionState = createSelector(
  getPaymentRequestsState,
  fromReducer.getActivePaymentRequestsActionState
);

export const getActivePaymentRequests = createSelector(
  getPaymentRequestsState,
  fromReducer.getActivePaymentRequests
);
export const getActivePaymentRequestsCount = createSelector(
  getPaymentRequestsState,
  fromReducer.getActivePaymentRequestsCount
);
export const getNextDuePaymentRequest = createSelector(
  getPaymentRequestsState,
  fromReducer.getNextDuePaymentRequest
);
export const getArchivePaymentsRequests = createSelector(
  getPaymentRequestsState,
  fromReducer.getArchivePaymentsRequests
);
export const getArchivePaymentsActionState = createSelector(
  getPaymentRequestsState,
  fromReducer.getArchivePaymentsActionState
);

export const getArchivePaymentRequestsCount = createSelector(
  getPaymentRequestsState,
  fromReducer.getArchivePaymentRequestsCount
);
