import { createAction, props } from '@ngrx/store';
import { IPublishedOffer, IPublishedOffersResponse } from '../../../models';

export const LoadOffers = createAction(
  '[News] Load Offers',
  props<{ offset: number; limit: number; forced?: boolean }>()
);

export const LoadOffersSuccess = createAction(
  '[News] Load Offers Success',
  props<{ response: IPublishedOffersResponse; oldState?: boolean }>()
);

export const LoadOffersFailed = createAction(
  '[News] Load Offers Failed',
  props<{ error: Error }>()
);

export const LoadOffer = createAction('[News] Load LoadOffer', props<{ offerId: string }>());

export const LoadOfferSuccess = createAction(
  '[News] Load Offer Success',
  props<{ response: IPublishedOffer }>()
);

export const LoadOfferFailed = createAction('[News] Load Offer Failed', props<{ error: Error }>());

export const LoadPublishedOffersPublic = createAction(
  '[News] Load Public Offers',
  props<{ offset: number; limit: number }>()
);

export const LoadPublishedOffersPublicSuccess = createAction(
  '[News] Load Public Offers Success',
  props<{ response: IPublishedOffersResponse }>()
);

export const LoadPublishedOffersPublicFailed = createAction(
  '[News] Load Public Offers Failed',
  props<{ error: Error }>()
);
