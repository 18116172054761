import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { IPaymentPositionResident, IPaymentResponse } from '../../models';
import { paymentRequestsQuery } from '../../queries';

@Injectable()
export class PaymentRequestsFacade {
  constructor(private apollo: Apollo) {}

  getPaymentsRequests(archived: boolean): Observable<IPaymentPositionResident[]> {
    return this.apollo
      .query<{ getPaymentRequests: IPaymentResponse }>({
        query: paymentRequestsQuery,
        variables: {
          archived,
        },
      })
      .pipe(
        map(response => {
          if (response?.data?.getPaymentRequests?.paymentPositionResidentBeans) {
            return response.data.getPaymentRequests.paymentPositionResidentBeans;
          } else if (response?.errors) {
            throw new Error(response.errors[0].message);
          }
          throw new Error();
        })
      );
  }
}
