export const fileToBase64 = (file: File, split = true): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result as string;
      const resolvedData = split ? result.split(',')[1] : result;
      return resolve(resolvedData);
    };
    reader.onerror = reject;
  });
