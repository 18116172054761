import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'timeAgo',
  standalone: true,
})
export class TimeAgoPipe extends DatePipe implements PipeTransform {
  override transform(value: Date | string | number): string | null;
  override transform(value: null | undefined): null;
  override transform(value: Date | string | number | null | undefined): string | null {
    // TODO add translations
    if (typeof value === 'number') {
      const timeDifference = Date.now() - value;
      if (timeDifference < 60 * 1000) {
        return `Jetzt`;
      }

      // Less than 1 hour ago
      if (timeDifference < 60 * 60 * 1000) {
        const minutes = Math.floor(timeDifference / (60 * 1000));
        return `Vor ${minutes} Minute${minutes > 1 ? 'n' : ''}`;
      }

      // Less than 24 hours ago
      if (timeDifference < 24 * 60 * 60 * 1000) {
        const hours = Math.floor(timeDifference / (60 * 60 * 1000));
        return `Vor ${hours} Stunde${hours > 1 ? 'n' : ''}`;
      }

      // Less than 6 days ago
      if (timeDifference < 6 * 24 * 60 * 60 * 1000) {
        const days = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
        return `Vor ${days} Tag${days > 1 ? 'e' : ''}`;
      }

      // Otherwise, return the date
      return super.transform(value, 'EEEEEE dd.MM.yyyy');
    }

    return null;
  }
}
