import { createReducer, on } from '@ngrx/store';
import { IFaq, IFaqOverviewResponseBean } from '../../models';
import { ActionStateCreator, IActionState } from '../../utils';
import * as fromActions from './support.actions';

export interface ISupportState {
  faqs: IFaq[];
  faqsActionState: IActionState;
  faqsOverview: IFaqOverviewResponseBean;
  faqsOverviewActionState: IActionState;
}

export const initialState: ISupportState = {
  faqs: [],
  faqsActionState: ActionStateCreator.create(),
  faqsOverview: null,
  faqsOverviewActionState: ActionStateCreator.create(),
};

export const supportReducer = createReducer(
  initialState,

  on(fromActions.LoadFaqs, state => {
    return {
      ...state,
      faqsActionState: ActionStateCreator.onStart(),
    };
  }),

  on(fromActions.LoadFaqsSuccess, (state, { faqs }) => {
    return {
      ...state,
      faqsActionState: ActionStateCreator.onSuccess(),
      faqs: faqs,
    };
  }),

  on(fromActions.LoadFaqsFailed, (state, { error }) => {
    return {
      ...state,
      faqsActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadOverviewFaqs, state => {
    return {
      ...state,
      faqsOverviewActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.LoadOverviewFaqsSuccess, (state, { faqsOverview }) => {
    return {
      ...state,
      faqsOverviewActionState: ActionStateCreator.onSuccess(),
      faqsOverview: faqsOverview,
    };
  }),

  on(fromActions.LoadOverviewFaqsFailed, (state, { error }) => {
    return {
      ...state,
      faqsOverviewActionState: ActionStateCreator.onError(error),
    };
  })
);

export const getFaqs = (state: ISupportState) => state.faqs;
export const getFaqsActionState = (state: ISupportState) => state.faqsActionState;
export const getOverviewFaqs = (state: ISupportState) => state.faqsOverview;
export const getOverviewFaqsActionState = (state: ISupportState) => state.faqsOverviewActionState;
