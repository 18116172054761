import { Directive } from '@angular/core';
import { ImmomioIconName } from '../../models';

@Directive()
export class AccordionBaseComponent {
  selectedIndex: number;
  public immomioIconName = ImmomioIconName;

  toggleSelection(index: number) {
    if (this.selectedIndex && this.selectedIndex === index) {
      this.selectedIndex = null;
    } else {
      this.selectedIndex = index;
    }
  }
}
