@for (contract of contracts$ | async; track contract.id; let index = $index) {
  <label
    class="form-check form-check-label d-flex flex-column flex-sm-row justify-content-between"
    [for]="'select-contract-' + index"
  >
    <div class="d-flex align-items-center">
      <input
        [value]="contract"
        [id]="'select-contract-' + index"
        class="form-check-input me-2"
        type="radio"
        name="contract"
        formControlName="contract"
      />
      <div class="me-2">
        <rs-web-icon [icon]="contract.type | contractIcon" [size]="15"></rs-web-icon>
      </div>
      <div class="text-break">
        {{ contract.property.address | address }}
      </div>
    </div>
    <div class="text-break mt-1 mt-sm-0">
      {{ contract.externalId }}
    </div>
  </label>
}
