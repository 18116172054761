import { IResidentS3File } from '../general';
import { TicketStatus } from './ticket-status.enum';
import { TicketIssueType } from './ticket-issue-type.enum';

export interface IMetadataTicketDetailsResponse {
  id: string;
  title: string;
  status: TicketStatus;
  customerStatus: string;
  created: string;
  updated: string;
  issueType: TicketIssueType;
  categoryResponses: ICategoryResponse[];
  formResponses: IFormResponse[];
  lastMessageDate: string;
  unreadMessages: number;
  externalTicket: boolean;
}

export interface ICategoryResponse {
  index: number;
  questionText: string;
  response: string;
}

export interface IFormResponse {
  index: number;
  questionText: string;
  type: FormResponseQuestionType;
  responses: string[];
  attachments: IResidentS3File[];
}

export enum FormResponseQuestionType {
  LABEL = 'LABEL',
  TEXTFIELD = 'TEXTFIELD',
  TEXTBOX = 'TEXTBOX',
  DATE = 'DATE',
  ATTACHMENTS = 'ATTACHMENTS',
  SELECTION = 'SELECTION',
}
