export enum ImmomioIcons {
  AirCondition = 'air-condition',
  Archive = 'archive',
  Area = 'area',
  Armchair = 'armchair',
  ArrowDown = 'arrow-down',
  ArrowLeft = 'arrow-left',
  ArrowRight = 'arrow-right',
  ArrowUp = 'arrow-up',
  Attic = 'attic',
  Balcony = 'balcony',
  BarrierFree = 'barrier-free',
  Basement = 'basement',
  Bathtub = 'bathtub',
  Bed = 'bed',
  BellFilled = 'bell-filled',
  Bidet = 'bidet',
  Bike = 'bike',
  Blinders = 'blinders',
  Bling = 'bling',
  Board = 'board',
  Bubbletub = 'bubbletub',
  BurgerMenuExpand = 'burger-menu-expand',
  BurgerMenuReduce = 'burger-menu-reduce',
  BurgerMenu = 'burger-menu',
  Bus = 'bus',
  Calendar = 'calendar',
  Camera = 'camera',
  ChartBar = 'chart-bar',
  ChartLine = 'chart-line',
  ChartPie = 'chart-pie',
  ChatTemplate = 'chat-template',
  Chat = 'chat',
  CheckOutlined = 'check-outlined',
  Check = 'check',
  ChevronDown = 'chevron-down',
  ChevronLeft = 'chevron-left',
  ChevronRight = 'chevron-right',
  ChevronUp = 'chevron-up',
  Clipboard = 'clipboard',
  Clock = 'clock',
  CloseOutlined = 'close-outlined',
  Close = 'close',
  Coin = 'coin',
  Commercial = 'commercial',
  Compass = 'compass',
  Computer = 'computer',
  ContactList = 'contact-list',
  ContextMenuOutlined = 'context-menu-outlined',
  ContextMenu = 'context-menu',
  Contract = 'contract',
  Cookie = 'cookie',
  Copy = 'copy',
  Crane = 'crane',
  Crosshair = 'crosshair',
  Dashboard = 'dashboard',
  Data = 'data',
  Document = 'document',
  Door = 'door',
  Download = 'download',
  Editor = 'editor',
  EnergyCertificate = 'energy-certificate',
  Error = 'error',
  Expand = 'expand',
  Export = 'export',
  Extinguisher = 'extinguisher',
  EyeClosed = 'eye-closed',
  EyeOpen = 'eye-open',
  Filter = 'filter',
  Fire = 'fire',
  Fireplace = 'fireplace',
  Flashes = 'flashes',
  Floor = 'floor',
  FolderOutlined = 'folder-outlined',
  Folder = 'folder',
  Food = 'food',
  Garage = 'garage',
  Garden = 'garden',
  GasContainer = 'gas-container',
  Gear = 'gear',
  Globe = 'globe',
  HalfRooms = 'half-rooms',
  Hammer = 'hammer',
  Handicapped = 'handicapped',
  Heating = 'heating',
  Highway = 'highway',
  History = 'history',
  Hospital = 'hospital',
  HouseDoor = 'house-door',
  House = 'house',
  Info = 'info',
  Intercom = 'intercom',
  InternetCable = 'internet-cable',
  Key = 'key',
  Keyboard = 'keyboard',
  Kitchen = 'kitchen',
  LaundryRoom = 'laundry-room',
  Laundry = 'laundry',
  Law = 'law',
  Lift = 'lift',
  LightBulb = 'light-bulb',
  LightningOutlined = 'lightning-outlined',
  Lightning = 'lightning',
  Link = 'link',
  Linkedin = 'linkedin',
  List = 'list',
  LocationOutlined = 'location-outlined',
  Location = 'location',
  LockClosed = 'lock-closed',
  LockOpen = 'lock-open',
  Loggia = 'loggia',
  Logout = 'logout',
  Magnifier = 'magnifier',
  Mail = 'mail',
  Masonry = 'masonry',
  Megaphone = 'megaphone',
  Memory = 'memory',
  MinusOutlined = 'minus-outlined',
  Minus = 'minus',
  Mobile = 'mobile',
  MoneyBag = 'money-bag',
  MoneyPig = 'money-pig',
  Monument = 'monument',
  MoreMoney = 'more-money',
  MovingBox = 'moving-box',
  News = 'news',
  Note = 'note',
  Office = 'office',
  PaintBrush = 'paint-brush',
  PaintRoller = 'paint-roller',
  PaperClip = 'paper-clip',
  PaperPlane = 'paper-plane',
  Parking = 'parking',
  PartyCannon = 'party-cannon',
  PaymentRequest = 'payment-request',
  Pdf = 'pdf',
  Pen = 'pen',
  Pet = 'pet',
  Phone = 'phone',
  Pipe = 'pipe',
  Plane = 'plane',
  Plug = 'plug',
  PlusOutlined = 'plus-outlined',
  Plus = 'plus',
  Pool = 'pool',
  Publish = 'publish',
  QuestionOutlined = 'question-outlined',
  Redirect = 'redirect',
  Refresh_1 = 'refresh-1',
  Refresh = 'refresh',
  Reporting = 'reporting',
  School = 'school',
  SearchProfile = 'search-profile',
  Senior = 'senior',
  Share = 'share',
  Shower = 'shower',
  Skyscraper = 'skyscraper',
  Sorting = 'sorting',
  Stairs = 'stairs',
  Stairwell = 'stairwell',
  Store = 'store',
  Sun = 'sun',
  Tag = 'tag',
  Terrace = 'terrace',
  ThumbsUp = 'thumbs-up',
  Ticket = 'ticket',
  Toilet = 'toilet',
  Train = 'train',
  Trash = 'trash',
  Tv = 'tv',
  Upload = 'upload',
  Urinal = 'urinal',
  User = 'user',
  UtilityRoom = 'utility-room',
  Warning = 'warning',
  Water = 'water',
  Wbs = 'wbs',
  WholeRooms = 'whole-rooms',
  Window = 'window',
  Xing = 'xing',
}
