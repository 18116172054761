import { Directive } from '@angular/core';
import { Store } from '@ngrx/store';

import { ImmomioIconName, LoadingType } from '../../models';
import * as fromPaymentsState from '../../+state/payment';

@Directive()
export abstract class PaymentsWidgetBaseComponent {
  public activePaymentRequests$ = this.store.select(fromPaymentsState.getActivePaymentRequests);
  public activePaymentRequestsCount$ = this.store.select(
    fromPaymentsState.getActivePaymentRequestsCount
  );
  public nextDuePaymentRequest$ = this.store.select(fromPaymentsState.getNextDuePaymentRequest);
  public paymentRequestsState$ = this.store.select(
    fromPaymentsState.getActivePaymentRequestsActionState
  );
  public immomioIconName = ImmomioIconName;
  public loadingTypes: LoadingType[] = [];

  constructor(protected store: Store<fromPaymentsState.IPaymentsState>) {}
}
