import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EmergencyContactsOverviewBaseComponent } from '@resident-nx/shared';
import { ContactDetailsWebComponent } from '../contact-details/contact-details.component';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { CardsContainerHeaderWebComponent } from '../../molecules/cards-container-header/cards-container-header.component';
import { LoaderWebComponent } from '../../atoms/loader/loader.component';
import { IconWebComponent } from '../../atoms/icon/icon.component';

@Component({
  selector: 'rs-web-emergency-contacts-overview',
  templateUrl: './emergency-contacts-overview.component.html',
  styleUrls: ['./emergency-contacts-overview.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ContactDetailsWebComponent,
    LoaderWebComponent,
    CardsContainerHeaderWebComponent,
    TranslateModule,
    AsyncPipe,
    IconWebComponent,
    NgbAccordionModule,
  ],
})
export class EmergencyContactsOverviewWebComponent extends EmergencyContactsOverviewBaseComponent {}
