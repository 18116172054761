import { IAddress } from '../';

export interface IAgent {
  firstName: string;
  name: string;
  phone: string;
  address: IAddress;
  emergencyPhone: string;
  email: string;
  responsibilities: IResponsibility[];
}

export interface IResponsibility {
  id: string;
  name: string;
}
