import { Directive } from '@angular/core';
import { Store } from '@ngrx/store';

import { ImmomioIconName, LoadingType } from '../../models';
import * as fromPaymentsState from '../../+state/payment';

@Directive()
export abstract class PaymentRequestsBaseComponent {
  public activePaymentRequests$ = this.store.select(fromPaymentsState.getActivePaymentRequests);
  public activePaymentRequestsCount$ = this.store.select(
    fromPaymentsState.getActivePaymentRequestsCount
  );
  public paymentRequestsState$ = this.store.select(
    fromPaymentsState.getActivePaymentRequestsActionState
  );
  public immomioIconName = ImmomioIconName;
  public archivePaymentRequests$ = this.store.select(fromPaymentsState.getArchivePaymentsRequests);
  public archivePaymentRequestsState$ = this.store.select(
    fromPaymentsState.getArchivePaymentsActionState
  );
  public archiveViewOpened = false;
  public archivedPaymentRequestsCount$ = this.store.select(
    fromPaymentsState.getArchivePaymentRequestsCount
  );
  public loadingTypes: LoadingType[] = [];

  constructor(protected store: Store) {}

  toggleArchivedView(): void {
    this.archiveViewOpened = !this.archiveViewOpened;
  }

  refreshList(args) {
    const pullRefresh = args.object;
    this.store.dispatch(fromPaymentsState.LoadPaymentRequests({ forced: true }));
    this.store.dispatch(fromPaymentsState.LoadArchivePaymentRequests({ forced: true }));
    pullRefresh.refreshing = false;
  }
}
