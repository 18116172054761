import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ButtonTypeEnum,
  getNews,
  ImmomioIconName,
  INewsState,
  LoadingType,
  NewsWidgetBaseComponent,
} from '@resident-nx/shared';
import { ContentCardPreset } from '../../molecules/content-card/content-card.model';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { CardWebComponent } from '../../atoms/card/card.component';
import { ContentCardWebComponent } from '../../molecules/content-card/content-card.component';
import { CardsContainerHeaderWebComponent } from '../../molecules/cards-container-header/cards-container-header.component';
import { LoadingSkeletonWebComponent } from '../../atoms/loading-skeleton/loading-skeleton.component';

@Component({
  selector: 'rs-web-news-widget',
  templateUrl: './news-widget.component.html',
  styleUrls: ['./news-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LoadingSkeletonWebComponent,
    CardsContainerHeaderWebComponent,
    ContentCardWebComponent,
    CardWebComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class NewsWidgetWebComponent extends NewsWidgetBaseComponent {
  @Input() override icon = ImmomioIconName.News;
  @Input() override title = 'news.current_news_l';
  @Input() override buttonText = 'news.more_current_news_a';
  @Input() override maxLines = 1;

  public newsLimit = 4;
  public override news$ = this.newsStore.select(getNews);
  public contentCardPreset = ContentCardPreset;
  public buttonTypeEnum = ButtonTypeEnum;
  protected readonly LoadingType = LoadingType;

  constructor(protected override newsStore: Store<INewsState>) {
    super(newsStore);
  }

  public onTap(newsId: number): void {
    this.tapped.emit(newsId);
  }

  public onTapOverview(): void {
    this.overviewTapped.emit();
  }
}
