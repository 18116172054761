import { gql } from 'apollo-angular';

export const registerDeviceMutation = gql`
  mutation registerDevice($input: RegisterDeviceInputBean!) {
    registerDevice(input: $input)
  }
`;

export const unregisterDeviceMutation = gql`
  mutation unregisterDevice($firebaseToken: String!) {
    unregisterDevice(firebaseToken: $firebaseToken)
  }
`;
