import { Directive } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromAnnouncementsState from '../../+state/news/announcements';

@Directive()
export abstract class AnnouncementDetailsBaseComponent {
  announcement$ = this.announcementsStore.select(fromAnnouncementsState.getAnnouncement);
  announcementActionState$ = this.announcementsStore.select(
    fromAnnouncementsState.getAnnouncementActionState
  );

  constructor(private announcementsStore: Store<fromAnnouncementsState.IAnnouncementsState>) {}
}
