import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { Store } from '@ngrx/store';
import { EMPTY, of, switchMap } from 'rxjs';

import { getAnnouncement, getAnnouncementActionState, LoadAnnouncement } from '../../+state';
import { IPublishedAnnouncement } from '../../models';
import { ResolverCommonService } from './resolver-common.service';

export const AnnouncementResolver: ResolveFn<IPublishedAnnouncement> = route => {
  const common = inject(ResolverCommonService);
  const store = inject(Store);

  const { resolverWaitForData } = route.data;
  const id = route.params?.id;
  if (!id) {
    return EMPTY;
  }
  return store.select(getAnnouncement).pipe(
    switchMap(announcement => {
      if (announcement && announcement.id === id) {
        return of(announcement);
      } else {
        return common.resolveHandler<IPublishedAnnouncement>(
          getAnnouncementActionState,
          LoadAnnouncement({ announcementId: id }),
          getAnnouncement,
          resolverWaitForData,
          true
        );
      }
    })
  );
};
