import { Directive, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromAccountUser from '../../+state/account/user-profile';
import { IEnvironment, ILegalInfoHref, ILegalTexts } from '../../models';
import { RouterService } from '../../services';
import { IActionState } from '../../utils';
import { ActivatedRoute } from '@angular/router';

@Directive()
export class UserLegalInfoPageBaseComponent implements OnInit {
  public legalTexts$: Observable<ILegalTexts>;
  public legalTextsState$: Observable<IActionState>;
  public hrefs: ILegalInfoHref;
  public htmlString = '';

  constructor(
    public route: ActivatedRoute,
    public routerService: RouterService,
    private store: Store<fromAccountUser.IUserProfileState>,
    @Inject('ENVIRONMENT') private environment: IEnvironment
  ) {}

  ngOnInit() {
    if (this.environment.legalInfoHrefs) {
      this.hrefs = this.environment.legalInfoHrefs;
    }
    this.legalTexts$ = this.store.select(fromAccountUser.getLegalTexts);
    this.legalTextsState$ = this.store.select(fromAccountUser.getLegalTextsActionState);
  }

  public open(html: string): void {
    this.htmlString = html;
  }
}
