import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { CardFooterBaseComponent, ImmomioIconName } from '@resident-nx/shared';
import { interval } from 'rxjs';
import { LabelWebComponent } from '../../atoms/label/label.component';
import { IconWebComponent } from '../../atoms/icon/icon.component';

@UntilDestroy()
@Component({
  selector: 'rs-web-card-footer',
  templateUrl: './card-footer.component.html',
  styleUrls: ['./card-footer.component.scss'],
  standalone: true,
  imports: [IconWebComponent, LabelWebComponent],
})
export class CardFooterWebComponent extends CardFooterBaseComponent implements OnInit {
  @Input() publishedAt: string;
  public immomioIconName = ImmomioIconName;
  public displayText = '';

  constructor(
    protected override translate: TranslateService,
    protected override datePipe: DatePipe
  ) {
    super(translate, datePipe);
  }

  ngOnInit(): void {
    this.displayText = this.setTimeAgo(Number(this.publishedAt));
    interval(60000)
      .pipe(untilDestroyed(this))
      .subscribe(() => (this.displayText = this.setTimeAgo(Number(this.publishedAt))));
  }
}
