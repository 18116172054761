import { ITicketCategory, TicketIssueType, TicketStatus } from './';
import { ICustomerSpecificDataResponse, IEditAction, IPageInfo, IResidentS3File } from '../general';

export interface ITicketResidentOverviewResponse {
  edges: ITicketResidentOverviewEdge[];
  pageInfo: IPageInfo;
}

export interface ITicketResidentOverviewEdge {
  cursor: string;
  node: ITicketResidentOverview;
}

export interface ITicketResidentOverview {
  id: string;
  ticketNr: string;
  title: string;
  state: TicketStatus;
  category: ITicketCategory;
  created: IEditAction;
  updated: IEditAction;
  titleImage: IResidentS3File;
  issueType: TicketIssueType;
  customerSpecificData: ICustomerSpecificDataResponse[];
}

export enum TicketSortField {
  UPDATED = 'UPDATED',
  CREATED = 'CREATED',
}
