import { Directive, EventEmitter, Injector, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, filter, take } from 'rxjs';
import * as fromRegisterState from '../../+state/account/registration';
import { IRegisterResident, IRegisterResidentResponse } from '../../models';
import { passwordValidator, customEmailValidator } from '../../utils/validators/validators';
import { FeedbackService, RouterService } from '../../services';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { IActionState } from '../../utils';
import { StorageService } from '../../infrastructure';

@Directive()
export abstract class RegisterFormBaseComponent {
  @Output() formSubmit = new EventEmitter();
  public registerResidentActionState$: Observable<IActionState>;
  registeredEmail: string;

  fg: FormGroup = this.fb.group({
    contractData: this.fb.group({
      contractNumber: [
        '',
        // '13660-0015-01',
        Validators.compose([
          Validators.required,
          Validators.pattern(/^[0-9]{0,4}[/]{0,1}[0-9]{5}[-]{1}[0-9]{4}[-]{1}[0-9]{2}$/),
        ]),
      ],
      partnerNumber: [
        '',
        // '2167721',
        Validators.compose([Validators.required, Validators.pattern(/^[0-9]{4,}$/)]),
      ],
    }),
    personalData: this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, customEmailValidator])],
      password: ['', Validators.compose([Validators.required, passwordValidator])],
    }),
    meta: this.fb.group({
      agreement: [false, Validators.requiredTrue],
    }),
  });
  store: Store<fromRegisterState.RegistrationState>;

  constructor(
    protected fb: FormBuilder,
    private storage: StorageService,
    private injector: Injector,
    private feedback: FeedbackService,
    private translate: TranslateService,
    private router: RouterService,
    private activatedRoute: ActivatedRoute
  ) {
    this.store = this.injector.get(Store<fromRegisterState.RegistrationState>);
    this.registerResidentActionState$ = this.store.select(
      fromRegisterState.getRegisterResidentActionState
    );
  }

  submit(fg: FormGroup) {
    if (!fg.valid) {
      fg.markAllAsTouched();
      return;
    }

    this.formSubmit.next(fg.value);

    this.store.dispatch(
      fromRegisterState.RegisterResident({
        registerResidentInput: this.createRegisterResidentInputResponse(),
      })
    );

    this.registerResidentActionState$
      .pipe(
        filter(state => !state.pending),
        take(1)
      )
      .subscribe(actionState => {
        if (actionState.done) {
          this.store
            .select(fromRegisterState.getRegisterResidentResponse)
            .pipe(
              filter(response => !!response),
              take(1)
            )
            .subscribe(response => this.handleResponse(response));
        }
      });
  }

  public createRegisterResidentInputResponse = (): IRegisterResident => {
    const { contractNumber, partnerNumber } = this.fg.get('contractData').value;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { email, password, phone, mobile, phonePrefix, mobilePrefix } =
      this.fg.get('personalData').value;

    this.registeredEmail = email.toLowerCase();

    const registerResidentInput: IRegisterResident = {
      email: this.registeredEmail,
      password,
      passwordConfirmation: password,
      identifiers: [
        {
          key: 'contractNumber',
          value: contractNumber,
        },
        {
          key: 'partnerNumber',
          value: partnerNumber,
        },
      ],
    };
    return registerResidentInput;
  };

  private handleResponse(res: IRegisterResidentResponse): void {
    if (res.status === 'RESIDENT_SUCCESSFULLY_REGISTERED_L') {
      this.storage.setItem('loginEmail', this.registeredEmail);
      this.storage.setItem('tmpAccessToken', res.accessToken);
      this.router.navigate(['../registration-success'], {
        relativeTo: this.activatedRoute,
      });
    } else {
      this.registrationError();
    }
  }

  private registrationError(): void {
    this.feedback.error({
      message: this.translate.instant('register.fail_message_l'),
      title: this.translate.instant('registration_failed_l'),
    });
  }
}
