import { createAction, props } from '@ngrx/store';
import {
  ICheckEmailExists,
  ICheckInvitationCode,
  ICheckNumbersResponse,
  IRegisterResidentResponse,
  IRequestInvitationCodeForm,
  IResetPasswordBeanPayload,
  IUserExistsByEmail,
  IVerifyEmailInput,
  RegisterResidentInput,
} from '../../../models';

export const RegisterResident = createAction(
  '[Account Registration] Register Resident',
  props<{ registerResidentInput: RegisterResidentInput }>()
);

export const RegisterResidentSuccess = createAction(
  '[Account Registration] Register Resident Success',
  props<{ registerResidentResponse: IRegisterResidentResponse }>()
);

export const RegisterResidentFailed = createAction(
  '[Account Registration] Register Resident Failed',
  props<{ error: Error }>()
);

export const VerifyContractAndPartnerNumber = createAction(
  '[Account Registration] Verify Contract and Partner Number',
  props<{ contractNumber: string; partnerNumber: string }>()
);

export const VerifyContractAndPartnerNumberSuccess = createAction(
  '[Account Registration] Verify Contract and Partner Number Success',
  props<{ checkNumbersResponse: ICheckNumbersResponse }>()
);

export const VerifyContractAndPartnerNumberFailed = createAction(
  '[Account Registration] Verify Contract and Partner Number Failed',
  props<{ error: Error }>()
);

export const TriggerRegisterConfirmationEmail = createAction(
  '[Account Registration] Trigger Register Confirmation Email',
  props<{ contractNumber: string; partnerNumber: string }>()
);

export const TriggerRegisterConfirmationEmailSuccess = createAction(
  '[Account Registration] Trigger Register Confirmation Email Success'
);

export const TriggerRegisterConfirmationEmailFailed = createAction(
  '[Account Registration] Trigger Register Confirmation Email Failed',
  props<{ error: Error }>()
);

export const VerifyRegistrationEmail = createAction(
  '[Account Registration] Register Verify Registration Email',
  props<{ verifyEmailInput: IVerifyEmailInput }>()
);

export const VerifyRegistrationEmailSuccess = createAction(
  '[Account Registration] Register Verify Registration Email Success'
);

export const VerifyRegistrationEmailFailed = createAction(
  '[Account Registration] Register Verify Registration Email Failed',
  props<{ error: Error }>()
);

export const InitResetPassword = createAction(
  '[Account Registration] Init Reset Password',
  props<{ email: string; web?: boolean }>()
);

export const InitResetPasswordSuccess = createAction(
  '[Account Registration] Init Reset Password Success'
);

export const InitResetPasswordFailed = createAction(
  '[Account Registration] Init Reset Password Failed',
  props<{ error: Error }>()
);

export const ResetPassword = createAction(
  '[Account Registration] Reset Password',
  props<{ resetPasswordBeanPayload: IResetPasswordBeanPayload }>()
);

export const ResetPasswordSuccess = createAction('[Account Registration] Reset Password Success');

export const ResetPasswordFailed = createAction(
  '[Account Registration] Reset Password Failed',
  props<{ error: Error }>()
);

export const SaveInvitationCode = createAction(
  '[Account Registration] Save Invitation Code',
  props<{ invitationCode: string }>()
);

export const CheckInvitationCode = createAction(
  '[Account Registration] Check Invitation Code',
  props<{ invitationCode: string }>()
);

export const CheckInvitationCodeSuccess = createAction(
  '[Account Registration] Check Invitation Code Success',
  props<{ response: ICheckInvitationCode }>()
);

export const CheckInvitationCodeFailed = createAction(
  '[Account Registration] Check Invitation Code Failed',
  props<{ error: Error }>()
);

export const CheckEmailExists = createAction(
  '[Account Registration] Check Email Exists',
  props<{ checkEmailExists: IUserExistsByEmail }>()
);

export const CheckEmailExistsSuccess = createAction(
  '[Account Registration] Check Email Exists Success',
  props<{ response: ICheckEmailExists; email?: string }>()
);

export const CheckEmailExistsFailed = createAction(
  '[Account Registration] Check Email Exists Failed',
  props<{ error: Error }>()
);

export const RequestInvitationCode = createAction(
  '[Account Registration] Request Invitation Code',
  props<{ requestInvitationCode: IRequestInvitationCodeForm }>()
);

export const RequestInvitationCodeSuccess = createAction(
  '[Account Registration] Request Invitation Code Success',
  props<{ response: boolean }>()
);

export const RequestInvitationCodeFailed = createAction(
  '[Account Registration] Request Invitation Code Failed',
  props<{ error: Error }>()
);

export const ClearInvitationCodeState = createAction(
  '[Account Registration] Clear CheckInvitationCode State'
);

export const ClearCheckEmailExistsState = createAction(
  '[Account Registration] Clear CheckEmailExists State'
);

export const ClearRegistrationFormState = createAction(
  '[Account Registration] Clear RegistrationForm State'
);
