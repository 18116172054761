import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, tap } from 'rxjs';
import { ROUTE } from '../../models';
import { Router } from '@angular/router';
import { getContracts, getContractsActionState, LoadContracts } from '@resident-nx/shared';
import { LocalStorageService } from '../storage';

export function DashboardPageGuard() {
  return () => {
    const store = inject(Store);
    const router = inject(Router);
    const localStorageSerice = inject(LocalStorageService);
    return store.select(getContractsActionState).pipe(
      tap(state => {
        if (!state.pending && !state.done) {
          store.dispatch(LoadContracts({}));
        }
      }),
      filter(state => state.done),
      switchMap(() =>
        store.select(getContracts).pipe(
          map(contracts => {
            const hideWelcomeScreen = localStorageSerice.getItem('hideWelcomeScreen');
            if (!contracts?.length && hideWelcomeScreen) {
              router.navigate([ROUTE.NEWS]);
              return false;
            } else return true;
          })
        )
      )
    );
  };
}
