import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { catchError, map, of } from 'rxjs';

import { RegisterDeviceInput } from '../../models';
import { registerDeviceMutation, unregisterDeviceMutation } from '../../queries';

@Injectable()
export class NotificationsFacade {
  constructor(private apollo: Apollo) {}

  public registerDevice(deviceData: RegisterDeviceInput) {
    return this.apollo
      .mutate<boolean>({
        mutation: registerDeviceMutation,
        fetchPolicy: 'no-cache',
        variables: {
          input: deviceData,
        },
      })
      .pipe(
        map(response => response?.data),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }

  public unregisterDevice(firebaseToken: string) {
    if (!firebaseToken) {
      return of(false);
    }
    return this.apollo
      .mutate<boolean>({
        mutation: unregisterDeviceMutation,
        fetchPolicy: 'no-cache',
        variables: {
          firebaseToken,
        },
      })
      .pipe(
        map(response => response?.data),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }
}
