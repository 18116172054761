import { gql } from 'apollo-angular';

export const legalTextFields = `
  agb
  imprint
  privacy
  digitalDocumentsReceival
  digitalDocumentsReceivalMandatory
`;

export const getLegalTextsQuery = gql`
  query getLegalTexts {
    legalTexts {
      ${legalTextFields}
    }
  }
`;
