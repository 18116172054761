import { createAction, props } from '@ngrx/store';
import { IPaymentPositionResident } from '../../models';

export const LoadPaymentRequests = createAction(
  '[Payment] Load Payments Requests',
  props<{ forced?: boolean }>()
);

export const LoadPaymentRequestsSuccess = createAction(
  '[Payment] Load Payments Requests Success',
  props<{ response: IPaymentPositionResident[] }>()
);

export const LoadPaymentRequestsFailed = createAction(
  '[Payment] Load Payments Requests Failed',
  props<{ error: Error }>()
);

export const LoadArchivePaymentRequests = createAction(
  '[Payment] Load Archive Payments Requests',
  props<{ forced?: boolean }>()
);

export const LoadArchivePaymentRequestsSuccess = createAction(
  '[Payment] Load Archive Payments Requests Success',
  props<{ response: IPaymentPositionResident[] }>()
);

export const LoadArchivePaymentRequestsFailed = createAction(
  '[Payment] Load Archive Payments Requests Failed',
  props<{ error: Error }>()
);
