import { Injectable } from '@angular/core';
import { concatMap, from, of, switchMap, withLatestFrom } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  emergencyContactsConverter,
  EmergencyContactsFacade,
  ErrorHandlerService,
} from '../../../services';
import * as fromActions from './emergency-contacts.actions';
import * as fromReducers from './emergency-contacts.reducer';
import * as fromEmergencyContactsState from './';

@Injectable()
export class EmergencyContactsEffects {
  constructor(
    private actions$: Actions,
    private emergencyContactsFacade: EmergencyContactsFacade,
    private store: Store<fromReducers.IEmergencyContactsState>,
    private errorHandlerService: ErrorHandlerService
  ) {}

  emergencyContactsExists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.EmergencyContactsExists),
      mergeMap(() => {
        return this.emergencyContactsFacade.emgergencyContactsExists().pipe(
          map(response => {
            return fromActions.EmergencyContactsExistsSuccess({
              emergencyContactExists: response.emergencyContactExists,
            });
          }),
          catchError(error => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on emergency-contacts.effects.ts:emergencyContactsExists$:',
              error
            );
            return of(fromActions.EmergencyContactsExistsFailed({ error }));
          })
        );
      })
    )
  );

  getEmergencyContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.GetEmergencyContacts),
      withLatestFrom(
        this.store.select(fromEmergencyContactsState.getActiveRequests),
        this.store.select(fromEmergencyContactsState.getEmergencyContactsByContract)
      ),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_, activeRequests, emergencyContactsByContract]) =>
        from(activeRequests).pipe(
          concatMap(contract => {
            if (emergencyContactsByContract && emergencyContactsByContract[contract]) {
              return of(
                fromActions.GetEmergencyContactsSuccess({
                  emergencyContactsResponse: emergencyContactsByContract[contract],
                  contract,
                })
              );
            }
            return this.emergencyContactsFacade.getEmergencyContacts(contract).pipe(
              map(emergencyContactsConverter.fromDTO),
              map(response =>
                fromActions.GetEmergencyContactsSuccess({
                  emergencyContactsResponse: response,
                  contract,
                })
              ),
              catchError(error => {
                this.errorHandlerService.handleError(
                  '(REQUEST ERROR) on emergency-contacts.effects.ts:getEmergencyContacts$:',
                  error
                );
                return of(fromActions.GetEmergencyContactsFailed({ contract, error }));
              })
            );
          })
        )
      )
    )
  );
}
