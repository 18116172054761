import { createReducer, on } from '@ngrx/store';
import { ActionStateCreator, IActionState } from '../../utils';
import * as fromActions from './service.actions';
import { IResidentS3File } from '../../models';

export interface IServiceState {
  downloads: File[];
  currentDownload: File;
  downloadActionState: IActionState;
  uploadedFiles: IResidentS3File[];
  uploadingActionState: IActionState;
}

export const initialState: IServiceState = {
  downloads: [],
  currentDownload: null,
  downloadActionState: ActionStateCreator.create(),
  uploadedFiles: [],
  uploadingActionState: ActionStateCreator.create(),
};

export const serviceReducer = createReducer(
  initialState,

  on(fromActions.DownloadDocument, state => {
    return {
      ...state,
      currentDownload: null,
      downloadActionState: ActionStateCreator.onStart(),
    };
  }),

  on(fromActions.DownloadDocumentSuccess, (state, { file }) => {
    return {
      ...state,
      downloadActionState: ActionStateCreator.onSuccess(),
      currentDownload: file,
      downloads: [...state.downloads, file],
    };
  }),

  on(fromActions.DownloadDocumentFailed, (state, { error }) => {
    return {
      ...state,
      downloadActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.UploadFiles, fromActions.UploadFilesWeb, state => {
    return {
      ...state,
      uploadingActionState: ActionStateCreator.onStart(),
    };
  }),

  on(fromActions.UploadFilesSuccess, (state, { files }) => {
    return {
      ...state,
      uploadingActionState: ActionStateCreator.onSuccess(),
      uploadedFiles: [...files],
    };
  }),

  on(fromActions.UploadFilesFailed, (state, { error }) => {
    return {
      ...state,
      uploadingActionState: ActionStateCreator.onError(error),
    };
  }),
  on(fromActions.ClearUploadedFiles, state => {
    return {
      ...state,
      uploadedFiles: [],
    };
  })
);

export const getDownloads = (state: IServiceState) => state.downloads;
export const getCurrentDownload = (state: IServiceState) => state.currentDownload;
export const getDownloadActionState = (state: IServiceState) => state.downloadActionState;

export const getUploadedFiles = (state: IServiceState) => state.uploadedFiles;
export const getUploadingActionState = (state: IServiceState) => state.uploadingActionState;
