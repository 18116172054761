import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NoDataBaseComponent } from '@resident-nx/shared';

@Component({
  selector: 'rs-web-no-data',
  templateUrl: './no-data.component.html',
  imports: [TranslateModule],
  styleUrls: ['./no-data.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoDataWebComponent extends NoDataBaseComponent {}
