import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import * as fromMasterDataState from '../../+state/account/masterdata';
import { IEnvironment } from '../../models';
import { getSelectedCustomerIdent } from '../../+state';

@Injectable()
export class CustomerService {
  public customerIdent: string;
  private customerIdentsForBandODamageFlow: string[] = [
    // 'fd3f402c-70a7-4523-878c-b070c50dd059', // ITG dev account for testing
    'be32bb20-fd85-47af-8d1f-82785160d779', // LEG sbx
    '6b557caf-1766-4271-9489-d71873eac482', //VHW sbx
  ];
  private customerIdentsWithoutTicketManagement: string[] = [
    'e85c71a3-fc2d-4c7d-93ea-ede2922474c6', //GEBAUSIE STG
    'c73a5486-b9e5-3f28-31fa-a8ade4a2e061', // GEBAUSIE PROD
  ];
  private customerIdentsWIthMessengerAndTicketImageEnabled: string[] = [
    '8c738452-348d-51a9-3f45-68981e4d8f6f', //GWH SBX
    '50712445-ab7b-8c4b-9e9f-59458bb0087c', //GWH PROD
  ];

  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private store: Store
  ) {
    this.store.select(getSelectedCustomerIdent).subscribe(response => {
      this.customerIdent = response;
    });
  }

  public showBandODamageFlow() {
    return this.customerIdentsForBandODamageFlow.some(
      customerIdent => customerIdent === this.customerIdent
    );
  }

  public hideTicketManagement(): boolean {
    return this.customerIdentsWithoutTicketManagement.some(
      customerIdent => customerIdent === this.customerIdent
    );
  }

  public appPreviewMode(): Observable<boolean> {
    return this.store.select(fromMasterDataState.getContracts).pipe(
      map(contracts => {
        // the user has no contracts or neither contract has started
        return !contracts || contracts.length === 0 || !contracts.some(c => c.contractStartTime);
      })
    );
  }

  public showMessengerAndTicketImage(): boolean {
    return this.customerIdentsWIthMessengerAndTicketImageEnabled.some(
      customerIdent => customerIdent === this.customerIdent
    );
  }
}
