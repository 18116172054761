export enum SettingsNotificationEmailType {
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  OFFER = 'OFFER',
  NEWS = 'NEWS',
  NEW_DOCUMENT = 'NEW_DOCUMENT',
  TICKET_COMMENT = 'TICKET_COMMENT',
  TICKET_STATUS_UPDATE = 'TICKET_STATUS_UPDATE',
  PAYMENT = 'PAYMENT',
}

export interface INotificationEmailSettings {
  allowedNotifications: SettingsNotificationEmailType[];
}

export class NotificationEmailSettings implements INotificationEmailSettings {
  allowedNotifications: SettingsNotificationEmailType[];

  constructor() {
    this.allowedNotifications = [];
  }
}

export const ANNOUNCEMENT_SETTINGS = [
  SettingsNotificationEmailType.ANNOUNCEMENT,
  SettingsNotificationEmailType.OFFER,
  SettingsNotificationEmailType.NEWS,
];
export const DOCUMENT_SETTINGS = [SettingsNotificationEmailType.NEW_DOCUMENT];
export const TICKET_SETTINGS = [
  SettingsNotificationEmailType.TICKET_COMMENT,
  SettingsNotificationEmailType.TICKET_STATUS_UPDATE,
];
export const PAYMENT_SETTINGS = [SettingsNotificationEmailType.PAYMENT];
export const SETTING_NOTIFICATIONS = 'NOTIFICATIONS';
export const SETTING_EMAILS = 'EMAILS';
