import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * This function returns errors for a target control in a validation relation,
 * when related control is already valid, so the target control's related error
 * can be removed.
 */
function getUpdatedTargetErrors(target: AbstractControl, errorName: string) {
  const filteredErrors = {};

  Object.keys(target.errors || {})
    .filter(key => key !== errorName)
    .forEach(key => {
      filteredErrors[key] = target.errors[key];
    });

  return filteredErrors && Object.keys(filteredErrors).length ? filteredErrors : null;
}

export function matchControlValidatorFactory(matchControlName: string, isSource = false) {
  return (control: AbstractControl) => {
    if (!matchControlName || !control.parent) return null;

    const selfValue = control.value;
    const target = control.parent.get(matchControlName);

    if (target && selfValue !== target.value && !isSource) return { matchControl: true };

    if (target && selfValue === target.value && isSource) {
      target.setErrors(getUpdatedTargetErrors(target, 'matchControl'));
    }

    if (target && selfValue !== target.value && isSource) {
      target.setErrors({ ...target.errors, matchControl: true });
    }

    return null;
  };
}

export function patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      // if control is empty return no error
      return null;
    }

    // test the value of the control against the regexp supplied
    const valid = regex.test(control.value);

    // if true, return no error (no error), else return error passed in the second parameter
    return valid ? null : error;
  };
}

export function maxFileSize(maxSize: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: unknown } | null => {
    const files = control.value;
    if (files && files instanceof FileList) {
      const filesArray = [...files];
      const hasFileLargerThanMaxSize = filesArray.some(file => file.size > maxSize);
      if (hasFileLargerThanMaxSize) {
        return { fileMaxSize: true };
      }
    }
    return null;
  };
}

export function sameValueValidator(input: unknown): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null =>
    control.value === input ? null : { sameValueRequired: true };
}
