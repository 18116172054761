import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@resident-nx/shared';

@Injectable()
export class DialogWebService extends DialogService {
  constructor(private translate: TranslateService) {
    super();
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  action(title: string, actions: any[]) {
    //TODO
    return;
  }
}
