export const hexToHSL = (hexColor: string): { h: number; s: number; l: number } => {
  let r = '0',
    g = '0',
    b = '0';
  if (hexColor.length == 4) {
    r = `0x${hexColor[1]}${hexColor[1]}`;
    g = `0x${hexColor[2]}${hexColor[2]}`;
    b = `0x${hexColor[3]}${hexColor[3]}`;
  } else if (hexColor.length == 7) {
    r = `0x${hexColor[1]}${hexColor[2]}`;
    g = `0x${hexColor[3]}${hexColor[4]}`;
    b = `0x${hexColor[5]}${hexColor[6]}`;
  }
  // Then to HSL
  const rDec = parseInt(r, 16) / 255,
    gDec = parseInt(g, 16) / 255,
    bDec = parseInt(b, 16) / 255;

  const cMin = Math.min(rDec, gDec, bDec),
    cMax = Math.max(rDec, gDec, bDec),
    delta = cMax - cMin;
  let h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cMax == rDec) h = ((gDec - bDec) / delta) % 6;
  else if (cMax == gDec) h = (bDec - rDec) / delta + 2;
  else h = (rDec - gDec) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cMax + cMin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return { h, s, l };
};

export const HSLToHex = ({ h, s, l }) => {
  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2;
  let r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  // Having obtained RGB, convert channels to hex
  let rHex = Math.round((r + m) * 255).toString(16),
    gHex = Math.round((g + m) * 255).toString(16),
    bHex = Math.round((b + m) * 255).toString(16);

  // Prepend 0s, if necessary
  if (rHex.length == 1) rHex = `0${rHex}`;
  if (gHex.length == 1) gHex = `0${gHex}`;
  if (bHex.length == 1) bHex = `0${bHex}`;

  return `#${rHex}${gHex}${bHex}`;
};
