import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { IMasterDataUser, ImmomioIconName } from '../../models';

@Directive()
export abstract class ProfileButtonBaseComponent {
  @Input() user: IMasterDataUser;
  @Input() userInitials: string;
  @Input() className: string;
  @Output() tapped = new EventEmitter();
  public immomioIconName = ImmomioIconName;
}
