import { Inject, Injectable } from '@angular/core';
import * as fromTicketsState from '../../+state/tickets';
import {
  IContract,
  IEnvironment,
  IPublishedAnnouncement,
  IPublishedNews,
  IPublishedOffer,
  ITicketResidentOverview,
  ITicketResidentOverviewEdge,
} from '../../models';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as JsSearch from 'js-search';
import { Subscription, switchMap, tap } from 'rxjs';
import {
  getActiveDamageTickets,
  getActiveRequestTickets,
  getAnnouncements,
  getAnnouncementsActionState,
  getContracts,
  getContractsActionState,
  getNews,
  getNewsActionState,
  getOffers,
  getOffersActionState,
  getSearchResults,
  LoadActiveDamageTickets,
  LoadActiveRequestTickets,
  LoadAnnouncements,
  LoadContracts,
  LoadNews,
  LoadOffers,
  LoadPublishedNewsPublic,
  LoadPublishedOffersPublic,
} from '../../+state';
import { AddressPipe } from '../../pipes';

export interface ITicketResidentSearch extends ITicketResidentOverview {
  description: string;
  title: string;
  publishedAt: string;
}

export interface IContractSearch extends IContract {
  description: string;
  title: string;
  publishedAt?: string;
}

export interface IFunctionSearch {
  title: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  url: any[];
}

export enum SearchType {
  NEWS = 'NEWS',
  OFFER = 'OFFER',
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  INFORMATION = 'INFORMATION',
  DAMAGE_TICKET = 'DAMAGE_TICKET',
  REQUEST_TICKET = 'REQUEST_TICKET',
  DOCUMENT = 'DOCUMENT',
  CONTRACT = 'CONTRACT',
  FUNCTION = 'FUNCTION',
}

export interface ISearchItem {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchValue: any[];
  searchKey: string[] | string[][];
  searchType: SearchType;
  searchUidFieldName: string;
}

export interface ISearchResult {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  results: any[];
  type: SearchType;
}

// some apps might use different endpoints to retrieve data from the same category
// the type below is used to choose the eps fo the search and can be extended if needed
export type TSearchDispatchActions = {
  newsAction: ReturnType<typeof LoadNews> | ReturnType<typeof LoadPublishedNewsPublic>;
  offersAction: ReturnType<typeof LoadOffers> | ReturnType<typeof LoadPublishedOffersPublic>;
  announcementsAction: ReturnType<typeof LoadAnnouncements>;
  tickets: {
    damagesAction: ReturnType<typeof LoadActiveDamageTickets>;
    concernsAction: ReturnType<typeof LoadActiveRequestTickets>;
  };
  contractsAction: ReturnType<typeof LoadContracts>;
};

// Saas
const defaultSearchDispatchActions: TSearchDispatchActions = {
  newsAction: LoadNews({ offset: 0, limit: 100 }),
  offersAction: LoadOffers({ offset: 0, limit: 100 }),
  announcementsAction: LoadAnnouncements({ offset: 0, limit: 100 }),
  contractsAction: LoadContracts({}),
  tickets: {
    damagesAction: LoadActiveDamageTickets({ offset: 0, limit: 100 }),
    concernsAction: LoadActiveRequestTickets({ offset: 0, limit: 100 }),
  },
};

@Injectable()
export class SearchService {
  // defect item
  public damageTicketItems: ITicketResidentSearch[] = [];
  public damageTicketSearchedValues: ITicketResidentOverview[] = [];
  public damageTicketSearchKey = ['title', 'description']; // 'value' for searching in customerSpecificData

  // concern item
  public requestTicketItems: ITicketResidentSearch[] = [];
  public requestTicketSearchedValues: ITicketResidentOverview[] = [];
  public requestTicketSearchKey = ['title', 'description'];

  // news item;
  public newsItems: IPublishedNews[] = [];
  public newsItemsSearchedValues: IPublishedNews[] = [];
  public newsSearchKey = ['title', 'text'];

  // offer item;
  public offerItems: IPublishedOffer[] = [];
  public offerItemsSearchedValues: IPublishedOffer[] = [];
  public offerSearchKey = ['title', 'body'];

  // announcement item;
  public announcementItems: IPublishedAnnouncement[] = [];
  public announcementItemsSearchedValues: IPublishedAnnouncement[] = [];
  public announcementSearchKey = ['title', 'body'];

  // contract items;
  public contractItems: IContractSearch[] = [];
  public contractItemsSearchedValues: IContractSearch[] = [];
  public contractSearchKey = ['title', 'body'];

  // function items;
  public functionItems: IFunctionSearch[] = [];
  public functionItemsSearchedValues: IFunctionSearch[] = [];
  public functionSearchKey = ['title', 'description'];
  public searchableItems = [];
  results$ = this.store.select(getSearchResults);
  private subs$: Subscription[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private searches: { [key: string]: { item: ISearchItem; search: any } } = {};

  /*   // Information item
  public informationItems: ResidentInformation[];
  public informationItemsSearchedValues: ResidentInformation[];
  public informationSearchKey = ['title', 'text']; */

  /*  // Information item
  public documentItems: IResidentS3File[] = [];
  public documentItemsSearchedValues: IResidentS3File[] = [];
  public documentSearchKey = ['title']; */

  constructor(
    private store: Store,
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private translate: TranslateService
  ) {}

  public init(searchDispatchActions: TSearchDispatchActions = defaultSearchDispatchActions) {
    const { newsAction, offersAction, announcementsAction, contractsAction, tickets } =
      searchDispatchActions;
    this.initializeFunctions();
    this.initializeDamageTickets(tickets.damagesAction);
    this.initializeRequestTickets(tickets.concernsAction);
    this.initializeContracts(contractsAction);
    this.initializeNews(newsAction);
    this.initializeAnnouncement(announcementsAction);
    this.initializeOffers(offersAction);
  }

  search(target: string) {
    let searchResults: ISearchResult[] = [];

    for (const key in this.searches) {
      const search = this.searches[key];
      const results = search.search.search(target);
      if (results && results.length > 0) {
        const searchResult: ISearchResult = { type: search.item.searchType, results };
        searchResults = [...searchResults, searchResult];
      }
    }
    return searchResults;
  }

  private addItem(item: ISearchItem) {
    const search = new JsSearch.Search('id');
    search.addDocuments(item.searchValue);
    for (const sk of item.searchKey) {
      search.addIndex(sk);
    }
    this.searches[item.searchType] = { item, search };
  }

  private initializeDamageTickets(action: ReturnType<typeof LoadActiveDamageTickets>) {
    this.subs$[0] = this.store
      .select(fromTicketsState.getActiveDamageTicketsActionState)
      .pipe(
        tap(state => {
          if (!state.done && !state.pending && !state.error) {
            this.store.dispatch(action);
          }
        }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        switchMap(state => {
          return this.store.select(getActiveDamageTickets);
        })
      )
      .subscribe((tickets: ITicketResidentOverviewEdge[]) => {
        tickets.forEach(ticket => {
          const description = ticket.node.customerSpecificData?.find(
            e => e.key === 'description'
          )?.value;
          const title = ticket.node.category.name;
          const publishedAt = new Date(ticket.node.updated.date).getTime().toString().slice(0, -3);
          this.damageTicketItems.push({ ...ticket.node, description, title, publishedAt }); // format to find description in search
        });
        const searchItem = {
          searchValue: this.damageTicketItems,
          searchKey: this.damageTicketSearchKey,
          searchType: SearchType.DAMAGE_TICKET,
          searchUidFieldName: 'id',
        };
        this.addItem(searchItem);
      });
  }

  private initializeRequestTickets(action: ReturnType<typeof LoadActiveRequestTickets>) {
    this.subs$[1] = this.store
      .select(fromTicketsState.getActiveRequestTicketsActionState)
      .pipe(
        tap(state => {
          if (!state.done && !state.pending && !state.error) {
            this.store.dispatch(action);
          }
        }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        switchMap(state => {
          return this.store.select(getActiveRequestTickets);
        })
      )
      .subscribe((tickets: ITicketResidentOverviewEdge[]) => {
        tickets.forEach(ticket => {
          const description = ticket.node.customerSpecificData?.find(
            e => e.key === 'description'
          )?.value;
          const title = ticket.node.category.name;
          const publishedAt = new Date(ticket.node.updated.date)
            .getTime()
            .toString()
            .substring(0, -4);
          this.requestTicketItems.push({ ...ticket.node, description, title, publishedAt }); // format to find description in search
        });
        const searchItem = {
          searchValue: this.requestTicketItems,
          searchKey: this.requestTicketSearchKey,
          searchType: SearchType.REQUEST_TICKET,
          searchUidFieldName: 'id',
        };
        this.addItem(searchItem);
      });
  }

  private initializeNews(
    action: ReturnType<typeof LoadNews> | ReturnType<typeof LoadPublishedNewsPublic>
  ) {
    this.subs$[2] = this.store
      .select(getNewsActionState)
      .pipe(
        tap(state => {
          if (!state.done && !state.pending && !state.error) {
            return this.store.dispatch(action);
          }
        }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        switchMap(state => {
          return this.store.select(getNews);
        })
      )
      .subscribe(news => {
        news.forEach(newsItem => this.newsItems.push({ ...newsItem.node }));
        const searchItem = {
          searchValue: this.newsItems,
          searchKey: this.newsSearchKey,
          searchType: SearchType.NEWS,
          searchUidFieldName: 'id',
        };
        this.addItem(searchItem);
      });
  }

  private initializeOffers(
    action: ReturnType<typeof LoadOffers> | ReturnType<typeof LoadPublishedOffersPublic>
  ) {
    this.subs$[3] = this.store
      .select(getOffersActionState)
      .pipe(
        tap(state => {
          if (!state.done && !state.pending && !state.error) {
            return this.store.dispatch(action);
          }
        }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        switchMap(state => {
          return this.store.select(getOffers);
        })
      )
      .subscribe(offers => {
        offers.forEach(offer => this.offerItems.push({ ...offer.node }));
        const searchItem = {
          searchValue: this.offerItems,
          searchKey: this.offerSearchKey,
          searchType: SearchType.OFFER,
          searchUidFieldName: 'id',
        };
        this.addItem(searchItem);
      });
  }

  private initializeAnnouncement(action: ReturnType<typeof LoadAnnouncements>) {
    this.subs$[4] = this.store
      .select(getAnnouncementsActionState)
      .pipe(
        tap(state => {
          if (!state.done && !state.pending && !state.error) {
            return this.store.dispatch(action);
          }
        }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        switchMap(state => {
          return this.store.select(getAnnouncements);
        })
      )
      .subscribe(announcements => {
        announcements.forEach(announcement =>
          this.announcementItems.push({ ...announcement.node })
        );
        const searchItem = {
          searchValue: this.announcementItems,
          searchKey: this.announcementSearchKey,
          searchType: SearchType.ANNOUNCEMENT,
          searchUidFieldName: 'id',
        };
        this.addItem(searchItem);
      });
  }

  private initializeContracts(action: ReturnType<typeof LoadContracts>) {
    this.subs$[5] = this.store
      .select(getContractsActionState)
      .pipe(
        tap(state => {
          if (!state.done && !state.pending && !state.error) {
            return this.store.dispatch(action);
          }
        }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        switchMap(state => {
          return this.store.select(getContracts);
        })
      )
      .subscribe(contracts => {
        contracts.forEach(contract => {
          const description = new AddressPipe().transform(contract.address);
          const title = contract.externalId;
          this.contractItems.push({ ...contract, title, description });
        });
        const searchItem = {
          searchValue: this.contractItems,
          searchKey: this.contractSearchKey,
          searchType: SearchType.CONTRACT,
          searchUidFieldName: 'id',
        };
        this.addItem(searchItem);
      });
  }

  /* private initializeSelectedContractDocuments() {
    this.subs$[2] = this.store
      .select(fromDocumentsState.getContractDocumentsList)
      .pipe(untilDestroyed(this))
      .subscribe((documents: IResidentS3File[]) => {
        if (documents) {
          this.documentItems = documents;
          this.searchedItems.push({
            searchValue: this.documentItems,
            searchKey: this.documentSearchKey,
            searchType: SearchType.DOCUMENT,
            searchUidFieldName: 'title',
          });
        }
      });
  } */

  private initializeFunctions() {
    const functions: IFunctionSearch[] = [
      {
        title: this.translate.instant('ticket_damage.create_new_b'),
        url: ['content', 'concern', 'ticket-creation', 'PROPERTY_DAMAGE'],
      },
      {
        title: this.translate.instant('ticket_request.create_new_b'),
        url: ['content', 'concern', 'ticket-creation', 'REQUEST'],
      },
      {
        title: this.translate.instant('user.personal_data'),
        url: ['content', 'user', 'profile'],
      },
      {
        title: this.translate.instant('user.privacy'),
        url: ['content', 'user', 'privacy'],
      },
      {
        title: this.translate.instant('user.faq'),
        url: ['content', 'user', 'faq'],
      },
      {
        title: this.translate.instant('user.delete_account'),
        url: ['content', 'user', 'delete'],
      },
    ];
    this.functionItems = functions;
    const searchItem = {
      searchValue: this.functionItems,
      searchKey: this.functionSearchKey,
      searchType: SearchType.FUNCTION,
      searchUidFieldName: 'id',
    };
    this.addItem(searchItem);
  }
}
