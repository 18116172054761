import { createAction, props } from '@ngrx/store';
import { IFaq, IFaqOverviewResponseBean } from '../../models';

export const LoadFaqs = createAction('[Support] Load Faqs');
export const LoadOverviewFaqs = createAction('[Support] Load Overview Faqs');

export const LoadFaqsSuccess = createAction(
  '[Support] Load Faqs Success',
  props<{ faqs: IFaq[] }>()
);

export const LoadOverviewFaqsSuccess = createAction(
  '[Support] Load Overview Faqs Success',
  props<{ faqsOverview: IFaqOverviewResponseBean }>()
);

export const LoadFaqsFailed = createAction('[Support] Load Faqs Failed', props<{ error: Error }>());
export const LoadOverviewFaqsFailed = createAction(
  '[Support] Load Overview Faqs Failed',
  props<{ error: Error }>()
);
