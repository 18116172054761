import { Directive, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IConsumptionInfoItem, NameDisplayMode } from '../../models';
import * as fromConsumptionState from '../../+state/consumption';

@UntilDestroy()
@Directive()
export abstract class ConsumptionTypeSelectorBaseComponent implements OnInit {
  public meters: IConsumptionInfoItem[] = [];
  public selectedMeter: IConsumptionInfoItem;
  public nameDisplayMode = NameDisplayMode;

  constructor(private store: Store<fromConsumptionState.IConsumptionState>) {}

  ngOnInit() {
    this.store
      .select(fromConsumptionState.getAvailableMeters)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        if (res) {
          this.meters = res.meters;
        }
      });
    this.store
      .select(fromConsumptionState.getFirstSelectedMeter)
      .pipe(untilDestroyed(this))
      .subscribe(res => (this.selectedMeter = res));
  }

  public selectMeter(meter: IConsumptionInfoItem): void {
    this.store.dispatch(fromConsumptionState.SelectMeters({ meters: [meter] }));
  }
}
