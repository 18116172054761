import { Pipe, PipeTransform } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ITicketCreationSummaryItem, ScoreQuestionType } from '../models';

@Pipe({
  name: 'inputLabelOptional',
  standalone: true,
})
export class InputLabelOptionalPipe implements PipeTransform {
  transform(value: ITicketCreationSummaryItem, control: FormControl): string {
    if (
      control &&
      !control.hasValidator(Validators.required) &&
      value.type !== ScoreQuestionType.LABEL
    ) {
      return `${value.title} (optional)`;
    }
    return value.title;
  }
}
