import {
  ConsumptionDataVersion,
  ICustomerAppPermissions,
  ICustomerAppPermissionsResponse,
  IPermissions,
  IResidentAppSettings,
  TicketingVersion,
} from '../../../models';

export const permissionsConverter = {
  fromResidentAppSettings: (residentAppSettings: IResidentAppSettings): IResidentAppSettings => {
    return {
      ...residentAppSettings,
      ticketingVersion: residentAppSettings?.ticketingVersion || TicketingVersion.HIERARCHICAL,
      consumptionDataVersion:
        residentAppSettings?.consumptionDataVersion || ConsumptionDataVersion.EXTERNAL,
    };
  },

  fromDto: (response: ICustomerAppPermissionsResponse): ICustomerAppPermissions => {
    return {
      permissions: response?.bookedModulesResponseBean as IPermissions,
      residentAppSettings: permissionsConverter.fromResidentAppSettings(
        response?.residentAppSettings
      ),
    };
  },
};
