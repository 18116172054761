import { Elevation, ElevationDefaultConfigType } from './elevation.model';

export const ELEVATION_DEFAULT_CONFIG: ElevationDefaultConfigType = {
  elevation: Elevation.ZERO,
  elevationChangeStepOnHover: 2,
  elevationChangeStepOnClick: 1,
  elevationChangeStepOnTouch: -2,
  clickable: false,
};

export const ELEVATION_MAX_VALUE = Math.max(...Object.values(Elevation));
