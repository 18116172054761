import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getLogo, IResidentS3File } from '@resident-nx/shared';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rs-web-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, TranslateModule],
})
export class LogoComponent implements OnInit {
  public logo$: Observable<IResidentS3File>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.logo$ = this.store.select(getLogo);
  }
}
