import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  ButtonBorderRadiusEnum,
  ButtonSizeEnum,
  ButtonTypeEnum,
  DocumentSortField,
  getDocumentSorting,
  getSelectedContract,
  GetSharedContent,
  getSharedContent,
  ImmomioIconName,
  ISharedDocumentsResponse,
  ISharedFolderContentResponse,
  ISort,
} from '@resident-nx/shared';
import { combineLatest, Observable, tap } from 'rxjs';
import { FileDownloadWebService } from '../../../../services';
import { ImageBorderRadiusEnum } from '../../../atoms/image/image.model';
import { DocumentBreadcrumbsPipe } from '../../../../pipes/document-breadcrumb.pipe';
import { AsyncPipe } from '@angular/common';
import { LoaderWebComponent } from '../../../atoms/loader/loader.component';
import { ImageWebComponent } from '../../../atoms/image/image.component';
import { ButtonWebComponent } from '../../../atoms/button/button.component';
import { IconWebComponent } from '../../../atoms/icon/icon.component';
import { ElevationDirective } from '../../../../directives/elevation/elevation.directive';

@UntilDestroy()
@Component({
  selector: 'rs-web-documents-mobile',
  templateUrl: './document-mobile.component.html',
  styleUrls: ['./document-mobile.component.scss'],
  standalone: true,
  imports: [
    ElevationDirective,
    IconWebComponent,
    ButtonWebComponent,
    ImageWebComponent,
    LoaderWebComponent,
    AsyncPipe,
    DocumentBreadcrumbsPipe,
  ],
})
export class MobileDocumentsWebComponent implements OnInit {
  @Input() download: (downloadBlob: string, documentName: string) => void;
  public documents$: Observable<ISharedFolderContentResponse>;
  public downloadBlob: string;
  public previewUrl: string;
  public previewLoading: boolean;
  public previewOpen = false;
  public documentName: string;
  public documentType: string;

  //For image component styling
  public imageBorderRadius = ImageBorderRadiusEnum;

  //For button component styling
  public buttonSize = ButtonSizeEnum;
  public buttonBorderRadius = ButtonBorderRadiusEnum;
  public buttonType = ButtonTypeEnum;
  public immomioIconName = ImmomioIconName;
  private selectedContractId: string;
  private selectedSorting: ISort<DocumentSortField>;

  constructor(
    private store: Store,
    private downloadService: FileDownloadWebService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    combineLatest([this.store.select(getSelectedContract), this.store.select(getDocumentSorting)])
      .pipe(
        tap(([contract, sort]) => {
          this.selectedContractId = contract.id;
          this.selectedSorting = sort;
        }),
        untilDestroyed(this)
      )
      .subscribe();
    this.documents$ = this.store.select(getSharedContent);
  }

  public openFolder(folderId: string): void {
    this.store.dispatch(
      GetSharedContent({
        params: {
          folderId,
          contractId: this.selectedContractId,
          sortField: this.selectedSorting.value,
          sortOrder: this.selectedSorting.direction,
        },
      })
    );
  }

  public openPreview(document: ISharedDocumentsResponse) {
    this.previewLoading = true;
    this.previewOpen = true;
    this.documentName = document.name;
    this.documentType = document.extension;
    this.downloadService
      .getDocument(document)
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        this.previewUrl = data.preview as string;
        this.downloadBlob = data.download;
        this.previewLoading = false;
        this.cdr.markForCheck();
      });
  }

  public closePreview() {
    this.previewOpen = false;
  }
}
