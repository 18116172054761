import { IEnvironment } from '../models';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let appInfo: any;
export const environment: IEnvironment = {
  appVersion: appInfo.versionNumber,
  buildNumber: appInfo.buildNumber,
  commitHash: appInfo.commitHash,
  newrelicToken: '',
  cacheDuration: 30 * 60 * 1000,
  // common properties
};
