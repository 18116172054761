import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rsWebDate',
  standalone: true,
})
export class DateTransformPipe implements PipeTransform {
  transform(value: string): string {
    const dateValue = new Date(0);
    dateValue.setUTCSeconds(Number.parseFloat(value));
    const day = dateValue.toLocaleDateString('de-DE', { weekday: 'short' });
    const dateNumber = dateValue.toLocaleDateString('de-DE', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
    return `${day} ${dateNumber}`;
  }
}
