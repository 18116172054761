<div class="d-flex h-100">
  <i
    class="d-flex align-items-center"
    [class]="variant + ' ' + iconBaseClass + '-' + icon"
    [style.font-size]="size + 'px'"
    [style.color]="color"
    [style.cursor]="cursor"
    (click)="tapped.emit()"
  ></i>
</div>
