export interface ITranslationsData {
  translations: ITranslations[];
}
export interface ITranslations {
  key: string;
  translation: string;
}

export enum PhraseAppProject {
  BACKEND = 'BACKEND',
  LANDLORD = 'LANDLORD',
  ADMIN = 'ADMIN',
  TENANT = 'TENANT',
  HOMEPAGE = 'HOMEPAGE',
  SHARED = 'SHARED',
  RESIDENT = 'RESIDENT',
}
