import { Pipe, PipeTransform } from '@angular/core';
import { IAddress } from '../models';

@Pipe({
  name: 'address',
  standalone: true,
})
export class AddressPipe implements PipeTransform {
  transform(value: IAddress): string | null {
    if (!value) {
      return null;
    }
    let address = '';
    if (value.street) {
      address += value.street;
    }
    if (value.houseNumber) {
      address.length && (address += ' ');
      address += value.houseNumber;
    }
    if (value.zipCode) {
      address.length && (address += ', ');
      address += value.zipCode;
    }
    if (value.city) {
      address.length && (address += ' ');
      address += value.city;
    }
    return address;
  }
}
