import {
  FeedbackType,
  IFeedbackApi,
  IFeedbackHideOptions,
  IFeedbackShowOptions,
} from '../../models';

export abstract class FeedbackService implements IFeedbackApi {
  abstract show(options: IFeedbackShowOptions): Promise<void>;

  abstract hide(options?: IFeedbackHideOptions): Promise<void>;

  success(options: IFeedbackShowOptions): Promise<void> {
    options.type = FeedbackType.Success;
    return this.show(options);
  }

  warning(options: IFeedbackShowOptions): Promise<void> {
    options.type = FeedbackType.Warning;
    return this.show(options);
  }

  error(options: IFeedbackShowOptions): Promise<void> {
    options.type = FeedbackType.Error;
    return this.show(options);
  }

  info(options: IFeedbackShowOptions): Promise<void> {
    options.type = FeedbackType.Info;
    return this.show(options);
  }
}
