import { createSelector } from '@ngrx/store';

import { getNotificationSettingsState } from '../../reducers';
import * as fromReducer from './notification-settings.reducers';

export const getNotificationSettingsActionState = createSelector(
  getNotificationSettingsState,
  fromReducer.getNotificationSettingsActionState
);

export const getNotificationSettings = createSelector(
  getNotificationSettingsState,
  fromReducer.getNotificationSettings
);

export const getChangeNotificationSettingsActionState = createSelector(
  getNotificationSettingsState,
  fromReducer.getChangeNotificationSettingsActionState
);
