export interface IContactPersonResponse {
  id: string;
  name: string;
  position: string;
  company: string;
  serviceHour: string;
  contactData: IContactData;
  address: IEmergencyContactAddressResponse;
}

export interface IContactData {
  mobile: string;
  phone: string;
  email: string;
}

export interface IEmergencyContactAddressResponse {
  street: string;
  houseNumber: string;
  city: string;
  zipCode: string;
  region: string;
  country: string;
  district: string;
}
