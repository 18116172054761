<div class="date">
  <input
    class="form-control"
    #d="ngbDatepicker"
    ngbDatepicker
    [positionTarget]="icon"
    [formControl]="ngControl.control"
  />
  <div #icon class="calendar-icon">
    <rs-web-icon [icon]="ImmomioIconName.Calendar" (tapped)="d.toggle()"></rs-web-icon>
  </div>
</div>
