import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rsWebFileList',
  standalone: true,
})
export class FileListPipe implements PipeTransform {
  transform(value: FileList): File[] {
    if (!value) return [];
    return [...value];
  }
}
