import { Directive, Input, OnInit } from '@angular/core';
import { WhitelabelImage, WhitelabelImageType } from '../../models/general/whitelabel-image.enum';

@Directive()
export class WhitelabelImageBaseComponent implements OnInit {
  @Input() whitelabelImage: WhitelabelImage;
  @Input() whitelabelImageType: WhitelabelImageType;
  imageSource = '~/assets/shared/images/';

  ngOnInit() {
    this.imageSource +=
      this.whitelabelImageType + '/' + this.whitelabelImage + '.' + this.whitelabelImageType;
  }
}
