import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
} from '@angular/core';
import { ButtonTypeEnum, ImmomioIconName } from '@resident-nx/shared';
import { TranslateModule } from '@ngx-translate/core';
import { CardWebComponent } from '../../../atoms/card/card.component';
import { IconWebComponent } from '../../../atoms/icon/icon.component';
import { ButtonWebComponent } from '../../../atoms/button/button.component';
import { ElevationDirective } from '../../../../directives/elevation/elevation.directive';

@Component({
  selector: 'rs-web-archive-tickets-card',
  standalone: true,
  imports: [
    CardWebComponent,
    IconWebComponent,
    ButtonWebComponent,
    ElevationDirective,
    TranslateModule,
  ],
  templateUrl: './archive-tickets-card.component.html',
  styleUrl: './archive-tickets-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArchiveTicketsCardWebComponent {
  @Input({ required: true }) titleText: string;
  @Input({ required: true }) buttonText: string;
  @Input({ required: true }) icon: ImmomioIconName;
  @Output() expanded = new EventEmitter();
  public isCollapsed = signal(true);

  public toggleCollapsed() {
    this.isCollapsed.update(collapsed => !collapsed);
    this.expanded.emit();
    this.expanded.complete();
  }

  protected readonly immomioIconName = ImmomioIconName;
  protected readonly buttonTypeEnum = ButtonTypeEnum;
}
