import { MasterDataEffects } from './masterdata';
import { PermissionsEffects } from './permissions';
import { RegistrationEffects } from './registration';
import { UserProfileEffects } from './user-profile';
import { UserAppsEffects } from './user-apps';
import { LoginEffects } from './login';
import { EmergencyContactsEffects } from './emergency-contacts';

export * from './masterdata';
export * from './permissions';
export * from './registration';
export * from './user-profile';
export * from './user-apps';
export * from './emergency-contacts';

export * from './login';
export const ACCOUNT_EFFECTS = [
  UserProfileEffects,
  MasterDataEffects,
  PermissionsEffects,
  RegistrationEffects,
  LoginEffects,
  UserAppsEffects,
  EmergencyContactsEffects,
];
