import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ImmomioIconName, IPaymentPositionResident, PaymentRequestStatus } from '../../models';
import { IconFontVariant } from '../atoms';

@Directive()
export abstract class PaymentRequestCardBaseComponent {
  @Input() paymentRequest: IPaymentPositionResident;
  @Output() tapped = new EventEmitter();
  public immomioIconName = ImmomioIconName;
  public iconFontVariant = IconFontVariant;
  public paymentRequestStatus = PaymentRequestStatus;
}
