import { Component, Input, OnInit } from '@angular/core';
import {
  ConsumptionIconPipe,
  ConsumptionNamePipe,
  ConsumptionUnitTypePipe,
  getSelectedConsumptionForMeter,
  IConsumption,
  IConsumptionInfoItem,
  IConsumptionState,
  NameDisplayMode,
} from '@resident-nx/shared';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, DatePipe, DecimalPipe } from '@angular/common';
import { IconWebComponent } from '../../../atoms/icon/icon.component';

@Component({
  selector: 'rs-web-consumption-chart-additional-info',
  templateUrl: 'consumption-chart-additional-info.component.html',
  styleUrls: ['consumption-chart-additional-info.component.scss'],
  standalone: true,
  imports: [
    IconWebComponent,
    AsyncPipe,
    DecimalPipe,
    DatePipe,
    TranslateModule,
    ConsumptionIconPipe,
    ConsumptionNamePipe,
    ConsumptionUnitTypePipe,
  ],
})
export class ConsumptionChartAdditionalInfoWebComponent implements OnInit {
  @Input() meter: IConsumptionInfoItem;

  public selectedConsumption$: Observable<IConsumption>;

  constructor(private store: Store<IConsumptionState>) {}

  ngOnInit() {
    this.selectedConsumption$ = this.store.select(
      getSelectedConsumptionForMeter(this.meter.deviceId)
    );
  }

  protected readonly NameDisplayMode = NameDisplayMode;
}
