import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import {
  getOffers,
  getOffersActionState,
  LoadOffers,
  LoadPublishedOffersPublic,
} from '../../+state';
import { IPublishedOffersEdge } from '../../models';
import { ResolverCommonService } from './resolver-common.service';

export const OffersResolver: ResolveFn<IPublishedOffersEdge[]> = route => {
  const common = inject(ResolverCommonService);

  const { resolverWaitForData, isPublicContent, pageSize } = route.data;
  const offersPayload = {
    limit: pageSize ?? 10,
    offset: 0,
  };

  return common.resolveHandler<IPublishedOffersEdge[]>(
    getOffersActionState,
    isPublicContent ? LoadPublishedOffersPublic(offersPayload) : LoadOffers(offersPayload),
    getOffers,
    resolverWaitForData
  );
};
