export const updateStateList = (stateList, nodeToUpdate) => {
  const index = stateList.findIndex(o => o.node.id === nodeToUpdate.id);

  const updatedList = [...stateList];
  if (index !== -1) updatedList[index] = { ...stateList[index], node: nodeToUpdate };
  else
    updatedList.unshift({
      cursor: nodeToUpdate.id.toLocaleString(),
      node: nodeToUpdate,
    });

  return updatedList;
};
