<div rsWebElevation [elevation]="2" class="card-overview-content">
  <div class="d-flex" [class]="isCollapsed() ? 'collapsed' : 'expanded'">
    <div class="d-flex gap-2 primary-text">
      <rs-web-icon [icon]="icon"></rs-web-icon>
      <span> {{ titleText | translate }}</span>
    </div>
    @if (isCollapsed()) {
      <rs-web-button [type]="buttonTypeEnum.LIGHT_BORDERED" (tapped)="toggleCollapsed()">{{
        buttonText | translate
      }}</rs-web-button>
    } @else {
      <rs-web-icon
        [icon]="immomioIconName.Close"
        [size]="20"
        [cursor]="'pointer'"
        (tapped)="toggleCollapsed()"
      ></rs-web-icon>
    }
  </div>
  @if (!isCollapsed()) {
    <ng-content select="[archive-content]"> </ng-content>
  }
</div>
