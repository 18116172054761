<div class="mobile-title mb-5 title-m">{{ 'user_profile.contact_details_l' | translate }}</div>
<div class="flex-mobile-column row mb-4">
  <div class="d-flex flex-column col-6 custom-margin-bottom">
    <span class="column-title">{{ 'phone' | translate }}</span>
    @if (userData?.phone) {
      <span class="column-desc">{{ userData.phone }}</span>
    } @else {
      <ng-template [ngTemplateOutlet]="noData"></ng-template>
    }
  </div>
  <div class="d-flex flex-column col-6">
    <span class="column-title">{{ 'mobile_phone' | translate }}</span>
    @if (userData?.mobile) {
      <span class="column-desc">{{ userData.mobile }}</span>
    } @else {
      <ng-template [ngTemplateOutlet]="noData"></ng-template>
    }
  </div>
</div>
<div class="flex-mobile-column row mb-4">
  <div class="d-flex flex-column col-6 custom-margin-bottom">
    <span class="column-title">{{ 'general.login_email' | translate }}</span>
    @if (userData?.email) {
      <span class="column-desc">{{ userData.email }}</span>
    } @else {
      <ng-template [ngTemplateOutlet]="noData"></ng-template>
    }
  </div>
  <div class="d-flex flex-column col-6">
    <span class="column-title">{{ 'general.email_address' | translate }}</span>
    @if (userData?.contactEmail) {
      <span class="column-desc">{{ userData.contactEmail }}</span>
    } @else {
      <ng-template [ngTemplateOutlet]="noData"></ng-template>
    }
  </div>
</div>
