import { Directive, EventEmitter, Input, Output } from '@angular/core';
@Directive()
export abstract class ErrorScreenBaseComponent {
  @Input() imagePath? = '~/assets/shared/images/connection_lost.png';
  @Input() title = 'error_screen.title_l';
  @Input() description: string;
  @Input() tryAgainText = 'error_screen.try_again_l';
  @Input() showCancelBtn: boolean;
  @Input() cancellationText = 'error_screen.back_to_overview_l';
  @Input() headerTitle: string;
  @Input() showArrowBack = true;
  @Input() showBuildInfo = true;
  @Output() tryAgainBtnTapped: EventEmitter<boolean> = new EventEmitter();
  @Output() cancelBtnTapped: EventEmitter<boolean> = new EventEmitter();
}
