import { IEmergencyContactsResponse, IEmergencyContactsResponseBean } from '../../../models';

export const emergencyContactsConverter = {
  fromDTO: (response: IEmergencyContactsResponseBean): IEmergencyContactsResponse => {
    const contacts = [];
    response?.edges?.forEach(edge => {
      if (edge?.node) {
        contacts.push({ ...edge.node });
      }
    });
    return {
      contacts,
      hasNextPage: response?.pageInfo?.hasNextPage,
    };
  },
};
