<div class="login-container">
  <div class="login-form">
    <span class="caption-header">{{ 'sign_in_header' | translate }}</span>
    <rs-web-headline [level]="1" class="mb-4">{{ 'login_l' | translate }}</rs-web-headline>

    <form class="row">
      <rs-web-form-field>
        <input
          type="email"
          [formControl]="emailControl"
          [placeholder]="'registration_email_input_l' | translate"
          class="form-control"
          name="email"
          id="email"
        />
      </rs-web-form-field>
      <rs-web-button
        class="mb-4"
        [type]="buttonTypes.LINK"
        (tapped)="navigateToRequestResetPasswordPage()"
        >{{ 'password_forgot_a' | translate }}
      </rs-web-button>
      <rs-web-button
        class="mb-4"
        buttonType="submit"
        [disabled]="!emailControl.valid"
        (tapped)="submit()"
        >{{ 'general.log_in_now' | translate }}
      </rs-web-button>
      <rs-web-button [type]="buttonTypes.LIGHT_BORDERED" (tapped)="navigateToRegistration()"
        >{{ 'register.to_registration' | translate }}
      </rs-web-button>
    </form>
  </div>
</div>
