import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ErrorHandlerService, permissionsConverter, PermissionsFacade } from '../../../services';
import * as fromActions from './permissions.actions';

@Injectable()
export class PermissionsEffects {
  constructor(
    private actions$: Actions,
    private permissionsFacade: PermissionsFacade,
    private errorHandlerService: ErrorHandlerService
  ) {}

  loadPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadPermissions),
      switchMap(() =>
        this.permissionsFacade.getPermissions().pipe(
          map(permissionsConverter.fromDto),
          map(response => fromActions.LoadPermissionsSuccess({ response })),
          catchError(error => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on permissions.effects.ts:',
              error
            );
            return [fromActions.LoadPermissionsFailed(error)];
          })
        )
      )
    )
  );
}
