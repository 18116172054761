import { NgIfContext, NgTemplateOutlet } from '@angular/common';
import { Component, Input, TemplateRef } from '@angular/core';
import { DateRangePipe, IUserData } from '@resident-nx/shared';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rs-web-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss'],
  standalone: true,
  imports: [NgTemplateOutlet, TranslateModule, DateRangePipe],
})
export class PaymentDetailsWebComponent {
  @Input() userData: IUserData;
  @Input() noData: TemplateRef<NgIfContext<string>>;
}
