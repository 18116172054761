import { Injectable, signal } from '@angular/core';
import { FeedbackService, IFeedbackShowOptions } from '@resident-nx/shared';

@Injectable({
  providedIn: 'root',
})
export class FeedbackWebService extends FeedbackService {
  toasts = signal([]);

  hide(): Promise<void> {
    return Promise.resolve(undefined);
  }

  show(_options: IFeedbackShowOptions): Promise<void> {
    this.toasts.update(toasts => [
      ...toasts,
      {
        textOrTpl: _options.message,
        classname: 'bg-danger text-light',
        delay: _options.duration,
      },
    ]);

    return Promise.resolve(undefined);
  }

  remove(toast) {
    this.toasts.update(toasts => toasts.filter(t => t !== toast));
  }
}
