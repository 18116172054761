import { gql } from 'apollo-angular';

export const ticketDetailsFields = `
  id
  ticketNr
  title
  titleImage {
    title
    name
    type
    documentFileType
    documentType
    identifier
    extension
    encrypted
    url
    residentFileType
    location
    atlasMeta {
      key
      value
    }
  }
  state
  category {
    id
    name
  }
  created {
        editor {
          id
          name
        }
        date
      }
  updated {
        editor {
          id
          name
        }
        date
      }
  questions {
    rootQuestion {
      id
      questions {
        id
        data {
          type
          title
          order
          required
          maxAnswers
          meta {
            key
            value
          }
        }
        answers {
          id
          data {
            type
            order
            title
            multiline
            meta {
              key
              value
            }
          }
          questionIds
          meta {
            key
            value
          }
        }
        meta {
          key
          value
        }
      }
      meta {
        key
        value
      }
    }
    responses {
      questionId
      data {
        type
        comment
        ...on ScoreResponseInputText {
          response
       }
        ...on ScoreResponseSelection {
          answerIds
        }
        ...on ScoreResponseInputDate {
          response
        }
        ...on ScoreResponseInputAttachment {
          attachments: response {
            title
            name
            type
            documentType
            documentFileType
            identifier
            extension
            url
            residentFileType
          }
        }
      }
    }
  }
  customerSpecificData {
    key
    value
  }
  issueType
  unreadMessages
`;

export const ticketDetailHQQuery = gql`
  query ticketDetail($ticketId: ID!, $ticketIssueType: TicketIssueType!) {
    ticketDetail(ticketId: $ticketId, issueType: $ticketIssueType) {
      ${ticketDetailsFields}
    }
  }
`;
