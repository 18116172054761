import { createAction, props } from '@ngrx/store';
import { RegisterDeviceInput } from '../../models';

export const RegisterDevice = createAction(
  '[Resident Notifications] Register Device',
  props<{ registerDeviceInput: RegisterDeviceInput }>()
);

export const RegisterDeviceSuccess = createAction(
  '[Resident Notifications] Register Device Success',
  props<{ success: boolean }>()
);

export const RegisterDeviceFailed = createAction(
  '[Resident Notifications] Register Device Failed',
  props<{ error: Error }>()
);

export const UnregisterDevice = createAction(
  '[Resident Notifications] Unregister Device',
  props<{ identifier: string }>()
);

export const UnregisterDeviceSuccess = createAction(
  '[Resident Notifications] Unregister Device Success',
  props<{ success: boolean }>()
);

export const UnregisterDeviceFailed = createAction(
  '[Resident Notifications] Unregister Device Failed',
  props<{ error: Error }>()
);
