export const contactPersonResponseFields = `
  id
  name
  position
  company
  contactData {
    phone
    mobile
    email
  }
  address {
    street
    houseNumber
    city
    zipCode
    region
    country
    district
  }
  serviceHour
`;
