import { Directive, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import {
  getFirstSelectedMeter,
  getSelectedPeriodForChartForFirstMeter,
  getSelectedTimeframe,
  IConsumptionState,
} from '../../+state';
import * as fromConsumptionState from '../../+state/consumption';
import {
  ByDeviceId,
  ConsumptionTimeframe,
  IConsumptionInfoItem,
  ImmomioIconName,
  IPeriod,
  PeriodType,
} from '../../models';

@Directive()
export abstract class ConsumptionNavigationBaseComponent implements OnInit {
  public selectedPeriodForChart$: Observable<IPeriod>;
  public selectedMeter$: Observable<IConsumptionInfoItem>;
  public showPreviousArrow$: Observable<boolean>;
  public showNextArrow$: Observable<boolean>;
  public immomioIconName = ImmomioIconName;
  public periodType = PeriodType;
  public selectedTimeframe$: Observable<ByDeviceId<ConsumptionTimeframe>>;
  public consumptionTimeframe = ConsumptionTimeframe;

  constructor(private store: Store<IConsumptionState>) {}

  ngOnInit() {
    this.selectedMeter$ = this.store.select(getFirstSelectedMeter);
    this.selectedPeriodForChart$ = this.store.select(getSelectedPeriodForChartForFirstMeter);
    this.showPreviousArrow$ = this.store.select(
      fromConsumptionState.getShowPreviousArrowForFirstMeter
    );
    this.showNextArrow$ = this.store.select(fromConsumptionState.getShowNextArrowForFirstMeter);
    this.selectedTimeframe$ = this.store.select(getSelectedTimeframe);
  }

  public previousPeriod(deviceId: string): void {
    this.store.dispatch(fromConsumptionState.SelectPreviousPeriod({ deviceId }));
  }

  public nextPeriod(deviceId: string): void {
    this.store.dispatch(fromConsumptionState.SelectNextPeriod({ deviceId }));
  }
}
