import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IContactPersonResponse, ImmomioIconName } from '@resident-nx/shared';
import { IconWebComponent } from '../../atoms/icon/icon.component';

@Component({
  selector: 'rs-web-contact-preview',
  standalone: true,
  imports: [AsyncPipe, IconWebComponent],
  templateUrl: './contact-preview.component.html',
  styleUrl: './contact-preview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactPreviewComponent {
  @Input() contact: IContactPersonResponse;
  @Input() showSelectionArrowIndicator = false;

  public immomioIconName = ImmomioIconName;
}
