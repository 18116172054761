import { createReducer, on } from '@ngrx/store';

import * as fromActions from './email-settings.actions';
import { ActionStateCreator, IActionState } from '../../../utils';
import { INotificationEmailSettings } from '../../../models/notifications/notification-emails-settings.model';

export interface IEmailSettingsState {
  emailSettingsActionState: IActionState;
  emailSettings: INotificationEmailSettings;
  changeEmailSettingsActionState: IActionState;
}

export const initialState: IEmailSettingsState = {
  emailSettingsActionState: ActionStateCreator.create(),
  emailSettings: null,
  changeEmailSettingsActionState: ActionStateCreator.create(),
};

export const emailSettingsReducer = createReducer(
  initialState,

  on(fromActions.LoadEmailSettings, state => {
    return {
      ...state,
      emailSettingsActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.LoadEmailSettingsSuccess, (state, { success }) => {
    return {
      ...state,
      emailSettings: success,
      emailSettingsActionState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.LoadEmailSettingsFailed, (state, { error }) => {
    return {
      ...state,
      emailSettings: null,
      emailSettingsActionState: ActionStateCreator.onError(error),
    };
  }),
  on(fromActions.ChangeEmailSettings, state => {
    return {
      ...state,
      changeEmailSettingsActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.ChangeEmailSettingsSuccess, (state, { response }) => {
    return {
      ...state,
      emailSettings: response,
      changeEmailSettingsActionState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.ChangeEmailSettingsFailed, (state, { error }) => {
    return {
      ...state,
      changeEmailSettingsActionState: ActionStateCreator.onError(error),
    };
  })
);

export const getEmailSettingsActionState = (state: IEmailSettingsState) =>
  state.emailSettingsActionState;

export const getEmailSettings = (state: IEmailSettingsState) => state.emailSettings;

export const getChangeEmailSettingsActionState = (state: IEmailSettingsState) =>
  state.changeEmailSettingsActionState;
