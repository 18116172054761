import { Directive, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { IKeyValue } from '../../../models';
import { RouterService } from '../../../services';

@Directive()
export abstract class TicketsOverviewPageBaseComponent {
  @Input() public tabItems: IKeyValue[] = [];
  public contractId: string;
  public selectedTabIndex = 0;

  constructor(
    protected routerService: RouterService,
    protected route: ActivatedRoute,
    protected store: Store
  ) {}

  public select(index: number): void {
    this.tabItems.map(tab => {
      tab.active = tab.index === index;
      return tab;
    });
    this.selectedTabIndex = index;
  }
}
