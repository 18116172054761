import { createAction, props } from '@ngrx/store';
import { IPublishedAnnouncement, IPublishedAnnouncementsResponse } from '../../../models';

export const LoadAnnouncements = createAction(
  '[News] Load Announcements',
  props<{ offset: number; limit: number; forced?: boolean }>()
);

export const LoadAnnouncementsSuccess = createAction(
  '[News] Load Announcements Success',
  props<{ response: IPublishedAnnouncementsResponse; oldState?: boolean }>()
);

export const LoadAnnouncementsFailed = createAction(
  '[News] Load Announcements Failed',
  props<{ error: Error }>()
);

export const LoadAnnouncement = createAction(
  '[News] Load Announcement',
  props<{ announcementId: number }>()
);

export const LoadAnnouncementSuccess = createAction(
  '[News] Load Announcement Success',
  props<{ response: IPublishedAnnouncement }>()
);

export const LoadAnnouncementFailed = createAction(
  '[News] Load Announcement Failed',
  props<{ error: Error }>()
);
