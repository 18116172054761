import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { NavigationService } from '../navigation.service';

export interface NavigationDrawerParams {
  isFloatingSubMenu?: boolean;
  narrow?: boolean;
}

@UntilDestroy()
@Injectable()
export class NavigationDrawerService {
  private _isFloatingSubMenu: BehaviorSubject<boolean>;
  private _narrow: BehaviorSubject<boolean>;

  public get isFloatingSubMenu$() {
    return this._isFloatingSubMenu.asObservable().pipe(distinctUntilChanged());
  }

  public get narrow$() {
    return this._narrow.asObservable().pipe(distinctUntilChanged());
  }

  public get collapsable$() {
    return combineLatest(
      [this.navigation.mobileView$, this.navigation.overlay$],
      (mobileView, overlay) => !mobileView && !overlay
    );
  }

  public get narrowAndNotMobileView$() {
    return combineLatest(
      [this.navigation.mobileView$, this.narrow$],
      (mobileView, narrow) => !mobileView && narrow
    );
  }

  public get open$() {
    return combineLatest(
      [this.navigation.open$, this.navigation.openFloatingSubMenu$, this.isFloatingSubMenu$],
      (open, openFloatingSubMenu, isFloatingSubMenu) =>
        (!isFloatingSubMenu && open) || (isFloatingSubMenu && openFloatingSubMenu)
    );
  }

  constructor(public navigation: NavigationService) {}

  public init(params: NavigationDrawerParams = {}) {
    const { isFloatingSubMenu = false, narrow = false } = params;

    this._isFloatingSubMenu = new BehaviorSubject<boolean>(isFloatingSubMenu);
    this._narrow = new BehaviorSubject<boolean>(narrow);

    this.narrowAndNotMobileView$.pipe(untilDestroyed(this)).subscribe(narrowAndNotMobile => {
      if (!narrowAndNotMobile) this.navigation.closeFloatingSubMenu();
    });
  }

  public complete() {
    this._isFloatingSubMenu.complete();
    this._narrow.complete();
  }

  public tighten() {
    this._narrow.next(true);
  }

  public widen() {
    this._narrow.next(false);
  }
}
