import { Directive, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { switchMap } from 'rxjs/operators';
import { filter, map, Observable } from 'rxjs';

import {
  ITicketEditResponse,
  TicketingVersion,
  TicketIssueType,
  WhitelabelImage,
  WhitelabelImageType,
} from '../../../models';
import { RouterService } from '../../../services';
import * as fromTicketsState from '../../../+state/tickets';
import { getResidentAppSettings } from '../../../+state';

@UntilDestroy()
@Directive()
export class TicketCreationSuccessPageBaseComponent implements OnInit {
  public createTicketResponse$: Observable<ITicketEditResponse>;
  public issueType: TicketIssueType | undefined;
  public headerTitle: string;
  public ticketIssueType = TicketIssueType;
  public whitelabelImage = WhitelabelImage;
  public whitelabelImageType = WhitelabelImageType;
  public cancellationUrl = '../../';
  public ticketingVersion$: Observable<TicketingVersion> = this.store
    .select(getResidentAppSettings)
    .pipe(
      map(response => response?.ticketingVersion),
      filter(response => response !== null),
      untilDestroyed(this)
    );
  public ticketingVersion = TicketingVersion;

  constructor(
    private routerService: RouterService,
    private route: ActivatedRoute,
    private store: Store<fromTicketsState.ITicketsState>
  ) {}

  ngOnInit() {
    this.store.select(getResidentAppSettings).pipe(map(response => response?.ticketingVersion));

    this.createTicketResponse$ = this.ticketingVersion$.pipe(
      switchMap(version => {
        if (version === TicketingVersion.METADATA) {
          return this.store.select(fromTicketsState.getCreateTicketMetadataResponse);
        } else {
          return this.store.select(fromTicketsState.getCreateTicketHQResponse);
        }
      })
    );
    this.issueType = this.route.snapshot?.params?.type as TicketIssueType;
    if (this.issueType === TicketIssueType.PROPERTY_DAMAGE) {
      this.headerTitle = 'ticket_creation.success.damage_header_l';
    } else if (this.issueType === TicketIssueType.CONCERN) {
      this.headerTitle = 'ticket_creation.success.concern_header_l';
    }
  }

  public goToTicketOverview() {
    this.store.dispatch(fromTicketsState.ClearTicketCreation());
    if (this.issueType === TicketIssueType.CONCERN) {
      this.store.dispatch(fromTicketsState.LoadActiveRequestTickets({ offset: 0, limit: 20 }));
    } else if (this.issueType === TicketIssueType.PROPERTY_DAMAGE) {
      this.store.dispatch(fromTicketsState.LoadActiveDamageTickets({ offset: 0, limit: 20 }));
    }
    this.routerService.navigate([this.cancellationUrl], { relativeTo: this.route });
  }
}
