import { Directive } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromAnnouncementsState from '../../+state/news/announcements';
import { IPublishedAnnouncementsEdge } from '../../models';
import { IActionState } from '../../utils';

@Directive()
export abstract class AnnouncementsOverviewBaseComponent {
  public announcements$: Observable<IPublishedAnnouncementsEdge[]> = this.announcementsStore.select(
    fromAnnouncementsState.getAnnouncements
  );
  public hasNextPage$: Observable<boolean> = this.announcementsStore.select(
    fromAnnouncementsState.hasNextPageAnnouncements
  );
  public announcementsActionState$: Observable<IActionState> = this.announcementsStore.select(
    fromAnnouncementsState.getAnnouncementsActionState
  );

  public pageSize = 10;
  public pagination;

  constructor(
    protected announcementsStore: Store<fromAnnouncementsState.IAnnouncementsState>,
    pageSize = 10
  ) {
    this.pageSize = pageSize;
    this.pagination = { offset: 0, limit: this.pageSize };
  }

  public loadMoreAnnouncements(): void {
    this.pagination = { offset: this.pagination.offset + this.pageSize, limit: this.pageSize };
    this.announcementsStore.dispatch(fromAnnouncementsState.LoadAnnouncements(this.pagination));
  }
}
