import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class I18nService {
  constructor(
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  public loadDefaultTranslations() {
    this.http.get('').subscribe(translations => {
      this.translate.setTranslation('de', {
        translations,
      });
    });
  }

  public changeLanguage(lan: string) {
    this.translate.use(lan);
  }
}
