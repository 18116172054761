import { inject, Injectable } from '@angular/core';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import {
  IFederatedLoginEnvironmentVariables,
  RouterService,
  StorageService,
} from '@resident-nx/shared';

@Injectable({
  providedIn: 'root',
})
export class AngularKeycloakService extends KeycloakService {
  private storageService = inject(StorageService);
  private routerService = inject(RouterService);

  constructor() {
    super();

    this.keycloakEvents$.subscribe(event => {
      if (event.type === KeycloakEventType.OnTokenExpired) {
        void this.updateToken();
      }

      if (event.type === KeycloakEventType.OnAuthRefreshError) {
        this.routerService.navigate(['logout']);
      }
    });
  }

  public initializeKeycloak(): Promise<boolean> {
    const clientId = this.storageService.getItem(
      IFederatedLoginEnvironmentVariables.CLIENT_ID
    ) as string;
    const realm = this.storageService.getItem(IFederatedLoginEnvironmentVariables.REALM) as string;
    const baseUrl = this.storageService.getItem(
      IFederatedLoginEnvironmentVariables.BASE_URL_AUTH
    ) as string;

    return this.init({
      config: {
        url: baseUrl,
        realm,
        clientId,
      },
      initOptions: {
        onLoad: 'login-required',
      },
    });
  }
}
