import { createReducer, on } from '@ngrx/store';
import { IPageInfo, IPublishedAnnouncement, IPublishedAnnouncementsEdge } from '../../../models';
import { ActionStateCreator, IActionState, updateStateList } from '../../../utils';
import * as fromActions from './announcements.actions';

export interface IAnnouncementsState {
  announcements: IPublishedAnnouncementsEdge[];
  announcementsActionState: IActionState;
  hasNextPage: boolean;
  announcement: IPublishedAnnouncement;
  announcementActionState: IActionState;
  pageInfo: IPageInfo;
}

export const initialState: IAnnouncementsState = {
  announcements: [],
  announcementsActionState: ActionStateCreator.create(),
  hasNextPage: false,
  announcement: null,
  announcementActionState: ActionStateCreator.create(),
  pageInfo: null,
};

export const announcementsReducer = createReducer(
  initialState,

  on(fromActions.LoadAnnouncements, state => {
    return {
      ...state,
      announcementsActionState: ActionStateCreator.onStart(),
    };
  }),

  on(fromActions.LoadAnnouncementsSuccess, (state, { response, oldState }) => {
    if (oldState) {
      return {
        ...state,
        announcementsActionState: ActionStateCreator.onSuccess(),
        announcements: response.edges,
        hasNextPage: response.pageInfo?.hasNextPage,
        pageInfo: response.pageInfo,
      };
    } else {
      return {
        ...state,
        announcementsActionState: ActionStateCreator.onSuccess(),
        announcements: response.pageInfo?.hasPreviousPage
          ? state.announcements.concat(response.edges)
          : response.edges,
        hasNextPage: response.pageInfo?.hasNextPage,
        pageInfo: response.pageInfo,
      };
    }
  }),

  on(fromActions.LoadAnnouncementsFailed, (state, { error }) => {
    return {
      ...state,
      announcementsActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadAnnouncement, state => {
    return {
      ...state,
      announcement: null,
      announcementActionState: ActionStateCreator.onStart(),
    };
  }),

  on(fromActions.LoadAnnouncementSuccess, (state, { response }) => {
    return {
      ...state,
      announcementActionState: ActionStateCreator.onSuccess(),
      announcement: response,
      announcements: updateStateList(state.announcements, response),
    };
  }),

  on(fromActions.LoadAnnouncementFailed, (state, { error }) => {
    return {
      ...state,
      announcementActionState: ActionStateCreator.onError(error),
    };
  })
);

export const getAnnouncements = (state: IAnnouncementsState) => state.announcements;
export const getLatestAnnouncements = (state: IAnnouncementsState) =>
  state.announcements.slice(0, 5);
export const getAnnouncementsActionState = (state: IAnnouncementsState) =>
  state.announcementsActionState;
export const hasNextPage = (state: IAnnouncementsState) => state.hasNextPage;
export const getAnnouncement = (state: IAnnouncementsState) => state.announcement;
export const getAnnouncementActionState = (state: IAnnouncementsState) =>
  state.announcementActionState;
export const getAnnouncementPageInfo = (state: IAnnouncementsState) => state.pageInfo;
