export enum FileType {
  PDF = 'PDF',
  IMG = 'IMG',
  FLOOR_PLAN = 'FLOOR_PLAN',
  VIDEO = 'VIDEO',
  ENERGY_CERTIFICATE = 'ENERGY_CERTIFICATE',
  WB_CERTIFICATE = 'WB_CERTIFICATE',
  CREDIT_REPORT = 'CREDIT_REPORT',
  SELF_ASSESSMENT = 'SELF_ASSESSMENT',
  INCOME_STATEMENT = 'INCOME_STATEMENT',
  PROOF_OF_PAYMENT = 'PROOF_OF_PAYMENT',
  MAILATTACHMENTS = 'MAILATTACHMENTS',
  ZIP = 'ZIP',
  SHARED_DOCUMENT = 'SHARED_DOCUMENT',
  ICS = 'ICS',
  CSV = 'CSV',
  XLSX = 'XLSX',
  XML = 'XML',
}

export enum ResidentFileType {
  TICKET_ATTACHMENT = 'TICKET_ATTACHMENT',
  RENTAL_CONTRACT = 'RENTAL_CONTRACT',
  CONSUMPTION_DOCUMENT = 'CONSUMPTION_DOCUMENT',
  SERVICE_CHARGE_STATEMENT = 'SERVICE_CHARGE_STATEMENT',
  REQUESTED_DOCUMENT = 'REQUESTED_DOCUMENT',
}

export enum DocumentFileType {
  PDF = 'PDF',
  IMG = 'IMG',
  ZIP = 'ZIP',
  ICS = 'ICS',
  CSV = 'CSV',
  XLSX = 'XLSX',
  XML = 'XML',
}

export enum DocumentType {
  FLOOR_PLAN = 'FLOOR_PLAN',
  VIDEO = 'VIDEO',
  ENERGY_CERTIFICATE = 'ENERGY_CERTIFICATE',
  WB_CERTIFICATE = 'WB_CERTIFICATE',
  CREDIT_REPORT = 'CREDIT_REPORT',
  SELF_ASSESSMENT = 'SELF_ASSESSMENT',
  INCOME_STATEMENT = 'INCOME_STATEMENT',
  PROOF_OF_PAYMENT = 'PROOF_OF_PAYMENT',
  MAILATTACHMENTS = 'MAILATTACHMENTS',
  SHARED_DOCUMENT = 'SHARED_DOCUMENT',
  RESIDENT_CONTENT = '',
  RESIDENT_CONTENT_IMG = 'RESIDENT_CONTENT',
  DOCUMENT_MANAGEMENT = 'DOCUMENT_MANAGEMENT',
  TICKET_ATTACHMENT = 'TICKET_ATTACHMENT',
}
