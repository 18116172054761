import { createSelector } from '@ngrx/store';
import { getOffersState } from '../../reducers';
import * as fromReducer from './offers.reducer';

export const getOffers = createSelector(getOffersState, fromReducer.getOffers);
export const hasNextPageOffers = createSelector(getOffersState, fromReducer.hasNextPage);
export const getLatestOffers = createSelector(getOffersState, fromReducer.getLatestOffers);
export const getOffersActionState = createSelector(
  getOffersState,
  fromReducer.getOffersActionState
);
export const getOffer = createSelector(getOffersState, fromReducer.getOffer);
export const getOfferActionState = createSelector(getOffersState, fromReducer.getOfferActionState);
export const getOffersPageInfo = createSelector(getOffersState, fromReducer.getOffersPageInfo);
