import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import {
  getActivePaymentRequests,
  getActivePaymentRequestsActionState,
  LoadPaymentRequests,
} from '../../+state';
import { IPayment } from '../../models';
import { ResolverCommonService } from './resolver-common.service';

export const PaymentsResolver: ResolveFn<IPayment[]> = route => {
  const common = inject(ResolverCommonService);

  const { resolverWaitForData } = route.data;
  return common.resolveHandler<IPayment[]>(
    getActivePaymentRequestsActionState,
    LoadPaymentRequests({}),
    getActivePaymentRequests,
    resolverWaitForData
  );
};
