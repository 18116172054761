import { createReducer, on } from '@ngrx/store';
import { ISharedFolderContentResponse } from '../../models';
import { IActionState, ActionStateCreator } from '../../utils/state-utils';
import * as fromActions from './shared-content.actions';

export interface ISharedContentState {
  sharedContentActionState: IActionState;
  sharedContent: ISharedFolderContentResponse;
}

export const initialState: ISharedContentState = {
  sharedContentActionState: ActionStateCreator.create(),
  sharedContent: null,
};

export const sharedContentReducer = createReducer(
  initialState,

  on(fromActions.GetSharedContent, state => {
    return {
      ...state,
      sharedContentActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.GetSharedContentSuccess, (state, { response }) => {
    return {
      ...state,
      sharedContentActionState: ActionStateCreator.onSuccess(),
      sharedContent: response,
    };
  }),
  on(fromActions.GetSharedContentFailed, (state, { error }) => {
    return {
      ...state,
      sharedContentActionState: ActionStateCreator.onError(error),
    };
  })
);

export const getSharedContent = (state: ISharedContentState) => state.sharedContent;

export const getSharedContentActionState = (state: ISharedContentState) =>
  state.sharedContentActionState;
