export enum ProfileButtonColors {
  HAZELNUT = '#CCAC93',
  MAGENTA = '#D81B60',
  WALNUT = '#E07F5D',
  MUSTARD = '#F6BF25',
  KELP = '#AFB83B',
  SPINACH = '#5FBF89',
  FLIEDER = '#884DFF',
  POWERPUFF = '#EB96EB',
  CACAO = '#795548',
  TARO = '#808080',
}
