type MarkFunctionProperties<Component> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [Key in keyof Component]: Component[Key] extends Function ? never : Key;
};
type ExcludeFunctionPropertyNames<T> = MarkFunctionProperties<T>[keyof T];
type ExcludeFunctions<T> = Pick<T, ExcludeFunctionPropertyNames<T>>;

/**
 * Angular calls the ngOnChanges method of a component or directive whenever it detects changes to the input properties.
 * The method receives a SimpleChanges object of current and previous property values.
 * Unfortunately, the SimpleChanges interface isn’t fully typed out of the box
 * This helper provides types for the changes: SimpleChanges object of ngOnChanges
 *
 * Drawback: It also shows properties that are not decorated with @Input()
 */
export type NgChanges<Component, Props = ExcludeFunctions<Component>> = {
  [Key in keyof Props]: {
    previousValue: Props[Key];
    currentValue: Props[Key];
    firstChange: boolean;
    isFirstChange(): boolean;
  };
};
