import { gql } from 'apollo-angular';

export const loginDataFields = `
    clientId
    realm
    baseUrl
    host
    port
    scheme
    path
    redirectUri
    nonce
    state
    responseType
    responseMode
    scope
    kcIdpHint
    loginHint
    prompt
    redirectUrl
`;

export const getFederatedLoginQuery = gql`
  query getFederatedLoginData($input: LoginBean!) {
    federatedLogin(input: $input) {
      ${loginDataFields}
    }
  }
`;
