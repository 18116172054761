import { Directive } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  IHomepageModelState,
  getHomepageModuleToken,
  getHomepageModuleActionState,
} from '../../+state';

@Directive()
export abstract class HomepageBaseComponent {
  public token$ = this.homepageStore.select(getHomepageModuleToken);
  public tokenActionState$ = this.homepageStore.select(getHomepageModuleActionState);

  constructor(private homepageStore: Store<IHomepageModelState>) {}
}
