import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';

@Pipe({
  name: 'rsWebDateRange',
  standalone: true,
})
export class DateRangePipe implements PipeTransform {
  transform(from: string, to: string) {
    const fromDate = from ? format(new Date(from), 'dd.MM.yyyy') : undefined;
    const toDate = to ? format(new Date(to), 'dd.MM.yyyy') : undefined;

    if (fromDate && toDate) {
      return `${fromDate} - ${toDate}`;
    } else if (!fromDate) {
      return toDate;
    } else return fromDate;
  }
}
