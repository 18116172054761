import { Directive, Input, EventEmitter, Output } from '@angular/core';
import { ImmomioIconName } from '../../models';

@Directive()
export abstract class InfoBaseComponent {
  public immomioIconName = ImmomioIconName;
  @Output() tapped = new EventEmitter();
  @Input() context: {
    title: string;
    content: string;
  };
}
