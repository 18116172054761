import { createReducer, on } from '@ngrx/store';
import { ActionStateCreator, IActionState } from '../../../utils';
import * as fromActions from './welcome_screen.actions';

export interface IWelcomeScreenState {
  hideWelcomeScreen: boolean | null;
  hideWelcomeScreenActionState: IActionState;
}

export const initialState: IWelcomeScreenState = {
  hideWelcomeScreen: null,
  hideWelcomeScreenActionState: ActionStateCreator.create(),
};

export const hideWelcomeScreenReducer = createReducer(
  initialState,
  on(fromActions.setHideWelcomeScreen, (state, { hideWelcomeScreen }) => ({
    ...state,
    hideWelcomeScreen: hideWelcomeScreen,
    hideWelcomeScreenActionState: ActionStateCreator.onSuccess(),
  }))
);

export const getHideWelcomeScreen = (state: IWelcomeScreenState) => state?.hideWelcomeScreen;
export const getHideWelcomeScreenActionState = (state: IWelcomeScreenState) =>
  state.hideWelcomeScreenActionState;
