import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs';

import { EmailSettingsFacade, ErrorHandlerService } from '../../../services';
import * as fromActions from './email-settings.actions';

@Injectable()
export class EmailSettingsEffects {
  constructor(
    private actions$: Actions,
    private emailSettingsFacade: EmailSettingsFacade,
    private errorHandlerService: ErrorHandlerService
  ) {}

  loadEmailSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.LoadEmailSettings),
      switchMap(() =>
        this.emailSettingsFacade.getEmailSettings().pipe(
          map(success => fromActions.LoadEmailSettingsSuccess({ success })),
          catchError(error => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on email-settings.effects.ts:loadEmailSettings$:',
              error
            );
            return [fromActions.LoadEmailSettingsFailed({ error })];
          })
        )
      )
    );
  });

  changeEmailSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.ChangeEmailSettings),
      switchMap(({ payload }) =>
        this.emailSettingsFacade.updateEmailSettings(payload).pipe(
          map(response => fromActions.ChangeEmailSettingsSuccess({ response })),
          catchError(error => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on email-settings.effects.ts:changeEmailSettings$:',
              error
            );
            return [fromActions.ChangeEmailSettingsFailed({ error })];
          })
        )
      )
    );
  });
}
