import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { IMasterDataUser } from '../../models';

export function isLastnameAsyncValidator($user: Observable<IMasterDataUser>): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    /*
    TODO: refactor isLastnameAsyncValidator
    $user.pipe(
      switchMap(user => {
        if (user.lastName === control.value) {
          return of({ invalidLastname: true });
        }

        return of(null);
      })
    );
    */
    let isValidLastName = false;
    $user
      .pipe(map((user: IMasterDataUser) => user.lastName === control.value))
      .subscribe(v => (isValidLastName = v));

    if (!isValidLastName) {
      return of({ invalidLastname: true });
    }

    return of(null);
  };
}
