import { createAction, props } from '@ngrx/store';

import {
  ByDeviceId,
  ChartMonths,
  ConsumptionTimeframe,
  DeviceId,
  IConsumptionBenchmarkItemResponse,
  IConsumptionInfo,
  IConsumptionInfoItem,
  IConsumptionItem,
  IContract,
  IPeriod,
  PeriodType,
} from '../../models';

export const LoadMetersByContract = createAction(
  '[Resident Consumption] load meters by current selected contract'
);

export const LoadMetersBySelectedContractSuccess = createAction(
  '[Resident Consumption] load meters by current selected contract success',
  props<{ consumptionInfo: IConsumptionInfo; contract: IContract }>()
);
export const LoadMetersBySelectedContractFailed = createAction(
  '[Resident Consumption] load meters by current selected contract failed',
  props<{ error: Error }>()
);

export const SelectMeters = createAction(
  '[Resident Consumption] select meters',
  props<{ meters: IConsumptionInfoItem[] }>()
);

export const SelectLatestAvailablePeriod = createAction(
  '[Resident Consumption] Select Latest Available Period',
  props<{ contract: IContract }>()
);

export const ClearSelectedConsumption = createAction(
  '[Resident Consumption] Clear Selected Consumption'
);

export const LoadConsumptionForMeters = createAction(
  '[Resident Consumption] Load Consumption for Meters',
  props<{ deviceIds: string[] }>()
);

export const LoadConsumptionForMetersSuccess = createAction(
  '[Resident Consumption] Load Consumption for Meters Success',
  props<{
    responses: ByDeviceId<IConsumptionItem[]>;
    contract: IContract;
    deviceIds: string[];
  }>()
);

export const LoadConsumptionForMetersFailed = createAction(
  '[Resident Consumption] Load Consumption for Meters Failed',
  props<{ deviceIds: string[]; error: Error }>()
);

export const LoadPastConsumptionForMeters = createAction(
  '[Resident Consumption] Load Past Consumption for Meters',
  props<{ deviceIds: string[] }>()
);

export const LoadPastConsumptionForMetersSuccess = createAction(
  '[Resident Consumption] Load Past Consumption for Meters Success',
  props<{
    responses: ByDeviceId<IConsumptionItem[]>;
    contract: IContract;
    deviceIds: string[];
  }>()
);

export const LoadPastConsumptionForMetersFailed = createAction(
  '[Resident Consumption] Load Past Consumption for Meters Failed',
  props<{ deviceIds: string[]; error: Error }>()
);

export const LoadConsumptionBenchmarkForMeters = createAction(
  '[Resident Consumption] Load Consumption Benchmark for Meters',
  props<{ deviceIds: string[] }>()
);

export const LoadConsumptionBenchmarkForMetersSuccess = createAction(
  '[Resident Consumption] Load Consumption Benchmark for Meters Success',
  props<{
    responses: ByDeviceId<IConsumptionBenchmarkItemResponse[]>;
    contract: IContract;
    deviceIds: string[];
  }>()
);

export const LoadConsumptionBenchmarkForMetersFailed = createAction(
  '[Resident Consumption] Load Consumption Benchmark for Meters Failed',
  props<{ deviceIds: string[]; error: Error }>()
);

export const TriggerLoadConsumptionForAllSelectedMeters = createAction(
  '[Resident Consumption] Trigger Load Consumption for all Selected Meters'
);

export const SelectPreviousPeriod = createAction(
  '[Resident Consumption] Select Previous Period',
  props<{
    deviceId: string;
  }>()
);

export const SelectNextPeriod = createAction(
  '[Resident Consumption] Select Next Period',
  props<{ deviceId: string }>()
);

export const SelectPeriod = createAction(
  '[Resident Consumption] Select Period',
  props<{ deviceId: string; start: Date; end: Date }>()
);

export const LoadCurrentMonthConsumption = createAction(
  '[Resident Consumption] Load Current Month Consumption'
);

export const LoadCurrentMonthConsumptionSuccess = createAction(
  '[Resident Consumption] Load Current Month Consumption Success',
  props<{
    response: IConsumptionItem[];
    contract: IContract;
    selectedPeriod: IPeriod;
    selectedMeter: IConsumptionInfoItem;
  }>()
);

export const LoadCurrentMonthConsumptionFailed = createAction(
  '[Resident Consumption] Load Current Month Consumption Failed',
  props<{ error: Error }>()
);

export const SelectConsumption = createAction(
  '[Resident Consumption] Select Consumption',
  props<{
    selectedPeriod: IPeriod;
    periodType: PeriodType;
    deviceId: string;
  }>()
);

export const SetChartViewMonthsCount = createAction(
  '[Resident Consumption] Set Selected Chart Period',
  props<{ chartMonthsCount: ChartMonths }>()
);

export const SetSelectedTimeframe = createAction(
  '[Resident Consumption] Set Selected Timeframe',
  props<{ timeframe: ConsumptionTimeframe; deviceId: DeviceId }>()
);
