export enum ConsumptionType {
  POWER = 'power',
  COLD_WATER = 'cold_water',
  HOT_WATER = 'hot_water',
  HEATING = 'heating',
  PHOTOVOLTAIC = 'photovoltaic',
}

export enum NameDisplayMode {
  SHORT,
  LONG,
}

export enum ComparisonType {
  CURRENT,
  PAST,
  BENCHMARK,
}

export enum ComparisonStatus {
  GOOD,
  MEDIUM,
  BAD,
}

export enum ConsumptionTypeTranslateString {
  power = 'consumption_type.power_abbrev_l',
  cold_water = 'consumption_type.cold_water_abbrev_l',
  hot_water = 'consumption_type.hot_water_abbrev_l',
  heating = 'consumption_type.heating_abbrev_l',
  photovoltaic = 'consumption_type.photovoltaic_abbrev_l',
}
