import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { catchError, map, Observable } from 'rxjs';

import {
  ConsumptionAggregateType,
  ConsumptionDataVersion,
  ContractId,
  IConsumptionBenchmarkItemResponse,
  IConsumptionBenchmarkResponse,
  IConsumptionInfoDto,
  IConsumptionInfoItem,
  IConsumptionResponse,
  IMeterConsumptionInput,
} from '../../models';
import {
  getBenchmarkDataForMeterQuery,
  getConsumptionDataForMeterQuery,
  getConsumptionInfoQuery,
} from '../../queries';
import { Milliseconds, millisecondsToSeconds, toUtcTimeZone } from '../../utils';

const millisecondsToSecondsConsumptionAdapter = (
  date: Milliseconds,
  consumptionDataVersion: ConsumptionDataVersion = ConsumptionDataVersion.EXTERNAL
) => {
  return consumptionDataVersion === ConsumptionDataVersion.EXTERNAL
    ? millisecondsToSeconds(toUtcTimeZone(date))
    : millisecondsToSeconds(date);
};

@Injectable()
export class ConsumptionFacade {
  constructor(private apollo: Apollo) {}

  public getSelectedPeriodConsumption(
    selectedPeriod: { start: Milliseconds; end: Milliseconds },
    contract: ContractId,
    meter: IConsumptionInfoItem,
    aggregate: ConsumptionAggregateType,
    consumptionDataVersion: ConsumptionDataVersion
  ): Observable<IConsumptionResponse> {
    const input: IMeterConsumptionInput = {
      start: millisecondsToSecondsConsumptionAdapter(selectedPeriod.start, consumptionDataVersion),
      end: millisecondsToSecondsConsumptionAdapter(selectedPeriod.end, consumptionDataVersion),
      requestedContractNumber: contract,
      meterId: meter.deviceId,
      aggregate,
    };
    return this.apollo
      .query<{ getConsumptionDataForMeter: IConsumptionResponse }>({
        query: getConsumptionDataForMeterQuery,
        fetchPolicy: 'no-cache',
        variables: {
          input,
        },
      })
      .pipe(
        map(response => {
          if (response?.data?.getConsumptionDataForMeter) {
            return response?.data?.getConsumptionDataForMeter;
          } else if (response.errors?.length) {
            throw new Error(response.errors[0].message);
          } else {
            throw new Error();
          }
        }),
        /* // mock multiple meter out of one (POWER) given
        map(getConsumptionDataForMeter => {
          const consumptionList = getConsumptionDataForMeter?.consumptionList?.map(el => {
            (el.amount =
              meter.type === ConsumptionType.POWER ? el.amount : Math.floor(Math.random() * 10)),
              (el.unit = meter.measurementUnit),
              (el.consumptionType = meter.type);
            return el;
          });
          return { consumptionList };
        }),
        */
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }

  public getConsumptionInfo(contractId: ContractId): Observable<IConsumptionInfoDto> {
    return this.apollo
      .query<{ getConsumptionInfo: IConsumptionInfoDto }>({
        query: getConsumptionInfoQuery,
        fetchPolicy: 'no-cache',
        variables: {
          contractNumber: contractId,
        },
      })
      .pipe(
        map(response => {
          if (response.data?.getConsumptionInfo) {
            return response.data.getConsumptionInfo;
          } else {
            throw new Error(response.errors[0].message);
          }
        }),
        /*// mock multiple meter out of one (POWER) given
        map(getConsumptionInfo => {
          const mockInfo: IConsumptionInfoDto = { meters: [getConsumptionInfo.meters[0]] };
          mockInfo.meters.push({
            ...getConsumptionInfo.meters[0],
            type: ConsumptionType.COLD_WATER,
            measurementUnit: ConsumptionUnitType.M3,
          });
          mockInfo.meters.push({
            ...getConsumptionInfo.meters[0],
            type: ConsumptionType.HOT_WATER,
            measurementUnit: ConsumptionUnitType.M3,
          });
          mockInfo.meters.push({
            ...getConsumptionInfo.meters[0],
            type: ConsumptionType.HEATING,
            measurementUnit: ConsumptionUnitType.HCU,
          });
          mockInfo.meters.push({
            ...getConsumptionInfo.meters[0],
            type: ConsumptionType.PHOTOVOLTAIC,
            measurementUnit: ConsumptionUnitType.KWH,
          });
          return mockInfo;
        }),*/
        catchError((error: Error) => {
          throw new Error(error.message);
        })
      );
  }
  public getSelectedPeriodConsumptionBenchmark(
    selectedPeriod: { start: Milliseconds; end: Milliseconds },
    contract: ContractId,
    meter: IConsumptionInfoItem,
    aggregate: ConsumptionAggregateType,
    consumptionDataVersion: ConsumptionDataVersion
  ): Observable<IConsumptionBenchmarkItemResponse[]> {
    const input: IMeterConsumptionInput = {
      start: millisecondsToSecondsConsumptionAdapter(selectedPeriod.start, consumptionDataVersion),
      end: millisecondsToSecondsConsumptionAdapter(selectedPeriod.end, consumptionDataVersion),
      requestedContractNumber: contract,
      meterId: meter.deviceId,
      aggregate,
    };
    return this.apollo
      .query<{ getConsumptionBenchmarksForMeter: IConsumptionBenchmarkResponse }>({
        query: getBenchmarkDataForMeterQuery,
        fetchPolicy: 'no-cache',
        variables: {
          input,
        },
      })
      .pipe(
        map(response => {
          if (response?.data?.getConsumptionBenchmarksForMeter) {
            return response.data.getConsumptionBenchmarksForMeter.benchmarks;
          } else if (response.errors?.length) {
            throw new Error(response.errors[0].message);
          } else {
            throw new Error();
          }
        }),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }
}
