import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Elevation, ElevationType } from '../../../directives/elevation/elevation.model';
import { ElevationDirective } from '../../../directives/elevation/elevation.directive';
import { CardBorderRadiusEnum, CardBorderStyleEnum } from './card.enum';

@Component({
  selector: 'rs-web-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  standalone: true,
  imports: [ElevationDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardWebComponent {
  @Input() elevation: ElevationType = Elevation.ZERO;
  @Input() hover = false;
  @Input() withoutPadding = false;
  @Input() overflowHidden = true;
  @Input() borderStyle: CardBorderStyleEnum = 'normal';
  @Input() borderRadius = CardBorderRadiusEnum.BIG;

  @Output() clickEvent = new EventEmitter();

  public click(): void {
    this.clickEvent.emit();
  }
}
