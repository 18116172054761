import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromTicketState from '../../../+state/tickets';
import {
  TicketIssueType,
  IKeyValue,
  IHierarchicalQuestionTicketOverview,
  ITicketCreationSummary,
  IDisplaySummary,
  IHierarchicalQuestionAnswerTicketOverview,
} from '../../../models';
import { IActionState } from '../../../utils';
import { ticketHierarchicalQuestionsConverter } from '../../../services';

@Directive()
export class TicketCategoriesBaseComponent implements OnInit {
  @Input() issueType: TicketIssueType;
  @Input() className: string;
  @Input() step: { index: number };
  @Input() questions: IHierarchicalQuestionTicketOverview[];
  @Output() categoriesFinished = new EventEmitter();
  @Input() ticketCreationSummary: ITicketCreationSummary;
  public ticketCategoriesActionsState$: Observable<IActionState>;

  constructor(private ticketStore: Store<fromTicketState.ITicketsState>) {}

  ngOnInit() {
    if (this.issueType === TicketIssueType.PROPERTY_DAMAGE) {
      this.ticketCategoriesActionsState$ = this.ticketStore.select(
        fromTicketState.getDamageCategoriesActionState
      );
    } else if (this.issueType === TicketIssueType.CONCERN) {
      this.ticketCategoriesActionsState$ = this.ticketStore.select(
        fromTicketState.getConcernCategoriesActionState
      );
    }
  }

  public selectAnswer(item: IKeyValue<string>, index: number): void {
    const answerId = item.key;
    const answeredQuestion: IHierarchicalQuestionTicketOverview =
      ticketHierarchicalQuestionsConverter.getQuestionByAnswerId(answerId, this.questions);
    const answer: IHierarchicalQuestionAnswerTicketOverview = answeredQuestion.answers?.find(
      el => el.id === answerId
    );
    const displaySummary: IDisplaySummary = {
      questionTitle: answeredQuestion.data.title,
      answerTitle: answer?.data.title,
      attachments: null,
    };
    this.ticketCreationSummary.responses[index].response = {
      questionId: answeredQuestion.id,
      selectionData: {
        answerIds: [answerId],
        type: answeredQuestion.data.type,
      },
    };
    this.ticketCreationSummary.responses[index].displaySummary = displaySummary;

    // removing any possible questions after this
    this.ticketCreationSummary.responses.length = index + 1;

    if (ticketHierarchicalQuestionsConverter.hasNextQuestion(answer, this.questions)) {
      const nextQuestion = ticketHierarchicalQuestionsConverter.getNextQuestion(
        answer,
        this.questions
      );
      this.ticketCreationSummary.responses[index + 1] =
        ticketHierarchicalQuestionsConverter.formatQuestionToSummaryItem(nextQuestion);
    } else {
      this.categoriesFinished.emit();
    }
    this.step.index = index + 1;
  }
}
