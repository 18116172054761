import { Directive, EventEmitter, inject, input, Input, Output, TemplateRef } from '@angular/core';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[rsWebSideSheetContent]',
  standalone: true,
})
export class SideSheetContentDirective {
  @Input() label: string;
  @Input() disabled: boolean;
  currentStepValid = input(true);
  @Input() customDoneButtonText: string;
  @Output() next = new EventEmitter();
  @Output() done = new EventEmitter<NgbActiveOffcanvas>();
  public template = inject(TemplateRef);
}
