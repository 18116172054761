import { createAction, props } from '@ngrx/store';
import { CustomerIdent, ISaasResidentLandlordCustomerResponse } from '../../../models';

export const GetUserRegisteredSaasResidentApps = createAction(
  '[Account User Apps] Get User Registered Saas Resident Apps'
);

export const GetUserRegisteredSaasResidentAppSuccess = createAction(
  '[Account User Apps] Get User Registered Saas Resident Apps Success',
  props<{ registeredApps: ISaasResidentLandlordCustomerResponse[] }>()
);

export const GetUserRegisteredSaasResidentAppsFailed = createAction(
  '[Account User Apps] Get User Registered Saas Resident Apps Failed',
  props<{ error: Error }>()
);

export const SetSelectedCustomerIdent = createAction(
  '[Account User Apps] Set selected CustomerIdent',
  props<{ selectedCustomerIdent: CustomerIdent }>()
);

export const LoadCustomerIdent = createAction(
  '[AppSettings] Load Customer Ident',
  props<{ domain: string }>()
);

export const LoadCustomerIdentSuccess = createAction(
  '[AppSettings] Load Customer Ident Success',
  props<{ customerIdent: string }>()
);

export const LoadCustomerIdentFailed = createAction(
  '[AppSettings] Load Customer Ident Failed',
  props<{ error: Error }>()
);
