import { createAction, props } from '@ngrx/store';
import { IPublishedNews, IPublishedNewsResponse } from '../../../models';

export const LoadNews = createAction(
  '[News] Load News',
  props<{ offset: number; limit: number; forced?: boolean }>()
);

export const LoadNewsSuccess = createAction(
  '[News] Load News Success',
  props<{ response: IPublishedNewsResponse; oldState?: boolean }>()
);

export const LoadNewsFailed = createAction('[News] Load News Failed', props<{ error: Error }>());

export const LoadSingleNews = createAction('[News] Load Single News', props<{ newsId: string }>());

export const LoadSingleNewsSuccess = createAction(
  '[News] Load Single News Success',
  props<{ response: IPublishedNews }>()
);

export const LoadSingleNewsFailed = createAction(
  '[News] Load Single News Failed',
  props<{ error: Error }>()
);

export const LoadPublishedNewsPublic = createAction(
  '[News] Load Public News',
  props<{ offset: number; limit: number }>()
);

export const LoadPublishedNewsPublicSuccess = createAction(
  '[News] Load Public News Success',
  props<{ response: IPublishedNewsResponse }>()
);

export const LoadPublishedNewsPublicFailed = createAction(
  '[News] Load Public News Failed',
  props<{ error: Error }>()
);
