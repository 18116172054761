// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function coerceBooleanProperty(value: any): boolean {
  return value != null && String(value) !== 'false';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isValueNotNullAndUndefined(value: any): boolean {
  return value !== null && value !== undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isValueNullOrUndefined(value: any): boolean {
  return value === null || value === undefined;
}
