import { IAdministrationUnit, IAddress } from '../';

export interface IProperty {
  externalId: string;
  id: string;
  administrationUnit: IAdministrationUnit;
  name: string;
  address: IAddress;
  floor: number;
  rooms: number;
}
