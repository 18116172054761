import { createAction, props } from '@ngrx/store';
import {
  ConsentType,
  IChangePasswordPayload,
  ILegalTexts,
  IResetEmailBeanPayload,
  IUserData,
} from '../../../models';
import { IConsent } from '../../../models/account/consent.model';

export const DeleteUser = createAction('[Account User-Profile] Delete User');

export const DeleteUserSuccess = createAction('[Account User-Profile] Delete User Success');

export const DeleteUserFailed = createAction(
  '[Account User-Profile] Delete User Failed',
  props<{ error: Error }>()
);

export const ChangePassword = createAction(
  '[Account User-Profile] Change Password',
  props<{ changePasswordPayload: IChangePasswordPayload }>()
);

export const ChangePasswordSuccess = createAction('[Account User-Profile] Change Password Success');

export const ChangePasswordFailed = createAction(
  '[Account User-Profile] Change Password Failed',
  props<{ error: Error }>()
);

export const ChangeEmail = createAction(
  '[Account User-Profile] Change Email',
  props<{ changeEmailPayload: IResetEmailBeanPayload }>()
);

export const ChangeEmailSuccess = createAction('[Account User-Profile] Change Email Success');

export const ChangeEmailFailed = createAction(
  '[Account User-Profile] Change Email Failed',
  props<{ error: Error }>()
);

export const LoadLegalTexts = createAction('[Account User-Profile] Load Legal Texts');

export const LoadLegalTextsSuccess = createAction(
  '[Account User-Profile] Load Legal Texts Success',
  props<{ legalTexts: ILegalTexts }>()
);

export const LoadLegalTextsFailed = createAction(
  '[Account User-Profile] Load Legal Texts Failed',
  props<{ error: Error }>()
);

export const LoadPersonalData = createAction('[Account User-Profile] Load Personal Data');

export const LoadPersonalDataSuccess = createAction(
  '[Account User-Profile] Load Personal Data Success',
  props<{ personalData: IUserData }>()
);

export const LoadPersonalDataFailed = createAction(
  '[Account User-Profile] Load Personal Data Failed',
  props<{ error: Error }>()
);

export const GetConsent = createAction(
  '[Account Registration] Get Consent',
  props<{ consentType: ConsentType }>()
);

export const GetConsentSuccess = createAction(
  '[Account Registration] Get Consent Success',
  props<{ response: IConsent }>()
);
export const GetConsentFailed = createAction(
  '[Account Registration] Get Consent Failed',
  props<{ error: Error }>()
);

export const SetConsent = createAction(
  '[Account Registration] Set Consent',
  props<{ consentType: ConsentType; consentId: number }>()
);

export const SetConsentSuccess = createAction(
  '[Account Registration] Set Consent Success',
  props<{ response: boolean }>()
);
export const SetConsentFailed = createAction(
  '[Account Registration] Set Consent Failed',
  props<{ error: Error }>()
);
