import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PictureBaseComponent } from '@resident-nx/shared';

@Component({
  selector: 'rs-web-picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
})
export class PictureWebComponent extends PictureBaseComponent {
  // TODO this component needs to be written more generic
  // and adjustable, like the image component in the ui project
}
