import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive()
export abstract class TabBarBaseComponent {
  @Input() tabItems = [];
  @Output() selectTab: EventEmitter<number> = new EventEmitter<number>();
  @Input() selectedIndex = 0;

  constructor(protected translate: TranslateService) {}

  public select(event: number): void {
    this.selectedIndex = event;
    this.selectTab.emit(this.selectedIndex);
  }
}
