import { createReducer, on } from '@ngrx/store';
import { IPaymentPositionResident } from '../../models';
import * as fromActions from './payment.actions';
import { ActionStateCreator, IActionState } from '../../utils';

//Leaving for later usage
const sortPaymentRequest = (requests: IPaymentPositionResident[]): IPaymentPositionResident[] => {
  const sortOrder = ['OVERDUE', 'ERROR', 'OPEN', 'CHECK_MANUALLY', 'PAID', 'CANCELED', 'PENDING'];
  return requests.sort((a, b) => {
    return sortOrder.indexOf(a.status as string) - sortOrder.indexOf(b.status as string);
  });
};

export interface IPaymentsState {
  activePaymentRequestsActionState: IActionState;
  activePaymentRequests: IPaymentPositionResident[];
  nextDuePaymentRequest: IPaymentPositionResident;
  activePaymentRequestsCount: number;
  archivePaymentRequestsActionState: IActionState;
  archivePaymentRequests: IPaymentPositionResident[];
  archivePaymentRequestsCount: number;
}

export const initialState: IPaymentsState = {
  activePaymentRequestsActionState: ActionStateCreator.create(),
  activePaymentRequests: null,
  nextDuePaymentRequest: null,
  activePaymentRequestsCount: 0,
  archivePaymentRequests: null,
  archivePaymentRequestsActionState: ActionStateCreator.create(),
  archivePaymentRequestsCount: 0,
};

export const paymentsReducer = createReducer(
  initialState,
  on(fromActions.LoadPaymentRequests, state => {
    return {
      ...state,
      activePaymentRequestsActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.LoadPaymentRequestsSuccess, (state, { response }) => {
    const sortedResponse = sortPaymentRequest([...response]);
    const activePaymentRequestsCount = response.length;
    const nextDuePaymentRequest = response.reduce((earliest, current) => {
      if (current.dueDate < earliest.dueDate) {
        return current;
      }
      return earliest;
    }, response[0]);

    return {
      ...state,
      activePaymentRequests: sortedResponse,
      activePaymentRequestsCount,
      nextDuePaymentRequest,
      activePaymentRequestsActionState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.LoadPaymentRequestsFailed, (state, { error }) => {
    return {
      ...state,
      activePaymentRequestsActionState: ActionStateCreator.onError(error),
    };
  }),
  on(fromActions.LoadArchivePaymentRequests, state => {
    return {
      ...state,
      archivePaymentRequestsActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.LoadArchivePaymentRequestsSuccess, (state, { response }) => {
    const sortedResponse = sortPaymentRequest([...response]);
    return {
      ...state,
      archivePaymentRequests: sortedResponse,
      archivePaymentRequestsCount: response.length,
      archivePaymentRequestsActionState: ActionStateCreator.onSuccess(),
    };
  }),
  on(fromActions.LoadArchivePaymentRequestsFailed, (state, { error }) => {
    return {
      ...state,
      paymentRequestsActionState: ActionStateCreator.onError(error),
    };
  })
);

export const getActivePaymentRequestsActionState = (state: IPaymentsState) =>
  state.activePaymentRequestsActionState;
export const getActivePaymentRequests = (state: IPaymentsState) => state.activePaymentRequests;
export const getActivePaymentRequestsCount = (state: IPaymentsState) =>
  state.activePaymentRequestsCount;
export const getNextDuePaymentRequest = (state: IPaymentsState) => state.nextDuePaymentRequest;
export const getArchivePaymentsRequests = (state: IPaymentsState) => state.archivePaymentRequests;
export const getArchivePaymentsActionState = (state: IPaymentsState) =>
  state.archivePaymentRequestsActionState;
export const getArchivePaymentRequestsCount = (state: IPaymentsState) =>
  state.archivePaymentRequestsCount;
