import { gql } from 'apollo-angular';
import { residentS3FileTypeFields } from '../common';

export const metadataTicketDetailsFields = `
  id
  title
  status,
  customerStatus,
  created
  updated
  issueType
  categoryResponses {
    index
    questionText
    response
  }
  formResponses {
    index
    questionText
    type
    responses
    attachments {
      ${residentS3FileTypeFields}
    }
  }
  unreadMessages
`;

export const ticketDetailMetadataQuery = gql`
  query metadataTicketDetail($ticketId: ID!, $ticketIssueType: TicketIssueType!) {
    metadataTicketDetail(ticketId: $ticketId, issueType: $ticketIssueType) {
      ${metadataTicketDetailsFields}
    }
  }
`;
