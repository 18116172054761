import { Routes } from '@angular/router';

export const unauthenticatedRoutes: Routes = [
  {
    path: 'auth',
    loadComponent: () =>
      import('../unauthenticated/components/').then(component => component.AuthWebComponent),
  },
  {
    path: 'unauthorized-user',
    loadComponent: () =>
      import('./components/').then(component => component.UnauthorizedUserWebComponent),
  },
];
