import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { getUser, getUserActionState, LoadMasterData } from '../../+state';
import { IUser } from '../../models';
import { ResolverCommonService } from './resolver-common.service';

export const UserResolver: ResolveFn<IUser> = route => {
  const common = inject(ResolverCommonService);

  const { resolverWaitForData } = route.data;
  return common.resolveHandler<IUser>(
    getUserActionState,
    LoadMasterData(),
    getUser,
    resolverWaitForData
  );
};
