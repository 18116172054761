import { Directive, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive()
export abstract class PasswordSetFormBaseComponent {
  @Input() fg: FormGroup;

  subs$: Subscription[] = [];

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    this.subs$.forEach(sub => sub?.unsubscribe());
  }
}
