import { Pipe, PipeTransform } from '@angular/core';
import { PaymentRequestStatus } from '../models';

@Pipe({
  name: 'paymentBgStatus',
  standalone: true,
})
export class PaymentStatusBgPipe implements PipeTransform {
  transform(status: string): string | null {
    if (!status) {
      return 'bg-status_cancelled';
    }
    switch (status) {
      case PaymentRequestStatus.OPEN:
        return 'bg-status_open';
      case PaymentRequestStatus.ERROR:
        return 'bg-status_error';
      case PaymentRequestStatus.OVERDUE:
        return 'bg-status_open';
      case PaymentRequestStatus.CHECK_MANUALLY:
        return 'bg-status_open';
      case PaymentRequestStatus.CANCELED:
        return 'bg-status_cancelled';
      case PaymentRequestStatus.PAID:
        return 'bg-status_done';
      case PaymentRequestStatus.PAID_OUT:
        return 'bg-status_done';
      case PaymentRequestStatus.PENDING:
        return 'bg-status_in-progress';
      case PaymentRequestStatus.PENDING_PAY_OUT:
        return 'bg-status_in-progress';
      case PaymentRequestStatus.REFUNDED:
        return 'bg-status_done';
      default:
        return 'bg-status_cancelled';
    }
  }
}
