<div class="news-overview container container--with-spacing">
  <h1 class="primary-text">News</h1>
  <hr />
  <rs-web-announcements-widget
    (tapped)="onTapAnnouncement($event)"
    (overviewTapped)="onTapAnnouncementOverview()"
  ></rs-web-announcements-widget>

  <hr />
  <rs-web-offers-widget
    (tapped)="onTapOffer($event)"
    (overviewTapped)="onTapOfferOverview()"
    (homepageModuleTapped)="onTapOfferHomepage()"
  ></rs-web-offers-widget>

  <hr />
  <rs-web-news-widget
    (tapped)="onTapCurrentNews($event)"
    (overviewTapped)="onTapCurrentNewsOverview()"
  ></rs-web-news-widget>
</div>
