import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BreadcrumbItem, ButtonTypeEnum } from '@resident-nx/shared';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonWebComponent } from '../button/button.component';

@Component({
  selector: 'rs-web-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonWebComponent, TranslateModule],
})
export class BreadcrumbsWebComponent {
  private _items: BreadcrumbItem[];
  protected readonly buttonTypeEnum = ButtonTypeEnum;

  @Input() get items(): BreadcrumbItem[] {
    return this._items;
  }

  set items(value) {
    this._items = this.handleBreadcrumbs(value);
  }

  @Input() trim = true;
  @Input() trimLength = 3;
  @Input() readonly: boolean;
  @Output() navigateToUrl = new EventEmitter<string>();
  @Output() navigateBack = new EventEmitter<void>();

  public navigate(url: string): void {
    if (this.items[this.items.length - 1].url === url) return;
    this.navigateToUrl.emit(url);
  }

  private handleBreadcrumbs(breadcrumbs: BreadcrumbItem[] = []): BreadcrumbItem[] {
    if (!this.trim || breadcrumbs.length <= this.trimLength) return breadcrumbs;

    const indicesToShow = {
      0: true,
    };

    for (let i = breadcrumbs.length; i > breadcrumbs.length - this.trimLength; i--) {
      indicesToShow[i] = true;
    }

    return breadcrumbs.map((breadcrumb, index) => {
      if (indicesToShow[index]) return breadcrumb;

      return {
        url: undefined,
        label: '..',
      };
    });
  }
}
