import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LabelBaseComponent } from '@resident-nx/shared';

@Component({
  selector: 'rs-web-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LabelWebComponent extends LabelBaseComponent {}
