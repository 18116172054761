import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { of } from 'rxjs';
import { IFederatedLoginEnvironmentVariables, StorageService } from '@resident-nx/shared';

export const LoginPageGuard: CanActivateFn = () => {
  const sessionStorage = inject(StorageService);
  const router = inject(Router);

  const clientId = sessionStorage.getItem(IFederatedLoginEnvironmentVariables.CLIENT_ID);

  if (!clientId) return of(true);

  void router.navigate(['']);

  return of(false);
};
