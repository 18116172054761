export const SHARED_ENDPOINTS = {
  AUTH: '/auth',
  CUSTOMER_SELECTION: '/user-apps',
  CONTENT: '/content',
  HOME: '/content/home',
  NEWS: '/content/news',
  CONCERN: '/content/concern',
  DASHBOARD: '/content/dashboard',
  CREATION_PROPERTY_DAMAGE: '/content/concern/ticket-creation/PROPERTY_DAMAGE',
  CREATION_CONCERN: '/content/concern/ticket-creation/PROPERTY_CONCERN',
  PASSWORD_RESET_SUCCESS_PAGE: 'auth/password-changed',
  PASSWORD_RESET_EMAIL_SENT_PAGE: 'auth/email-sent',
};
