import { createAction, props } from '@ngrx/store';
import { INotificationEmailSettings } from '../../../models/notifications/notification-emails-settings.model';

export const LoadEmailSettings = createAction('[Resident Emails Settings] Load Email Settings');

export const LoadEmailSettingsSuccess = createAction(
  '[Resident Email Settings] Load Email Settings Success',
  props<{ success: INotificationEmailSettings }>()
);

export const LoadEmailSettingsFailed = createAction(
  '[Resident Email Settings] Load Email Settings Failed',
  props<{ error: Error }>()
);

export const ChangeEmailSettings = createAction(
  '[Resident Email Settings] Change Email Settings',
  props<{ payload: INotificationEmailSettings }>()
);

export const ChangeEmailSettingsSuccess = createAction(
  '[Resident Email Settings] Change Email Settings Success',
  props<{ response: INotificationEmailSettings }>()
);

export const ChangeEmailSettingsFailed = createAction(
  '[Resident Email Settings] Change Email Settings Failed',
  props<{ error: Error }>()
);
