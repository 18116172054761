import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { ContentTabItem } from './tab-item';
import { IconWebComponent } from '../../atoms/icon/icon.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'rs-web-content-tabs',
  templateUrl: './content-tabs.component.html',
  styleUrls: ['./content-tabs.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgClass, IconWebComponent, RouterOutlet],
})
export class ContentTabsComponent implements OnInit {
  @Input() tabItems: ContentTabItem[] = [];
  @Input() className: string;
  public selectedTab!: string;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.tabItems?.length > 0) {
      this.selectedTab = this.tabItems[0].id;
      this.selectedTab && this.selectTab(this.selectedTab);
    }
  }

  public selectTab(tabId: string): void {
    this.selectedTab = tabId;
    void this.router.navigate([tabId], { relativeTo: this.activeRoute });
  }
}
