<div class="p-4">
  <div class="d-flex justify-content-between">
    <div class="ticket-delete-modal__title">{{ 'cancel.process_l' | translate }}</div>
    <rs-web-icon
      [cursor]="'pointer'"
      [size]="20"
      [icon]="immomioIconName.Close"
      (tapped)="activeModal.dismiss()"
    ></rs-web-icon>
  </div>
  <div class="pt-4">{{ 'cancel.ticket.description.first.line_l' | translate }}</div>
  <div class="py-4">{{ 'cancel.ticket.description.second.line_l' | translate }}</div>
  <div class="d-flex gap-3">
    <rs-web-button
      class="flex-grow-1"
      [type]="buttonTypeEnum.LIGHT_BORDERED"
      (tapped)="activeModal.dismiss()"
      >{{ 'ticket_cancel_rejection_a' | translate }}</rs-web-button
    >
    <rs-web-button
      class="flex-grow-1"
      [iconRight]="immomioIconName.Trash"
      [type]="buttonTypeEnum.NEGATIVE"
      (tapped)="activeModal.close()"
      >{{ 'ticket.cancel_a' | translate }}</rs-web-button
    >
  </div>
</div>
