import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';

import {
  getActiveDamageTickets,
  getActiveDamageTicketsActionState,
  LoadActiveDamageTickets,
} from '../../+state';
import { ITicketResidentOverviewEdge } from '../../models';
import { ResolverCommonService } from './resolver-common.service';
import { CustomerService } from '../customer';

export const DamageTicketsActiveResolver: ResolveFn<ITicketResidentOverviewEdge[]> = (
  route: ActivatedRouteSnapshot
) => {
  const common = inject(ResolverCommonService);
  const customerService = inject(CustomerService);

  /**
   * don't send requests for active damage tickets if the B&O Damage Tool is used
   */
  const bAndODamageFlow = customerService.showBandODamageFlow();
  if (bAndODamageFlow) {
    return null;
  }
  const { resolverWaitForData } = route.data;

  return common.resolveHandler<ITicketResidentOverviewEdge[]>(
    getActiveDamageTicketsActionState,
    LoadActiveDamageTickets({ offset: 0, limit: 20 }),
    getActiveDamageTickets,
    resolverWaitForData
  );
};
