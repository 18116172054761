<div class="container">
  <div class="mb-5">
    <h1>{{ 'user.faq' | translate }}</h1>
    <hr />
  </div>
  <div class="d-flex align-items-center mb-3">
    <div class="icon-header d-flex me-2">
      <rs-web-icon [icon]="immomioIconName.QuestionOutlined"></rs-web-icon>
    </div>
    <span class="primary-text"
      ><strong>{{ 'faq.header_title' | translate }}</strong></span
    >
  </div>

  @if (faqsActionState$ | async) {
    @if (faqs$ | async; as faqs) {
      <div [closeOthers]="true" class="m-y-4" ngbAccordion>
        @for (faq of faqs?.faqGroups[0]?.faqs; track faq.id) {
          <div #item="ngbAccordionItem" ngbAccordionItem>
            <div ngbAccordionHeader>
              <button ngbAccordionButton>
                <div class="d-flex me-3 icon">
                  <rs-web-icon
                    [icon]="item.collapsed ? immomioIconName.Plus : immomioIconName.Minus"
                    [color]="'var(--color-primary)'"
                  ></rs-web-icon>
                </div>

                {{ faq.title }}
              </button>
            </div>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <span [innerHtml]="faq?.text"></span>
                  <div class="mt-3">
                    <a href="{{ faq?.href }}" target="_blank">{{ faq?.href }}</a>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        }
      </div>
    }
  } @else {
    <rs-web-loader></rs-web-loader>
  }
</div>
