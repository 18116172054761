import { Directive, Input, HostBinding, HostListener } from '@angular/core';
import { ELEVATION_DEFAULT_CONFIG, ELEVATION_MAX_VALUE } from './elevation.config';
import { Elevation, ElevationType } from './elevation.model';

const isTouchScreen = (): boolean => 'ontouchstart' in window || navigator.maxTouchPoints > 0;

@Directive({
  selector: '[rsWebElevation]',
  standalone: true,
})
export class ElevationDirective {
  @Input() elevation = ELEVATION_DEFAULT_CONFIG.elevation;
  @Input() elevationChangeStepOnHover = ELEVATION_DEFAULT_CONFIG.elevationChangeStepOnHover;
  @Input() elevationChangeStepOnClick = ELEVATION_DEFAULT_CONFIG.elevationChangeStepOnClick;
  @Input() elevationChangeStepOnTouch = ELEVATION_DEFAULT_CONFIG.elevationChangeStepOnTouch;
  @Input() clickable = ELEVATION_DEFAULT_CONFIG.clickable; // = with hover & click effect for non-touch devices + touch effect for touch devices

  public isHoverActive = false;
  public isMouseDownActive = false;
  public isTouchActive = false;

  public useElevation(elevation: ElevationType): boolean {
    const baseElevation = this.elevation;
    const elevationIsBaseElevation = baseElevation === elevation;

    if (isTouchScreen()) {
      const touchState = this.clickable && this.isTouchActive;
      if (
        touchState &&
        !(
          elevationIsBaseElevation &&
          elevation + this.elevationChangeStepOnTouch > ELEVATION_MAX_VALUE
        )
      ) {
        return baseElevation === elevation - this.elevationChangeStepOnTouch;
      } else {
        return elevationIsBaseElevation;
      }
    } else {
      const hoverState = this.clickable && this.isHoverActive;
      const clickState = this.clickable && this.isMouseDownActive;
      if (
        clickState &&
        !(
          elevationIsBaseElevation &&
          elevation + this.elevationChangeStepOnClick > ELEVATION_MAX_VALUE
        )
      ) {
        return baseElevation === elevation - this.elevationChangeStepOnClick;
      } else if (
        hoverState &&
        !(
          elevationIsBaseElevation &&
          elevation + this.elevationChangeStepOnHover > ELEVATION_MAX_VALUE
        )
      ) {
        return baseElevation === elevation - this.elevationChangeStepOnHover;
      } else {
        return elevationIsBaseElevation;
      }
    }
  }

  @HostBinding('class.mdc-elevation-transition') get transition() {
    return this.clickable;
  }

  // TODO: find a way to handle the elevation z-levels (0-24) dynamically
  @HostBinding('class.mdc-elevation--z0') get z0() {
    return this.useElevation(Elevation.ZERO);
  }

  @HostBinding('class.mdc-elevation--z1') get z1() {
    return this.useElevation(Elevation.ONE);
  }

  @HostBinding('class.mdc-elevation--z2') get z2() {
    return this.useElevation(Elevation.TWO);
  }

  @HostBinding('class.mdc-elevation--z3') get z3() {
    return this.useElevation(Elevation.THREE);
  }

  @HostBinding('class.mdc-elevation--z4') get z4() {
    return this.useElevation(Elevation.FOUR);
  }

  @HostBinding('class.mdc-elevation--z5') get z5() {
    return this.useElevation(Elevation.FIVE);
  }

  @HostBinding('class.mdc-elevation--z6') get z6() {
    return this.useElevation(Elevation.SIX);
  }

  @HostBinding('class.mdc-elevation--z7') get z7() {
    return this.useElevation(Elevation.SEVEN);
  }

  @HostBinding('class.mdc-elevation--z8') get z8() {
    return this.useElevation(Elevation.EIGHT);
  }

  @HostBinding('class.mdc-elevation--z9') get z9() {
    return this.useElevation(Elevation.NINE);
  }

  @HostBinding('class.mdc-elevation--z10') get z10() {
    return this.useElevation(Elevation.TEN);
  }

  @HostBinding('class.mdc-elevation--z11') get z11() {
    return this.useElevation(Elevation.ELEVEN);
  }

  @HostBinding('class.mdc-elevation--z12') get z12() {
    return this.useElevation(Elevation.TWELVE);
  }

  @HostBinding('class.mdc-elevation--z13') get z13() {
    return this.useElevation(Elevation.THIRTEEN);
  }

  @HostBinding('class.mdc-elevation--z14') get z14() {
    return this.useElevation(Elevation.FOURTEEN);
  }

  @HostBinding('class.mdc-elevation--z15') get z15() {
    return this.useElevation(Elevation.FIFTEEN);
  }

  @HostBinding('class.mdc-elevation--z16') get z16() {
    return this.useElevation(Elevation.SIXTEEN);
  }

  @HostBinding('class.mdc-elevation--z17') get z17() {
    return this.useElevation(Elevation.SEVENTEEN);
  }

  @HostBinding('class.mdc-elevation--z18') get z18() {
    return this.useElevation(Elevation.EIGHTEEN);
  }

  @HostBinding('class.mdc-elevation--z19') get z19() {
    return this.useElevation(Elevation.NINETEEN);
  }

  @HostBinding('class.mdc-elevation--z20') get z20() {
    return this.useElevation(Elevation.TWENTY);
  }

  @HostBinding('class.mdc-elevation--z21') get z21() {
    return this.useElevation(Elevation.TWENTY_ONE);
  }

  @HostBinding('class.mdc-elevation--z22') get z22() {
    return this.useElevation(Elevation.TWENTY_TWO);
  }

  @HostBinding('class.mdc-elevation--z23') get z23() {
    return this.useElevation(Elevation.TWENTY_THREE);
  }

  @HostBinding('class.mdc-elevation--z24') get z24() {
    return this.useElevation(Elevation.TWENTY_FOUR);
  }

  @HostListener('mouseenter') mouseover() {
    this.isHoverActive = true;
  }

  @HostListener('mouseleave') mouseleave() {
    this.isHoverActive = false;
    this.isMouseDownActive = false;
  }

  @HostListener('mousedown') mousedown() {
    this.isMouseDownActive = true;
  }

  @HostListener('mouseup') mouseup() {
    this.isMouseDownActive = false;
  }

  @HostListener('touchstart') touchstart() {
    this.isTouchActive = true;
  }

  @HostListener('touchmove') touchmove() {
    this.isTouchActive = false;
  }

  @HostListener('touchend') touchend() {
    this.isTouchActive = false;
  }
}
