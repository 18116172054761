import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import {
  getRegisteredUserApps,
  getRegisteredUserAppsActionState,
  GetUserRegisteredSaasResidentApps,
} from '../../+state';
import { ISaasResidentLandlordCustomerResponse } from '../../models';
import { ResolverCommonService } from './resolver-common.service';

export const UserAppsResolver: ResolveFn<ISaasResidentLandlordCustomerResponse[]> = route => {
  const common = inject(ResolverCommonService);

  const { resolverWaitForData } = route.data;
  return common.resolveHandler<ISaasResidentLandlordCustomerResponse[]>(
    getRegisteredUserAppsActionState,
    GetUserRegisteredSaasResidentApps(),
    getRegisteredUserApps,
    resolverWaitForData
  );
};
