import { Directive, Input } from '@angular/core';
import { LoadingType } from '../../models';

@Directive()
export abstract class LoadingSkeletonBaseComponent {
  @Input() show = false;
  @Input() className?: string;
  @Input() types: LoadingType[] = [];
  public loadingType = LoadingType;
}
