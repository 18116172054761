import { createReducer, on } from '@ngrx/store';
import { IContract, IMasterDataUser, IResidentS3File, ContractStatus } from '../../../models';
import { ActionStateCreator, dateStringToMilliseconds, IActionState } from '../../../utils';
import * as fromActions from './masterdata.actions';

const getPrimaryContract = (user: IMasterDataUser, contracts?: IContract[]): IContract => {
  let contract: IContract;
  if (contracts?.length === 1) {
    contract = contracts[0];
    return contract;
  } else if (contracts?.length > 1) {
    if (user?.mainContractId) {
      contract = contracts.find(el => el.externalId === user?.mainContractId);
    } else if (contracts.some(el => el.status !== ContractStatus.EXPIRED && el.type === 'FLAT')) {
      contract = contracts.find(el => el.status !== ContractStatus.EXPIRED && el.type === 'FLAT');
    } else if (contracts.some(el => el.status !== ContractStatus.EXPIRED)) {
      contract = contracts.find(el => el.status !== ContractStatus.EXPIRED);
    } else {
      contract = contracts[0];
    }
    return contract;
  }
  return null;
};

export interface IMasterDataState {
  user: IMasterDataUser;
  userActionState: IActionState;
  updateMasterDataActionState: IActionState;
  contracts: IContract[];
  contractsActionState: IActionState;
  selectedContract: IContract;
  selectedContractDocumentsActionState: IActionState;
  selectedContractDocuments: IResidentS3File[];
  selectedContractSelectedDocument: IResidentS3File;
  contractsCount: number;
}

export const initialState: IMasterDataState = {
  user: null,
  userActionState: ActionStateCreator.create(),
  updateMasterDataActionState: ActionStateCreator.create(),
  contracts: [],
  contractsActionState: ActionStateCreator.create(),
  selectedContract: null,
  selectedContractDocumentsActionState: ActionStateCreator.create(),
  selectedContractDocuments: [],
  selectedContractSelectedDocument: null,
  contractsCount: null,
};

export const masterDataReducer = createReducer(
  initialState,

  on(fromActions.LoadMasterData, state => {
    return {
      ...state,
      userActionState: ActionStateCreator.onStart(),
    };
  }),

  on(fromActions.LoadMasterDataSuccess, (state, { user }) => {
    return {
      ...state,
      userActionState: ActionStateCreator.onSuccess(),
      user,
      selectedContract: getPrimaryContract(user, state.contracts),
    };
  }),

  on(fromActions.LoadMasterDataFailed, (state, { error }) => {
    return {
      ...state,
      userActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.LoadContracts, state => {
    return {
      ...state,
      contractsActionState: ActionStateCreator.onStart(),
    };
  }),

  on(fromActions.LoadContractsSuccess, (state, { contracts }) => {
    contracts = contracts?.map(contract => {
      const contractStartTime = contract.contractStart
        ? dateStringToMilliseconds(contract.contractStart)
        : null;
      const contractEndTime = contract.contractEnd
        ? dateStringToMilliseconds(contract.contractEnd)
        : null;
      return {
        ...contract,
        contractStartTime,
        contractEndTime,
      };
    });
    const sortedContracts =
      contracts?.length > 0
        ? [...contracts].sort((a: IContract, b: IContract) =>
            a.contractStartTime && b.contractStartTime
              ? a.contractStartTime - b.contractStartTime
              : 1
          )
        : [];
    return {
      ...state,
      contractsActionState: ActionStateCreator.onSuccess(),
      contracts: sortedContracts,
      contractsCount: contracts?.length,
      selectedContract: getPrimaryContract(state.user, contracts),
    };
  }),

  on(fromActions.LoadContractsFailed, (state, { error }) => {
    return {
      ...state,
      contractsActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.SelectContract, (state, { id }) => {
    const selectedContract = state.contracts?.find(contract => contract.id == id);
    const isSameContract = state.selectedContract && state.selectedContract.id == id;

    let newState = {
      ...state,
      selectedContract: selectedContract,
    };

    if (!isSameContract) {
      newState = {
        ...newState,
        selectedContractDocuments: null,
        selectedContractDocumentsActionState: ActionStateCreator.create(),
      };
    }
    return newState;
  }),

  on(fromActions.LoadContractDocuments, state => {
    return {
      ...state,
      selectedContractDocumentsActionState: ActionStateCreator.onStart(),
    };
  }),

  on(fromActions.LoadContractDocumentsSuccess, (state, { documents }) => {
    return {
      ...state,
      selectedContractDocumentsActionState: ActionStateCreator.onSuccess(),
      selectedContractDocuments: documents,
    };
  }),

  on(fromActions.LoadContractDocumentsFailed, (state, { error }) => {
    return {
      ...state,
      selectedContractDocumentsActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.SelectContractDocument, (state, { identifier }) => {
    const document = state.selectedContractDocuments?.find(d => d.identifier == identifier);
    return {
      ...state,
      selectedContractSelectedDocument: document,
    };
  })
);

export const getUser = (state: IMasterDataState) => state.user;
export const getUserActionState = (state: IMasterDataState) => state.userActionState;
export const getUpdateMasterDataActionState = (state: IMasterDataState) =>
  state.updateMasterDataActionState;
export const getContracts = (state: IMasterDataState) => state.contracts;
export const getContractsActionState = (state: IMasterDataState) => state.contractsActionState;
export const getSelectedContract = (state: IMasterDataState) => state.selectedContract;
export const getSelectedContractDocuments = (state: IMasterDataState) =>
  state.selectedContractDocuments;
export const getSelectedContractDocumentsActionState = (state: IMasterDataState) =>
  state.selectedContractDocumentsActionState;
export const getSelectedContractSelectedDocument = (state: IMasterDataState) =>
  state.selectedContractSelectedDocument;
export const getContractsCount = (state: IMasterDataState) => state.contractsCount;
