import { createSelector } from '@ngrx/store';
import { getHomepageModuleState } from '../reducers';
import * as fromReducer from './homepage-module.reducer';

export const getHomepageModuleToken = createSelector(
  getHomepageModuleState,
  fromReducer.getHomepageModuleToken
);
export const getHomepageModuleActionState = createSelector(
  getHomepageModuleState,
  fromReducer.getHomepageModuleTokenActionState
);
