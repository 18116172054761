import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ImageWebComponent } from '../../../../../../web/components/atoms/image/image.component';
import { LabelWebComponent } from '../../../../../../web/components/atoms/label/label.component';

@Component({
  selector: 'rs-web-tickets-no-catalogue',
  standalone: true,
  imports: [LabelWebComponent, TranslateModule, ImageWebComponent],
  templateUrl: './tickets-no-catalogue.component.html',
  styleUrl: './tickets-no-catalogue.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketsNoCatalogueComponent {}
