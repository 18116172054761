import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as fromActions from './masterdata.actions';
import * as fromFeedbackActions from '../../general/feedback/feedback.actions';

import { IEnvironment } from '../../../models';
import {
  AccountMasterDataFacade,
  ErrorHandlerService,
  masterdataConverter,
} from '../../../services';
import { LOGOUT } from '../../meta-reducers';
import { AuthService } from '../../../infrastructure';
import { Store } from '@ngrx/store';
import { of, withLatestFrom } from 'rxjs';
import { getContracts, getContractsActionState } from './masterdata.selectors';
import { differenceInMinutes } from 'date-fns';

@Injectable()
export class MasterDataEffects {
  loadMasterData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadMasterData),
      switchMap(() =>
        this.masterDataFacade.getMasterData().pipe(
          map(masterdataConverter.fromDto),
          map(user =>
            fromActions.LoadMasterDataSuccess({
              user,
            })
          ),
          catchError(error => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on masterdata.effects.ts:loadMasterData$:',
              error
            );
            return [
              fromActions.LoadMasterDataFailed({ error }),
              void this.authService.logout('appProvider'),
              LOGOUT(),
              fromFeedbackActions.ShowFeedbackError({
                title: 'login_failed_l',
                message: 'unexpected_error_m',
              }),
            ];
          })
        )
      )
    )
  );
  updateMasterData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.UpdateMasterData),
      switchMap(({ payload }) =>
        // eslint-disable-next-line
        this.masterDataFacade.updateUserMasterdata(payload).pipe(
          switchMap(createdTicketId => [
            fromActions.UpdateMasterDataSuccess({ createdTicketId }),
            fromFeedbackActions.ShowFeedbackSuccess({
              message: 'update_masterdata.success_m',
            }),
          ]),
          catchError(error => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on masterdata.effects.ts:updateMasterData$:',
              error
            );
            return [
              fromFeedbackActions.ShowFeedbackError({
                message: 'update_masterdata.error_m',
              }),
              fromActions.UpdateMasterDataFailed({ error }),
            ];
          })
        )
      )
    )
  );
  loadContracts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadContracts),
      withLatestFrom(this.store.select(getContracts), this.store.select(getContractsActionState)),
      switchMap(([action, data, actionState]) => {
        const noData = !data || data?.length === 0;
        const doneTimeIsOld =
          actionState?.doneTime && differenceInMinutes(new Date(), actionState.doneTime) > 30;
        if (noData || doneTimeIsOld || action.forced) {
          return this.masterDataFacade.getContracts().pipe(
            map(masterdataConverter.fromContractsDto),
            map(contracts => {
              return fromActions.LoadContractsSuccess({
                contracts,
              });
            }),
            catchError(error => {
              this.errorHandlerService.handleError(
                '(REQUEST ERROR) on masterdata.effects.ts:loadContracts$:',
                error
              );
              return [
                fromActions.LoadContractsFailed({ error }),
                void this.authService.logout('appProvider', 'auth'),
                LOGOUT(),
              ];
            })
          );
        } else {
          return of(fromActions.LoadContractsSuccess({ contracts: data }));
        }
      })
    )
  );
  loadContractDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadContractDocuments),
      switchMap(({ contractId }) =>
        this.masterDataFacade.getContractDocumentsList(contractId).pipe(
          map(documents =>
            fromActions.LoadContractDocumentsSuccess({
              documents,
            })
          ),
          catchError(error => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on masterdata.effects.ts:loadContractDocuments$:',
              error
            );
            return [fromActions.LoadContractDocumentsFailed({ error })];
          })
        )
      )
    )
  );
  setMovingAddressSent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.SetMovingAddressSent),
      switchMap(({ contractId, movingAddressSent }) =>
        this.masterDataFacade.setMovingAddressSent(contractId, movingAddressSent).pipe(
          switchMap(success => [
            fromActions.LoadContracts({ forced: true }),
            fromActions.SetMovingAddressSentSuccess({ success }),
          ]),
          catchError(error => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on masterdata.effects.ts:setMovingAddressSent$:',
              error
            );
            return [fromActions.SetMovingAddressSentError({ error })];
          })
        )
      )
    )
  );

  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private actions$: Actions,
    private masterDataFacade: AccountMasterDataFacade,
    private router: Router,
    private authService: AuthService,
    private store: Store,
    private errorHandlerService: ErrorHandlerService
  ) {}
}
