import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonBorderRadiusEnum, ButtonSizeEnum, ButtonTypeEnum } from '@resident-nx/shared';
import { ImageBorderRadiusEnum } from '../../../atoms/image/image.model';
import { LoaderWebComponent } from '../../../atoms/loader/loader.component';
import { ImageWebComponent } from '../../../atoms/image/image.component';
import { ButtonWebComponent } from '../../../atoms/button/button.component';

@Component({
  selector: 'rs-web-document-preview-modal',
  templateUrl: './document-preview-modal.component.html',
  styleUrls: ['./document-preview-modal.component.scss'],
  standalone: true,
  imports: [ButtonWebComponent, ImageWebComponent, LoaderWebComponent],
})
export class DocumentWebPreviewModalComponent {
  @Input() documentType: string;
  @Input() documentName: string;
  @Input() previewUrl: string;
  @Input() downloadBlob: string;
  @Input() previewLoading: boolean;
  @Output() download = new EventEmitter<{ previewUrl: string; downloadBlob: string }>();

  //For image component styling
  public imageBorderRadius = ImageBorderRadiusEnum;

  //For button component styling
  public buttonSize = ButtonSizeEnum;
  public buttonBorderRadius = ButtonBorderRadiusEnum;
  public buttonType = ButtonTypeEnum;

  constructor(public ngbModal: NgbActiveModal) {}

  public onDownload() {
    this.ngbModal.close({ documentName: this.documentName, downloadBlob: this.downloadBlob });
  }
}
