import { gql } from 'apollo-angular';

export const paymentRequestsFields = `
  id
  subject
  amount
  dueDate
  lastReminderDate
  status
  category
  paymentLandingPageUrl
`;

export const paymentRequestsQuery = gql`
  query getPaymentRequests($archived: Boolean!) {
    getPaymentRequests(archived: $archived) {
      paymentPositionResidentBeans {
        ${paymentRequestsFields}
      }
    }
  }
`;
