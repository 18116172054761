import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconBaseComponent } from '@resident-nx/shared';

@Component({
  selector: 'rs-web-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconWebComponent extends IconBaseComponent {
  @Input() cursor: 'pointer' | 'initial' | 'unset' = 'unset';
}
