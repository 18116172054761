import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  concatMap,
  delay,
  EMPTY,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { endOfMonth, startOfMonth, subYears } from 'date-fns';

import {
  consumptionConverter,
  ConsumptionFacade,
  consumptionInfoConverter,
  consumptionLegalFilter,
  consumptionTimeframeToAggregate,
  getSavedConsumption,
  isSelectedPeriodAllowedAndAvailable,
} from '../../services';
import {
  ByDeviceId,
  ConsumptionAggregateType,
  IConsumptionBenchmarkItemResponse,
  IConsumptionInfo,
  IConsumptionItem,
  IPeriod,
} from '../../models';
import * as fromActions from './consumption.actions';
import {
  LoadConsumptionBenchmarkForMetersFailed,
  LoadConsumptionBenchmarkForMetersSuccess,
  LoadConsumptionForMetersFailed,
  LoadConsumptionForMetersSuccess,
  LoadPastConsumptionForMetersFailed,
  LoadPastConsumptionForMetersSuccess,
} from './consumption.actions';
import { getResidentAppSettings } from '../account';
import * as fromReducers from '../account/masterdata/masterdata.reducer';
import * as fromMasterDataState from '../account/masterdata';
import * as fromConsumptionState from './';

@Injectable()
export class ConsumptionEffects {
  constructor(
    private actions$: Actions,
    private consumptionFacade: ConsumptionFacade,
    private store: Store<fromReducers.IMasterDataState>
  ) {}

  loadMetersForSelectedContract$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.LoadMetersByContract),
      withLatestFrom(
        this.store.select(fromMasterDataState.getSelectedContract),
        this.store.select(fromConsumptionState.getMetersByContract),
        this.store.select(getResidentAppSettings)
      ),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_, contract, metersByContract, { consumptionDataVersion }]) => {
        // we can only request data if the contract has started
        if (contract.contractStartTime) {
          const contractId = contract?.id;
          if (contractId && metersByContract && metersByContract[contractId]) {
            const consumptionInfo = metersByContract[contractId];
            return of(
              fromActions.LoadMetersBySelectedContractSuccess({ consumptionInfo, contract })
            );
          }
          return this.consumptionFacade.getConsumptionInfo(contractId).pipe(
            map(v => consumptionInfoConverter.fromDto(v, consumptionDataVersion)),
            map((consumptionInfo: IConsumptionInfo) =>
              fromActions.LoadMetersBySelectedContractSuccess({ consumptionInfo, contract })
            ),
            catchError((error: Error) => [
              fromActions.LoadMetersBySelectedContractFailed({ error }),
            ])
          );
        } else {
          return EMPTY;
        }
      })
    );
  });

  /***
   * on LoadMetersSuccess action dispatch SelectMeters
   */
  selectDefaultMeterOnLoadMetersSuccess = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.LoadMetersBySelectedContractSuccess),
      withLatestFrom(this.store.select(fromConsumptionState.getAvailableMeters)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_, availableMeters]) => {
        if (availableMeters?.meters?.length > 0) {
          return of(
            fromActions.SelectMeters({
              meters: availableMeters.meters,
            })
          );
        }
        return of(
          fromActions.LoadMetersBySelectedContractFailed({
            error: {
              name: 'No available meters',
              message: 'No available meters',
            },
          })
        );
      })
    );
  });

  /***
   * on SelectMeters action dispatch ClearSelectedConsumption
   * to remove already selected value
   */
  clearSelectedConsumptionOnSelectMeters = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectMeters),
      switchMap(() => {
        return of(fromConsumptionState.ClearSelectedConsumption());
      })
    );
  });

  /***
   * on SelectMeter action dispatch SelectLatestAvailablePeriod
   */
  selectLatestAvailablePeriodOnSelectMeters = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectMeters),
      withLatestFrom(this.store.select(fromMasterDataState.getSelectedContract)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_, contract]) => {
        return of(fromConsumptionState.SelectLatestAvailablePeriod({ contract }));
      })
    );
  });

  /***
   * on SelectMeter action dispatch LoadSelectedPeriodConsumption
   */
  loadConsumptionOnSelectMeters = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectMeters),
      switchMap(({ meters }) => {
        const deviceIds = meters.map(meter => meter.deviceId);
        return of(fromConsumptionState.LoadConsumptionForMeters({ deviceIds }));
      })
    );
  });

  /***
   * on SelectMeters action dispatch LoadPastConsumptionForMeters
   */
  loadPastConsumptionOnSelectMeters = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectMeters),
      switchMap(({ meters }) => {
        const deviceIds = meters.map(meter => meter.deviceId);
        return of(fromConsumptionState.LoadPastConsumptionForMeters({ deviceIds }));
      })
    );
  });

  /***
   * on SelectMeters action dispatch LoadConsumptionBenchmark
   */
  loadConsumptionBenchmarkOnSelectMeters = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectMeters),
      switchMap(({ meters }) => {
        const deviceIds = meters.map(meter => meter.deviceId);
        return of(fromConsumptionState.LoadConsumptionBenchmarkForMeters({ deviceIds }));
      })
    );
  });

  triggerLoadConsumptionForAllSelectedMeters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.TriggerLoadConsumptionForAllSelectedMeters),
      withLatestFrom(this.store.select(fromConsumptionState.getSelectedMeters)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_, selectedMeters]) => {
        const deviceIds = selectedMeters.map(meter => meter.deviceId);
        return of(fromConsumptionState.LoadConsumptionForMeters({ deviceIds }));
      })
    )
  );

  loadConsumptionForMeters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadConsumptionForMeters),
      withLatestFrom(
        this.store.select(fromConsumptionState.getSelectedPeriodsForRequests),
        this.store.select(fromConsumptionState.getSelectedPeriodsForCharts),
        this.store.select(fromMasterDataState.getSelectedContract),
        this.store.select(fromConsumptionState.getSelectedMeters),
        this.store.select(fromConsumptionState.getSavedConsumptionData),
        this.store.select(fromConsumptionState.getSelectedTimeframe),
        this.store.select(getResidentAppSettings)
      ),
      concatMap(
        ([
          { deviceIds },
          selectedPeriodsForRequests,
          selectedPeriodsForCharts,
          contract,
          selectedMeters,
          savedConsumptionData,
          timeframe,
          { consumptionDataVersion },
        ]) => {
          const meters = selectedMeters.filter(meter => deviceIds.includes(meter.deviceId));
          if (meters.length === 0) {
            return of(
              fromActions.LoadConsumptionForMetersFailed({
                deviceIds,
                error: {
                  name: 'No selected meters found for deviceIds',
                  message: 'No selected meters found for deviceIds',
                },
              })
            );
          }

          const results$: ByDeviceId<Observable<IConsumptionItem[]>> = {};

          for (const meter of meters) {
            const selectedPeriodForRequest = selectedPeriodsForRequests[meter.deviceId];
            const selectedPeriodForChart = selectedPeriodsForCharts[meter.deviceId];

            if (!isSelectedPeriodAllowedAndAvailable(contract, selectedPeriodForRequest, meter)) {
              const error = `Selected period not available for ${meter.deviceId}`;
              return of(
                fromActions.LoadConsumptionForMetersFailed({
                  deviceIds,
                  error: {
                    name: error,
                    message: error,
                  },
                })
              );
            }

            const savedConsumptionPeriod = getSavedConsumption(
              savedConsumptionData,
              contract.id,
              meter.deviceId,
              selectedPeriodForChart
            );

            const aggregate = consumptionTimeframeToAggregate(timeframe[meter.deviceId]);

            results$[meter.deviceId] = savedConsumptionPeriod?.consumption?.length
              ? of(savedConsumptionPeriod.consumption)
              : this.consumptionFacade
                  .getSelectedPeriodConsumption(
                    selectedPeriodForRequest,
                    contract.id,
                    meter,
                    aggregate,
                    consumptionDataVersion
                  )
                  .pipe(
                    map(response => consumptionLegalFilter(response, contract)),
                    map(response => consumptionConverter.fromDto(response))
                  );
          }

          return forkJoin(results$).pipe(
            map(responses =>
              LoadConsumptionForMetersSuccess({
                responses,
                contract,
                deviceIds,
              })
            ),
            catchError(error =>
              of(
                LoadConsumptionForMetersFailed({
                  error,
                  deviceIds,
                })
              )
            )
          );
        }
      )
    )
  );

  loadPastConsumptionForMeters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.LoadPastConsumptionForMeters),
      withLatestFrom(
        this.store.select(fromConsumptionState.getSelectedPeriodsForRequests),
        this.store.select(fromConsumptionState.getSelectedPeriodsForCharts),
        this.store.select(fromMasterDataState.getSelectedContract),
        this.store.select(fromConsumptionState.getSelectedMeters),
        this.store.select(fromConsumptionState.getSavedConsumptionData),
        this.store.select(fromConsumptionState.getSelectedTimeframe),
        this.store.select(getResidentAppSettings)
      ),
      concatMap(
        ([
          { deviceIds },
          selectedPeriodsForRequests,
          selectedPeriodsForCharts,
          contract,
          selectedMeters,
          savedConsumptionData,
          timeframe,
          { consumptionDataVersion },
        ]) => {
          const meters = selectedMeters.filter(meter => deviceIds.includes(meter.deviceId));

          if (meters.length === 0) {
            return of(
              fromActions.LoadPastConsumptionForMetersFailed({
                deviceIds,
                error: {
                  name: 'No selected meters found for deviceIds',
                  message: 'No selected meters found for deviceIds',
                },
              })
            );
          }

          const results$: ByDeviceId<Observable<IConsumptionItem[]>> = {};

          for (const meter of meters) {
            const selectedPeriodForRequest = selectedPeriodsForRequests[meter.deviceId];
            const selectedPeriodForChart = selectedPeriodsForCharts[meter.deviceId];

            const pastConsumptionPeriod: IPeriod = {
              start: subYears(selectedPeriodForRequest.start, 1).getTime(),
              end: subYears(selectedPeriodForRequest.end, 1).getTime(),
            };

            if (
              pastConsumptionPeriod.end < contract.contractStartTime ||
              pastConsumptionPeriod.end < meter.firstEntry
            ) {
              const error = `Selected period not available for ${meter.deviceId}`;

              return of(
                fromActions.LoadPastConsumptionForMetersFailed({
                  error: {
                    name: error,
                    message: error,
                  },
                  deviceIds,
                })
              );
            }
            if (
              pastConsumptionPeriod.start < contract.contractStartTime ||
              pastConsumptionPeriod.start < meter.firstEntry
            ) {
              pastConsumptionPeriod.start = Math.max(contract.contractStartTime, meter.firstEntry);
            }
            const savedConsumptionPeriod = getSavedConsumption(
              savedConsumptionData,
              contract.id,
              meter.deviceId,
              selectedPeriodForChart
            );

            const aggregate = consumptionTimeframeToAggregate(timeframe[meter.deviceId]);

            results$[meter.deviceId] = savedConsumptionPeriod?.pastConsumption?.length
              ? of(savedConsumptionPeriod.pastConsumption)
              : this.consumptionFacade
                  .getSelectedPeriodConsumption(
                    pastConsumptionPeriod,
                    contract.id,
                    meter,
                    aggregate,
                    consumptionDataVersion
                  )
                  .pipe(
                    map(response => consumptionLegalFilter(response, contract)),
                    map(response => consumptionConverter.fromDto(response))
                  );
          }

          return forkJoin(results$).pipe(
            map(responses =>
              LoadPastConsumptionForMetersSuccess({
                responses,
                contract,
                deviceIds,
              })
            ),
            catchError(error =>
              of(
                LoadPastConsumptionForMetersFailed({
                  error,
                  deviceIds,
                })
              )
            )
          );
        }
      )
    );
  });

  loadBenchmarkConsumptionForMeters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.LoadConsumptionBenchmarkForMeters),
      withLatestFrom(
        this.store.select(fromConsumptionState.getSelectedPeriodsForRequests),
        this.store.select(fromConsumptionState.getSelectedPeriodsForCharts),
        this.store.select(fromMasterDataState.getSelectedContract),
        this.store.select(fromConsumptionState.getSelectedMeters),
        this.store.select(fromConsumptionState.getSavedConsumptionData),
        this.store.select(fromConsumptionState.getSelectedTimeframe),
        this.store.select(getResidentAppSettings)
      ),
      concatMap(
        ([
          { deviceIds },
          selectedPeriodsForRequests,
          selectedPeriodsForCharts,
          contract,
          selectedMeters,
          savedConsumptionData,
          timeframe,
          { consumptionDataVersion },
        ]) => {
          const meters = selectedMeters.filter(meter => deviceIds.includes(meter.deviceId));
          if (meters.length === 0) {
            return of(
              fromActions.LoadConsumptionBenchmarkForMetersFailed({
                deviceIds,
                error: {
                  name: 'No selected meters found for deviceIds',
                  message: 'No selected meters found for deviceIds',
                },
              })
            );
          }

          const results$: ByDeviceId<Observable<IConsumptionBenchmarkItemResponse[]>> = {};

          for (const meter of meters) {
            const selectedPeriodForRequest = selectedPeriodsForRequests[meter.deviceId];
            const selectedPeriodForChart = selectedPeriodsForCharts[meter.deviceId];

            if (!isSelectedPeriodAllowedAndAvailable(contract, selectedPeriodForRequest, meter)) {
              const error = `Selected period not available for ${meter.deviceId}`;

              return of(
                fromActions.LoadConsumptionBenchmarkForMetersFailed({
                  deviceIds,
                  error: {
                    name: error,
                    message: error,
                  },
                })
              );
            }

            const savedConsumptionPeriod = getSavedConsumption(
              savedConsumptionData,
              contract.id,
              meter.deviceId,
              selectedPeriodForChart
            );

            const aggregate = consumptionTimeframeToAggregate(timeframe[meter.deviceId]);

            results$[meter.deviceId] = savedConsumptionPeriod?.benchmark?.length
              ? of(savedConsumptionPeriod.benchmark)
              : this.consumptionFacade.getSelectedPeriodConsumptionBenchmark(
                  selectedPeriodForRequest,
                  contract.id,
                  meter,
                  aggregate,
                  consumptionDataVersion
                );
          }

          return forkJoin(results$).pipe(
            map(responses =>
              LoadConsumptionBenchmarkForMetersSuccess({
                responses,
                contract,
                deviceIds,
              })
            ),
            catchError(error =>
              of(
                LoadConsumptionBenchmarkForMetersFailed({
                  error,
                  deviceIds,
                })
              )
            )
          );
        }
      )
    );
  });

  // delay 100 is added for cases where the graph will switch from ScatterSeries to AreaSeries
  loadConsumptionOnSelectPreviousPeriod = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectPreviousPeriod),
      delay(100),
      switchMap(({ deviceId }) =>
        of(fromActions.LoadConsumptionForMeters({ deviceIds: [deviceId] }))
      )
    );
  });

  loadPastConsumptionOnSelectPreviousPeriod = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectPreviousPeriod),
      switchMap(({ deviceId }) =>
        of(fromActions.LoadPastConsumptionForMeters({ deviceIds: [deviceId] }))
      )
    );
  });

  loadConsumptionBenchmarkOnSelectPreviousPeriod = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectPreviousPeriod),
      switchMap(({ deviceId }) =>
        of(fromActions.LoadConsumptionBenchmarkForMeters({ deviceIds: [deviceId] }))
      )
    );
  });

  // delay 100 is added for cases where the graph will switch from ScatterSeries to AreaSeries
  loadConsumptionOnSelectNextPeriod = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectNextPeriod),
      delay(100),
      switchMap(({ deviceId }) =>
        of(fromActions.LoadConsumptionForMeters({ deviceIds: [deviceId] }))
      )
    );
  });

  loadPastConsumptionOnSelectNextPeriod = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectNextPeriod),
      switchMap(({ deviceId }) =>
        of(fromActions.LoadPastConsumptionForMeters({ deviceIds: [deviceId] }))
      )
    );
  });

  loadConsumptionBenchmarkOnSelectNextPeriod = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectNextPeriod),
      switchMap(({ deviceId }) =>
        of(fromActions.LoadConsumptionBenchmarkForMeters({ deviceIds: [deviceId] }))
      )
    );
  });

  // delay 100 is added for cases where the graph will switch from ScatterSeries to AreaSeries
  loadConsumptionOnSelectPeriod = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectPeriod),
      delay(100),
      switchMap(({ deviceId }) =>
        of(fromActions.LoadConsumptionForMeters({ deviceIds: [deviceId] }))
      )
    );
  });

  loadPastConsumptionOnSelectPeriod = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectPeriod),
      switchMap(({ deviceId }) =>
        of(fromActions.LoadPastConsumptionForMeters({ deviceIds: [deviceId] }))
      )
    );
  });

  loadConsumptionBenchmarkOnSelectPeriod = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectPeriod),
      switchMap(({ deviceId }) =>
        of(fromActions.LoadConsumptionBenchmarkForMeters({ deviceIds: [deviceId] }))
      )
    );
  });

  /***
   * on SelectMeters action check if updateInterval exist for the meter
   * and dispatch LoadCurrentMonthConsumption if yes
   */
  loadCurrentMonthConsumptionOnSelectMeters = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectMeters),
      switchMap(({ meters }) => {
        const [firstMeter] = meters;
        if (firstMeter?.updateInterval) {
          return of(fromConsumptionState.LoadCurrentMonthConsumption());
        } else {
          return EMPTY;
        }
      })
    );
  });

  loadCurrentMonthConsumption$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.LoadCurrentMonthConsumption),
      withLatestFrom(
        this.store.select(fromMasterDataState.getSelectedContract),
        this.store.select(fromConsumptionState.getFirstSelectedMeter),
        this.store.select(getResidentAppSettings)
      ),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_, contract, selectedMeter, { consumptionDataVersion }]) => {
        if (!selectedMeter) {
          return of(
            fromActions.LoadCurrentMonthConsumptionFailed({
              error: {
                name: 'Selected period not available',
                message: 'Selected period not available',
              },
            })
          );
        }
        const today = Date.now();
        const start = Math.max(startOfMonth(today).getTime(), selectedMeter.firstEntry); // firstEntry for meter can be after start of current month
        const end = endOfMonth(today).getTime();
        const selectedPeriod = { start, end };
        if (!isSelectedPeriodAllowedAndAvailable(contract, selectedPeriod, selectedMeter)) {
          return of(
            fromActions.LoadCurrentMonthConsumptionFailed({
              error: {
                name: 'Selected period not available',
                message: 'Selected period not available',
              },
            })
          );
        }

        return this.consumptionFacade
          .getSelectedPeriodConsumption(
            selectedPeriod,
            contract.id,
            selectedMeter,
            ConsumptionAggregateType.MONTH,
            consumptionDataVersion
          )
          .pipe(
            map(response => consumptionLegalFilter(response, contract)),
            map(response => consumptionConverter.fromDto(response)),
            map(response =>
              fromActions.LoadCurrentMonthConsumptionSuccess({
                response,
                contract,
                selectedPeriod,
                selectedMeter,
              })
            ),
            catchError((error: Error) => [fromActions.LoadCurrentMonthConsumptionFailed({ error })])
          );
      })
    );
  });

  /**
   * get data on timeframe change
   * find selected meter and period and resolution and get data from BE
   */
  setSelectedTimeframe = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SetSelectedTimeframe),
      switchMap(({ timeframe, deviceId }) => {
        return of(fromConsumptionState.LoadConsumptionForMeters({ deviceIds: [deviceId] }));
      })
    );
  });
}
