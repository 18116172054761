import { IResidentAnnouncementContent, IPublishedAnnouncement } from '../../../models';

export const announcementConverter = {
  fromDto: (response: IResidentAnnouncementContent): IPublishedAnnouncement => {
    return {
      id: response.id,
      title: response.title,
      text: response.description,
      publishedAt: response.publishedAt,
      publishedUntil: response.publishedUntil,
      createdAt: response.createdAt,
      attachments: response.attachments,
    };
  },
};
