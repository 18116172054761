import { createAction, props } from '@ngrx/store';
import {
  CategoryId,
  IContactPersonResponse,
  IHQTicketDetailsResponse,
  IMetadataTicketDetailsResponse,
  IRootQuestionResponseTicketShortInput,
  IRootQuestionTicketOverview,
  ISort,
  ITicketConversationEdit,
  ITicketCreationSummary,
  ITicketEdit,
  ITicketEditResponse,
  ITicketMessageGroup,
  ITicketMetadataCreate,
  ITicketResidentCategory,
  ITicketResidentOverviewResponse,
  TicketIssueType,
  TicketSortField,
  TicketStatus,
} from '../../models';

export const LoadDamageTickets = createAction(
  '[News] Load Damage Tickets',
  props<{
    issueType: TicketIssueType;
    ticketStatus: TicketStatus;
    offset: number;
    limit: number;
    sort?: ISort<TicketSortField>;
  }>()
);

export const LoadDamageTicketsSuccess = createAction(
  '[News] Load Damage Tickets Success',
  props<{ response: ITicketResidentOverviewResponse }>()
);

export const LoadDamageTicketsFailed = createAction(
  '[News] Load Damage Tickets Failed',
  props<{ error: Error }>()
);

export const LoadRequestTickets = createAction(
  '[News] Load Request Tickets',
  props<{
    issueType: TicketIssueType;
    ticketStatus: TicketStatus;
    offset: number;
    limit: number;
    sort?: ISort<TicketSortField>;
  }>()
);

export const LoadRequestTicketsSuccess = createAction(
  '[News] Load Request Tickets Success',
  props<{ response: ITicketResidentOverviewResponse }>()
);

export const LoadRequestTicketsFailed = createAction(
  '[News] Load Request Tickets Failed',
  props<{ error: Error }>()
);

export const LoadDamageCategoryQuestions = createAction('[Tickets] Load Damage Category Questions');
export const LoadDamageCategoryQuestionsSuccess = createAction(
  '[Tickets] Load Damage Category Questions Success',
  props<{ damageCategories: IRootQuestionTicketOverview }>()
);
export const LoadDamageCategoryQuestionsFailed = createAction(
  '[Tickets] Load Damage Category Questions Failed',
  props<{ error: Error }>()
);

export const LoadConcernCategoryQuestions = createAction(
  '[Tickets] Load Concern Category Questions'
);
export const LoadConcernCategoryQuestionsSuccess = createAction(
  '[Tickets] Load Concern Category Questions Success',
  props<{ concernCategories: IRootQuestionTicketOverview }>()
);
export const LoadConcernCategoryQuestionsFailed = createAction(
  '[Tickets] Load Concern Category Questions Failed',
  props<{ error: Error }>()
);

export const LoadDamageQuestions = createAction(
  '[Tickets] Load Damage Questions',
  props<{ damageCategoryQuestions: IRootQuestionResponseTicketShortInput }>()
);
export const LoadDamageQuestionsSuccess = createAction(
  '[Tickets] Load Damage Questions Success',
  props<{ damageQuestions: ITicketResidentCategory }>()
);
export const LoadDamageQuestionsFailed = createAction(
  '[Resident Tickets] Load Damage Questions Failed',
  props<{ error: Error }>()
);

export const LoadConcernQuestions = createAction(
  '[Resident Tickets] Load Concern Questions',
  props<{ concernCategoryQuestions: IRootQuestionResponseTicketShortInput }>()
);
export const LoadConcernQuestionsSuccess = createAction(
  '[Resident Tickets] Load Concern Questions Success',
  props<{ concernQuestions: ITicketResidentCategory }>()
);
export const LoadConcernQuestionsFailed = createAction(
  '[Resident Tickets] Load Concern Questions Failed',
  props<{ error: Error }>()
);

export const LoadActiveDamageTickets = createAction(
  '[Resident Tickets] Load Active Damage Tickets',
  props<{ offset: number; limit: number; sort?: ISort<TicketSortField> }>()
);

export const LoadActiveDamageTicketsSuccess = createAction(
  '[Resident Tickets] Load Active Damage Tickets Success',
  props<{ response: ITicketResidentOverviewResponse[]; sort?: ISort<TicketSortField> }>()
);

export const LoadActiveDamageTicketsFailed = createAction(
  '[Resident Tickets] Load Active Damage Tickets Failed',
  props<{ error: Error }>()
);
export const LoadActiveDamageTicketsForSelectedContract = createAction(
  '[Resident Tickets] Load Active Damage For Selected Contract Tickets',
  props<{ offset: number; limit: number; sort?: ISort<TicketSortField> }>()
);

export const LoadActiveDamageTicketsForSelectedContractSuccess = createAction(
  '[Resident Tickets] Load Active Damage Tickets For Selected Contract Success',
  props<{ response: ITicketResidentOverviewResponse[]; sort?: ISort<TicketSortField> }>()
);

export const LoadActiveDamageTicketsForSelectedContractFailed = createAction(
  '[Resident Tickets] Load Active Damage Tickets For Selected Contract Failed',
  props<{ error: Error }>()
);

export const LoadActiveRequestTickets = createAction(
  '[Resident Tickets] Load Active Request Tickets',
  props<{ offset: number; limit: number; sort?: ISort<TicketSortField> }>()
);

export const LoadActiveRequestTicketsSuccess = createAction(
  '[Resident Tickets] Load Active Request Tickets Success',
  props<{ response: ITicketResidentOverviewResponse[]; sort?: ISort<TicketSortField> }>()
);

export const LoadActiveRequestTicketsFailed = createAction(
  '[Resident Tickets] Load Active Request Tickets Failed',
  props<{ error: Error }>()
);

export const LoadActiveRequestTicketsForSelectedContract = createAction(
  '[Resident Tickets] Load Active Reques For Selected Contract Tickets',
  props<{ offset: number; limit: number; sort?: ISort<TicketSortField> }>()
);

export const LoadActiveRequestTicketsForSelectedContractSuccess = createAction(
  '[Resident Tickets] Load Active Request Tickets For Selected Contract Success',
  props<{ response: ITicketResidentOverviewResponse[]; sort?: ISort<TicketSortField> }>()
);

export const LoadActiveRequestTicketsForSelectedContractFailed = createAction(
  '[Resident Tickets] Load Active Request Tickets For Selected Contract Failed',
  props<{ error: Error }>()
);

// Ticket details
export const LoadTicketDetails = createAction(
  '[Resident Tickets] Load Ticket Details',
  props<{ ticketId: string; ticketIssueType: TicketIssueType }>()
);

export const LoadTicketDetailsSuccess = createAction(
  '[Resident Tickets] Load Ticket Details Success',
  props<{ response: IHQTicketDetailsResponse }>()
);

export const LoadTicketDetailsFailed = createAction(
  '[Resident Tickets] Load Ticket Details Failed',
  props<{ error: Error }>()
);

// Archive tickets - damages
export const LoadArchiveDamageTickets = createAction(
  '[Resident Tickets] Load Archive Damage Tickets',
  props<{ offset: number; limit: number; sort?: ISort<TicketSortField> }>()
);

export const LoadArchiveDamageTicketsSuccess = createAction(
  '[Resident Tickets] Load Archive Damage Tickets Success',
  props<{ response: ITicketResidentOverviewResponse[]; sort?: ISort<TicketSortField> }>()
);

export const LoadArchiveDamageTicketsFailed = createAction(
  '[Resident Tickets] Load Archive Damage Tickets Failed',
  props<{ error: Error }>()
);
export const LoadArchiveDamageTicketsForSelectedContract = createAction(
  '[Resident Tickets] Load Archive Damage Tickets For Selected Contract',
  props<{ offset: number; limit: number; sort?: ISort<TicketSortField> }>()
);

export const LoadArchiveDamageTicketsForSelectedContractSuccess = createAction(
  '[Resident Tickets] Load Archive Damage Tickets For Selected Contract Success',
  props<{ response: ITicketResidentOverviewResponse[]; sort?: ISort<TicketSortField> }>()
);

export const LoadArchiveDamageTicketsForSelectedContractFailed = createAction(
  '[Resident Tickets] Load Archive Damage Tickets For Selected Contract Failed',
  props<{ error: Error }>()
);

// Archive tickets - requests/concerns
export const LoadArchiveRequestTickets = createAction(
  '[Resident Tickets] Load Archive Request Tickets',
  props<{ offset: number; limit: number; sort?: ISort<TicketSortField> }>()
);

export const LoadArchiveRequestTicketsSuccess = createAction(
  '[Resident Tickets] Load Archive Request Tickets Success',
  props<{ response: ITicketResidentOverviewResponse[]; sort?: ISort<TicketSortField> }>()
);

export const LoadArchiveRequestTicketsFailed = createAction(
  '[Resident Tickets] Load Archive Request Tickets Failed',
  props<{ error: Error }>()
);

// Archive tickets - requests/concerns
export const LoadArchiveRequestTicketsForSelectedContract = createAction(
  '[Resident Tickets] Load Archive Request Tickets For Selected Contract',
  props<{ offset: number; limit: number; sort?: ISort<TicketSortField> }>()
);

export const LoadArchiveRequestTicketsForSelectedContractSuccess = createAction(
  '[Resident Tickets] Load Archive Request Tickets For Selected Contract Success',
  props<{ response: ITicketResidentOverviewResponse[]; sort?: ISort<TicketSortField> }>()
);

export const LoadArchiveRequestTicketsForSelectedContractFailed = createAction(
  '[Resident Tickets] Load Archive Request Tickets For Selected Contract Failed',
  props<{ error: Error }>()
);

export const CreateTicketSummary = createAction(
  '[Resident Tickets] Create Ticket Summary',
  props<{ ticketSummary: ITicketCreationSummary }>()
);

export const CancelTicketCreation = createAction('[Resident Tickets] Cancel Ticket Creation');

export const CreateTicketHQ = createAction(
  '[Resident Tickets] Create Ticket HQ',
  props<{ newTicketHQ: ITicketEdit }>()
);

export const CreateTicketHQSuccess = createAction(
  '[Resident Tickets] Create Ticket HQ Success',
  props<{ createTicketResponse: ITicketEditResponse }>()
);

export const CreateTicketHQFailed = createAction(
  '[Resident Tickets] Create Ticket HQ Failed',
  props<{ error: Error }>()
);

export const ClearTicketCreation = createAction('[Resident Tickets] Clear Ticket Creation');

export const CancelTicket = createAction(
  '[Resident Tickets] Cancel Ticket',
  props<{ ticketId: string; ticketIssueType: TicketIssueType }>()
);

export const CancelTicketSuccess = createAction(
  '[Resident Tickets] Cancel Ticket Success',
  props<{ cancelTicketResponse: boolean; ticketId: string; ticketIssueType: TicketIssueType }>()
);

export const CancelTicketFailed = createAction(
  '[Resident Tickets] Cancel Ticket Failed',
  props<{ error: Error }>()
);

// Ticket chat
export const LoadTicketChat = createAction(
  '[Resident Tickets] Load Ticket Chat',
  props<{ ticketId: string }>()
);

export const LoadTicketChatSuccess = createAction(
  '[Resident Tickets] Load Ticket Chat Success',
  props<{ ticketMessageGroups: ITicketMessageGroup[] }>()
);

export const LoadTicketChatFailed = createAction(
  '[Resident Tickets] Load Ticket Chat Failed',
  props<{ error: Error }>()
);

export const AddMessageToTicket = createAction(
  '[Resident Tickets] Add Message To Ticket',
  props<{ message: ITicketConversationEdit }>()
);

export const AddMessageToTicketSuccess = createAction(
  '[Resident Tickets] Add Message To Ticket Success',
  props<{ ticketMessageGroups: ITicketMessageGroup[] }>()
);

export const AddMessageToTicketFailed = createAction(
  '[Resident Tickets] Add Message To Ticket Failed',
  props<{ error: Error }>()
);

export const LoadMetadataTicketDetails = createAction(
  '[Resident Tickets] Load Metadata Ticket Details',
  props<{ ticketId: string; ticketIssueType: TicketIssueType }>()
);

export const LoadMetadataTicketDetailsSuccess = createAction(
  '[Resident Tickets] Load Metadata Ticket Details Success',
  props<{ response: IMetadataTicketDetailsResponse }>()
);

export const LoadMetadataTicketDetailsFailed = createAction(
  '[Resident Tickets] Load Metadata Ticket Details Failed',
  props<{ error: Error }>()
);

export const CreateTicketMetadata = createAction(
  '[Resident Tickets] Create Ticket Metadata',
  props<{ newTicketMetadata: ITicketMetadataCreate }>()
);

export const CreateTicketMetadataSuccess = createAction(
  '[Resident Tickets] Create Ticket Metadata Success',
  props<{ createTicketResponse: ITicketEditResponse }>()
);

export const CreateTicketMetadataFailed = createAction(
  '[Resident Tickets] Create Ticket Metadata Failed',
  props<{ error: Error }>()
);

export const LoadDirectAssignmentContacts = createAction(
  '[Resident Tickets] Load Direct Assignment Contacts',
  props<{ category: CategoryId }>()
);
export const LoadDirectAssignmentContactsSuccess = createAction(
  '[Resident Tickets] Load Direct Assignment Contacts Success',
  props<{ directAssignmentContacts: IContactPersonResponse[] }>()
);
export const LoadDirectAssignmentContactsError = createAction(
  '[Resident Tickets] Load Direct Assignment Contacts Error',
  props<{ error: Error }>()
);
