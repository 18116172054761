import { gql } from 'apollo-angular';

export const notificationSettingsQuery = gql`
  query getNotificationSettings {
    getPushNotificationSettings {
      allowedNotifications
    }
  }
`;

export const notificationSettingsMutation = gql`
  mutation UpdatePushNotificationSettings($input: PushNotificationSettingsEditBean!) {
    updatePushNotificationSettings(input: $input) {
      allowedNotifications
    }
  }
`;
