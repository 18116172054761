import { Directive } from '@angular/core';

import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, map, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  ImmomioIconName,
  SettingsNotificationEmailType,
  INotificationEmailSettings,
  NotificationEmailSettings,
  ANNOUNCEMENT_SETTINGS,
  DOCUMENT_SETTINGS,
  PAYMENT_SETTINGS,
  TICKET_SETTINGS,
  SETTING_NOTIFICATIONS,
} from '../../models';
import {
  getPermissions,
  ChangeEmailSettings,
  ChangeNotificationSettings,
  getNotificationSettings,
  getEmailSettings,
} from '../../+state';

@UntilDestroy()
@Directive()
export class EmailNotificationSettingsPageBaseComponent {
  public settings$ = this.getSetting();

  public immomioIconName = ImmomioIconName;
  public announcementSettings = ANNOUNCEMENT_SETTINGS;
  public documentSettings = DOCUMENT_SETTINGS;
  public ticketSettings = TICKET_SETTINGS;
  public paymentSettings = PAYMENT_SETTINGS;

  public $showPayments: Observable<boolean> = this.store.select(getPermissions).pipe(
    map(permissions => permissions?.payment),
    distinctUntilChanged(),
    filter(permission => permission !== null),
    untilDestroyed(this)
  );

  constructor(
    protected settingType: string,
    protected store: Store
  ) {}

  getSetting() {
    return this.store
      .select(
        this.settingType == SETTING_NOTIFICATIONS ? getNotificationSettings : getEmailSettings
      )
      .pipe(map((settings: INotificationEmailSettings) => settings?.allowedNotifications));
  }

  toggleSetting(
    settingTypes: SettingsNotificationEmailType[],
    settings: SettingsNotificationEmailType[]
  ) {
    const payload: INotificationEmailSettings = new NotificationEmailSettings();

    payload.allowedNotifications = settings.slice();

    settingTypes.forEach(type => {
      const index = payload.allowedNotifications.indexOf(type);
      index > -1
        ? payload.allowedNotifications.splice(index, 1)
        : payload.allowedNotifications.push(type);
    });

    this.settingType == SETTING_NOTIFICATIONS
      ? this.store.dispatch(ChangeNotificationSettings({ payload }))
      : this.store.dispatch(ChangeEmailSettings({ payload }));
  }
}
