import { Inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { EMPTY, first, Observable, of, switchMap, tap } from 'rxjs';

import { IEnvironment } from '../../models';
import { IActionState } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class ResolverCommonService {
  constructor(
    private store: Store,
    @Inject('ENVIRONMENT') private environment: IEnvironment
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resolveHandler<T>(stateSelector, action, force?: boolean): Observable<IActionState>;
  resolveHandler<T>(
    stateSelector,
    action,
    dataSelector,
    resolverWaitForData: boolean,
    force?: boolean
  ): Observable<T>;
  resolveHandler<T>(
    stateSelector,
    action,
    dataSelector?,
    resolverWaitForData = false,
    force = false
  ) {
    return this.store.select(stateSelector).pipe(
      tap((state: IActionState) => {
        if (!state.pending) {
          if (!state.done || force) {
            return this.store.dispatch(action);
          }
          if (state.doneTime && this.environment.cacheDuration) {
            const { cacheDuration } = this.environment;
            const expTime = Date.now() - state.doneTime;
            if (expTime >= cacheDuration) {
              return this.store.dispatch(action);
            }
          }
        }
      }),
      switchMap(state => {
        if (dataSelector && typeof dataSelector !== 'boolean') {
          if (state.error) {
            return of(null);
          }
          if (state.done || !resolverWaitForData) {
            return this.store.select(dataSelector) as Observable<T>;
          }
          return EMPTY;
        } else {
          return of(state);
        }
      }),
      first()
    );
  }
}
