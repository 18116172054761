import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { catchError, map, Observable } from 'rxjs';
import {
  CategoryId,
  ContractId,
  IConversationEditResponse,
  IDirectAssignmentContacts,
  IHQTicketDetailsResponse,
  IMetadataTicketDetailsResponse,
  IRootQuestionResponseTicketShortInput,
  IRootQuestionTicketOverview,
  ISort,
  ITicketConversation,
  ITicketConversationEdit,
  ITicketEdit,
  ITicketEditResponse,
  ITicketMetadataCreate,
  ITicketResidentCategory,
  ITicketResidentOverviewResponse,
  TicketIssueType,
  TicketSortField,
  TicketStatus,
} from '../../models';
import {
  addMessageToTicketMutation,
  cancelTicketQuery,
  createTicketHQMutation,
  createTicketMetadataMutation,
  getCategoryQuery,
  getDirectAssignmentsQuery,
  getTicketCategoryQuestionsQuery,
  getTicketQuestionsQuery,
  ticketChatQuery,
  ticketDetailHQQuery,
  ticketDetailMetadataQuery,
  ticketsOverviewQuery,
} from '../../queries';

@Injectable()
export class TicketsFacade {
  constructor(private apollo: Apollo) {}

  public getTicketsOverview(
    contractNumber: string,
    issueType: TicketIssueType,
    ticketStatus: TicketStatus,
    offset: number,
    limit: number,
    sort?: ISort<TicketSortField>
  ): Observable<ITicketResidentOverviewResponse> {
    return this.apollo
      .query<{ ticketsOverview: ITicketResidentOverviewResponse }>({
        query: ticketsOverviewQuery,
        variables: {
          input: {
            issueType,
            ticketStatus,
            contractNumber,
            ticketOverviewSortField: sort?.value,
            sortOrder: sort?.direction,
          },
          offset,
          limit,
        },
      })
      .pipe(map(response => response?.data?.ticketsOverview));
  }

  public getCategoryQuestions(
    issueType: TicketIssueType,
    contractNumber: string
  ): Observable<IRootQuestionTicketOverview> {
    return this.apollo
      .query<{ ticketCategoryQuestions: IRootQuestionTicketOverview }>({
        query: getTicketCategoryQuestionsQuery,
        variables: { issueType, contractNumber },
      })
      .pipe(
        map(response => response?.data?.ticketCategoryQuestions),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }

  public getTicketQuestions(
    request: IRootQuestionResponseTicketShortInput,
    issueType: TicketIssueType,
    contractNumber: string
  ): Observable<IRootQuestionTicketOverview> {
    return this.apollo
      .query<{ ticketQuestions: IRootQuestionTicketOverview }>({
        query: getTicketQuestionsQuery,
        variables: {
          categoryResponses: {
            rootQuestionId: request.rootQuestionId,
            responses: request.responses,
          },
          issueType,
          contractNumber,
        },
      })
      .pipe(
        map(response => {
          return response?.data?.ticketQuestions;
        }),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }

  public getDetailedQuestions(
    request: IRootQuestionResponseTicketShortInput,
    issueType: TicketIssueType,
    contractNumber: string
  ): Observable<ITicketResidentCategory> {
    return this.apollo
      .query<{ getCategory: ITicketResidentCategory }>({
        query: getCategoryQuery,
        variables: {
          categoryResponses: {
            rootQuestionId: request.rootQuestionId,
            responses: request.responses,
          },
          issueType,
          contractNumber,
        },
      })
      .pipe(
        map(response => {
          return response?.data?.getCategory;
        })
      );
  }

  public getTicketDetailsHQ(
    ticketId: string,
    ticketIssueType: TicketIssueType
  ): Observable<IHQTicketDetailsResponse> {
    return this.apollo
      .query<{ ticketDetail: IHQTicketDetailsResponse }>({
        query: ticketDetailHQQuery,
        variables: { ticketId, ticketIssueType },
      })
      .pipe(
        map(response => {
          if (response?.data?.ticketDetail) {
            return response?.data?.ticketDetail;
          } else {
            throw new Error(response.errors[0].message);
          }
        })
      );
  }

  public createTicketHQ(input: ITicketEdit): Observable<ITicketEditResponse> {
    return this.apollo
      .mutate<{ createTicket: ITicketEditResponse }>({
        mutation: createTicketHQMutation,
        variables: { input },
      })
      .pipe(
        map(response => {
          if (response?.data?.createTicket) {
            return response.data.createTicket;
          } else if (response?.errors) {
            throw new Error(response.errors[0].message);
          }
          throw new Error();
        })
      );
  }

  public cancelTicket(ticketId: string, issueType: TicketIssueType): Observable<boolean> {
    return this.apollo
      .mutate<{ cancelTicket: boolean }>({
        mutation: cancelTicketQuery,
        variables: { input: { ticketId }, issueType },
      })
      .pipe(
        map(response => {
          /**
           * TODO
           * BE always sends cancelTicket: null
           * change this when they fix that
           * https://immomio.atlassian.net/browse/ART-5575
           */
          if (
            response?.data &&
            Object.prototype.hasOwnProperty.call(response.data, 'cancelTicket')
          ) {
            return response.data.cancelTicket;
          } else if (response?.errors) {
            throw new Error(response.errors[0].message);
          }
          throw new Error();
        })
      );
  }

  public getTicketChat(ticketId: string): Observable<ITicketConversation> {
    return this.apollo
      .query<{ getTicketConversation: ITicketConversation }>({
        query: ticketChatQuery,
        variables: { ticketId },
      })
      .pipe(
        map(response => {
          if (response?.data?.getTicketConversation) {
            return response.data.getTicketConversation;
          } else {
            throw new Error(response.errors[0].message);
          }
        })
      );
  }

  public addMessageToTicket(input: ITicketConversationEdit): Observable<IConversationEditResponse> {
    return this.apollo
      .mutate<{ addMessageToTicketConversation: IConversationEditResponse }>({
        mutation: addMessageToTicketMutation,
        variables: {
          input,
        },
      })
      .pipe(
        map(result => {
          if (result?.data?.addMessageToTicketConversation) {
            return result.data.addMessageToTicketConversation;
          } else if (result?.errors) {
            throw new Error(result.errors[0].message);
          }
          throw new Error();
        })
      );
  }

  public getTicketDetailsMetadata(
    ticketId: string,
    ticketIssueType: TicketIssueType
  ): Observable<IMetadataTicketDetailsResponse> {
    return this.apollo
      .query<{ metadataTicketDetail: IMetadataTicketDetailsResponse }>({
        query: ticketDetailMetadataQuery,
        variables: { ticketId, ticketIssueType },
      })
      .pipe(
        map(response => {
          if (response?.data?.metadataTicketDetail) {
            return response?.data?.metadataTicketDetail;
          } else {
            throw new Error(response.errors[0].message);
          }
        })
      );
  }

  public createTicketMetadata(input: ITicketMetadataCreate): Observable<ITicketEditResponse> {
    return this.apollo
      .mutate<{ createMetadataTicket: ITicketEditResponse }>({
        mutation: createTicketMetadataMutation,
        variables: { input },
      })
      .pipe(
        map(response => {
          /**
           * TODO
           * BE always sends createMetadataTicket: null
           * change this when they fix that
           */
          if (
            response?.data &&
            Object.prototype.hasOwnProperty.call(response.data, 'createMetadataTicket')
          ) {
            return response.data.createMetadataTicket;
          } else if (response?.errors) {
            throw new Error(response.errors[0].message);
          }
          throw new Error();
        })
      );
  }

  public getDirectAssignments(
    categoryId: CategoryId,
    contractId: ContractId
  ): Observable<IDirectAssignmentContacts> {
    return this.apollo
      .query<{ getDirectAssignments: IDirectAssignmentContacts }>({
        query: getDirectAssignmentsQuery,
        variables: { contractId, categoryId },
      })
      .pipe(
        map(response => {
          if (response?.data?.getDirectAssignments) {
            return response.data.getDirectAssignments;
          } else if (response?.errors) {
            throw new Error(response.errors[0].message);
          }
          throw new Error();
        })
      );
  }
}
