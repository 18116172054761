import { inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { RouterService } from '@resident-nx/shared';

@Injectable({
  providedIn: 'root',
})
export class RouterWebService extends RouterService {
  private router = inject(Router);
  private location = inject(Location);

  navigate = this.router.navigate.bind(this.router);
  back = this.location.back.bind(this.location);

  navigateToExternalUrl(value: string) {
    location.href = value;
  }
}
