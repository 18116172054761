import { Pipe, PipeTransform } from '@angular/core';
import {
  IMetadataTicketDetailsResponse,
  IHQTicketDetailsResponse,
  TicketingVersion,
  TicketStatus,
} from '../models';

@Pipe({
  name: 'ticketStatus',
  standalone: true,
})
export class TicketStatusPipe implements PipeTransform {
  transform(
    details: IHQTicketDetailsResponse | IMetadataTicketDetailsResponse,
    version: TicketingVersion
  ): TicketStatus {
    if (!details) {
      return null;
    }
    if (version === TicketingVersion.HIERARCHICAL) {
      return (details as IHQTicketDetailsResponse).state;
    } else if (version === TicketingVersion.METADATA) {
      return (details as IMetadataTicketDetailsResponse).status;
    } else {
      return null;
    }
  }
}
