import { createSelector } from '@ngrx/store';
import { getAnnouncementsState } from '../../reducers';
import * as fromReducer from './announcements.reducer';

export const getAnnouncements = createSelector(getAnnouncementsState, fromReducer.getAnnouncements);
export const hasNextPageAnnouncements = createSelector(
  getAnnouncementsState,
  fromReducer.hasNextPage
);
export const getLatestAnnouncements = createSelector(
  getAnnouncementsState,
  fromReducer.getLatestAnnouncements
);
export const getAnnouncementsActionState = createSelector(
  getAnnouncementsState,
  fromReducer.getAnnouncementsActionState
);
export const getAnnouncement = createSelector(getAnnouncementsState, fromReducer.getAnnouncement);
export const getAnnouncementActionState = createSelector(
  getAnnouncementsState,
  fromReducer.getAnnouncementActionState
);
export const getAnnouncementPageInfo = createSelector(
  getAnnouncementsState,
  fromReducer.getAnnouncementPageInfo
);
