import { createReducer, on } from '@ngrx/store';

import { CustomerIdent, ISaasResidentLandlordCustomerResponse } from '../../../models';
import { ActionStateCreator, IActionState } from '../../../utils';
import * as fromActions from './user-apps.actions';

export interface IUserAppsState {
  userRegisteredSaasResidentAppsActionState: IActionState;
  userRegisteredSaasResidentApps: ISaasResidentLandlordCustomerResponse[];
  selectedCustomerIdent: CustomerIdent | null;
}

export const initialState: IUserAppsState = {
  userRegisteredSaasResidentAppsActionState: ActionStateCreator.create(),
  userRegisteredSaasResidentApps: [],
  selectedCustomerIdent: null,
};

export const userAppsReducer = createReducer(
  initialState,
  on(fromActions.GetUserRegisteredSaasResidentApps, state => {
    return {
      ...state,
      userRegisteredSaasResidentAppsActionState: ActionStateCreator.onStart(),
    };
  }),
  on(fromActions.GetUserRegisteredSaasResidentAppSuccess, (state, { registeredApps }) => {
    return {
      ...state,
      userRegisteredSaasResidentAppsActionState: ActionStateCreator.onSuccess(),
      userRegisteredSaasResidentApps: registeredApps,
    };
  }),
  on(fromActions.GetUserRegisteredSaasResidentAppsFailed, (state, { error }) => {
    return {
      ...state,
      userRegisteredSaasResidentAppsActionState: ActionStateCreator.onError(error),
    };
  }),

  on(fromActions.SetSelectedCustomerIdent, (state, { selectedCustomerIdent }) => {
    return {
      ...state,
      selectedCustomerIdent,
    };
  }),

  on(fromActions.LoadCustomerIdent, state => {
    return {
      ...state,
      userRegisteredSaasResidentAppsActionState: ActionStateCreator.onStart(),
    };
  }),

  on(fromActions.LoadCustomerIdentSuccess, state => {
    // removed selectedCustomerIdent instead it's set via loadCustomerIdentSuccess$ in the effects
    return {
      ...state,
      userRegisteredSaasResidentAppsActionState: ActionStateCreator.onSuccess(),
    };
  }),

  on(fromActions.LoadCustomerIdentFailed, (state, { error }) => {
    return {
      ...state,
      userRegisteredSaasResidentAppsActionState: ActionStateCreator.onError(error),
    };
  })
);

export const getRegisteredUserAppsActionState = (state: IUserAppsState) =>
  state.userRegisteredSaasResidentAppsActionState;

export const getRegisteredUserApps = (state: IUserAppsState) =>
  state.userRegisteredSaasResidentApps;
export const getSelectedCustomerIdent = (state: IUserAppsState) => state.selectedCustomerIdent;
