import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ICheckEmailExists,
  ICheckInvitationCode,
  ICheckNumbersResponse,
  IRegisterResidentResponse,
} from '../../../models';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as fromFeedbackActions from '../../general/feedback';

import {
  AccountRegistrationFacade,
  AccountUserFacade,
  ErrorHandlerService,
  RouterService,
} from '../../../services';
import * as fromActions from './registration.actions';

@Injectable()
export class RegistrationEffects {
  registerResident$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.RegisterResident),
      switchMap(({ registerResidentInput }) =>
        this.registrationFacade.registerUser(registerResidentInput).pipe(
          map((registerResidentResponse: IRegisterResidentResponse) =>
            fromActions.RegisterResidentSuccess({ registerResidentResponse })
          ),
          catchError(error => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on registration.effects.ts:registerResident$:',
              error
            );
            return [
              fromFeedbackActions.ShowFeedbackError({
                message: 'register.fail_message_l',
                title: 'registration_failed_l',
              }),
              fromActions.RegisterResidentFailed({ error }),
            ];
          })
        )
      )
    )
  );
  checkNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.VerifyContractAndPartnerNumber),
      switchMap(({ contractNumber, partnerNumber }) =>
        // eslint-disable-next-line
        this.registrationFacade.verifyContractAndPartnerNumber(contractNumber, partnerNumber).pipe(
          mergeMap((checkNumbersResponse: ICheckNumbersResponse) => [
            fromFeedbackActions.ShowFeedbackSuccess({
              message: 'check_contract_partner_numbers_success_l',
            }),
            fromActions.VerifyContractAndPartnerNumberSuccess({
              checkNumbersResponse,
            }),
          ]),
          catchError(error => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on registration.effects.ts:checkNumbers$:',
              error
            );
            return [
              fromFeedbackActions.ShowFeedbackError({
                message: 'check_contract_partner_numbers_failed_l',
              }),
              fromActions.VerifyContractAndPartnerNumberFailed({ error }),
            ];
          })
        )
      )
    )
  );
  triggerRegisterConfirmEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.TriggerRegisterConfirmationEmail),
      switchMap(({ contractNumber, partnerNumber }) =>
        // eslint-disable-next-line
        this.registrationFacade.triggerConfirmEmail(contractNumber, partnerNumber).pipe(
          mergeMap(() => [
            fromFeedbackActions.ShowFeedbackSuccess({
              message: 'registration.resend_confirm_email_success_m',
            }),
            fromActions.TriggerRegisterConfirmationEmailSuccess(),
          ]),
          catchError(error => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on registration.effects.ts:triggerRegisterConfirmEmail$:',
              error
            );
            return [
              fromFeedbackActions.ShowFeedbackError({
                message: 'registration.resend_confirm_email_error_m',
              }),
              fromActions.TriggerRegisterConfirmationEmailFailed({ error }),
            ];
          })
        )
      )
    )
  );
  verifyRegistrationEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.VerifyRegistrationEmail),
      switchMap(({ verifyEmailInput }) =>
        // eslint-disable-next-line
        this.registrationFacade.verifyEmail(verifyEmailInput).pipe(
          mergeMap(() => [
            fromFeedbackActions.ShowFeedbackSuccess({
              message: 'registration.confirmation_success_l',
            }),
            fromActions.VerifyRegistrationEmailSuccess(),
          ]),
          catchError(error => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on registration.effects.ts:verifyRegistrationEmail$:',
              error
            );
            return [
              fromFeedbackActions.ShowFeedbackError({
                message: 'registration.confirmation_error_l',
              }),
              fromActions.VerifyRegistrationEmailFailed({ error }),
            ];
          })
        )
      )
    )
  );
  initResetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.InitResetPassword),
      switchMap(({ email, web }) => {
        return this.userFacade.initResetPassword(email).pipe(
          tap(() => {
            if (web) {
              this.router.navigate(['logout'], { queryParams: { emailSent: true } });
            } else {
              this.router.navigate([this.endpoints.PASSWORD_RESET_EMAIL_SENT_PAGE]);
            }
          }),
          mergeMap(() => [
            fromActions.InitResetPasswordSuccess(),
            fromFeedbackActions.ShowFeedbackSuccess({
              message: 'authentication.init_reset_password_success_m',
            }),
          ]),
          catchError(error => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on registration.effects.ts:initResetPassword$:',
              error
            );
            return [
              fromActions.InitResetPasswordFailed({ error }),
              fromFeedbackActions.ShowFeedbackError({
                message: 'authentication.init_reset_password_error_m',
              }),
            ];
          })
        );
      })
    )
  );
  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.ResetPassword),
      switchMap(({ resetPasswordBeanPayload }) => {
        return this.userFacade.resetPassword(resetPasswordBeanPayload).pipe(
          tap(() => this.router.navigate([this.endpoints.PASSWORD_RESET_SUCCESS_PAGE])),
          mergeMap(() => [
            fromActions.ResetPasswordSuccess(),
            fromFeedbackActions.ShowFeedbackSuccess({
              message: 'user_profile.change_password_success_m',
            }),
          ]),
          catchError(error => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on registration.effects.ts:resetPassword$:',
              error
            );
            return [
              fromActions.ResetPasswordFailed({ error }),
              fromFeedbackActions.ShowFeedbackError({
                message: 'user_profile.change_password_error_m',
              }),
            ];
          })
        );
      })
    )
  );
  checkInvitationCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.CheckInvitationCode),
      switchMap(action =>
        this.registrationFacade.checkInvitationCode(action.invitationCode).pipe(
          map((response: ICheckInvitationCode) =>
            fromActions.CheckInvitationCodeSuccess({
              response,
            })
          ),
          catchError((error: Error) => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on registration.effects.ts:checkInvitationCode$:',
              error
            );
            return [fromActions.CheckInvitationCodeFailed({ error })];
          })
        )
      )
    )
  );
  checkEmailExists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.CheckEmailExists),
      switchMap(action =>
        this.registrationFacade.checkEmailExists(action.checkEmailExists).pipe(
          map((response: ICheckEmailExists) =>
            fromActions.CheckEmailExistsSuccess({
              response,
              email: action.checkEmailExists.email,
            })
          ),
          catchError((error: Error) => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on registration.effects.ts:checkEmailExists$:',
              error
            );
            return [fromActions.CheckEmailExistsFailed({ error })];
          })
        )
      )
    )
  );
  requestInvitationCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.RequestInvitationCode),
      switchMap(action =>
        this.registrationFacade.requestInvitationCode(action.requestInvitationCode).pipe(
          map((response: boolean) => {
            return fromActions.RequestInvitationCodeSuccess({ response });
          }),
          catchError((error: Error) => {
            this.errorHandlerService.handleError(
              '(REQUEST ERROR) on registration.effects.ts:requestInvitationCode$:',
              error
            );
            return [
              fromActions.RequestInvitationCodeFailed({ error }),
              fromFeedbackActions.ShowFeedbackError({
                title: 'error_upon_request',
                message: 'register.request_invitation_invalid_input',
              }),
            ];
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private registrationFacade: AccountRegistrationFacade,
    private userFacade: AccountUserFacade,
    private router: RouterService,
    @Inject('ENDPOINTS') private endpoints,
    private errorHandlerService: ErrorHandlerService
  ) {}
}
