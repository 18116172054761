import { Directive, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ConsentType, IEnvironment, ILegalInfoHref, ILegalTexts, LegalType } from '../../models';
import { RouterService } from '../../services';
import { IActionState } from '../../utils';
import * as fromRegistrationStore from '../../+state/account/registration';
import * as fromAccountUser from '../../+state/account/user-profile';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive()
export abstract class ConsentSetFormBaseComponent implements OnInit {
  @Input() fg: FormGroup;
  @Output() legalTextChecked = new EventEmitter<{ checked: boolean; type: ConsentType }>();
  @Output() documentChecked = new EventEmitter<{ checked: boolean; type: ConsentType }>();
  public legalType = LegalType;
  public hrefs: ILegalInfoHref;
  public legalTextsState$: Observable<IActionState>;
  public legalTexts: ILegalTexts;
  public customerName: string;

  constructor(
    public route: ActivatedRoute,
    public routerService: RouterService,
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private store: Store<fromAccountUser.IUserProfileState>
  ) {}

  ngOnInit() {
    if (this.environment.legalInfoHrefs) {
      this.hrefs = this.environment.legalInfoHrefs;
    }
    this.store.dispatch(fromAccountUser.LoadLegalTexts());
    this.store
      .select(fromAccountUser.getLegalTexts)
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        this.legalTexts = data;
      });
    this.store
      .select(fromRegistrationStore.getInvitationCodeResponse)
      .pipe(untilDestroyed(this))
      .subscribe(inviteCodeCheckResponse => {
        this.customerName = inviteCodeCheckResponse.customerName;
      });

    this.legalTextsState$ = this.store.select(fromAccountUser.getLegalTextsActionState);
  }

  public goToLegalDetail(legalType: LegalType) {
    void this.routerService.navigate(['../legal-detail', legalType], {
      relativeTo: this.route,
    });
  }
}
