import { Directive, Inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IEnvironment, ImmomioIconName } from '../../models';
import {
  LoadOverviewFaqs,
  getOverviewFaqs,
  getOverviewFaqsActionState,
} from '../../../shared/+state';

@Directive()
export class FaqPageBaseComponent implements OnInit {
  @Input() public showMoreLink = false;
  faqsActionState$ = this.store.select(getOverviewFaqsActionState);
  faqs$ = this.store.select(getOverviewFaqs);
  readMoreHref: string;
  public immomioIconName = ImmomioIconName;
  public isOpen = false;

  constructor(
    private store: Store,
    @Inject('ENVIRONMENT') env: IEnvironment
  ) {
    this.readMoreHref = env['faqReadMoreHref'];
  }

  ngOnInit(): void {
    this.store.dispatch(LoadOverviewFaqs());
  }

  public toggleAccordion() {
    this.isOpen = !this.isOpen;
  }

  public pullToRefresh(args): void {
    const pullRefresh = args.object;
    this.store.dispatch(LoadOverviewFaqs());
    pullRefresh.refreshing = false;
  }
}
