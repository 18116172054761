import { ChangeDetectionStrategy, Component, inject, input, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonTypeEnum, IContactPersonResponse, ImmomioIconName } from '@resident-nx/shared';
import { NgTemplateOutlet } from '@angular/common';
import { ContactDetailsWebComponent } from '../../contact-details/contact-details.component';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ImageWebComponent } from '../../../atoms/image/image.component';
import { IconWebComponent } from '../../../atoms/icon/icon.component';
import { ButtonWebComponent } from '../../../atoms/button/button.component';

@Component({
  selector: 'rs-web-ticket-direct-assignment',
  standalone: true,
  templateUrl: './ticket-direct-assignment.component.html',
  styleUrl: './ticket-direct-assignment.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslateModule,
    ImageWebComponent,
    IconWebComponent,
    NgTemplateOutlet,
    ButtonWebComponent,
    ContactDetailsWebComponent,
  ],
})
export class TicketDirectAssignmentComponent {
  public title = input.required<string>();
  public contacts = input.required<IContactPersonResponse[]>();
  public selectedContact = signal<IContactPersonResponse | undefined>(undefined);
  protected readonly ImmomioIconName = ImmomioIconName;
  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  #activeOffCanvas = inject(NgbActiveOffcanvas);

  public dismiss() {
    this.#activeOffCanvas.dismiss();
  }
}
