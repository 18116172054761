import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { IResidentS3File } from '../../models';

@Directive()
export abstract class AttachmentBaseComponent {
  @Input() attachment: IResidentS3File;
  @Input() className?: string;
  @Input() icon?: boolean = true;
  @Output() tap?: EventEmitter<boolean> = new EventEmitter();
}
