<div class="homepage container container--with-spacing">
  <div class="row">
    <!--TODO translation-->
    <span class="title-xxl col homepage__header">{{ 'news.offers_l' | translate }}</span>
  </div>
  <div class="row">
    @if (url$ | async) {
      <iframe
        #iframe
        [src]="url$ | async"
        [height]="iFrameHeight"
        width="100%"
        style="border: 0; overflow: hidden"
        (load)="onLoad()"
      ></iframe>
    }
    @if ((url$ | async) === undefined) {
      <div class="d-flex justify-content-center">
        <rs-web-loader></rs-web-loader>
      </div>
    }
  </div>
</div>
