import { Directive, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromMasterDataState from '../../+state/account/masterdata';
import * as fromConsumptionState from '../../+state/consumption';
import {
  ContractSelectionTypes,
  ContractStatus,
  IContract,
  IEnvironment,
  TicketIssueType,
} from '../../models';

@Directive()
export abstract class ContractMenuBaseComponent {
  @Input() title = 'contracts.select_contract_l';
  @Input() contractSelectionType = ContractSelectionTypes.TICKET;
  @Input() topSpacing = 0;
  @Input() disableExpiredContractSelection = false;
  @Output() contractSelected = new EventEmitter();
  @Output() showContractsEmitter = new EventEmitter<boolean>();

  public issueType: TicketIssueType | undefined;
  public contracts$ = this.store.select(fromMasterDataState.getContracts);
  public contractsActionState$ = this.store.select(fromMasterDataState.getContractsActionState);
  public selectedContract$ = this.store.select(fromMasterDataState.getSelectedContract);
  public contractStatus = ContractStatus;

  constructor(
    @Inject('ENVIRONMENT') public environment: IEnvironment,
    public store: Store<fromMasterDataState.IMasterDataState>,
    public consumptionStore: Store<fromConsumptionState.IConsumptionState>,
    public route: ActivatedRoute
  ) {}

  public selectContract(contract: IContract): void {
    this.store.dispatch(
      fromMasterDataState.SelectContract({
        id: contract.id,
      })
    );
    this.consumptionStore.dispatch(fromConsumptionState.LoadMetersByContract());
    this.showContractsEmitter.emit(false);
  }
}
