import { createAction, props } from '@ngrx/store';

import { IAppSettings } from '../../../models';

export const LoadAppSettings = createAction('[AppSettings] Load Theme and Logo');

export const LoadAppSettingsSuccess = createAction(
  '[AppSettings] Load Theme and Logo Success',
  props<{ appSettings: IAppSettings }>()
);

export const LoadAppSettingsFailed = createAction(
  '[AppSettings] Load Theme and Logo Failed',
  props<{ error: Error }>()
);
