import {
  IContract,
  IContractResponse,
  IMasterDataResponse,
  IMasterDataUser,
  IMasterDataUserDto,
  ISepaMandate,
} from '../../../models';
import { obfuscateString } from '../../../utils';

export const masterdataConverter = {
  fromUserDto: (user: IMasterDataUserDto): IMasterDataUser => {
    const initials =
      user?.firstName?.charAt(0).toUpperCase() + user?.lastName?.charAt(0).toUpperCase();
    return {
      ...user,
      id: user.id || '',
      firstName: user.firstName || '',
      lastContractEnd: user.lastContractEnd || '',
      initials: initials.length === 2 ? initials : '',
    };
  },

  fromContractsDto: (response: IContractResponse): IContract[] => {
    function obfuscateIban(sepaMandate: ISepaMandate): ISepaMandate {
      return {
        ...sepaMandate,
        iban: sepaMandate.iban ? obfuscateString(sepaMandate.iban) : null,
      };
    }

    return response?.contracts.map(contract => {
      return {
        ...contract,
        sepaMandate: contract?.sepaMandate ? obfuscateIban(contract.sepaMandate) : null,
      };
    });
  },

  fromDto: (response: IMasterDataResponse): IMasterDataUser => {
    return response && masterdataConverter.fromUserDto(response.residentUser);
  },
};
