import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { Store } from '@ngrx/store';
import { EMPTY, of, switchMap } from 'rxjs';

import { getSingleNews, getSingleNewsActionState, LoadSingleNews } from '../../+state';
import { IPublishedNews } from '../../models';
import { ResolverCommonService } from './resolver-common.service';

export const CurrentNewsSingleResolver: ResolveFn<IPublishedNews> = route => {
  const common = inject(ResolverCommonService);
  const store = inject(Store);

  const { resolverWaitForData } = route.data;
  const id = route.params?.id;
  if (!id) {
    return EMPTY;
  }
  return store.select(getSingleNews).pipe(
    switchMap(news => {
      if (news && news.id === id) {
        return of(news);
      } else {
        return common.resolveHandler<IPublishedNews>(
          getSingleNewsActionState,
          LoadSingleNews({ newsId: id }),
          getSingleNews,
          resolverWaitForData,
          true
        );
      }
    })
  );
};
