import { Directive, Inject, OnInit } from '@angular/core';
import { IEnvironment } from '../../models';

@Directive()
export abstract class AppBuildInfoBaseComponent implements OnInit {
  public meta: string;

  constructor(@Inject('ENVIRONMENT') private environment: IEnvironment) {}

  ngOnInit() {
    this.meta =
      this.environment.envName === 'production'
        ? `App Version: ${this.environment.appVersion} \nID: ${this.environment.commitHash}`
        : `App Version: ${this.environment.appVersion} \nID: ${this.environment.commitHash} \nBuild: ${this.environment.buildNumber} ${this.getEnvironment()}`;
  }

  private getEnvironment() {
    return this.environment.hasModifiedEnvironment
      ? '\nEnvironment: Live'
      : '\nEnvironment: ' + this.environment.envName;
  }
}
