import { ImmomioIconName } from '@resident-nx/shared';

export enum NavigationItemStateEnum {
  WARNING = 'warning',
}

export type NavigationItem = {
  name: string;
  label: string;
  link?: string;
  icon?: ImmomioIconName;
  hide?: boolean;
  redirectLink?: string;
  withPermission?: string;
  notificationCount?: number;
  state?: NavigationItemStateEnum;
  children?: NavigationItem[];
};
