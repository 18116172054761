import { Directive, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  getMetersByContractActionState,
  getSelectedContract,
  IMasterDataState,
  getContractsCount,
} from '../../+state';
import * as fromConsumptionState from '../../+state/consumption';
import { IContract } from '../../models';
import { IActionState } from '../../utils';

@Directive()
export class ConsumptionPageBaseComponent {
  public metersByContractActionState$: Observable<IActionState>;
  public selectedContract$: Observable<IContract>;
  public contractsCount$: Observable<number>;
  private store: Store<fromConsumptionState.IConsumptionState>;
  private masterDataStore: Store<IMasterDataState>;

  constructor(protected injector: Injector) {
    this.store = injector.get(Store<fromConsumptionState.IConsumptionState>);
    this.masterDataStore = injector.get(Store<IMasterDataState>);
    this.selectedContract$ = this.masterDataStore.select(getSelectedContract);
    this.contractsCount$ = this.masterDataStore.select(getContractsCount);
    this.metersByContractActionState$ = this.store.select(getMetersByContractActionState);
  }
}
