<div class="cards-container pb-2" (scroll)="onCardsScroll($event.target)" #scrollableContainer>
  @for (meter of metersByContract?.meters; track meter.deviceId) {
    <rs-web-consumption-dashboard-card
      [meter]="meter"
      (click)="navigateToDetails(meter.deviceId)"
    ></rs-web-consumption-dashboard-card>
  }
</div>

@if (metersByContract?.meters.length > 1) {
  <div class="mobile-controls">
    <rs-web-icon
      [ngClass]="{ hidden: activeCardIndex === 0 }"
      (click)="getPrevConsumption(true)"
      [icon]="immomioIconName.ChevronLeft"
      [size]="16"
    ></rs-web-icon>
    <div class="d-flex circle-container">
      @for (meter of metersByContract?.meters; track meter.deviceId; let index = $index) {
        <div class="circle" [ngClass]="{ active: activeCardIndex === index }"></div>
      }
    </div>
    <rs-web-icon
      [ngClass]="{ hidden: activeCardIndex === metersByContract?.meters.length - 1 }"
      (click)="getNextConsumption(true)"
      [icon]="immomioIconName.ChevronRight"
      [size]="16"
    ></rs-web-icon>
  </div>
}
