import { Directive, Input, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  GetEmergencyContacts,
  getEmergencyContactsActionState,
  getEmergencyContactsByContract,
} from '../../+state';
import { ContractId, IEmergencyContactsResponse, ImmomioIconName, LoadingType } from '../../models';
import { IActionState } from '../../utils';

@Directive()
export abstract class EmergencyContactsOverviewBaseComponent implements OnChanges {
  @Input() contract: ContractId;
  public emergencyContactsByContract$: Observable<Record<ContractId, IEmergencyContactsResponse>>;
  public emergencyContactActionState$: Observable<Record<ContractId, IActionState>>;
  public immomioIconName = ImmomioIconName;
  public loadingTypes: LoadingType[] = [];

  constructor(private store: Store) {}

  ngOnChanges() {
    this.store.dispatch(GetEmergencyContacts({ contract: this.contract }));
    this.emergencyContactsByContract$ = this.store.select(getEmergencyContactsByContract);
    this.emergencyContactActionState$ = this.store.select(getEmergencyContactsActionState);
  }
}
