import { Directive, EventEmitter, Input, Output } from '@angular/core';

import {
  ImmomioIconName,
  ITicketResidentOverview,
  ITicketResidentOverviewEdge,
} from '../../../models';
import { IActionState } from '../../../utils';

@Directive()
export abstract class TicketsBaseComponent {
  @Input() tickets: ITicketResidentOverviewEdge[] = [];
  @Input() ticketsActionState: IActionState;
  @Input() hasNextPage: boolean;
  @Output() openTicket: EventEmitter<ITicketResidentOverview> = new EventEmitter();
  @Output() loadMoreTickets: EventEmitter<void> = new EventEmitter();
  @Input() txtLoadMore = '';
  @Input() txtNoTickets = '';
  public immomioIconName = ImmomioIconName;
}
