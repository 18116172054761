import { Inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { AuthService } from '../../infrastructure';
import { RouterService } from '../general';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: RouterService,
    private authService: AuthService,
    @Inject('ENDPOINTS') private endpoints
  ) {}

  canActivate() {
    const authToken = this.authService.getToken();
    if (authToken?.accessToken) {
      return true;
    } else {
      void this.router.navigate([this.endpoints.AUTH]);
      return false;
    }
  }
}
