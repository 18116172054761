import { IPageInfo } from '../general';

export interface IFaq {
  uuid: string;
  title: string;
  text?: string;
  children?: IFaq[];
  link?: string;
  selected?: boolean;
}

export interface IPublishedFaqsResponseBean {
  edges: IPublishedFaqsResponseEdge[];
  pageInfo: IPageInfo;
}
export interface IPublishedFaqsResponseEdge {
  cursor: string;
  node: IFaq;
}

export interface IFaqOverviewResponseBean {
  faqGroups: IFaqGroupResponseBean[];
}

export interface IFaqGroupResponseBean {
  id: string;
  name: string;
  faqs: IFaqResponseBean[];
}

export interface IFaqResponseBean {
  id: string;
  uuid: string;
  title: string;
  text: string;
  href: string;
}
