import { Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive()
export abstract class TicketChatAccessButtonBaseComponent {
  @Input() title: string;
  @Input() buttonText: string;
  @Input() notification: number;
  @Input() className: string;
  @Output() tapped?: EventEmitter<boolean> = new EventEmitter();
}
