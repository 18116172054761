import { IEditAction, IResidentS3File } from '../general';
import { IRootQuestionTicketOverview, IScoreResponseData } from './ticket-questions.model';
import { TicketStatus } from './ticket-status.enum';
import { TicketIssueType } from './ticket-issue-type.enum';
import { ITicketCategory } from './ticket-category.model';
import { ScoreQuestionType } from './question-type.enum';

export interface ITicketTapResponse {
  ticketId: string;
  ticketIssueType: TicketIssueType;
}

export interface IHQTicketDetailsResponse {
  id: string;
  ticketNr: string;
  title: string;
  titleImage: IResidentS3File;
  state: TicketStatus;
  category: ITicketCategory;
  created: IEditAction;
  updated: IEditAction;
  questions: IRootQuestionResponseTicketOverviewBean;
  customerSpecificData: { key: string; value: string }[];
  issueType: TicketIssueType;
  unreadMessages: number;
}

export interface IRootQuestionResponseTicketOverviewBean {
  rootQuestion: IRootQuestionTicketOverview;
  responses: IHierarchicalQuestionTicketResponseOverviewBean[];
}

export interface IHierarchicalQuestionTicketResponseOverviewBean {
  questionId: string;
  data: ITicketDetailsScoreQuestionType;
}

export interface ITicketDetailsScoreQuestionType extends IScoreResponseData {
  answerIds?: string[];
  response?: number | string | IResidentS3File[];
  attachments?: IResidentS3File[];
}

export enum TicketDetailsState {
  OVERVIEW = 'OVERVIEW',
  SUMMARY = 'SUMMARY',
  TIMETABLE = 'TIMETABLE',
  CANCEL = 'CANCEL',
}

export interface ITicketDetailsSummary {
  question: string;
  textAnswer: string | null;
  dateAnswer: string | null;
  selectionAnswer: string | null;
  attachments: IResidentS3File[] | null;
  type: ScoreQuestionType;
  order: number;
}
