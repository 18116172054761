import { gql } from 'apollo-angular';

export const getSharedContentFields = `
    folderId
    folderName
    documents {
        id
        name
        extension
    }
    folders {
      id
      name
    }
    breadcrumbs {
        id
        name
    }
`;

export const getSharedContentQuery = gql`
  query getSharedContent($input: SharedFolderContentRequestBean!) {
    getSharedContent (input: $input) {
      ${getSharedContentFields}
    }
  }
`;
